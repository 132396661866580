import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AppFormsModule } from '@forms/forms.module';
import { TranslateModule } from '@ngx-translate/core';

import { BaseTextareaModule } from '@forms/base-textarea/base-textarea.module';
import { HtmlToTextPipe } from '@pipes/htmlToText/html-to-text.pipe';
import { BlockColumnComponent } from '@shared/blocks/block-columns/block-column/block-column.component';
import { BlockClusterComponent } from '@shared/blocks/block/block-cluster/block-cluster.component';
import { BlockImageComponent } from '@shared/blocks/block/block-image/block-image.component';
import { BlockNineFieldMatrixComponent } from '@shared/blocks/block/block-nine-field-matrix/block-nine-field-matrix.component';
import { BlockTextComponent } from '@shared/blocks/block/block-text/block-text.component';
import { BlockInstructionSettingsComponent } from '@shared/blocks/settings/block-instruction-settings/block-instruction-settings.component';
import { FileUploadModule } from '@shared/components/file-upload/file-upload.module';
import { LoaderContainerModule } from '@shared/components/loader-container/loader-container.module';
import { PhotoProfileModule } from '@shared/components/photo-profile/photo-profile.module';
import { ResizableElementModule } from '@shared/components/resizable-element/resizable-element.module';
import { TextEditorModule } from '@shared/components/text-editor/text-editor.module';
import { ModalActionsModule } from '@shared/modal/modal-actions/modal-actions.module';
import { BaseAutocompleteComponent } from '../../modules/forms/base-autocomplete/base-autocomplete.component';
import { MaterialModule } from '../material/material.module';
import { BlockColumnsComponent } from './block-columns/block-columns.component';
import { BlockIdComponent } from './block-id/block-id.component';
import { BlockEmbeddedComponent } from './block/block-embedded/block-embedded.component';
import { BlockInfoBoxComponent } from './block/block-info-box/block-info-box.component';
import { BlockInputSelectionComponent } from './block/block-input-selection/block-input-selection.component';
import { BlockInputComponent } from './block/block-input/block-input.component';
import { BlockListingComponent } from './block/block-listing/block-listing.component';
import { BlockQuestionComponent } from './block/block-question/block-question.component';
import { BlockReadonlyComponent } from './block/block-readonly/block-readonly.component';
import { BlockRoleInputComponent } from './block/block-role-input/block-role-input.component';
import { BlockSelectionComponent } from './block/block-selection/block-selection.component';
import { BlockSingleChoiceComponent } from './block/block-single-choice/block-single-choice.component';
import { BlockSliderComponent } from './block/block-slider/block-slider.component';
import { BlockTableAttributeComponent } from './block/block-table/block-table-attribute/block-table-attribute.component';
import { BlockTableViewComponent } from './block/block-table/block-table-view/block-table-view.component';
import { BlockTableComponent } from './block/block-table/block-table.component';
import { BlockVideoComponent } from './block/block-video/block-video.component';
import { BlockWrapperComponent } from './block/block-wrapper/block-wrapper.component';
import { BlockComponent } from './block/block.component';
import { BlocksContainerComponent } from './blocks-container/blocks-container.component';
import { MediaUploadModalComponent } from './modals/media-upload-modal/media-upload-modal.component';
import { BlockCqlSettingsComponent } from './settings/block-cql-settings/block-cql-settings.component';
import { BlockPlaybookSettingsComponent } from './settings/block-playbook-settings/block-playbook-settings.component';
import { BlockStylesSettingsComponent } from './settings/block-styles-settings/block-styles-settings.component';
import { SettingsComponent } from './settings/settings.component';

@NgModule({
  declarations: [
    BlocksContainerComponent,
    BlockColumnComponent,
    BlockComponent,
    BlockTextComponent,
    BlockImageComponent,
    BlockColumnsComponent,
    BlockWrapperComponent,
    BlockVideoComponent,
    BlockEmbeddedComponent,
    BlockInfoBoxComponent,
    BlockTableComponent,
    BlockSingleChoiceComponent,
    BlockInputComponent,
    MediaUploadModalComponent,
    SettingsComponent,
    BlockSingleChoiceComponent,
    BlockSelectionComponent,
    BlockSliderComponent,
    BlockQuestionComponent,
    BlockRoleInputComponent,
    BlockInputSelectionComponent,
    BlockIdComponent,
    BlockReadonlyComponent,
    BlockCqlSettingsComponent,
    BlockPlaybookSettingsComponent,
    BlockStylesSettingsComponent,
    BlockNineFieldMatrixComponent,
    BlockListingComponent,
    BlockClusterComponent,
    BlockTableAttributeComponent,
    BlockTableViewComponent
  ],
  exports: [BlocksContainerComponent, BlockComponent, BlockWrapperComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TextEditorModule,
    TranslateModule,
    HtmlToTextPipe,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    MatTabsModule,
    AppFormsModule,
    ModalActionsModule,
    FileUploadModule,
    ResizableElementModule,
    LoaderContainerModule,
    PhotoProfileModule,
    BaseTextareaModule,
    BlockInstructionSettingsComponent,
    BaseAutocompleteComponent
  ]
})
export class BlocksModule {}
