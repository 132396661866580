<div class="base-boolean-field">
  <ng-container [class]="'' + classFormField" [ngSwitch]="type">
    <mat-checkbox
      class="base-boolean-field__field base-boolean-field__field_checkbox"
      color="primary"
      [disableRipple]="true"
      *ngSwitchCase="BOOLEAN_FIELD_TYPE.checkbox"
      [formControl]="formControl"
      (change)="change($event)"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </mat-checkbox>
    <mat-slide-toggle
      class="base-boolean-field__field base-boolean-field__field_toggle"
      color="primary"
      *ngSwitchCase="BOOLEAN_FIELD_TYPE.toggle"
      [formControl]="formControl"
      (change)="change($event)"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </mat-slide-toggle>
  </ng-container>
  <mat-error class="base-boolean-field__error" *ngIf="isShowError && control?.invalid && control?.touched && errorMessage">
    <span>{{ errorMessage }}</span>
  </mat-error>
</div>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
