import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, computed, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import { debounceTime, Observable } from 'rxjs';

import { BooleanFieldType } from '@base/common/modules/forms/base-boolean-field/base-boolean-field.component';
import { getBlockIdentifier } from '@misc/helpers';
import { getModuleElementPath } from '@misc/helpers/module-helpers';
import { ISegmentAnswerValue, SegmentAnswer } from '@models/classes/module-answer.model';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { AppIcon } from '@models/enums/app.icons';
import { InputType } from '@models/enums/input-type.enum';
import { HtmlToTextPipe } from '@pipes/htmlToText/html-to-text.pipe';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import {
  BlockClusterAnswerModalComponent,
  IBlockClusterModalContext
} from '@shared/blocks/block/block-cluster/block-cluster-answer-modal/block-cluster-answer-modal.component';
import { BlockAnswerOption, BlockCluster, BlockClusterColumn, BlockClusterOption, BlockType } from '@shared/blocks/models/block.model';
import { ModalService } from '@shared/modal/modal.service';

export const clusterColumnsDefaultCount: number = 5;

export enum BlockClusterAnswerId {
  CUSTOM_OPTIONS = 'customOptions',
  SELECTED_OPTIONS = 'selectedOptions'
}

@Component({
  selector: 'app-block-cluster',
  templateUrl: './block-cluster.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-cluster.component.scss'],
  providers: [HtmlToTextPipe]
})
export class BlockClusterComponent extends BaseBlockComponent<BlockCluster> implements OnInit {
  readonly pageKey: string = 'BLOCK.CLUSTER.';
  readonly AppIcon = AppIcon;
  readonly BooleanFieldType = BooleanFieldType;
  readonly inputType = InputType;
  readonly minColumnsCount: number = 2;
  readonly maxColumnsCount: number = 6;
  readonly columnsCountControl: FormControl<number> = new FormControl<number>(clusterColumnsDefaultCount, [
    Validators.min(this.minColumnsCount),
    Validators.max(this.maxColumnsCount)
  ]);
  readonly customOptionControl: FormControl<string> = new FormControl<string>('');
  readonly dynamicOptionsBlockIdControl: FormControl<string> = new FormControl<string>('');
  readonly dynamicOptionsToggle: FormControl<boolean> = new FormControl<boolean>(false);
  readonly customOptionToggle: FormControl<boolean> = new FormControl<boolean>(false);
  readonly newOptionName: WritableSignal<string> = signal('');
  readonly dynamicOptionsBlockId: Signal<string> = toSignal(this.dynamicOptionsBlockIdControl.valueChanges);
  readonly dynamicOptionsProvider: Signal<ModuleSegment> = computed(() => {
    return getModuleElementPath(this._moduleService.module(), this.dynamicOptionsBlockId()).segment?.item;
  });
  readonly isDynamicOptionsBlockIdValid: Signal<boolean> = computed(() => {
    const segment: ModuleSegment = this.dynamicOptionsProvider();
    return this.dynamicOptionsBlockId() && segment && [BlockType.SELECTION, BlockType.LISTING].includes(segment.template);
  });

  readonly initialOptions: WritableSignal<BlockClusterOption[]> = signal([]);
  readonly selectedOptions: WritableSignal<BlockAnswerOption[]> = signal([]);
  readonly columnColorWhiteOverride: WritableSignal<boolean> = signal(false);
  private readonly _translate: TranslateService = inject(TranslateService);
  private readonly _htmlToText: HtmlToTextPipe = inject(HtmlToTextPipe);
  private readonly _modalService: ModalService = inject(ModalService);

  ngOnInit(): void {
    this._initDynamicOptionsControls();
    this._setInitialSettingsControls();
    this._setColumns();
    this._setOptions();
  }

  addOption(): void {
    this.block.options.push(
      plainToInstance(BlockClusterOption, {
        id: getBlockIdentifier(),
        value: this.newOptionName()
      } as BlockClusterOption)
    );
    this.newOptionName.set('');
    this.save();
  }

  addCustomOption(): void {
    this.initialOptions.set([
      ...this.initialOptions(),
      plainToInstance(BlockClusterOption, {
        id: getBlockIdentifier(),
        value: this.customOptionControl.value,
        isCustom: true
      } as BlockClusterOption)
    ]);
    this.customOptionControl.setValue('');
    this._saveAnswer();
  }

  isCustomOptionSelected(option: BlockClusterOption): boolean {
    return this.selectedOptions().some((selected: BlockAnswerOption) => selected.groupId === option.id);
  }

  deleteOption(idx: number): void {
    this.block.options.splice(idx, 1);
    this.save();
  }

  deleteAnswerOption(option: BlockAnswerOption): void {
    this.selectedOptions.set(this.selectedOptions().filter((selected: BlockAnswerOption) => selected.id !== option.id));
    this._saveAnswer();
    this._fillColumns();
  }

  deleteCustomOption(option: BlockClusterOption): void {
    this.initialOptions.set(this.initialOptions().filter((initial: BlockClusterOption) => initial.id !== option.id));
    this._saveAnswer();
    this._fillColumns();
  }

  onDropOption(event: CdkDragDrop<BlockAnswerOption[]>, column: BlockClusterColumn): void {
    if (!event.isPointerOverContainer) return;
    if (event.previousContainer !== event.container) {
      if (event.item.data.groupId) {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        event.item.data.columnId = column.id;
        this._saveAnswer();
        this._fillColumns();
      } else {
        this._openAnswerModal(
          {
            id: getBlockIdentifier(),
            groupId: event.item.data.id
          },
          event.item.data,
          column
        ).subscribe((answer: BlockAnswerOption): void => {
          answer.columnId = column.id;
          this.selectedOptions.set([...this.selectedOptions(), answer]);
          this._saveAnswer();
          this._fillColumns();
        });
      }
    }
  }

  getOptionGroup(groupId: string): BlockClusterOption {
    return this.initialOptions().find((item: BlockClusterOption) => item.id === groupId);
  }

  closeSetting(): void {
    this._setInitialSettingsControls();
    this._setColumns();
  }

  saveSetting(): void {
    this.block.columnsCount = this.columnsCountControl.value;
    this.block.isDynamicOptionsEnabled = this.dynamicOptionsToggle.value;
    this.block.isCustomOptionEnabled = this.customOptionToggle.value;
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this._setColumns();
    this.save();
  }

  getColumnValueById(columnId: string): string {
    return this.block.columns.find(column => column.id === columnId)?.value;
  }

  private _saveAnswer(): void {
    if (this.isEditMode || !this.isUserScope || this.isUserInputDisabled) return;

    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: [
        {
          subId: BlockClusterAnswerId.CUSTOM_OPTIONS,
          value: this.initialOptions()
            .filter((item: BlockClusterOption) => item.isCustom)
            .map((item: BlockClusterOption) => ({
              subId: item.id,
              value: item.value
            }))
        },
        {
          subId: BlockClusterAnswerId.SELECTED_OPTIONS,
          value: this.selectedOptions().map((item: BlockAnswerOption) => ({
            subId: item.id,
            value: item.value,
            meta: {
              description: item.description,
              columnId: item.columnId,
              groupId: item.groupId
            } as Partial<BlockAnswerOption>
          }))
        }
      ]
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }

  private _setColumns(): void {
    const columnsCount: number = this.block.columns?.length || 0;
    const settingsColumnsCount: number = +this.columnsCountControl.value || 0;

    if (columnsCount !== settingsColumnsCount) {
      this.block.columns = Array.from(
        { length: settingsColumnsCount },
        (_: unknown, idx: number) =>
          this.block.columns[idx] ||
          plainToInstance(BlockClusterColumn, {
            id: getBlockIdentifier(),
            isActive: true
          } as BlockClusterColumn)
      );
    }
    // bg has same color as columns making it invisible. set it to white instead
    this.columnColorWhiteOverride.set(this.isUserInputDisabled && this.blockStyleSettings?.bgColor === '#F4F6F7');
  }

  private _initDynamicOptionsControls(): void {
    this.dynamicOptionsBlockIdControl.setValue(this.block.dynamicOptionsBlockId);
    this.dynamicOptionsBlockIdControl.valueChanges.pipe(debounceTime(300)).subscribe((blockId: string): void => {
      if (this.isDynamicOptionsBlockIdValid()) {
        this.block.dynamicOptionsBlockId = blockId;
        this.save();
      }
    });
    this._moduleService.answerUpdated$.subscribe(() => this._setOptions());
  }

  private _getDynamicOptions(answer: SegmentAnswer): BlockClusterOption[] {
    return answer?.content
      ? answer.content.map((item: ISegmentAnswerValue) =>
          plainToInstance(BlockClusterOption, {
            id: item.subId,
            value: item.value
          } as BlockClusterOption)
        )
      : [];
  }

  private _setOptions(): void {
    this.initialOptions.set(
      this.block.isDynamicOptionsEnabled && this.isDynamicOptionsBlockIdValid()
        ? this._getDynamicOptions(this.dynamicOptionsProvider()?.answer?.answer)
        : this.block.options.map((item: BlockClusterOption) => plainToInstance(BlockClusterOption, item))
    );

    if (this.segmentAnswer?.content) {
      const [customOptions, selectedOptions]: ISegmentAnswerValue<any>[] = this.segmentAnswer.content;

      this.initialOptions.set(
        this.initialOptions().concat(
          (customOptions.value as ISegmentAnswerValue[]).map(
            (item: ISegmentAnswerValue<Partial<BlockClusterOption>>): BlockClusterOption => ({
              id: item.subId,
              value: item.value as string,
              isCustom: true
            })
          )
        )
      );
      this.selectedOptions.set(
        (selectedOptions.value as ISegmentAnswerValue[]).map(
          (item: ISegmentAnswerValue<Partial<BlockAnswerOption>>): BlockAnswerOption => ({
            id: item.subId,
            value: item.value as string,
            description: item.meta.description,
            columnId: item.meta.columnId,
            groupId: item.meta.groupId
          })
        )
      );
    }

    this._fillColumns();
  }

  private _fillColumns(): void {
    this.block.columns.forEach((column: BlockClusterColumn) => {
      column.options = this.selectedOptions().filter((selected: BlockAnswerOption) => selected.columnId === column.id) || [];
    });
  }

  private _openAnswerModal(
    answer: Partial<BlockAnswerOption>,
    option: BlockClusterOption,
    column: BlockClusterColumn
  ): Observable<BlockAnswerOption> {
    return this._modalService.open(
      {
        component: BlockClusterAnswerModalComponent,
        title: this._translate.instant(`${this.pageKey}MODAL_TITLE`, { name: this._htmlToText.transform(column.value) }),
        actions: [
          { name: 'BUTTON_NAME.CANCEL', type: 'close', color: 'accent' },
          { name: 'BUTTON_NAME.SAVE', type: 'submit', color: 'primary' }
        ],
        context: { entity: { answer, option } as IBlockClusterModalContext }
      },
      { maxWidth: '45rem' }
    );
  }

  private _setInitialSettingsControls(): void {
    this.columnsCountControl.setValue(this.block.columnsCount);
    this.dynamicOptionsToggle.setValue(this.block.isDynamicOptionsEnabled);
    this.customOptionToggle.setValue(this.block.isCustomOptionEnabled);
  }
}
