import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-soon-card',
  templateUrl: './soon-card.component.html',
  styleUrls: ['./soon-card.component.scss']
})
export class SoonCardComponent {
  // TODO: add type
  @Input() item: Params;
}
