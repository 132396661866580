export enum DATE_FORMAT {
  DM = 'dd.MM',
  DMY = 'dd.MM.yyyy',
  TIME = 'HH:mm',
  FULL = 'dd.MM.yyyy - HH:mm',
  MEDIUM = 'd MMMM YYYY'
}
export const SUPPORT_TYPES_IMAGE_CROPPER = ['png', 'jpeg', 'bmp', 'webp', 'ico'];
export const PER_PAGE_DEFAULT: number = 10;
export const TIME_SHOW_MESSAGE: number = 3000;
export const DEFAULT_POSITION: number = 1;
export const IMAGE_MAX_WIDTH: number = 1200;
export const MAX_VIDEO_WIDTH: number = 1280;
