import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { VALIDATORS_SET } from '@misc/constants/validators-set.constant';
import { difference } from '@misc/helpers/difference.function';
import { CognitoAttributeName, User } from '@models/classes/user.model';
import { CognitoService } from '@services/cognito/cognito.service';
import { LoaderService } from '@services/loader/loader.service';
import { IModalComponentContext, ModalComponent } from '@shared/modal/modal.component';

enum EmailChangeStep {
  firstStep = 'firstStep',
  secondStep = 'secondStep'
}

@Component({
  selector: 'app-email-change-modal',
  templateUrl: './email-change-modal.component.html',
  styleUrls: ['./email-change-modal.component.scss']
})
export class EmailChangeModalComponent extends BaseFormAbstractComponent implements OnInit {
  @Input() context: IModalComponentContext<User>;
  activeStep: EmailChangeStep = EmailChangeStep.firstStep;
  emailChangeStep: typeof EmailChangeStep = EmailChangeStep;

  get user(): User {
    return this.context?.entity;
  }

  get defaultValues(): User {
    return {} as User;
  }

  get dialog(): MatDialogRef<ModalComponent<User>> {
    return this.context?.dialog;
  }

  get firstForm(): {
    [key: string]: AbstractControl<any, any>;
  } {
    return this.getGroup(this.emailChangeStep.firstStep).controls;
  }

  get secondForm(): {
    [key: string]: AbstractControl<any, any>;
  } {
    return this.getGroup(this.emailChangeStep.secondStep).controls;
  }

  constructor(
    private _translate: TranslateService,
    private _fb: FormBuilder,
    private _loaderService: LoaderService,
    private _cognitoService: CognitoService,
    private _translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  getModalResult(): User {
    return difference(this.formGroup.getRawValue(), this.defaultValues) as User;
  }

  onSubmit(): void {
    if (this.getGroup(EmailChangeStep.firstStep).invalid) {
      return;
    }

    if (this.activeStep == EmailChangeStep.firstStep) {
      this.secondStep();
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.dialog.close(this.getModalResult());
  }

  private _initForm(): void {
    this.formGroup = this._fb.group({
      firstStep: this._fb.group({
        email: ['', Validators.compose([Validators.required, VALIDATORS_SET.EMAIL])]
      }),
      secondStep: this._fb.group({
        code: ['', Validators.compose([Validators.required])]
      })
    });
  }

  async secondStep(): Promise<void> {
    this._loaderService.on();

    await this._cognitoService.updateAttributes([
      { Name: CognitoAttributeName.EMAIL, Value: this.firstForm.email.value as string }
    ] as CognitoUserAttribute[]);

    this._loaderService.off();

    this.formGroup.markAsUntouched();
    this.dialog.componentInstance.data = {
      component: EmailChangeModalComponent,
      title: 'MODALS.VERIFICATION_EMAIL_HEADER',
      text: this._translateService.instant('MODALS.VERIFICATION_EMAIL_TEXT', { email: this.firstForm.email.value }),
      actions: [
        { name: 'BUTTON_NAME.CANCEL', type: 'close', color: 'accent' },
        { name: 'BUTTON_NAME.VERIFY', type: 'submit', color: 'primary' }
      ]
    };
    this.activeStep = EmailChangeStep.secondStep;
  }
}
