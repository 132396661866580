<div class="autocomplete">
  <div [class.autocomplete__input-wrap_opened]="isOpened && filteredSearchList?.length" class="autocomplete__input-wrap">
    <input
      #inputRef
      (blur)="handleFocusOut()"
      (focus)="isOpened = true"
      (input)="search$.next(inputRef.value)"
      [placeholder]="placeholder"
      autocomplete="disabled"
      class="autocomplete__input"
      type="text"
    />
    <ul *ngIf="isOpened && filteredSearchList?.length" @fadeInOut class="autocomplete__search-list">
      <li (click)="selectItem(item)" *ngFor="let item of filteredSearchList" class="autocomplete__list-item" tabindex="0">
        <ng-container *ngTemplateOutlet="listItemTemplate; context: { $implicit: item }"></ng-container>
      </li>
    </ul>
  </div>
  <ul class="autocomplete__selected-list">
    <li *ngFor="let item of selectedList" @fadeInOut class="autocomplete__list-item">
      <ng-container *ngTemplateOutlet="listItemTemplate; context: { $implicit: item, isSelectedList: true }"></ng-container>
      <button (click)="removeItem(item)" class="autocomplete__list-remove" mat-icon-button>
        <mat-icon [svgIcon]="AppIcon.CLOSE"></mat-icon>
      </button>
    </li>
  </ul>
</div>
