import { VerticalAlignment } from '@base/models/enums/vertical-alignment.enum';
import { BlockStyleSettings } from './block-style-settings.model';

export enum BlockColumnType {
  TEXT = 'text',
  IMAGE = 'image'
}

export class BlockColumn {
  id?: string;
  type: BlockColumnType;
  position: number;
  styleSettings?: BlockStyleSettings;
}

export class BlockColumnText extends BlockColumn {
  override type: BlockColumnType.TEXT;
  content: string;
  alignment?: VerticalAlignment;
}

export class BlockColumnImage extends BlockColumn {
  override type: BlockColumnType.IMAGE;
  fileId: string;
  fileUrl: string;
  originalId: string;
  originalUrl: string;
  alignment?: VerticalAlignment;
}

export type AnyBlockColumn = BlockColumnText | BlockColumnImage;
