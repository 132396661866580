import { Exclude, Expose, Transform } from 'class-transformer';
import { ModuleSite } from './module-site.model';
import { ModuleElement } from '@models/classes/module-element.model';
import { transformToModelsArray } from '@misc/helpers/model-conversion/transform-helpers/transform-to-models-array.function';
import { AbstractModel } from './_base.model';

@Exclude()
export class ModuleStep extends ModuleElement {
  @Expose()
  @Transform(transformToModelsArray(ModuleSite))
  sites: ModuleSite[];
  @Expose()
  value?: string;
  @Expose()
  section?: AbstractModel;
}
