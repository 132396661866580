<loader-container [isLoading]="isLoading()" [spinnerSize]="50">
  <div
    [ngStyle]="!isEditMode && blockViewStyles"
    (click)="isEditMode && openDialog($event)"
    [class.view-mode]="!isEditMode"
    class="block-embedded"
    tabindex="0"
  >
    <app-resizable-element
      (sizeChanged)="handleSizeChange($event)"
      *ngIf="block.fileUrl; else emptyVideo"
      [hideControls]="!isEditMode"
      [height]="block.height"
    >
      <iframe *ngIf="safeUrl; else emptyVideo" [src]="safeUrl" class="block-embedded__frame"></iframe>
    </app-resizable-element>

    <ng-template #emptyVideo>
      <mat-icon [svgIcon]="AppIcon.CODE" class="block-embedded__icon"></mat-icon>
      <p class="block-embedded__label">{{ placeholder | translate }}</p>
    </ng-template>
  </div>
</loader-container>

<ng-template #settingTemplate>
  <app-settings [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.EMBEDDED' | translate)" (save)="saveSetting()">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook" [disabled]="true"></app-block-playbook-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
