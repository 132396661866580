import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { UserRole } from '@models/enums/user-role.enum';
import { RoleSwitcherService } from '@services/role-switcher/role-switcher.service';

@Directive({
  selector: '[showForRoles]'
})
export class ShowForRolesDirective {
  private readonly _templateRef = inject(TemplateRef<any>);
  private readonly _viewContainer = inject(ViewContainerRef);
  private readonly _roleSwitcher = inject(RoleSwitcherService);

  private _roles: UserRole[];
  private _viewRef: EmbeddedViewRef<any>;

  @Input() set showForRoles(roles: UserRole[]) {
    this._roles = roles;
    this._renderView();
  }

  get currentRole(): UserRole {
    return this._roleSwitcher.selectedRole;
  }

  constructor() {
    this._roleSwitcher.SELECTED_ITEM$.pipe(takeUntilDestroyed()).subscribe(this._renderView.bind(this));
  }

  private _renderView(): void {
    if (this._viewRef) {
      this._viewRef.destroy();
      this._viewRef = null;
    }
    if (!this._roles?.length || this._roles.find((role: UserRole) => this.currentRole === role)) {
      this._viewRef = this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }
}
