import { Exclude, Expose } from 'class-transformer';
import { AbstractModel } from './_base.model';
import { User } from './user.model';
import { ModuleElementType } from '@models/enums/module-element-type';

@Exclude()
export class ModuleElement extends AbstractModel {
  @Expose({ toClassOnly: true })
  slug?: string;
  @Expose()
  name: string;
  @Expose({ toClassOnly: true })
  version?: number;
  @Expose({ toClassOnly: true })
  publishedAt?: Date;
  @Expose({ toClassOnly: true })
  updatedBy?: User;
  @Expose({ toClassOnly: true })
  author?: string;
  @Expose({ toClassOnly: true })
  authorName?: string;
  @Expose({ toClassOnly: true })
  type?: ModuleElementType;
  @Expose({ toClassOnly: true })
  isReadOnly?: boolean;
  @Expose({ toClassOnly: true })
  parentId?: string;
  @Expose()
  position?: string;
  @Expose()
  parent?: AbstractModel;
  @Expose()
  cql?: string;
}
