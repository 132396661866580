import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs';

import { MetaTypeApiService } from '@services/api/admin/meta-type/meta-type-api.service';

@Injectable({
  providedIn: 'root'
})
export class MetaTypeService {
  private readonly _metaTypeApiService: MetaTypeApiService = inject(MetaTypeApiService);

  readonly metaTypes = this._metaTypeApiService.getItems().pipe(shareReplay());
}
