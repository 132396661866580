import { Component, inject, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { AppIcon } from '@base/models/enums/app.icons';
import { LoomLinkParser, YoutubeLinkParser } from '@misc/helpers/video-link-parsers';
import { ModuleMedia } from '@models/classes/module-media.model';
import { FileType } from '@models/enums/file-type.enum';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { IMediaBlockData, MediaUploadModalComponent } from '@shared/blocks/modals/media-upload-modal/media-upload-modal.component';
import { BlockVideo } from '@shared/blocks/models/block.model';
import { IResizableResult } from '@shared/components/resizable-element/resizable-element.component';
import { ModalService } from '@shared/modal/modal.service';

@Component({
  selector: 'app-block-video',
  templateUrl: './block-video.component.html',
  styleUrls: ['./block-video.component.scss']
})
export class BlockVideoComponent extends BaseBlockComponent<BlockVideo> implements OnChanges {
  placeholder: string = 'MODULES.VIDEO_BLOCK_PLACEHOLDER';
  videoSafeUrl: SafeUrl;
  isCustomVideo: boolean;
  readonly AppIcon = AppIcon;
  private readonly _modalService: ModalService = inject(ModalService);
  private readonly _sanitizer: DomSanitizer = inject(DomSanitizer);

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block) {
      this._refreshMediaUrl();
    }
  }

  saveSetting(): void {
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  override openDialog(e: Event): void {
    super.openDialog(e);

    this._modalService
      .open<IMediaBlockData>(
        {
          component: MediaUploadModalComponent,
          context: {
            entity: {
              id: this.block.id,
              type: FileType.video
            }
          }
        },
        {
          maxWidth: '53rem',
          panelClass: 'no-actions'
        }
      )
      .subscribe(({ media }: IMediaBlockData): void => {
        this.block.fileId = media.id;
        this.block.fileUrl = media.src;
        this._setSafeUrl(this.block.fileUrl);
        this.save();
      });
  }

  handleSizeChange({ width }: IResizableResult): void {
    this.block.width = width;
    this.save();
  }

  private _refreshMediaUrl(): void {
    if (this.block.fileId) {
      this.getMediaSignedUrl(this.block.fileId).subscribe((res: ModuleMedia): void => {
        this.block.fileUrl = res.src;
        this._setSafeUrl(this.block.fileUrl);
      });
    } else {
      this._setSafeUrl(this.block.fileUrl);
    }
  }

  private _setSafeUrl(unsafe: string): void {
    this.isCustomVideo = new YoutubeLinkParser(unsafe).isMatched() || new LoomLinkParser(unsafe).isMatched();
    this.videoSafeUrl = this.isCustomVideo
      ? this._sanitizer.bypassSecurityTrustResourceUrl(unsafe)
      : this._sanitizer.bypassSecurityTrustUrl(unsafe);
  }
}
