<div class="photo-profile">
  <img *ngIf="img; else empty" class="photo-profile__img" [src]="img" alt="Photo profile image" />
  <ng-template #empty>
    <div class="photo-profile__img-empty" (click)="uploadImage()" tabindex="0">
      {{ defaultLabel }}
    </div>
  </ng-template>
  <div class="photo-profile__buttons" *ngIf="isEdit">
    <button mat-icon-button class="photo-profile__button" type="button" (click)="uploadImage()">
      <mat-icon class="photo-profile__icon-btn" [svgIcon]="AppIcon.CAMERA"></mat-icon>
    </button>
    <button mat-icon-button class="photo-profile__button" type="button" *ngIf="img" (click)="remove.emit()">
      <mat-icon class="photo-profile__icon-btn photo-profile__icon-btn-trash" [svgIcon]="AppIcon.TRASH"></mat-icon>
    </button>
  </div>
</div>
