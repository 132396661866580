<div *ngIf="isInitialized" class="image-cropper-modal">
  <mat-progress-bar *ngIf="isLoading" [mode]="'indeterminate'" class="image-cropper-modal__progress"></mat-progress-bar>
  <div>
    <image-cropper
      *ngIf="!isLoading"
      [cropper]="cropper"
      [imageURL]="imageSrc"
      [maintainAspectRatio]="false"
      [transform]="transform"
      [canvasRotation]="canvasRotation"
      (imageCropped)="imageCropped($event)"
      output="base64"
      class="image-cropper-modal__cropper image-cropper image-cropper_edit"
      [format]="typeImageCropper"
    ></image-cropper>

    <div class="image-cropper-modal__buttons-section">
      <div class="image-cropper-modal__container">
        <p class="image-cropper-modal__container-heading">{{ 'MODALS.IMAGE_CROPPER_FILE' | translate }}</p>
        <div class="image-cropper-modal__buttons">
          <button mat-icon-button (click)="upload()">
            <mat-icon [svgIcon]="AppIcon.UPLOAD"></mat-icon>
          </button>
        </div>
      </div>
      <div class="image-cropper-modal__container">
        <p class="image-cropper-modal__container-heading">{{ 'MODALS.IMAGE_CROPPER_VIEW' | translate }}</p>
        <div class="image-cropper-modal__buttons">
          <button mat-icon-button (click)="zoomIn()">
            <mat-icon [svgIcon]="AppIcon.ZOOM_MAX_BTN"></mat-icon>
          </button>
          <button mat-icon-button (click)="zoomOut()" [disabled]="scale <= 0">
            <mat-icon [svgIcon]="AppIcon.ZOOM_MIN_BTN"></mat-icon>
          </button>
        </div>
      </div>
      <div class="image-cropper-modal__container">
        <p class="image-cropper-modal__container-heading">{{ 'MODALS.IMAGE_CROPPER_DISPLAY' | translate }}</p>
        <div class="image-cropper-modal__buttons">
          <button mat-icon-button (click)="rotateLeft()">
            <mat-icon [svgIcon]="AppIcon.ROTATE_BTN"></mat-icon>
          </button>
          <button mat-icon-button (click)="crop()">
            <mat-icon [svgIcon]="AppIcon.CROP_BTN"></mat-icon>
          </button>
          <button mat-icon-button (click)="center()">
            <mat-icon [svgIcon]="AppIcon.CENTER_BTN"></mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="hasAlignment" class="image-cropper-modal__container">
        <p class="image-cropper-modal__container-heading">{{ 'MODALS.IMAGE_CROPPER_ALIGNMENT' | translate }}</p>
        <div class="image-cropper-modal__buttons">
          <button mat-icon-button [class.image-cropper-modal__selected]="alignment === 'top'" (click)="getImagePosition('top')">
            <mat-icon [svgIcon]="AppIcon.TEXT_TOP"></mat-icon>
          </button>
          <button mat-icon-button [class.image-cropper-modal__selected]="alignment === 'center'" (click)="getImagePosition('center')">
            <mat-icon [svgIcon]="AppIcon.TEXT_CENTER"></mat-icon>
          </button>
          <button mat-icon-button [class.image-cropper-modal__selected]="alignment === 'bottom'" (click)="getImagePosition('bottom')">
            <mat-icon [svgIcon]="AppIcon.TEXT_BOTTOM"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
