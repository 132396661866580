import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { ModuleSegment } from '@models/classes/module-segment.model';

@Injectable({
  providedIn: 'root'
})
export class SegmentsApiService extends ApiBaseAbstractService<ModuleSegment> {
  protected readonly _URL_PATH: string = '/admin/modules';
  protected override _URL_KEY: string = 'segments';
  protected readonly _MODEL: ClassConstructor<ModuleSegment> = ModuleSegment;
}
