import { Exclude, Expose, Transform, instanceToPlain, plainToInstance } from 'class-transformer';

import { ModuleElement } from '@models/classes/module-element.model';
import { AnyBlock, BlockType, BlockTypeInstanceMap, DEFAULT_BLOCK_TYPE } from '@shared/blocks/models/block.model';
import { ModuleAnswer } from './module-answer.model';
import { AbstractModel } from './_base.model';

@Exclude()
export class ModuleSegment extends ModuleElement {
  @Expose()
  @Transform(
    ({ value, obj }) => {
      let block: AnyBlock;

      try {
        block = JSON.parse(value) || {};
      } catch (err) {
        block = value || {};
      }

      return plainToInstance(BlockTypeInstanceMap[(obj.template as BlockType) || DEFAULT_BLOCK_TYPE], block);
    },
    { toClassOnly: true }
  )
  @Transform(({ value }) => JSON.stringify(instanceToPlain(value)), { toPlainOnly: true })
  value: AnyBlock;

  @Expose()
  @Transform(({ value }) => value || DEFAULT_BLOCK_TYPE)
  template: BlockType;

  @Expose()
  @Transform(({ value }) => plainToInstance(ModuleAnswer, value), { toClassOnly: true })
  @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
  answer?: ModuleAnswer;

  @Expose()
  isInPlaybook: boolean;

  @Expose()
  site?: AbstractModel;
}
