import { ModuleSection } from '@models/classes/module-section.model';
import { Module } from '@models/classes/module.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { ModuleSite } from '@models/classes/module-site.model';
import { ModuleSegment } from '@models/classes/module-segment.model';

export function foreachModuleElement(
  module: Module,
  sectionCb?: (item: ModuleSection) => void | boolean,
  stepCb?: (item: ModuleStep) => void | boolean,
  siteCb?: (item: ModuleSite) => void | boolean,
  segmentCb?: (item: ModuleSegment) => void | boolean
): void {
  for (const section of module.sections) {
    if (sectionCb && sectionCb(section)) return;

    for (const step of section.steps) {
      if (stepCb && stepCb(step)) return;

      for (const site of step.sites) {
        if (siteCb && siteCb(site)) return;

        for (const segment of site.segments) {
          if (segmentCb && segmentCb(segment)) return;
        }
      }
    }
  }
}
