<div [class.view-mode]="!isEditMode" [ngStyle]="!isEditMode && blockViewStyles" class="block-single-choice">
  <div class="block-common__title">{{ 'BLOCK.SINGLE_CHOICE.TITLE' | translate }}</div>
  <app-text-editor
    (contentChanged)="block.question = $event; save()"
    [content]="block.question"
    [isViewMode]="!isEditMode"
    class="block-common__field"
  ></app-text-editor>

  <div class="block-common__title">{{ 'BLOCK.SINGLE_CHOICE.SELECTION' | translate }}</div>
  <div
    [ngClass]="{
      'block-single-choice__answers_only-two-items': answers.length === 2
    }"
    class="block-single-choice__answers"
  >
    <div
      (click)="selectAnswer(answer)"
      *ngFor="let answer of answers"
      [ngClass]="{
        'block-single-choice__answer-card_selected': selectedAnswer === answer,
        'block-single-choice__answer-card_disabled': isUserInputDisabled
      }"
      class="block-single-choice__answer-card"
      tabindex="0"
    >
      <div *ngIf="isEditMode" class="block-common__option-wrap">
        <app-block-id [blockID]="[block.id, answer.id]"></app-block-id>
        <div class="block-common__field block-single-choice__answer">
          <app-text-editor
            (contentChanged)="answer.content = $event; save()"
            [content]="answer.content"
            [isViewMode]="!isEditMode"
          ></app-text-editor>
        </div>
      </div>
      <ng-container *ngIf="!isEditMode">
        <div class="block-single-choice__answer-icon">
          <mat-icon [svgIcon]="AppIcon.BLOCK_SINGLE_CHOICE_CHECKBOX" class="block-single-choice__answer-icon-checkbox"></mat-icon>
        </div>
        <div class="block-single-choice__answer">
          <app-text-editor [content]="answer.content" [isViewMode]="!isEditMode"></app-text-editor>
        </div>
      </ng-container>
    </div>
  </div>

  <div [class.mt-3]="isEditMode" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
    <div *ngIf="isEditMode" class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.instruction = $event; save()"
      [content]="block.instruction"
      [class.view-mode]="!isEditMode"
      [isViewMode]="!isEditMode"
      class="block-common__field block-common__field_last"
    ></app-text-editor>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.SINGLE_CHOICE' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.SINGLE_CHOICE.SETTINGS.ANSWER_COUNT_FIELD' | translate }}</span>
      </h4>
      <base-form-input [control]="answerCountControl" [hideLabel]="true" [inputType]="InputType.number" max="6" min="2"></base-form-input>
    </div>
    <mat-divider></mat-divider>
    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
