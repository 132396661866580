import { inject, Pipe, PipeTransform, Renderer2 } from '@angular/core';

@Pipe({
  name: 'htmlToText',
  standalone: true
})
export class HtmlToTextPipe implements PipeTransform {
  private readonly _renderer: Renderer2 = inject(Renderer2);

  transform(value: string): string {
    if (!value) return '';
    const elem: HTMLSpanElement = this._renderer.createElement('span');
    elem.innerHTML = value;
    const { textContent } = elem;
    elem.remove();
    return (textContent || '').trim();
  }
}
