import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetNewPasswordComponent } from './set-new-password.component';
import { AppFormsModule } from '@forms/forms.module';
import { MaterialModule } from '@shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordValidationModule } from '@shared/components/password-validation/password-validation.module';

@NgModule({
  declarations: [SetNewPasswordComponent],
  imports: [CommonModule, AppFormsModule, MaterialModule, TranslateModule, PasswordValidationModule],
  exports: [SetNewPasswordComponent]
})
export class SetNewPasswordModule {}
