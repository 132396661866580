import { DestroyRef, Signal, assertInInjectionContext, inject, signal } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This function is the temporary solution because at this moment, the native Angular toSignal() function does not support mutable objects,
 * but signal() and computed() functions have this support with an optional equal function.
 * I hope after some Angular updates, when toSignal will supports custom equal function, we can use it for mutable objects instead of
 * this implementation
 * @param {Observable<T>} source an observable
 * @returns {Signal<T | undefined>} a new signal
 */
export function toMutableSignal<T>(source: Observable<T>): Signal<T | undefined> {
  // Throw error when this function was executed outside of injectable context
  assertInInjectionContext(toMutableSignal);

  const destroyRef = inject(DestroyRef);

  // Create signal with custom equal function for supports mutable objects
  const state = signal<T | undefined>(undefined, { equal: () => false });

  // Subscribe on observable changes and set to signal
  const subscription = source.subscribe(value => {
    state.set(value);
  });

  // Unsubscribe when the current context is destroyed, if requested.
  destroyRef?.onDestroy(subscription.unsubscribe.bind(subscription));

  // Return current signal as readonly signal
  return state.asReadonly();
}
