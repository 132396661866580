import { ISegmentAnswerValue } from '@base/models/classes/module-answer.model';
import { IIdentifierResolver } from '@misc/helpers/cql/cql-parser';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { BlockType } from '@shared/blocks/models/block.model';

export class SegmentIdentifierResolver implements IIdentifierResolver {
  constructor(private _segments: ModuleSegment[]) {}

  bool = (identifier: string): boolean => {
    const parts = identifier.split('.');
    const segmentId = parts[0];
    const subId = parts[1];
    const subSubId = parts.length > 2 ? parts[2] : '';
    for (const segment of this._segments) {
      if (segment.answer?.answer.segmentId !== segmentId) {
        continue;
      }
      switch (segment.template) {
        case BlockType.SINGLE_CHOICE:
        case BlockType.SELECTION:
        case BlockType.QUESTION:
        case BlockType.SLIDER:
          return anySelectedWithSubId(segment, subId);
        case BlockType.INPUT_SELECTION:
          return anySelectedValueWithSubIdAndSubSubId(segment, subId, subSubId);
      }
    }
    return false;
  };

  num = (identifier: string): number => {
    const parts = identifier.split('.');
    const segmentId = parts[0];
    const subId = parts[1];
    const subSubId = parts.length > 2 ? parts[2] : '';
    for (const segment of this._segments) {
      if (segment.answer?.answer.segmentId !== segmentId) {
        continue;
      }
      switch (segment.template) {
        case BlockType.INPUT:
          return parseInt(valueOfFirstMatchingSubId(segment, subId)) || 0;
        case BlockType.ROLE_INPUT:
          return parseInt(valueOfFirstValueMatchingSubIdAndSubSubId(segment, subId, subSubId)) || 0;
      }
    }
    return 0;
  };

  str = (identifier: string): string => {
    const parts = identifier.split('.');
    const segmentId = parts[0];
    const subId = parts[1];
    for (const segment of this._segments) {
      if (segment.answer?.answer.segmentId !== segmentId) {
        continue;
      }
      switch (segment.template) {
        case BlockType.INPUT:
          return valueOfFirstMatchingSubId(segment, subId);
      }
    }
    return '';
  };
}

function valueOfFirstMatchingSubId(segment: ModuleSegment, subId: string): string {
  return segment.answer?.answer.content.find(c => c.subId === subId).value as string;
}

function valueOfFirstValueMatchingSubIdAndSubSubId(segment: ModuleSegment, subId: string, subSubId: string): string {
  return segment.answer?.answer.content
    .filter(c => c.subId === subId)
    .flatMap(c => c.value as ISegmentAnswerValue[])
    .find(v => v.subId === subSubId).value as string;
}

function anySelectedWithSubId(segment: ModuleSegment, subId: string): boolean {
  return segment.answer?.answer.content.some(c => c.subId === subId);
}

function anySelectedValueWithSubIdAndSubSubId(segment: ModuleSegment, subId: string, subSubId: string): boolean {
  return segment.answer?.answer.content.some(c => {
    if (c.subId !== subId) {
      return false;
    }
    const value = c.value as ISegmentAnswerValue;
    return value.subId === subSubId;
  });
}
