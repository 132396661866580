<div class="errors">
  <p class="body-6 errors__item" [class.errors__item_error]="errorsShow?.[errorKey.minLength]">
    {{ PAGE_KEY + 'LENGTH_PASSWORD' | translate }}
  </p>
  <p class="body-6 errors__item" [class.errors__item_error]="errorsShow?.[errorKey.upperCaseLetter]">
    {{ PAGE_KEY + 'UPPER_CASE_LETTER' | translate }}
  </p>
  <p class="body-6 errors__item" [class.errors__item_error]="errorsShow?.[errorKey.lowerCaseLetter]">
    {{ PAGE_KEY + 'LOWER_CASE_LETTER' | translate }}
  </p>
  <p class="body-6 errors__item" [class.errors__item_error]="errorsShow?.[errorKey.symbols]">
    {{ PAGE_KEY + 'SYMBOLS' | translate }}
  </p>
  <p class="body-6 errors__item" [class.errors__item_error]="errorsShow?.[errorKey.digits]">
    {{ PAGE_KEY + 'DIGITS' | translate }}
  </p>
  <p class="body-6 errors__item" [class.errors__item_error]="errorsShow?.[errorKey.mustMatch]">
    {{ PAGE_KEY + 'MUST_MATCH' | translate }}
  </p>
</div>
