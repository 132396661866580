import { Exclude, Expose, Transform } from 'class-transformer';
import { ModuleStep } from './module-step.model';
import { ModuleElement } from '@models/classes/module-element.model';
import { transformToModelsArray } from '@misc/helpers/model-conversion/transform-helpers/transform-to-models-array.function';
import { AbstractModel } from './_base.model';

@Exclude()
export class ModuleSection extends ModuleElement {
  @Expose()
  @Transform(transformToModelsArray(ModuleStep))
  steps: ModuleStep[];
  @Expose()
  value?: string;
  @Expose()
  module?: AbstractModel;
}
