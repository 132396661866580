<div [class.view-mode]="!isEditMode" [ngStyle]="!isEditMode && blockViewStyles" class="block-columns">
  <ng-container *ngFor="let item of columns; let idx = index">
    <app-block-column
      (dataChanged)="save()"
      [column]="item"
      [isEditMode]="isEditMode"
      class="block-columns__item"
      [class.block-columns__item_align-center]="item.alignment === 'center'"
      [class.block-columns__item_align-bottom]="item.alignment === 'bottom'"
    ></app-block-column>

    <button (click)="swapColumns(idx)" *ngIf="isEditMode" class="block-columns__swap" mat-icon-button>
      <mat-icon [svgIcon]="AppIcon.SWAP"></mat-icon>
    </button>
  </ng-container>
</div>

<ng-template #settingTemplate>
  <app-settings (save)="saveSetting()" [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.' + getBlockName() | translate)">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
