import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { ModuleSection } from '@models/classes/module-section.model';

@Injectable({
  providedIn: 'root'
})
export class AdminSectionsApiService extends ApiBaseAbstractService<ModuleSection> {
  protected readonly _URL_PATH: string = '/admin/modules';
  protected override _URL_KEY: string = 'sections';
  protected readonly _MODEL: ClassConstructor<ModuleSection> = ModuleSection;
}
