import { AnyBlock } from '../models/block.model';

export class BlockHistoryHandler {
  private _historyStack: AnyBlock[] = [];
  private readonly _maxHistorySize: number = 20;

  get isHasHistory(): boolean {
    return this._historyStack.length > 1;
  }

  isBlockChanged(block: AnyBlock): boolean {
    const lastBlockSignature: string = JSON.stringify(this._historyStack.at(-1));
    const newBlockSignature: string = JSON.stringify(block);

    return lastBlockSignature !== newBlockSignature;
  }

  add(block: AnyBlock): void {
    if (!this.isBlockChanged(block)) return;

    this._historyStack.push(JSON.parse(JSON.stringify(block)));

    if (this._historyStack.length > this._maxHistorySize) {
      this._historyStack.shift();
    }
  }

  getPrev(): AnyBlock {
    let prevItem: AnyBlock;

    if (this._historyStack.length > 1) {
      this._historyStack.pop();
      prevItem = this._historyStack.at(-1);
      this._historyStack.pop();
      return prevItem;
    }

    return prevItem;
  }
}
