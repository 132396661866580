import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnyBlockColumn, BlockColumnImage, BlockColumnText, BlockColumnType } from '@shared/blocks/models/block-column.model';

@Component({
  selector: 'app-block-column',
  templateUrl: './block-column.component.html',
  styleUrls: ['./block-column.component.scss']
})
export class BlockColumnComponent {
  @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
  @Input() column: AnyBlockColumn;
  @Input() isEditMode: boolean;
  ColumnType: typeof BlockColumnType = BlockColumnType;

  get textColumn(): BlockColumnText {
    return this.column as BlockColumnText;
  }

  get imageColumn(): BlockColumnImage {
    return this.column as BlockColumnImage;
  }
}
