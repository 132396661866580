import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { CustomValidators } from '@base/misc/custom-validators';
import { AppIcon } from '@base/models/enums/app.icons';
import { InputType } from '@base/models/enums/input-type.enum';
import { IOption } from '@base/models/interfaces/forms/option.interface';
import { getBlockIdentifier, validateControlAndChildren } from '@misc/helpers';
import { ISegmentAnswerValue, SegmentAnswer } from '@models/classes/module-answer.model';
import { BlockRoleInput, BlockRoleInputOption } from '../../models/block.model';
import { BaseBlockComponent } from '../base-block.component';

export type OccupationType = 'Intern' | 'Extern';

// This custom validator gets occupation control and checks value, when the value is "External", return Required validator, otherwise
// validation does not need
const requiredWhenOccupationIsExternal = (occupationControl: FormControl<OccupationType>): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return occupationControl.value === 'Extern' ? Validators.required(control) : (): ValidationErrors | null => null;
  };
};

@Component({
  selector: 'app-block-role-input',
  templateUrl: './block-role-input.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-role-input.component.scss']
})
export class BlockRoleInputComponent extends BaseBlockComponent<BlockRoleInput> implements OnChanges {
  readonly AppIcon = AppIcon;
  readonly InputType = InputType;

  readonly questionsSelectOptions: IOption<any>[] = [
    {
      value: 'Intern',
      label: 'Intern'
    },
    {
      value: 'Extern',
      label: 'Extern'
    }
  ];

  readonly optionsCountControl = new FormControl(1, [Validators.min(1), Validators.max(10)]);

  readonly inputArray: FormArray<
    FormGroup<{
      id: FormControl<string>;
      occupation: FormControl<OccupationType>;
      expense: FormControl<string>;
      cost: FormControl<string>;
    }>
  > = new FormArray([]);

  constructor() {
    super();

    this.inputArray.valueChanges.pipe(takeUntilDestroyed(), debounceTime(1000)).subscribe(() => {
      validateControlAndChildren(this.inputArray);
      this.saveAnswers();
    });
  }

  get options(): BlockRoleInputOption[] {
    return this.block.options;
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block) {
      this.optionsCountControl.setValue(this.block.options.length);
      this.inputArray.clear();

      for (let i = 0; i < this.block.options.length; i++) {
        const option = this.block.options[i];
        const occupationControl = new FormControl<OccupationType>({ value: 'Intern', disabled: this.isUserInputDisabled }, [
          Validators.required
        ]);
        const costControl = new FormControl({ value: '', disabled: this.isUserInputDisabled }, [
          requiredWhenOccupationIsExternal(occupationControl),
          CustomValidators.digits
        ]);
        const group = new FormGroup({
          id: new FormControl(option.id, [Validators.required]),
          occupation: occupationControl,
          expense: new FormControl({ value: '', disabled: this.isUserInputDisabled }, [Validators.required, CustomValidators.digits]),
          cost: costControl
        });
        this.inputArray.push(group, { emitEvent: false });
      }
    }
    if (changes.segment && this.segmentAnswer) {
      for (const group of this.inputArray.controls) {
        for (const answer of this.segmentAnswer.content) {
          if (group.value.id === answer.subId) {
            group.controls.occupation.setValue(((answer.value as ISegmentAnswerValue[])[0].value as OccupationType) ?? 'Intern', {
              emitEvent: false
            });
            group.controls.expense.setValue((answer.value as ISegmentAnswerValue[])[1].value as string, { emitEvent: false });
            group.controls.cost.setValue((answer.value as ISegmentAnswerValue[])[2].value as string, { emitEvent: false });
          }
        }
      }
    }
  }

  isOccupationExtern(i: number): boolean {
    const group = this.inputArray.controls[i];
    return this.isEditMode || group.controls.occupation.value === 'Extern';
  }

  getOccupationInputControl(i: number): FormControl<OccupationType> {
    const group = this.inputArray.controls[i];
    return group.controls.occupation as FormControl<OccupationType>;
  }

  getExpenseInputControl(i: number): FormControl<string> {
    const group = this.inputArray.controls[i];
    return group.controls.expense as FormControl;
  }

  getCostInputControl(i: number): FormControl<string> {
    const group = this.inputArray.controls[i];
    return group.controls.cost as FormControl;
  }

  getCalculatedResult(i: number): number {
    const group = this.inputArray.controls[i];
    try {
      const expense = parseFloat(group?.getRawValue()?.expense);
      const cost = parseFloat(group?.getRawValue()?.cost);
      const result = expense * cost;
      if (typeof result === 'number' && !isNaN(result)) {
        return result;
      }
    } catch (e) {
      console.error(e);
    }
    return 0;
  }

  questionChanged(event: string): void {
    this.block.question = event;
    this.save();
  }

  titleChanged(option: BlockRoleInputOption, event: string): void {
    option.title = event;
    this.save();
  }

  labelExpenseChanged(option: BlockRoleInputOption, event: string): void {
    option.labelExpense.value = event;
    this.save();
  }

  labelCostChanged(option: BlockRoleInputOption, event: string): void {
    option.labelCost.value = event;
    this.save();
  }

  labelTotalChanged(option: BlockRoleInputOption, event: string): void {
    option.labelTotal.value = event;
    this.save();
  }

  closeSetting(): void {
    this.optionsCountControl.setValue(this.block.options.length);
  }

  saveSetting(): void {
    const count = this.optionsCountControl.value;
    const diff = count - this.block.options.length;
    if (diff > 0) {
      for (let i = this.block.options.length; i < count; i++) {
        this.block.options.push({
          id: getBlockIdentifier(),
          title: '',
          occupation: getBlockIdentifier(),
          labelExpense: { id: getBlockIdentifier(), value: '' },
          labelCost: { id: getBlockIdentifier(), value: '' },
          labelTotal: { id: getBlockIdentifier(), value: '' }
        });
      }
    } else if (diff < 0) {
      this.block.options.splice(count, this.block.options.length);
    }

    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  saveAnswers(): void {
    if (this.isUserInputDisabled) return;
    if (this.isEditMode || this.segment?.id == null || !this._transformationService.canAcceptAnswer() || !this.inputArray.valid) return;
    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: this.inputArray.value.map((item, i) => {
        return {
          subId: item.id,
          value: [
            {
              subId: this.block.options[i].occupation,
              value: item.occupation
            },
            {
              subId: this.block.options[i].labelExpense.id,
              value: item.expense
            },
            {
              subId: this.block.options[i].labelCost.id,
              value: item.cost
            },
            {
              subId: this.block.options[i].labelTotal.id,
              value: this.getCalculatedResult(i).toString()
            }
          ]
        };
      })
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }
}
