import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Optional,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, of, skip } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { foreachModuleElement } from '@misc/helpers/foreach-module-element';
import { runInAnyCase } from '@misc/rxjs-operators/run-in-any-case.operator';
import { SegmentAnswer } from '@models/classes/module-answer.model';
import { ModuleMedia } from '@models/classes/module-media.model';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { UserRole } from '@models/enums/user-role.enum';
import { AdminMediaApiService } from '@services/api/admin/media/admin-media-api.service';
import { ModuleService } from '@services/module/module.service';
import { RightSideNavService } from '@services/right-side-nav/right-side-nav.service';
import { RoleSwitcherService } from '@services/role-switcher/role-switcher.service';
import { TransformationService } from '@services/transformation/transformation.service';
import { BlockCqlSettings } from '@shared/blocks/models/block-cql-settings.model';
import { BlockPlaybookSettings } from '@shared/blocks/models/block-playbook-settings.model';
import { BlockStyleSettings } from '@shared/blocks/models/block-style-settings.model';
import { Block } from '@shared/blocks/models/block.model';
import { BlockCqlSettingsComponent } from '@shared/blocks/settings/block-cql-settings/block-cql-settings.component';
import { BlockInstructionSettingsComponent } from '@shared/blocks/settings/block-instruction-settings/block-instruction-settings.component';
import { BlockPlaybookSettingsComponent } from '@shared/blocks/settings/block-playbook-settings/block-playbook-settings.component';
import { BlockStylesSettingsComponent } from '@shared/blocks/settings/block-styles-settings/block-styles-settings.component';
import { BaseComponent } from '../../components/base/base.component';
import { SettingsComponent } from '../settings/settings.component';

@Component({ template: '' })
export abstract class BaseBlockComponent<B = Block> extends BaseComponent implements OnChanges, AfterViewInit {
  @Output() dataChanged: EventEmitter<Partial<ModuleSegment>> = new EventEmitter<Partial<ModuleSegment>>();
  @Input() block: B;
  @Input() segment: ModuleSegment;
  @Input() isEditMode: boolean;
  @Input() isReadonly: boolean;
  @Input() isOpenSetting: BehaviorSubject<boolean>;
  @ViewChild('settingTemplate') settingTemplate: TemplateRef<SettingsComponent>;
  @Optional() @ViewChild(BlockCqlSettingsComponent) cqlSettingsComponent: BlockCqlSettingsComponent;
  @Optional() @ViewChild(BlockPlaybookSettingsComponent) playbookSettingsComponent: BlockPlaybookSettingsComponent;
  @Optional() @ViewChild(BlockStylesSettingsComponent) styleSettingsComponent: BlockStylesSettingsComponent;
  @Optional() @ViewChild(BlockInstructionSettingsComponent) instructionComponent: BlockInstructionSettingsComponent;
  readonly isLoading = signal<boolean>(false);
  readonly allSegments: ModuleSegment[] = [];
  protected _currentUuid: string = uuidv4();
  protected _rightSideNavService: RightSideNavService = inject(RightSideNavService);
  protected _transformationService: TransformationService = inject(TransformationService);
  protected _moduleService: ModuleService = inject(ModuleService);
  private _roleSwitcher: RoleSwitcherService = inject(RoleSwitcherService);
  private _adminMediaApiService: AdminMediaApiService = inject(AdminMediaApiService);

  get isUserScope(): boolean {
    return this._roleSwitcher.selectedRole === UserRole.user;
  }

  get blockStyleSettings(): BlockStyleSettings {
    return (this.block as Block)?.styleSettings;
  }

  get updatedCqlSettings(): BlockCqlSettings {
    return this.cqlSettingsComponent?.settingsFormValue;
  }

  get updatedPlaybookSettings(): BlockPlaybookSettings {
    return this.playbookSettingsComponent?.settingsFormValue;
  }

  get updatedStyleSettings(): BlockStyleSettings {
    return this.styleSettingsComponent?.settingsFormValue;
  }

  get updatedInstructionSettings(): boolean {
    return this.instructionComponent?.instructionValue;
  }

  get blockViewStyles(): Params {
    return {
      'background-color': this.blockStyleSettings?.bgColor,
      border: this.blockStyleSettings?.showBorder ? '1px solid var(--color-blue-25)' : 'none'
    };
  }

  get segmentAnswer(): SegmentAnswer {
    return this.segment.answer?.answer as SegmentAnswer;
  }

  get isUserInputDisabled(): boolean {
    return (
      this.isReadonly ||
      (this.segmentAnswer && this._transformationService.currentSite?.id !== this._transformationService.latestSite?.id) ||
      (this._roleSwitcher.isUser() && !this._transformationService.canAcceptAnswer())
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this._setAllSegments();
  }

  save(extraParams: Partial<ModuleSegment> = {}): void {
    this.dataChanged.emit({ value: this.block, ...extraParams } as Partial<ModuleSegment>);
  }

  getMediaSignedUrl(mediaId: string): Observable<ModuleMedia> {
    if (!mediaId) return of(null);
    this.isLoading.set(true);
    return (
      this._roleSwitcher.selectedRole === UserRole.admin
        ? this._adminMediaApiService.getItem(mediaId, {}, { skipLoaderStart: true })
        : this._transformationService.getMediaSignedUrl(mediaId)
    ).pipe(
      runInAnyCase(() => {
        this.isLoading.set(false);
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.settingTemplate && this.isOpenSetting) {
      this.isOpenSetting.pipe(skip(1), takeUntilDestroyed(this._destroyRef)).subscribe((): void => {
        this._rightSideNavService.setTemplate(this.settingTemplate);
        this._rightSideNavService.toggle(this._currentUuid);
      });
    }
  }

  openDialog(e?: Event): void {
    e?.preventDefault();
    e?.stopPropagation();
  }

  private _setAllSegments(): void {
    if (!this.segment) return;
    this.allSegments.length = 0;
    foreachModuleElement(this._moduleService.module(), null, null, null, (segment: ModuleSegment) => {
      this.allSegments.push(segment);
    });
  }
}
