<div class="style-settings">
  <div class="settings__block">
    <h4 class="body-1 settings__title">{{ pageKey + 'BACKGROUND_COLOR' | translate }}</h4>
    <div class="style-settings__color-list">
      <div
        (click)="setBgColor(color)"
        *ngFor="let color of colors"
        [style.background-color]="color"
        class="style-settings__color-item"
        tabindex="0"
      >
        <mat-icon *ngIf="color === controls?.bgColor?.value">check</mat-icon>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div class="settings__block settings__block_flex">
    <h4 class="body-1 settings__title">{{ pageKey + 'SHOW_BORDER' | translate }}</h4>
    <base-boolean-field
      [control]="controls?.showBorder"
      [type]="BooleanFieldType.toggle"
      class="mat-mdc-slide-toggle_blue"
    ></base-boolean-field>
  </div>
</div>
