import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordValidationComponent } from './password-validation.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PasswordValidationComponent],
  exports: [PasswordValidationComponent],
  imports: [CommonModule, TranslateModule]
})
export class PasswordValidationModule {}
