<div class="progress-bar">
  <div class="progress-bar__info">
    <div class="progress-bar__info-header">
      <div [style.background]="color" class="progress-bar__color"></div>
      <span class="body-6">{{ label }}</span>
    </div>
    <p class="body-6">{{ value | number: '1.0-0' }}%</p>
  </div>
  <div [style.background]="'rgba(' + rgb + ', 0.1)'" class="progress-bar__line-box">
    <div [style.background]="color" [style.width]="value + '%'" class="progress-bar__line">
      <div class="progress-bar__line-circle"></div>
    </div>
  </div>
</div>
