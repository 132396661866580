import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import ImageResize from 'image-resize';
import { CropperPosition, ImageCroppedEvent, ImageTransform, OutputFormat } from 'ngx-image-cropper';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { IMAGE_MAX_WIDTH, SUPPORT_TYPES_IMAGE_CROPPER } from '@misc/constants/_base.constant';
import { blobToDataUrl } from '@misc/helpers/blob-to-dataurl';
import { dataUrlToFile } from '@misc/helpers/data-url-to-file.function';
import { ModuleMedia } from '@models/classes/module-media.model';
import { AppIcon } from '@models/enums/app.icons';
import { MediaUploadService } from '@services/media-upload/media-upload.service';
import { IModalComponentContext, ModalComponent } from '@shared/modal/modal.component';
import { VerticalAlignment } from '@base/models/enums/vertical-alignment.enum';

export interface ICroppedImage {
  id: string;
  src: string;
  media?: ModuleMedia;
  alignImage?: VerticalAlignment;
  hasAlignment?: boolean;
}

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent extends BaseFormAbstractComponent implements OnInit, OnChanges {
  @Input() context: IModalComponentContext<ICroppedImage>;
  hasAlignment: boolean = false;
  alignment: string = 'top';
  isInitialized: boolean;
  isUpdateImage: boolean = false;
  isLoading: boolean = true;
  image: string;
  media: ModuleMedia;
  imageSizeByCropper: { width: number; height: number };
  canvasRotation: number = 0;
  scale = 1;
  transform: ImageTransform = {};
  cropper: CropperPosition = {} as CropperPosition;
  croppedImage: string;
  readonly AppIcon = AppIcon;
  createdUrl: string;
  private _mediaUpload: MediaUploadService = inject(MediaUploadService);

  get dialog(): MatDialogRef<ModalComponent<ICroppedImage>> {
    return this.context?.dialog;
  }

  get imageSrc(): string {
    return this.image;
  }

  get typeImageCropper(): OutputFormat {
    const type: string = this.media?.type?.substring(this.media?.type.lastIndexOf('/') + 1, this.media?.type?.length) || this.media?.type;
    if (SUPPORT_TYPES_IMAGE_CROPPER.includes(type) && this.scale >= 1) {
      return type as OutputFormat;
    }

    return 'png';
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.media = this.context.entity.media;
    this.checkSize(this.context.entity?.src);
    this.hasAlignment = this.context.entity.hasAlignment;
    this.alignment = this.context.entity.alignImage || 'top';
  }

  async checkSize(src: string): Promise<void> {
    const file = await dataUrlToFile(src, 'image.png', 'image/png');
    const img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    this.resetImage();
    this.isUpdateImage = true;
    this.createdUrl = src;

    if (img.width > IMAGE_MAX_WIDTH) {
      const imageResize: ImageResize = new ImageResize();
      const fileBase64 = await blobToDataUrl(file);
      let res: HTMLCanvasElement = await imageResize.updateOptions({ width: IMAGE_MAX_WIDTH }).get(fileBase64);
      res = await imageResize.resize(res);
      this.isUpdateImage = true;
      this.image = res.toDataURL();
    } else {
      this.image = await blobToDataUrl(file);
    }
    this.isLoading = false;
  }

  ngOnChanges({ context }: SimpleChanges): void {
    if (context?.currentValue?.src !== context?.previousValue?.src) {
      this.isUpdateImage = true;
      this.image = context?.currentValue?.src;
    }
  }

  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    this.croppedImage = event.base64;

    if (this.isUpdateImage) {
      this.imageSizeByCropper = {
        width: event.cropperPosition.x2,
        height: event.cropperPosition.y2
      };

      this.isUpdateImage = false;
    }
  }

  onSubmit(): void {
    this.dialog.close({ src: this.croppedImage, alignImage: this.alignment });
  }

  rotateLeft(): void {
    this.canvasRotation--;
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    });
    this.flipAfterRotate();
  }

  flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };

    this.isUpdateImage = true;
  }

  zoomOut(): void {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(): void {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  crop(): void {
    this.isUpdateImage = true;
    this.image = this.croppedImage;
    this.resetImage();
  }

  resetImage(): void {
    this.scale = 1;
    this.canvasRotation = 0;
    this.transform = {};
  }

  async center(): Promise<void> {
    const x1 = this.imageSizeByCropper.width / 2;
    const y1 = this.imageSizeByCropper.height / 2;

    if (this.imageSizeByCropper.width === this.imageSizeByCropper.height) {
      this.cropper = {
        x1: 0,
        y1: 0,
        x2: this.imageSizeByCropper.width,
        y2: this.imageSizeByCropper.height
      };
    } else if (this.imageSizeByCropper.width > this.imageSizeByCropper.height) {
      this.cropper = {
        x1: x1 - this.imageSizeByCropper.height / 2,
        y1: 0,
        x2: x1 + this.imageSizeByCropper.height / 2,
        y2: this.imageSizeByCropper.height
      };
    } else if (this.imageSizeByCropper.width < this.imageSizeByCropper.height) {
      this.cropper = {
        x1: 0,
        y1: y1 - this.imageSizeByCropper.width / 2,
        x2: this.imageSizeByCropper.width,
        y2: y1 + this.imageSizeByCropper.width / 2
      };
    }
  }

  upload(): void {
    this.dialog.close();
    this._mediaUpload.OPEN_UPLOAD_DIALOG$.next(this.context.entity?.id);
  }

  getImagePosition(value: string): void {
    this.alignment = value;
    this.isUpdateImage = true;
  }
}
