import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { ModuleStep } from '@models/classes/module-step.model';
import { transformToModel } from '@base/misc/helpers/model-conversion/transform-helpers/transform-to-model.function';

@Exclude()
export class PlaybookChapterElement extends AbstractModel {
  @Expose()
  position: number;
  @Expose()
  @Transform(transformToModel(ModuleStep))
  step: ModuleStep;
  @Expose()
  active: boolean;
}
