import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { MaterialModule } from '@shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '@shared/modal/modal.service';
import { ModalActionsModule } from '@shared/modal/modal-actions/modal-actions.module';
import { UserModalModule } from '@shared/modal/modals/user-modal/user-modal.module';
import { SetNewPasswordModule } from '@shared/modal/modals/set-new-password/set-new-password.module';
import { AvatarEditModalModule } from '@shared/modal/modals/avatar-edit-modal/avatar-edit-modal.module';
import { EmailChangeModalModule } from '@shared/modal/modals/email-change-modal/email-change-modal.module';
import { StartModalModule } from '@shared/modal/modals/start-modal/start-modal.module';
import { PasswordResetModalModule } from '@shared/modal/modals/password-reset-modal/password-reset-modal.module';
import { VerificationCodeModalModule } from '@shared/modal/modals/verefication-code-modal/verification-code-modal.module';
import { ImageCropperModalModule } from '@shared/modal/modals/image-cropper-modal/image-cropper-modal.module';

@NgModule({
  declarations: [ModalComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    ModalActionsModule,
    UserModalModule,
    SetNewPasswordModule,
    AvatarEditModalModule,
    EmailChangeModalModule,
    StartModalModule,
    PasswordResetModalModule,
    VerificationCodeModalModule,
    ImageCropperModalModule
  ],
  providers: [ModalService],
  exports: [
    UserModalModule,
    SetNewPasswordModule,
    AvatarEditModalModule,
    EmailChangeModalModule,
    StartModalModule,
    PasswordResetModalModule,
    VerificationCodeModalModule,
    ImageCropperModalModule
  ]
})
export class ModalModule {}
