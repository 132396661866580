import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { Organization } from './organization.model';
import { User } from '@models/classes/user.model';
import { attributesToModel } from '@misc/helpers';

@Exclude()
export class OrganizationUser extends AbstractModel {
  @Expose()
  organization: Organization;
  @Expose()
  user: string;
  @Expose()
  @Transform(({ value }) => (value ? attributesToModel(value, User, {}) : null))
  cognitoUser?: User;
  @Expose()
  firstName: string; 
  @Expose()
  lastName: string;
  @Expose()
  email: string;
  @Expose()
  isOwner: boolean;
}
