<div class="settings">
  <div class="settings__title">
    <h2 class="title-1">{{ title | translate }}</h2>
    <button class="settings__close" mat-icon-button (click)="rightSideNavService.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="settings__content">
    <ng-content></ng-content>
  </div>
  <div class="settings__buttons">
    <button mat-flat-button class="settings__button" color="accent" (click)="cancelHandler()">
      {{ 'BUTTON_NAME.CANCEL' | translate }}
    </button>
    <button mat-flat-button class="settings__button" color="primary" (click)="saveHandler()">
      {{ 'BUTTON_NAME.SAVE_2' | translate }}
    </button>
  </div>
</div>
