import { Component, input, output } from '@angular/core';
import { DisplayMode, MetaTypeAttribute } from '@base/models/classes/meta-type.model';
import { AppIcon } from '@base/models/enums/app.icons';

@Component({
  selector: 'app-block-table-attribute',
  templateUrl: './block-table-attribute.component.html',
  styleUrl: './block-table-attribute.component.scss'
})
export class BlockTableAttributeComponent {
  readonly attribute = input<MetaTypeAttribute>();
  readonly hidePredefinedOption = input<boolean>(false);
  readonly saveAttribute = output<DisplayMode>();
  readonly pageKey: string = 'BLOCK.TABLE.ATTRIBUTE.';
  readonly AppIcon = AppIcon;

  save($event: DisplayMode): void {
    this.saveAttribute.emit($event);
  }
}
