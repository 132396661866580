import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { TitlePipe } from '@pipes/title/title.pipe';
import { PathParsePipe } from '@pipes/path-parse/path-parse.pipe';
import { RolePipe } from '@pipes/role/role.pipe';

@NgModule({
  declarations: [TitlePipe, PathParsePipe, RolePipe],
  exports: [TitlePipe, PathParsePipe, RolePipe],
  providers: [TitleCasePipe],
  imports: [CommonModule]
})
export class PipesModule {}
