<div *ngIf="activeStep === emailChangeStep.firstStep">
  <base-form-input
    class="email-change-modal__input"
    [control]="firstForm?.email"
    [required]="true"
    [hideLabel]="true"
    [placeholder]="'INPUT_NAME.EMAIL' | translate"
    [inputType]="INPUT_TYPE.email"
  ></base-form-input>
</div>
<div *ngIf="activeStep === emailChangeStep.secondStep">
  <base-form-input
    class="email-change-modal__input"
    [control]="secondForm?.code"
    [required]="true"
    [hideLabel]="true"
    [placeholder]="'INPUT_NAME.CODE' | translate"
    [inputType]="INPUT_TYPE.text"
  ></base-form-input>
</div>
