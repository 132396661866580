import { AbstractModel } from './_base.model';
import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class MetaType extends AbstractModel {
  @Expose()
  name: string;
  @Expose()
  attributes: MetaTypeAttribute[];
}

@Exclude()
export class MetaTypeAttribute {
  @Expose()
  id: string | undefined;
  @Expose()
  name: string;
  @Expose()
  description: string;
  @Expose()
  type: MetaAttributeType;
  @Expose()
  primary: boolean;
  @Expose()
  options?: string[];
  @Expose()
  displayMode?: DisplayMode;
}

export type DisplayMode = 'edit' | 'display' | 'hide' | 'predefined';

export type MetaAttributeType = 'string' | 'boolean' | 'number' | 'selection';
