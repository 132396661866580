import { parse } from '@misc/helpers/cql/cql-parser';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { CqlConstant, cqlConstants, CqlOperator, cqlOperators } from '@models/enums/cql-operator.enum';
import { BlockIdPrefix, SegmentIdPrefix } from '@shared/blocks/models/block-id-prefix';

export class CqlValidator {
  query: string;
  tokens: string[];
  private _validOperators: CqlOperator[] = cqlOperators;
  private _validConstants: CqlConstant[] = cqlConstants;
  private _blockIdPattern: string = `${SegmentIdPrefix.BLOCK}(${Object.values(BlockIdPrefix).join('|')})[a-z0-9]+`;
  private _optionIdPattern: string = `${SegmentIdPrefix.OPTION}[a-z0-9]+`;
  // eslint-disable-next-line no-useless-escape
  private _valuePattern: string = `^"?\%?[a-z0-9]+\%?"?$`;
  // eslint-disable-next-line no-useless-escape
  private _operandPattern: string = `${this._blockIdPattern}(\.${this._optionIdPattern})|${this._valuePattern}`;
  private _blocksSnapshots: string[];

  constructor(segments: ModuleSegment[]) {
    this._blocksSnapshots = segments.map(item => JSON.stringify(item.value));
  }

  isValidQuery(query: string): boolean {
    try {
      parse(query);
    } catch (_: any) {
      return false;
    }
    this.setQueryTokens(query);
    if (!this._isValidBlockIds()) return false;
    if (this.tokens.length === 1) return this._isValidSingleToken();
    if (!this._isValidParenthesis()) return false;
    if (!this._isValidUnaryOperators()) return false;
    if (!this._isValidOrder()) return false;

    return true;
  }

  setQueryTokens(query: string): void {
    this.query = this._prepareQueryString(query);
    this.tokens = this.query.split(/\s+/);
  }

  private _isValidUnaryOperators(): boolean {
    const operators: CqlOperator[] = [CqlOperator.NOT_SIGN];

    for (const [index, token] of this.tokens.entries()) {
      if (operators.includes(token as CqlOperator)) {
        if (this._isValidOperand(this.tokens[index + 1])) {
          this.tokens.splice(index, 1);
        } else return false;
      }
    }

    return true;
  }

  private _isValidOrder(): boolean {
    const isFirstTokenOperand: boolean = new RegExp(this._operandPattern).test(this.tokens.at(0));

    for (const [index, token] of this.tokens.entries()) {
      if (isFirstTokenOperand) {
        if (index % 2 === 0) {
          if (!this._isValidOperand(token)) return false;
        } else {
          if (!this._validOperators.includes(token as CqlOperator)) return false;
        }
      } else {
        if (index % 2 === 0) {
          if (!this._validOperators.includes(token as CqlOperator)) return false;
        } else {
          if (!this._isValidOperand(token)) return false;
        }
      }
    }

    return true;
  }

  private _isValidBlockIds(): boolean {
    // eslint-disable-next-line no-useless-escape
    const ids: string[] = this.tokens.filter(token => new RegExp(`${this._blockIdPattern}(\.${this._optionIdPattern})`, 'i').test(token));

    return ids.every(operandId => {
      const operandIdPath: string[] = operandId.split('.');
      const block: string = this._blocksSnapshots.find(item => item.includes(operandIdPath.at(0)));
      return block && operandIdPath.every(id => block.includes(id));
    });
  }

  private _prepareQueryString(query: string): string {
    let result: string = (query || '').trim();

    result = result
      .replace(new RegExp('s*\\' + CqlOperator.LP + 's*', 'g'), ` ${CqlOperator.LP} `)
      .replace(new RegExp('s*\\' + CqlOperator.RP + 's*', 'g'), ` ${CqlOperator.RP} `);

    return result.trim();
  }

  private _isValidSingleToken(): boolean {
    const token: string = this.tokens.at(0);
    return (
      this._validConstants.includes(token as CqlConstant) ||
      // eslint-disable-next-line no-useless-escape
      new RegExp(`${this._blockIdPattern}(\.${this._optionIdPattern})`, 'i').test(token)
    );
  }

  private _isValidParenthesis(): boolean {
    const parenthesis: (CqlOperator.LP | CqlOperator.RP)[] = [];

    for (const token of this.tokens) {
      if (token === CqlOperator.LP) {
        parenthesis.push(token);
      } else if (token === CqlOperator.RP) {
        if (parenthesis.length === 0 || parenthesis.pop() !== CqlOperator.LP) {
          return false;
        }
      }
    }

    while (this.tokens.includes(CqlOperator.LP) || this.tokens.includes(CqlOperator.RP)) {
      const lpIdx: number = this.tokens.indexOf(CqlOperator.LP);
      if (lpIdx > -1) this.tokens.splice(lpIdx, 1);
      const rpIdx: number = this.tokens.indexOf(CqlOperator.RP);
      if (rpIdx > -1) this.tokens.splice(rpIdx, 1);
    }

    return parenthesis.length === 0;
  }

  private _isValidOperand(operand: string): boolean {
    return new RegExp(this._operandPattern, 'i').test(operand);
  }
}

// For testing purpose only
export const cqlTestCases = [
  'mod-sli-qvhmrec.opt-22m1vf0 OR mod-sli-qvhmrec.opt-22m1vf0',
  'mod-inp-2.opt-22m1vf0 == "%test%"',
  'mod-inp-2.opt-22m1vf0 == "test%"',
  'mod-inp-2.opt-22m1vf0 != "%test%"',
  'NOT mod-sli-1.opt-22m1vf0',
  'mod-que-1.opt-22m1vf0',
  'mod-au-1.opt-22m1vf0 OR mod-au-1.opt-22m1vf0 AND mod-au-1.opt-22m1vf0',
  'mod-au-1.opt-22m1vf0 OR (mod-au-1.opt-22m1vf0 AND mod-au-1.opt-22m1vf0)',
  'mod-rol-1.opt-22m1vf0 > 500',
  'mod-se-1.opt-22m1vf0 OR mod-se-1.opt-22m1vf0 OR mod-se-1.opt-22m1vf0',
  '(mod-sli-qvhmrec.opt-tvi2f3l == "%test%" AND mod-sli-qvhmrec.opt-tvi2f3l >= 20) OR mod-sli-qvhmrec.opt-tvi2f3l != "%entry%"',
  '!(mod-sli-qvhmrec.opt-tvi2f3l) AND !(mod-sli-qvhmrec.opt-mrc9a7d)'
];
