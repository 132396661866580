<div class="left-side">
  <div class="left-side__head">
    <button *ngIf="showBackButton" class="left-side__back" (click)="goBack()" mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h3 class="left-side__title" *ngIf="title">{{ title | translate }}</h3>
  </div>

  <ng-content></ng-content>
</div>
