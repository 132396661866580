import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyBlockComponent } from './empty-block.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material/material.module';

@NgModule({
  declarations: [EmptyBlockComponent],
  exports: [EmptyBlockComponent],
  imports: [CommonModule, MaterialModule, TranslateModule]
})
export class EmptyBlockModule {}
