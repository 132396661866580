import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAutocompleteComponent } from './custom-autocomplete.component';
import { MaterialModule } from '@shared/material/material.module';

@NgModule({
  declarations: [CustomAutocompleteComponent],
  exports: [CustomAutocompleteComponent],
  imports: [CommonModule, MaterialModule]
})
export class CustomAutocompleteModule {}
