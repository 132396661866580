import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { transformToModelsArray } from '@misc/helpers/model-conversion/transform-helpers/transform-to-models-array.function';
import { PlaybookChapter } from '@models/classes/playbook-chapter.model';

@Exclude()
export class Playbook extends AbstractModel {
  @Expose()
  value: string;
  @Expose()
  @Transform(transformToModelsArray(PlaybookChapter))
  chapters: PlaybookChapter[];
}
