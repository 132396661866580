import { NgModule } from '@angular/core';
import { BaseModule } from '@shared/components/base/base.module';
import { BreadCrumbsModule } from '@shared/components/bread-crumbs/bread-crumbs.module';
import { DataTableModule } from '@shared/components/data-table/data-table.module';
import { EmptyBlockModule } from '@shared/components/empty-block/empty-block.module';
import { ErrorMsgModule } from '@shared/components/error-msg/error-msg.module';
import { FileUploadModule } from '@shared/components/file-upload/file-upload.module';
import { IconButtonModule } from '@shared/components/icon-button/icon-button.module';
import { LeftSideMenuModule } from '@shared/components/left-side-menu/left-side-menu.module';
import { ModuleCardModule } from '@shared/components/module-card/module-card.module';
import { NotificationModule } from '@shared/components/notification/notification.module';
import { PasswordValidationModule } from '@shared/components/password-validation/password-validation.module';
import { PhotoProfileModule } from '@shared/components/photo-profile/photo-profile.module';
import { ProgressBarModule } from '@shared/components/progress-bar/progress-bar.module';
import { ResizableElementModule } from '@shared/components/resizable-element/resizable-element.module';
import { SoonCardModule } from '@shared/components/soon-card/soon-card.module';
import { TextEditorModule } from '@shared/components/text-editor/text-editor.module';
import { TodoBlockModule } from '@shared/components/todo-block/todo-block.module';
import { CroppedTextModule } from './cropped-text/cropped-text.module';
import { LoaderContainerModule } from './loader-container/loader-container.module';
import { ModuleHierarchyComponent } from './module-hierarchy/module-hierarchy.component';
import { ModulePlaybookComponent } from './module-playbook/module-playbook.component';
import { TransformationHierarchyComponent } from './transformation-hierarchy/transformation-hierarchy.component';

@NgModule({
  imports: [ModulePlaybookComponent, ModuleHierarchyComponent, TransformationHierarchyComponent],
  exports: [
    BaseModule,
    LoaderContainerModule,
    CroppedTextModule,
    NotificationModule,
    ErrorMsgModule,
    DataTableModule,
    PhotoProfileModule,
    ProgressBarModule,
    BreadCrumbsModule,
    TodoBlockModule,
    ModuleCardModule,
    PasswordValidationModule,
    SoonCardModule,
    LeftSideMenuModule,
    IconButtonModule,
    TextEditorModule,
    FileUploadModule,
    ResizableElementModule,
    EmptyBlockModule,
    ModulePlaybookComponent,
    ModuleHierarchyComponent,
    TransformationHierarchyComponent
  ]
})
export class SharedComponentsModule {}
