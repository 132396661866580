import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class ModuleMedia {
  @Expose()
  id?: string;
  @Expose()
  src: string;
  name?: string;
  type?: string;
  progress?: number;
}
