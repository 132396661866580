<div class="notification" [style.display]="state().value === 'inactive' ? 'none' : ''" (click)="$event.stopPropagation()" tabindex="0">
  <div class="notification__content">
    <mat-icon *ngIf="svg" class="notification__icon" [svgIcon]="svg"></mat-icon>
    <mat-icon class="notification__icon-close" (click)="close()">close</mat-icon>
    <div class="notification__text">
      <h3 class="notification__heading body-1" *ngIf="header" [class]="options.titleClass" [attr.aria-label]="title">
        {{ header | translate }}
      </h3>
      <p
        class="notification__message body-2"
        *ngIf="message && options.enableHtml"
        role="alert"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></p>
      <p
        class="notification__message body-2"
        *ngIf="message && !options.enableHtml"
        role="alert"
        [class]="options.messageClass"
        [attr.aria-label]="message"
      >
        {{ message }}
      </p>
    </div>
  </div>
  <button *ngIf="options.closeButton" class="notification__close" mat-icon-button (click)="remove()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
