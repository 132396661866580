<div class="block-cluster">
  @if (isEditMode) {
    <div class="block-common__title">{{ pageKey + 'TITLE' | translate }}</div>
    <app-text-editor (contentChanged)="block.title = $event; save()" [content]="block.title" class="block-common__field"></app-text-editor>

    <div class="block-common__title">{{ pageKey + 'DESCRIPTION' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.description = $event; save()"
      [content]="block.description"
      class="block-common__field"
    ></app-text-editor>

    <div class="block-common__title">{{ pageKey + 'CLUSTER_COLUMNS' | translate }}</div>
    <div class="block-cluster__columns" [style.--columns-count]="block.columnsCount">
      <div *ngFor="let column of block.columns" class="block-cluster__column">
        <div class="block-common__option-wrap">
          <app-block-id class="narrow" [blockID]="[block.id, column.id]"></app-block-id>
          <app-text-editor
            (contentChanged)="column.value = $event; save()"
            [content]="column.value"
            [placeholder]="pageKey + 'COLUMN_PLACEHOLDER' | translate"
            class="block-common__field"
          ></app-text-editor>
        </div>
        <app-text-editor
          (contentChanged)="column.description = $event; save()"
          [content]="column.description"
          [placeholder]="pageKey + 'COLUMN_DESCRIPTION' | translate"
          class="block-common__field"
        ></app-text-editor>
        <div class="block-cluster__active-toggle">
          <div>{{ 'CHART.ACTIVE' | translate }}?</div>
          <base-boolean-field
            (controlChange)="column.isActive = $event; save()"
            [type]="BooleanFieldType.toggle"
            [value]="column.isActive"
            class="mat-mdc-slide-toggle_blue"
          ></base-boolean-field>
        </div>
      </div>
    </div>

    <div class="block-common__title">{{ pageKey + 'OPTIONS_TITLE' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.optionsTitle = $event; save()"
      [content]="block.optionsTitle"
      class="block-common__field"
    ></app-text-editor>

    <div class="block-common__title">{{ pageKey + 'OPTIONS' | translate }}</div>
    @if (block.isDynamicOptionsEnabled) {
      <div class="block-cluster__selection-id">
        <base-form-input
          [control]="dynamicOptionsBlockIdControl"
          [hideLabel]="true"
          [placeholder]="pageKey + 'OPTION_ID_PLACEHOLDER' | translate"
          class="block-common__option-input"
        ></base-form-input>
        <mat-icon [svgIcon]="isDynamicOptionsBlockIdValid() ? AppIcon.SUCCESS : AppIcon.WARNING"></mat-icon>
      </div>
      <p class="block-cluster__selection-id-hint">{{ pageKey + 'OPTION_ID_HINT' | translate }}</p>
    } @else {
      <div *ngFor="let option of block.options; let i = index" class="block-cluster__option-item">
        <div class="block-common__option-wrap">
          <app-block-id [blockID]="[block.id, option.id]"></app-block-id>
          <app-text-editor
            (contentChanged)="option.value = $event; save()"
            [content]="option.value"
            [placeholder]="pageKey + 'OPTION_PLACEHOLDER' | translate"
            class="block-common__field"
          ></app-text-editor>
        </div>

        <button (click)="deleteOption(i)" class="block-cluster__delete-button" color="accent" mat-icon-button>
          <mat-icon [svgIcon]="AppIcon.TRASH"></mat-icon>
        </button>
      </div>
      <div class="block-cluster__option-item">
        <app-text-editor
          (contentChanged)="newOptionName.set($event)"
          [changeDelay]="0"
          [content]="newOptionName()"
          [placeholder]="pageKey + 'OPTION_PLACEHOLDER' | translate"
          class="block-common__field"
        ></app-text-editor>

        <button (click)="addOption()" class="block-cluster__add-button" color="primary" mat-flat-button>
          {{ 'BUTTON_NAME.ADD' | translate }}
        </button>
      </div>
    }

    <div *ngIf="block.isInstructionEnabled">
      <div class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  } @else {
    <!--View mode-->
    <div [ngStyle]="blockViewStyles" class="block-common__view block-cluster__view">
      <app-text-editor
        [content]="block.title"
        [isViewMode]="true"
        [class.view-mode]="true"
        class="block-common__field block-cluster__title mb-2"
      ></app-text-editor>
      <app-text-editor
        [content]="block.description"
        [isViewMode]="true"
        [class.view-mode]="true"
        class="block-common__field block-cluster__description"
      ></app-text-editor>

      <div
        class="block-cluster__dnd-columns"
        [class.block-cluster__dnd-columns--input-disabled]="isUserInputDisabled"
        [class.block-common__readonly]="isUserInputDisabled"
        cdkDropListGroup
      >
        <div class="block-cluster__dnd-column block-cluster__dnd-column_selection" *ngIf="!isUserInputDisabled">
          <div class="block-cluster__dnd-column-title">
            <app-text-editor
              [content]="block.optionsTitle"
              [isViewMode]="true"
              [class.view-mode]="true"
              class="block-common__field"
            ></app-text-editor>
          </div>

          <div class="block-cluster__options" cdkDropListSortingDisabled cdkDropList [cdkDropListData]="initialOptions()">
            <div
              *ngFor="let option of initialOptions()"
              [cdkDragData]="option"
              cdkDrag
              cdkDragBoundary=".block-cluster__dnd-columns-wrap"
              class="block-cluster__option"
              [class.block-cluster__option_selected]="option.isCustom"
            >
              <app-text-editor
                [content]="option.value"
                [isViewMode]="true"
                [class.view-mode]="true"
                class="block-common__field block-cluster__option-content"
              ></app-text-editor>
              <button
                *ngIf="option.isCustom"
                mat-icon-button
                [disabled]="isCustomOptionSelected(option)"
                (click)="!isCustomOptionSelected(option) && deleteCustomOption(option)"
                class="block-cluster__option-delete block-cluster__option-delete_small"
              >
                <mat-icon [svgIcon]="AppIcon.CLOSE_CIRCLE"></mat-icon>
              </button>
            </div>
          </div>

          <div class="block-cluster__hint mb-4">
            <mat-icon [svgIcon]="AppIcon.DRAG_HINT"></mat-icon>
            <span>{{ pageKey + 'D&D_HINT' | translate }}</span>
          </div>

          <div *ngIf="block.isCustomOptionEnabled && !isUserInputDisabled" class="block-cluster__add-option-wrap">
            <input
              [formControl]="customOptionControl"
              [placeholder]="'INPUT_NAME.ADD_OPTION' | translate"
              type="text"
              class="block-cluster__add-option-input"
            />
            <button
              (click)="addCustomOption()"
              [disabled]="!customOptionControl.value"
              mat-icon-button
              class="block-cluster__add-option-btn"
            >
              <mat-icon [svgIcon]="AppIcon.PLUS"></mat-icon>
            </button>
          </div>
        </div>

        <div class="block-cluster__dnd-columns-wrap">
          <div
            *ngFor="let column of block.columns"
            class="block-cluster__dnd-column"
            [class.block-cluster__dnd-color-white]="columnColorWhiteOverride()"
            [class.block-cluster__dnd-column_disabled]="!column.isActive"
            [class.block-cluster__dnd-column_readonly]="isUserInputDisabled"
          >
            <div class="block-cluster__dnd-column-title">
              <app-text-editor
                [content]="column.value"
                [isViewMode]="true"
                [class.view-mode]="true"
                class="block-common__field"
              ></app-text-editor>
              <div class="block-cluster__tooltip">
                <mat-icon [matTooltip]="column.description | htmlToText" [svgIcon]="AppIcon.INFO_20"></mat-icon>
              </div>
            </div>

            <div
              class="block-cluster__options"
              cdkDropList
              cdkDropListSortingDisabled
              (cdkDropListDropped)="onDropOption($event, column)"
              [cdkDropListData]="column.options"
            >
              <div
                *ngFor="let option of column.options"
                [cdkDragData]="option"
                [class.block-cluster__option_selected]="!isUserInputDisabled"
                cdkDrag
                cdkDragBoundary=".block-cluster__dnd-columns-wrap"
                class="block-cluster__option"
              >
                <div>
                  <app-text-editor
                    [content]="option.value"
                    [isViewMode]="true"
                    [class.view-mode]="true"
                    class="block-common__field block-cluster__option-content"
                  ></app-text-editor>
                  <app-text-editor
                    [content]="getOptionGroup(option.groupId)?.value"
                    [isViewMode]="true"
                    [class.view-mode]="true"
                    class="block-common__field block-cluster__option-content small-text"
                  ></app-text-editor>
                </div>
                <button
                  *ngIf="!isUserInputDisabled"
                  mat-icon-button
                  (click)="deleteAnswerOption(option)"
                  class="block-cluster__option-delete"
                >
                  <mat-icon [svgIcon]="AppIcon.CLOSE_CIRCLE"></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isUserInputDisabled">
        <mat-divider class="block-cluster__divider"></mat-divider>
        <table class="block-cluster__descriptions-table mt-4">
          <tbody>
            <tr class="font-semibold">
              <th>{{ 'INPUT_NAME.NAME_TITLE' | translate }}</th>
              <th>{{ pageKey + 'DESCRIPTION' | translate }}</th>
              <th [innerHTML]="block.optionsTitle || (pageKey + 'OPTION' | translate)"></th>
              <th>{{ pageKey + 'CATEGORY' | translate }}</th>
            </tr>
            @for (option of selectedOptions(); track option.id) {
              <tr>
                <td>
                  <app-text-editor
                    [content]="option.value"
                    [isViewMode]="true"
                    [class.view-mode]="true"
                    class="block-common__field"
                  ></app-text-editor>
                </td>
                <td>
                  <app-text-editor
                    [content]="option.description"
                    [isViewMode]="true"
                    [class.view-mode]="true"
                    class="block-common__field"
                  ></app-text-editor>
                </td>
                <td>
                  <app-text-editor
                    [content]="getOptionGroup(option.groupId).value"
                    [isViewMode]="true"
                    [class.view-mode]="true"
                    class="block-common__field"
                  ></app-text-editor>
                </td>
                <td>
                  <app-text-editor
                    [content]="getColumnValueById(option.columnId)"
                    [isViewMode]="true"
                    [class.view-mode]="true"
                    class="block-common__field"
                  ></app-text-editor>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <app-text-editor
        *ngIf="block.isInstructionEnabled && !isUserInputDisabled"
        [content]="block.instruction"
        [isViewMode]="true"
        [class.view-mode]="true"
        class="block-common__field block-cluster__instruction"
      ></app-text-editor>
    </div>
  }
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.CLUSTER' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.DYNAMIC_OPTIONS' | translate }}</span>
      </h4>
      <base-boolean-field
        [control]="dynamicOptionsToggle"
        [type]="BooleanFieldType.toggle"
        [disabled]="customOptionToggle.value"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>

    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.CUSTOM_OPTIONS' | translate }}</span>
      </h4>
      <base-boolean-field
        [control]="customOptionToggle"
        [type]="BooleanFieldType.toggle"
        [disabled]="dynamicOptionsToggle.value"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>
          {{
            pageKey + 'SETTINGS.COLUMNS_COUNT'
              | translate
                : {
                    min: minColumnsCount,
                    max: maxColumnsCount
                  }
          }}
        </span>
      </h4>
      <base-form-input
        [control]="columnsCountControl"
        [hideLabel]="true"
        [inputType]="inputType.number"
        [max]="maxColumnsCount.toString()"
        [min]="minColumnsCount.toString()"
        [placeholder]="pageKey + 'SETTINGS.COLUMNS_COUNT' | translate: { min: minColumnsCount, max: maxColumnsCount }"
      ></base-form-input>
    </div>
    <mat-divider></mat-divider>

    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
