import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@directives/directives.module';
import { AppFormsModule } from '@forms/forms.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes/pipes.module';
import { BlocksModule } from '@shared/blocks/blocks.module';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { MaterialModule } from '@shared/material/material.module';
import { ModalModule } from '@shared/modal/modal.module';

// Components
@NgModule({
  exports: [
    MaterialModule,
    RouterModule,
    AppFormsModule,
    DirectivesModule,
    PipesModule,
    SharedComponentsModule,
    ModalModule,
    TranslateModule,
    BlocksModule
  ]
})
export class SharedModule {}
