import { BlockIdPrefix, SegmentIdPrefix } from '@shared/blocks/models/block-id-prefix';
import { BlockType } from '@shared/blocks/models/block.model';
import { getRandomIdentifier } from './get-random-identifier.function';

const idLength: number = 3;

export function getBlockIdentifier(blockType?: BlockType): string {
  const randomIdentifier = getRandomIdentifier();
  const id: string = randomIdentifier.slice(0, idLength);
  return blockType ? `${SegmentIdPrefix.BLOCK}${BlockIdPrefix[blockType]}${id}` : `${SegmentIdPrefix.OPTION}${id}`;
}
