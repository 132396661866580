<div [class.view-mode]="!isEditMode" [ngStyle]="!isEditMode && blockViewStyles" class="block-input">
  <div class="block-input__sections">
    <div>
      <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.INPUT.TITLE' | translate }}</div>
      <div class="block-input__question">
        <app-text-editor
          (contentChanged)="questionChanged($event)"
          [class.view-mode]="!isEditMode"
          [content]="block.question"
          [isViewMode]="!isEditMode"
          class="block-text"
        ></app-text-editor>
      </div>
    </div>

    <div *ngFor="let option of block.options; index as i" class="block-input__option-card">
      <div class="block-input__card-content">
        <div class="block-input__content">
          <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.INPUT.TITLE' | translate }}</div>

          <div *ngIf="isEditMode" class="block-common__option-wrap">
            <app-block-id [blockID]="[block.id, option.id]"></app-block-id>
            <div class="block-common__field block-input__text-editor">
              <app-text-editor
                (contentChanged)="titleChanged(option, $event)"
                [class.view-mode]="!isEditMode"
                [content]="option.title"
                [isViewMode]="!isEditMode"
                class="block-text"
              ></app-text-editor>
            </div>
          </div>

          <div *ngIf="!isEditMode" class="block-input__text-editor">
            <app-text-editor
              (contentChanged)="titleChanged(option, $event)"
              [class.view-mode]="!isEditMode"
              [content]="option.title"
              [isViewMode]="!isEditMode"
              class="block-text"
            ></app-text-editor>
          </div>
        </div>
        <div class="block-input__label">
          <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.INPUT.LABEL' | translate }}</div>
          <div *ngIf="isEditMode || option.label" class="block-input__text-editor">
            <app-text-editor
              (contentChanged)="labelChanged(option, $event)"
              [class.block-text_disabled]="isUserInputDisabled"
              [class.view-mode]="!isEditMode"
              [content]="option.label"
              [isViewMode]="!isEditMode"
              class="block-text"
            ></app-text-editor>
          </div>
          <input *ngIf="!isEditMode" [formControl]="getInputControl(i)" class="block-input__input" type="text" />
        </div>
      </div>
    </div>

    <div [class.mt-2]="!isEditMode" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <div *ngIf="isEditMode" class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        [class.view-mode]="!isEditMode"
        [isViewMode]="!isEditMode"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.INPUT' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>
    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.INPUT.SETTINGS.OPTIONS_COUNT_FIELD' | translate }}</span>
      </h4>
      <base-form-input [control]="optionsCountControl" [hideLabel]="true" [inputType]="InputType.number" max="10" min="1"></base-form-input>
    </div>
    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
