import { Component, inject, Input, OnInit } from '@angular/core';
import { AppFormsModule } from '@forms/forms.module';
import { BaseTextareaModule } from '@forms/base-textarea/base-textarea.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { IModalComponentContext } from '@shared/modal/modal.component';
import { BlockAnswerOption, BlockClusterOption } from '@shared/blocks/models/block.model';
import { NgIf } from '@angular/common';
import { HtmlToTextPipe } from '@pipes/htmlToText/html-to-text.pipe';

export interface IBlockClusterModalContext {
  option: BlockClusterOption;
  answer: BlockAnswerOption;
}

@Component({
  selector: 'app-block-cluster-answer-modal',
  standalone: true,
  imports: [AppFormsModule, BaseTextareaModule, TranslateModule, NgIf],
  providers: [HtmlToTextPipe],
  templateUrl: './block-cluster-answer-modal.component.html',
  styleUrl: './block-cluster-answer-modal.component.scss'
})
export class BlockClusterAnswerModalComponent extends BaseFormAbstractComponent implements OnInit {
  @Input() context: IModalComponentContext<IBlockClusterModalContext>;
  private readonly _htmlToText: HtmlToTextPipe = inject(HtmlToTextPipe);

  get option(): BlockClusterOption {
    return this.context.entity?.option;
  }

  get answer(): BlockAnswerOption {
    return this.context.entity?.answer;
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.answer?.value || '', Validators.required),
      group: new FormControl(
        {
          value: this._htmlToText.transform(this.option?.value),
          disabled: true
        },
        Validators.required
      ),
      description: new FormControl(this.answer?.description || '')
    });
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.context.dialog.close({
      ...this.answer,
      value: this.form.name.value,
      description: this.form.description.value
    } as BlockAnswerOption);
  }
}
