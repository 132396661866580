import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, debounceTime, of, startWith, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { fadeInOut } from '@base/animations/appearance.animations';
import { BaseComponent } from '@base/common/shared/components/base/base.component';
import { AbstractModel } from '@models/classes/_base.model';
import { AppIcon } from '@models/enums/app.icons';

@Component({
  selector: 'app-custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: ['./custom-autocomplete.component.scss'],
  animations: [fadeInOut(100)]
})
export class CustomAutocompleteComponent extends BaseComponent implements OnInit {
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;
  @Input() listItemTemplate: TemplateRef<any>;
  @Input() selectedList: AbstractModel[] = [];
  @Input() placeholder: string;
  @Input() useLocalFiltering: boolean;
  @Input() searchDebounceTime: number = 300;
  @Input() addItem$: (id: string) => Observable<AbstractModel>;
  @Input() removeItem$: (id: string) => Observable<void>;
  readonly AppIcon = AppIcon;
  readonly search$: Subject<string> = new Subject<string>();
  filteredSearchList: AbstractModel[];
  isOpened: boolean;
  private _searchList: AbstractModel[];

  @Input() getItems$: (s: string) => Observable<AbstractModel[]> = () => of([]);

  ngOnInit(): void {
    this.search$
      .pipe(takeUntilDestroyed(this._destroyRef), startWith(''), debounceTime(this.searchDebounceTime), distinctUntilChanged())
      .subscribe(this._getList.bind(this));
  }

  selectItem(item: AbstractModel): void {
    // FIXME: Calling this method multiple times causes multiple subscriptions
    (this.addItem$ ? this.addItem$(item.id) : of(null)).subscribe(() => {
      this.selectedList?.push(item);
      this._filterList();
      this.isOpened = false;
    });
    this.inputRef.nativeElement.value = '';
    this.search$.next('');
  }

  removeItem(item: AbstractModel): void {
    // FIXME: Calling this method multiple times causes multiple subscriptions
    (this.removeItem$ ? this.removeItem$(item.id) : of(null)).subscribe(() => {
      this.selectedList?.splice(
        this.selectedList.findIndex((selected: AbstractModel) => selected.id === item.id),
        1
      );
      this._filterList();
    });
  }

  handleFocusOut(): void {
    timer(200).subscribe(() => (this.isOpened = false));
  }

  private _getList(query: string = ''): void {
    // FIXME: Calling this method multiple times causes multiple subscriptions
    this.getItems$(query).subscribe(this._filterList.bind(this));
  }

  private _filterList(list: AbstractModel[] = this._searchList): void {
    this._searchList = list;
    this.filteredSearchList = list.filter(
      (item: AbstractModel) => !this.selectedList.some((selected: AbstractModel) => selected.id === item.id)
    );
  }
}
