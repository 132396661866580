import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { MetaType } from '@models/classes/meta-type.model';

@Injectable({
  providedIn: 'root'
})
export class MetaTypeApiService extends ApiBaseAbstractService<MetaType> {
  protected readonly _URL_PATH: string = '/admin/metatypes';
  protected readonly _MODEL: ClassConstructor<MetaType> = MetaType;
}
