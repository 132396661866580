import { Component, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';

import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { ErrorsKeyPasswordEnum } from '@models/enums/errors-key-password.enum';

@Component({
  selector: 'app-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss']
})
export class PasswordValidationComponent extends BaseFormAbstractComponent implements OnInit {
  readonly PAGE_KEY: string = 'PASSWORD_VALIDATION.';
  @Input() override formGroup: FormGroup;
  errorKey: typeof ErrorsKeyPasswordEnum = ErrorsKeyPasswordEnum;
  errorsShow: Params = {};

  ngOnInit(): void {
    const passwordControl: FormControl = this.getControl('password');
    const passwordConfirm: FormControl = this.getControl('passwordConfirm');

    this.formGroup.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      const errorValue: Params = {};
      errorValue[ErrorsKeyPasswordEnum.minLength] = !!passwordControl.errors?.[ErrorsKeyPasswordEnum.minLength];
      errorValue[ErrorsKeyPasswordEnum.upperCaseLetter] = !!passwordControl.errors?.[ErrorsKeyPasswordEnum.upperCaseLetter];
      errorValue[ErrorsKeyPasswordEnum.lowerCaseLetter] = !!passwordControl.errors?.[ErrorsKeyPasswordEnum.lowerCaseLetter];
      errorValue[ErrorsKeyPasswordEnum.symbols] = !!passwordControl.errors?.[ErrorsKeyPasswordEnum.symbols];
      errorValue[ErrorsKeyPasswordEnum.digits] = !!passwordControl.errors?.[ErrorsKeyPasswordEnum.digits];
      errorValue[ErrorsKeyPasswordEnum.mustMatch] = !!passwordConfirm.errors?.[ErrorsKeyPasswordEnum.mustMatch];
      this.errorsShow = errorValue;
    });
  }
}
