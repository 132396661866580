<div
  [style.--slider-parts-count]="options.length"
  (mouseleave)="setHoverIndex(null)"
  [class.view-mode]="!isEditMode"
  [ngStyle]="!isEditMode && blockViewStyles"
  class="block-slider"
>
  <div>
    <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.SLIDER.TITLE' | translate }}</div>
    <div class="block-slider__question">
      <app-text-editor
        (contentChanged)="questionChanged($event)"
        [class.view-mode]="!isEditMode"
        [content]="block.question"
        [isViewMode]="!isEditMode"
      ></app-text-editor>
      <div *ngIf="!isEditMode && showTooltip" class="block-slider__tooltip">
        <mat-icon [matTooltip]="tooltipContent" [svgIcon]="AppIcon.INFO_20"></mat-icon>
      </div>
    </div>
  </div>

  <div *ngIf="showDescription">
    <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.SLIDER.DESCRIPTION' | translate }}</div>
    <div class="block-slider__question">
      <app-text-editor
        (contentChanged)="descriptionChanged($event)"
        [class.view-mode]="!isEditMode"
        [content]="block.description"
        [isViewMode]="!isEditMode"
      ></app-text-editor>
    </div>
  </div>

  <div [class.block-slider__card_disabled]="isUserInputDisabled" class="block-slider__card">
    <div *ngIf="showLabels" class="block-slider__labels">
      <div class="block-slider__text-editor">
        <app-text-editor
          (contentChanged)="labelLeftChanged($event)"
          [class.view-mode]="!isEditMode"
          [content]="block?.labelLeft ?? ''"
          [isViewMode]="!isEditMode"
          [placeholder]="'BLOCK.SLIDER.LABEL_LEFT_PLACEHOLDER'"
        ></app-text-editor>
      </div>
      <div class="block-slider__text-editor">
        <app-text-editor
          (contentChanged)="labelRightChanged($event)"
          [class.view-mode]="!isEditMode"
          [content]="block?.labelRight ?? ''"
          [isViewMode]="!isEditMode"
          [placeholder]="'BLOCK.SLIDER.LABEL_RIGHT_PLACEHOLDER'"
        ></app-text-editor>
      </div>
    </div>

    <div class="block-slider__slider">
      <div class="block-slider__track">
        <div *ngFor="let part of activeParts" [ngStyle]="part" class="block-slider__thumb"></div>
      </div>
      <div class="block-slider__points">
        <div *ngFor="let option of options; index as i" class="block-slider__point-container">
          <button
            [disabled]="!option.active"
            (click)="setSelectedPoint(i)"
            (mouseenter)="setHoverIndex(i)"
            [ngClass]="{ 'block-slider__point_selected': i === selectedIndex }"
            class="block-slider__point"
          >
            <span></span>
          </button>
        </div>
      </div>
    </div>

    <div class="block-slider__option-list">
      <div *ngIf="!isEditMode" class="block-slider__point-labels">
        <div *ngFor="let option of options; index as i" class="block-slider__point-label">
          <app-text-editor [class.view-mode]="true" [content]="option.title" [isViewMode]="true" class="w-full"></app-text-editor>
        </div>
      </div>
      <div *ngIf="isEditMode" class="block-slider__options">
        <div *ngFor="let option of options; index as i" class="block-slider__option">
          <div class="block-common__option-wrap">
            <app-block-id [blockID]="[block.id, option.id]"></app-block-id>
            <div class="block-common__field block-slider__text-editor">
              <app-text-editor
                (contentChanged)="optionTitleChanged(i, $event)"
                [class.view-mode]="!isEditMode"
                [content]="option.title"
                [isViewMode]="!isEditMode"
                [placeholder]="'BLOCK.SLIDER.OPTION_TITLE_PLACEHOLDER' | translate: { index: i + 1 }"
              ></app-text-editor>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isEditMode && showDescriptions" class="block-slider__options">
        <div *ngFor="let option of options; index as i" class="block-slider__option">
          <div class="block-slider__text-editor">
            <app-text-editor
              (contentChanged)="optionDescriptionChanged(i, $event)"
              [class.view-mode]="!isEditMode"
              [content]="option.description"
              [isViewMode]="!isEditMode"
              [placeholder]="'BLOCK.SLIDER.OPTION_DESCRIPTION_PLACEHOLDER'"
            ></app-text-editor>
          </div>
        </div>
      </div>
      <div *ngIf="isEditMode" class="block-slider__options">
        <div *ngFor="let option of options; index as i" class="block-slider__option">
          <div class="block-slider__active-toggle">
            <div>{{ 'CHART.ACTIVE' | translate }}?</div>
            <base-boolean-field
              (controlChange)="optionActiveChanged(i, $event)"
              [type]="BooleanFieldType.toggle"
              [value]="option.active"
              class="mat-mdc-slide-toggle_blue"
            ></base-boolean-field>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showViewDescription" class="block-slider__description">
      <div class="block-slider__arrows">
        <div *ngFor="let option of options; index as i" class="block-slider__arrow-container">
          <div [ngClass]="{ 'block-slider__arrow_selected': i === (hoveredIndex ?? selectedIndex) }" class="block-slider__arrow"></div>
        </div>
      </div>
      <app-text-editor
        [class.view-mode]="true"
        [content]="options[hoveredIndex ?? selectedIndex].description"
        [isViewMode]="true"
      ></app-text-editor>
    </div>

    <div *ngIf="isEditMode && showTooltip" class="block-slider__tooltip-wrapper">
      <div class="block-slider__tooltip-label">{{ 'BLOCK.SLIDER.TOOLTIP_LABEL' | translate }}</div>
      <div class="block-slider__text-editor">
        <app-text-editor
          (contentChanged)="tooltipChanged($event)"
          [class.view-mode]="!isEditMode"
          [content]="block.tooltip"
          [isViewMode]="!isEditMode"
          [placeholder]="'MODULES.TEXT_BLOCK_PLACEHOLDER'"
        ></app-text-editor>
      </div>
    </div>
  </div>

  <div *ngIf="block.settings?.showInstruction && !isUserInputDisabled">
    <div *ngIf="isEditMode" class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.instruction = $event; save()"
      [content]="block.instruction"
      [class.view-mode]="!isEditMode"
      [isViewMode]="!isEditMode"
      class="block-common__field block-common__field_last"
    ></app-text-editor>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.SLIDER' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.SLIDER.SETTINGS.SHOW_EXTRA_DESCRIPTION_TOGGLE' | translate }}</span>
        <base-boolean-field
          [control]="showDescriptionControl"
          [type]="BooleanFieldType.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
    </div>

    <mat-divider></mat-divider>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.SLIDER.SETTINGS.SHOW_LABELS_TOGGLE' | translate }}</span>
        <base-boolean-field
          [control]="showLabelsControl"
          [type]="BooleanFieldType.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
    </div>

    <mat-divider></mat-divider>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.SLIDER.SETTINGS.OPTIONS_COUNT_FIELD' | translate }}</span>
      </h4>
      <base-form-input [control]="optionsCountControl" [hideLabel]="true" [inputType]="InputType.number" max="10" min="1"></base-form-input>
    </div>

    <mat-divider></mat-divider>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.SLIDER.SETTINGS.SHOW_ITEM_DESCRIPTION_TOGGLE' | translate }}</span>
        <base-boolean-field
          [control]="showDescriptionsControl"
          [type]="BooleanFieldType.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
    </div>

    <mat-divider></mat-divider>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.SLIDER.SETTINGS.SHOW_TOOLTIP_TOGGLE' | translate }}</span>
        <base-boolean-field
          [control]="showTooltipControl"
          [type]="BooleanFieldType.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
    </div>

    <mat-divider></mat-divider>

    <app-block-instruction-settings [isInstructionEnabled]="block.settings?.showInstruction"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
