import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { debounceTime } from 'rxjs';
import { filter } from 'rxjs/operators';

import { expandInOut } from '@base/animations/appearance.animations';
import { BooleanFieldType } from '@base/common/modules/forms/base-boolean-field/base-boolean-field.component';
import { getBlockIdentifier, numberToLetter } from '@misc/helpers';
import { ISegmentAnswerValue, SegmentAnswer } from '@models/classes/module-answer.model';
import { InputType } from '@models/enums/input-type.enum';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { BlockQuestion, QuestionBlockOption } from '@shared/blocks/models/block.model';

@Component({
  selector: 'app-block-question',
  templateUrl: './block-question.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-question.component.scss'],
  animations: [expandInOut(200)]
})
export class BlockQuestionComponent extends BaseBlockComponent<BlockQuestion> implements OnInit {
  readonly descriptionToggleControl: FormControl = new FormControl(false);
  readonly minOptionsCount: number = 2;
  readonly maxOptionsCount: number = 3;
  readonly optionsCountControl: FormControl = new FormControl(this.minOptionsCount, [
    Validators.min(this.minOptionsCount),
    Validators.max(this.maxOptionsCount)
  ]);
  answersForm: FormArray;
  selectedAnswer: ISegmentAnswerValue;
  commentControl: FormControl;
  readonly BooleanFieldType = BooleanFieldType;
  readonly inputType = InputType;
  readonly pageKey: string = 'BLOCK.QUESTION.';

  ngOnInit(): void {
    this.optionsCountControl.setValue(this.block.answerOptionsCount);
    this.descriptionToggleControl.setValue(this.block.isDescriptionEnabled);
    this._setAnswers();
    this._setCommentControl();
  }

  closeSetting(): void {
    this.optionsCountControl.setValue(this.block.answerOptionsCount);
    this.descriptionToggleControl.setValue(this.block.isDescriptionEnabled);
  }

  saveSetting(): void {
    this.block.answerOptionsCount = this.optionsCountControl.value;
    this.block.isDescriptionEnabled = this.descriptionToggleControl.value;
    this.block.styleSettings = this.updatedStyleSettings;
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this._setAnswers();
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  saveAnswer(option?: QuestionBlockOption): void {
    if (this.isUserInputDisabled) return;
    if (option) {
      this.selectedAnswer = {
        subId: option.id,
        value: option.value,
        meta: option.isCommentEnabled && this.commentControl.value
      };
    } else {
      this.selectedAnswer.meta = this.commentControl.value;
    }

    if (this.isEditMode || !this.isUserScope) return;

    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: [this.selectedAnswer]
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }

  numberToChar(num: number): string {
    return numberToLetter(num);
  }

  isCommentEnabled(): boolean {
    return this.block.answerOptions.find((item: QuestionBlockOption) => item.id === this.selectedAnswer?.subId)?.isCommentEnabled;
  }

  private _setCommentControl(): void {
    this.commentControl = new FormControl(this.selectedAnswer?.meta);
    this.commentControl.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(() => !this.isUserInputDisabled),
        debounceTime(1000)
      )
      .subscribe(() => this.saveAnswer());
  }

  private _setAnswers(): void {
    let answers: QuestionBlockOption[] = this.block.answerOptions || [];

    answers = Array(+this.optionsCountControl.value)
      .fill(null)
      .map((_: any, idx: number) => answers[idx] || plainToInstance(QuestionBlockOption, { id: getBlockIdentifier() }));

    this.block.answerOptions = answers;
    this.answersForm = new FormArray(
      answers.map(
        (item: QuestionBlockOption) =>
          new FormGroup({
            id: new FormControl(item.id),
            value: new FormControl(item.value),
            isCommentEnabled: new FormControl(item.isCommentEnabled)
          })
      )
    );

    this.answersForm.valueChanges.pipe(takeUntilDestroyed(this._destroyRef), debounceTime(1000)).subscribe((answerOptions: any) => {
      this.block.answerOptions = answerOptions;
      this.save();
    });

    this.selectedAnswer = this.segmentAnswer?.content?.at(0);
  }
}
