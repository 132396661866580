import { Component, Input, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { CqlValidator } from '@misc/helpers/cql-validator';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { BLOCK_CQL_DEFAULT, BlockCqlSettings } from '@shared/blocks/models/block-cql-settings.model';

@Component({
  selector: 'app-block-cql-settings',
  templateUrl: './block-cql-settings.component.html',
  styleUrls: ['./block-cql-settings.component.scss']
})
export class BlockCqlSettingsComponent implements OnInit {
  @Input() segments: ModuleSegment[];
  @Input() cql: string;
  readonly pageKey: string = 'SETTINGS.';
  private _settingsForm: FormGroup;
  private _fb: FormBuilder = inject(FormBuilder);
  private _cqlValidator: CqlValidator;

  get controls(): Record<keyof BlockCqlSettings, FormControl> {
    return this._settingsForm?.controls as Record<keyof BlockCqlSettings, FormControl>;
  }

  get settingsFormValue(): BlockCqlSettings {
    return this._settingsForm?.value;
  }

  ngOnInit(): void {
    this._settingsForm = this._fb.group({ cql: [this.cql || BLOCK_CQL_DEFAULT] });
    this._cqlValidator = new CqlValidator(this.segments);
    this.controls.cql.setValidators([this._cqlOperatorValidator.bind(this)]);
  }

  private _cqlOperatorValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.getRawValue();
    return this._cqlValidator.isValidQuery(value) ? null : { invalidQuery: true };
  }
}
