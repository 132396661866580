import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadCrumbsComponent } from './bread-crumbs.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CroppedTextModule } from '@shared/components/cropped-text/cropped-text.module';

@NgModule({
  declarations: [BreadCrumbsComponent],
  exports: [BreadCrumbsComponent],
  imports: [CommonModule, MatIconModule, RouterLink, TranslateModule, CroppedTextModule]
})
export class BreadCrumbsModule {}
