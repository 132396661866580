import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoonCardComponent } from './soon-card.component';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SoonCardComponent],
  exports: [SoonCardComponent],
  imports: [CommonModule, RouterLink, MatButtonModule, TranslateModule]
})
export class SoonCardModule {}
