import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';

import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { VALIDATORS_SET } from '@misc/constants/validators-set.constant';
import { difference } from '@misc/helpers/difference.function';
import { User } from '@models/classes/user.model';
import { AppIcon } from '@models/enums/app.icons';
import { UserRole } from '@models/enums/user-role.enum';
import { IOption } from '@models/interfaces/forms/option.interface';
import { IModalComponentContext, ModalComponent } from '@shared/modal/modal.component';
import { AdminOrganizationsService } from '@services/api/admin/organizations/admin-organizations.service';
import { Organization } from '@models/classes/organization.model';

@Component({
  selector: 'user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent extends BaseFormAbstractComponent implements OnInit {
  @Input() context: IModalComponentContext<User>;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  roleOptions: IOption[] = [];
  orgOptions: IOption[] = [];
  accordionOpened: boolean = false;
  readonly AppIcon = AppIcon;
  private readonly _AVAILABLE_ROLES: UserRole[] = [UserRole.admin];

  constructor(
    private _translate: TranslateService,
    private _fb: FormBuilder,
    private _organizationsService: AdminOrganizationsService
  ) {
    super();
  }

  get user(): User {
    return this.context?.entity;
  }

  get defaultValues(): User {
    return {} as User;
  }

  get dialog(): MatDialogRef<ModalComponent<User>> {
    return this.context?.dialog;
  }

  ngOnInit(): void {
    this._initOptions();
    this._initForm();
  }

  getModalResult(): User {
    return difference(this.formGroup.getRawValue(), this.defaultValues) as User;
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.dialog.close(this.getModalResult());
  }

  toggleMore(event: MouseEvent): void {
    event.stopPropagation();

    if (this.accordionOpened) {
      this.accordion.closeAll();
    } else {
      this.accordion.openAll();
    }

    this.accordionOpened = !this.accordionOpened;
  }

  private _initForm(): void {
    this.formGroup = this._fb.group({
      email: this._fb.control(this.defaultValues?.email, Validators.compose([Validators.required, VALIDATORS_SET.EMAIL])),
      firstName: this._fb.control(this.defaultValues?.firstName, Validators.compose([Validators.required, VALIDATORS_SET.NAME])),
      lastName: this._fb.control(this.defaultValues?.lastName, Validators.compose([Validators.required, VALIDATORS_SET.NAME])),
      company: this._fb.control(this.defaultValues?.company, Validators.compose([])),
      phoneNumber: this._fb.control(this.defaultValues?.phoneNumber, Validators.compose([VALIDATORS_SET.PHONE])),
      address: this._fb.control(this.defaultValues?.address, Validators.compose([])),
      addressAddition: this._fb.control(this.defaultValues?.addressAddition, Validators.compose([])),
      postCode: this._fb.control(this.defaultValues?.postCode, Validators.compose([])),
      city: this._fb.control(this.defaultValues?.city, Validators.compose([]))
    });
  }

  private _initOptions(): void {
    this.roleOptions = this._AVAILABLE_ROLES.map(
      (role: UserRole): IOption => ({
        value: role,
        label: this._translate.instant(`SERVICE_ROLE.${role.toUpperCase()}`),
        disabled: false
      })
    );
    this._organizationsService.getItems().subscribe((organizations: Organization[]): void => {
      this.orgOptions = organizations.map((item: Organization): IOption => ({ label: item.name, value: item.id }));
    });
  }
}
