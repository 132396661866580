import { ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from '@misc/custom-validators';

export interface IValidatorsSet {
  EMAIL: ValidatorFn | null;
  PASSWORD: ValidatorFn | null;
  PHONE: ValidatorFn | null;
  URL: ValidatorFn | null;
  NAME: ValidatorFn | null;
}

export const VALIDATORS_SET: Readonly<IValidatorsSet> = Object.freeze({
  EMAIL: Validators.compose([Validators.email, CustomValidators.fullEmail]),
  PASSWORD: Validators.compose([
    Validators.minLength(8),
    CustomValidators.upperCaseLetter,
    CustomValidators.lowerCaseLetter,
    CustomValidators.symbols,
    CustomValidators.digits
  ]),
  PHONE: Validators.compose([CustomValidators.phone]),
  URL: Validators.compose([CustomValidators.url]),
  NAME: Validators.compose([Validators.maxLength(90), CustomValidators.validName])
});
