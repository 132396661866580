import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppRoutingPaths } from '@base/app-routing.paths';
import { ModuleSection } from '@models/classes/module-section.model';
import { ModuleSite } from '@models/classes/module-site.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { Module } from '@models/classes/module.model';
import { Transformation } from '@models/classes/transformation.model';
import { AppIcon } from '@models/enums/app.icons';
import { TransformationStatus } from '@models/enums/transformation-status.enum';
import { INavLink } from '@models/interfaces/nav-link.interface';
import { TransformationService } from '@services/transformation/transformation.service';

@Component({
  selector: 'app-module-card',
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.scss']
})
export class ModuleCardComponent implements OnInit {
  @Input() transformation: Transformation;
  @Input() showTitle: boolean;

  readonly AppIcon = AppIcon;
  readonly AppRoutingPaths = AppRoutingPaths;

  private readonly _router: Router = inject(Router);
  private readonly _transformationService: TransformationService = inject(TransformationService);

  breadcrumbsList: INavLink[];

  get module(): Module {
    return this.transformation.module;
  }

  get isStartDisabled(): boolean {
    return this.transformation.status === TransformationStatus.COMPLETED;
  }

  get startButtonName(): string {
    return {
      [TransformationStatus.NOT_STARTED]: 'BUTTON_NAME.BEGIN_TRANSFORMATION',
      [TransformationStatus.IN_PROGRESS]: 'BUTTON_NAME.CONTINUE_TRANSFORMATION',
      [TransformationStatus.COMPLETED]: 'BUTTON_NAME.COMPLETED_TRANSFORMATION'
    }[this.transformation.status];
  }

  ngOnInit(): void {
    this._setBreadcrumbs();
  }

  getI18Key(key: string): string {
    return `MODULES.${key}`;
  }

  onStartClick(toCurrentStep?: boolean): void {
    this._transformationService.getCurrentTransformation(this.transformation.id).subscribe((item: Transformation) => {
      this.transformation.module = item.module;
      this._transformationService.startTransformation(toCurrentStep);
    });
  }

  onOverviewClick(): void {
    const route = AppRoutingPaths.CLIENT_TRANSFORMATIONS_MODULE_OVERVIEW.replace(':id', this.transformation.id);
    this._router.navigate([route]);
  }

  onPlaybookClick(): void {
    const route = AppRoutingPaths.CLIENT_TRANSFORMATIONS_MODULE_PLAYBOOK.replace(':id', this.transformation.id);
    this._router.navigate([route]);
  }

  private _setBreadcrumbs(): void {
    const site: ModuleSite = this.transformation.latest;
    if (!site) return;
    this.breadcrumbsList = [
      { icon: AppIcon.BULB, route: null },
      {
        name: ((site.step as ModuleStep)?.section as ModuleSection)?.name,
        route: null
      },
      { name: (site.step as ModuleStep)?.name, route: null },
      { name: site.name, route: null }
    ];
  }
}
