<div [class.view-mode]="!isEditMode" [ngStyle]="!isEditMode && blockViewStyles" class="block-role-input">
  <div class="block-role-input__sections">
    <div>
      <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.ROLE_INPUT.TITLE' | translate }}</div>
      <app-text-editor
        (contentChanged)="questionChanged($event)"
        [class.view-mode]="!isEditMode"
        [content]="block.question"
        [isViewMode]="!isEditMode"
        class="block-common__field"
      ></app-text-editor>
    </div>

    <div *ngFor="let option of block.options; index as i" class="block-role-input__option-card">
      <div class="block-role-input__content">
        <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.ROLE_INPUT.TITLE' | translate }}</div>
        <div class="block-common__option-wrap block-role-input">
          <app-block-id *ngIf="isEditMode" [blockID]="[block.id, option.id]"></app-block-id>
          <div class="block-common__field block-role-input__text-editor">
            <app-text-editor
              (contentChanged)="titleChanged(option, $event)"
              [class.view-mode]="!isEditMode"
              [content]="option.title"
              [isViewMode]="!isEditMode"
              class="block-text"
            ></app-text-editor>
          </div>
        </div>
      </div>
      <div *ngIf="!isEditMode" class="block-role-input__label">
        <div class="block-common__option-wrap">
          <div *ngIf="isEditMode || option.labelExpense?.value" class="block-role-input__text-editor">
            <app-text-editor
              [class.view-mode]="true"
              [content]="'BLOCK.ROLE_INPUT.LABEL_OCCUPATION' | translate"
              [isViewMode]="true"
              class="block-text"
            ></app-text-editor>
          </div>
        </div>
        <base-select
          [hideLabel]="true"
          [isShowError]="false"
          [options]="questionsSelectOptions"
          [control]="getOccupationInputControl(i)"
          panelClass="custom-panel"
          classFormField="custom-field"
          class="block-role-input__occupation-select"
        ></base-select>
      </div>
      <div *ngIf="!isEditMode" class="block-role-input__label-icon"></div>
      <div class="block-role-input__label">
        <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.ROLE_INPUT.LABEL_EXPENSE' | translate }}</div>

        <div class="block-common__option-wrap">
          <app-block-id *ngIf="isEditMode" [blockID]="[block.id, option.id, option.labelExpense?.id]"></app-block-id>
          <div *ngIf="isEditMode || option.labelExpense?.value" class="block-common__field block-role-input__text-editor">
            <app-text-editor
              (contentChanged)="labelExpenseChanged(option, $event)"
              [class.view-mode]="!isEditMode"
              [content]="option.labelExpense?.value"
              [isViewMode]="!isEditMode"
              class="block-text"
            ></app-text-editor>
          </div>
        </div>
        <input *ngIf="!isEditMode" type="number" [formControl]="getExpenseInputControl(i)" class="block-role-input__input" />
      </div>
      <div *ngIf="!isEditMode" [ngClass]="{ invisible: !isOccupationExtern(i) }" class="block-role-input__label-icon">
        <mat-icon [svgIcon]="AppIcon.CLOSE"></mat-icon>
      </div>
      <div [ngClass]="{ invisible: !isOccupationExtern(i) }" class="block-role-input__label">
        <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.ROLE_INPUT.LABEL_COST' | translate }}</div>

        <div class="block-common__option-wrap">
          <app-block-id *ngIf="isEditMode" [blockID]="[block.id, option.id, option.labelCost?.id]"></app-block-id>
          <div *ngIf="isEditMode || option.labelExpense?.value" class="block-common__field block-role-input__text-editor">
            <app-text-editor
              (contentChanged)="labelCostChanged(option, $event)"
              [class.view-mode]="!isEditMode"
              [content]="option.labelCost?.value"
              [isViewMode]="!isEditMode"
              class="block-text"
            ></app-text-editor>
          </div>
        </div>

        <input *ngIf="!isEditMode" type="number" [formControl]="getCostInputControl(i)" class="block-role-input__input" />
      </div>
      <div *ngIf="!isEditMode" class="block-role-input__label-icon"></div>

      <div [ngClass]="{ invisible: !isOccupationExtern(i) }" class="block-role-input__label">
        <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.ROLE_INPUT.LABEL_TOTAL' | translate }}</div>

        <div class="block-common__option-wrap">
          <app-block-id *ngIf="isEditMode" [blockID]="[block.id, option.id, option.labelTotal?.id]"></app-block-id>
          <div *ngIf="isEditMode || option.labelExpense?.value" class="block-common__field block-role-input__text-editor">
            <app-text-editor
              (contentChanged)="labelTotalChanged(option, $event)"
              [class.view-mode]="!isEditMode"
              [content]="option.labelTotal?.value"
              [isViewMode]="!isEditMode"
              class="block-text"
            ></app-text-editor>
          </div>
        </div>

        <input
          *ngIf="!isEditMode"
          [value]="getCalculatedResult(i)"
          class="block-role-input__input block-role-input__input_total"
          disabled
          type="text"
        />
      </div>
    </div>

    <div [class.mt-3]="!isEditMode" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <div *ngIf="isEditMode" class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        [class.view-mode]="!isEditMode"
        [isViewMode]="!isEditMode"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.ROLE_INPUT' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ 'BLOCK.INPUT.SETTINGS.OPTIONS_COUNT_FIELD' | translate }}</span>
      </h4>
      <base-form-input [control]="optionsCountControl" [hideLabel]="true" [inputType]="InputType.number" max="10" min="1"></base-form-input>
    </div>
    <mat-divider></mat-divider>
    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
