<div class="modal">
  <mat-tab-group *ngIf="isTabsVisible" class="modal__tabs">
    <mat-tab [label]="getI18Key('TAB_1') | translate">
      <base-form-input
        [control]="form.fileLink"
        [placeholder]="getI18Key(inputPlaceholder[mediaType]) | translate"
        class="modal__input"
      ></base-form-input>
      <modal-actions (submitted)="onSubmit()" [actions]="addLinkActions[mediaType]" class="modal__actions"></modal-actions>
    </mat-tab>

    <mat-tab [label]="getI18Key('TAB_2') | translate">
      <app-file-upload
        (uploaded)="handleUploadedFile($event)"
        (cancelled)="dialog.close(false)"
        [buttonTitle]="getI18Key(uploadButtonTitle[mediaType])"
        [mimeType]="mediaType"
      ></app-file-upload>
    </mat-tab>
  </mat-tab-group>
</div>
