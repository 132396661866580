<div class="paginator">
  <div class="paginator__info">
    {{ info }}
  </div>
  <pagination-template
    class="paginator__list"
    #p="paginationApi"
    [id]="paginatePipeArgs.id"
    [style.margin-top]="p.getLastPage() > 1 ? null : 0"
    [maxSize]="5"
    (pageChange)="paginate($event)"
  >
    <ng-container *ngIf="p.getLastPage() > 1">
      <button
        *ngFor="let page of p.pages"
        mat-button
        class="paginator__button paginator__button_page"
        [class.paginator__button_current]="p.getCurrent() === page.value"
        (click)="p.setCurrent(page.value)"
      >
        {{ page.label }}
      </button>
    </ng-container>
  </pagination-template>
  <div class="paginator__per-page">
    <base-select
      class="paginator__select"
      [control]="perPageControl"
      [options]="optionsPerPage"
      [triggerTemplate]="selectValue"
      [optionTemplate]="selectOption"
      panelClass="table-select-dialog"
    ></base-select>

    <ng-template #selectValue let-value>{{ value }} {{ 'BUTTON_NAME.ROWS' | translate }}</ng-template>

    <ng-template #selectOption let-value>{{ value.value }} {{ 'BUTTON_NAME.ROWS' | translate }}</ng-template>
  </div>
</div>
