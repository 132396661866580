<div mat-dialog-actions class="modal-actions" [class.modal-actions__confirm-actions]="isConfirm" *ngIf="actions">
  <ng-container *ngFor="let action of actions">
    <ng-container [ngSwitch]="action.type">
      <ng-container *ngSwitchCase="'submit'">
        <button
          mat-flat-button
          class="modal-actions__button modal-actions__button-submit"
          [ngClass]="action.class"
          [color]="action.color"
          (click)="submitted.emit(action.metadata || true)"
        >
          {{ action.name | translate }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'close'">
        <button
          mat-flat-button
          type="button"
          class="modal-actions__button"
          [mat-dialog-close]="action?.value"
          [ngClass]="action.class"
          [color]="action.color"
          (click)="navigate(action?.url)"
        >
          {{ action.name | translate }}
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
