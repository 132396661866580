import {
  BlockInput,
  BlockInputSelection,
  BlockNineFieldMatrix,
  BlockQuestion,
  BlockSingleChoice,
  BlockType
} from '@base/common/shared/blocks/models/block.model';
import { ModuleSegment } from '@base/models/classes/module-segment.model';
import { getBlockIdentifier } from './get-block-identifier.function';

type BlockLikeItem = Pick<ModuleSegment, 'template' | 'value'>;

export function updateBlockIdentifiers(item: BlockLikeItem): void {
  item.value.id = getBlockIdentifier(item.template);

  switch (item.template) {
    case BlockType.SINGLE_CHOICE: {
      (item.value as BlockSingleChoice).answers.forEach(option => {
        option.id = getBlockIdentifier();
      });
      break;
    }
    case BlockType.INPUT:
    case BlockType.SLIDER:
    case BlockType.ROLE_INPUT: {
      (item.value as BlockInput).options.forEach(option => {
        option.id = getBlockIdentifier();
      });
      break;
    }
    case BlockType.QUESTION: {
      (item.value as BlockQuestion).answerOptions.forEach(option => {
        option.id = getBlockIdentifier();
      });
      break;
    }
    case BlockType.INPUT_SELECTION: {
      (item.value as BlockInputSelection).questions.forEach(option => {
        option.id = getBlockIdentifier();
      });
      break;
    }
    case BlockType.NINE_FIELD_MATRIX: {
      const block: BlockNineFieldMatrix = item.value as BlockNineFieldMatrix;
      block.xConfig.min.id = getBlockIdentifier();
      block.xConfig.mid.id = getBlockIdentifier();
      block.xConfig.max.id = getBlockIdentifier();
      block.yConfig.min.id = getBlockIdentifier();
      block.yConfig.mid.id = getBlockIdentifier();
      block.yConfig.max.id = getBlockIdentifier();
      break;
    }
  }
}
