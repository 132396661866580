<label>
  <mat-form-field [appearance]="appearance" [class]="'' + classFormField">
    <mat-label *ngIf="!hideLabel">{{ placeholder }}</mat-label>
    <mat-icon *ngIf="icon" [svgIcon]="icon" matPrefix></mat-icon>
    <textarea
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMaxRows]="maxRows"
      [cdkAutosizeMinRows]="minRows"
      [formControl]="formControl"
      [maxLength]="maxLength"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [required]="required"
      cdkTextareaAutosize
      matInput
    ></textarea>
    <mat-error *ngIf="isShowError && control?.invalid && errorMessage">
      <span>{{ errorMessage }}</span>
    </mat-error>
  </mat-form-field>
</label>
