import { Clipboard } from '@angular/cdk/clipboard';
import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { AppIcon } from '@base/models/enums/app.icons';

@Component({
  selector: 'app-block-id',
  templateUrl: './block-id.component.html',
  styleUrls: ['./block-id.component.scss']
})
export class BlockIdComponent implements OnChanges {
  @Input() blockID: string | string[];
  @Input() mode: 'full' | 'icon' = 'icon';
  fullId: string;
  readonly AppIcon = AppIcon;
  private readonly _clipboard: Clipboard = inject(Clipboard);
  private readonly _notification: ToastrService = inject(ToastrService);
  private readonly _translate: TranslateService = inject(TranslateService);

  ngOnChanges({ blockID }: SimpleChanges): void {
    if (blockID) this._makeFullId(blockID.currentValue);
  }

  copyId(): void {
    this._clipboard.copy(this.fullId);
    this._notification.success(this._translate.instant('MESSAGE.COPIED'));
  }

  private _makeFullId(idPath: string | string[]): void {
    this.fullId = Array.isArray(idPath) ? idPath.join('.') : idPath;
  }
}
