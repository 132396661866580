<label>
  <mat-form-field [class]="'' + classFormField" [appearance]="appearance">
    @if (!hideLabel) {
      <mat-label>{{ placeholder }}</mat-label>
    }
    @if (icon) {
      <mat-icon matPrefix [svgIcon]="icon"></mat-icon>
    }
    <input
      matInput
      [formControl]="formControl"
      [placeholder]="placeholder"
      [required]="required"
      [readonly]="readonly"
      (focus)="handleFocus()"
      [autocomplete]="autocomplete"
      [matAutocomplete]="auto"
      [matAutocompletePosition]="autocompletePosition"
    />
    @if (formControl.value) {
      <button mat-icon-button matSuffix type="button" aria-label="Clear" tabindex="-1" class="form-field__close" (click)="onClear()">
        <mat-icon [svgIcon]="AppIcon.CLOSE"></mat-icon>
      </button>
    }
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayWith"
      hideSingleSelectionIndicator="true"
      class="base-autocomplete-panel"
    >
      <div>
        @for (item of options; let i = $index; track i) {
          <mat-option [value]="item">
            @if (optionTemplate) {
              <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item }"></ng-container>
            } @else {
              {{ displayWith(item) }}
            }
          </mat-option>
        }
      </div>
    </mat-autocomplete>
    @if (isShowError && control?.invalid && errorMessage) {
      <mat-error>
        <span>{{ errorMessage }}</span>
      </mat-error>
    }
  </mat-form-field>
</label>
