import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { AppIcon } from '@models/enums/app.icons';
import { FileType } from '@models/enums/file-type.enum';
import { IAvatar } from '@models/interfaces/avatar.interface';
import { ModalService } from '@shared/modal/modal.service';
import { AvatarEditModalComponent } from '@shared/modal/modals/avatar-edit-modal/avatar-edit-modal.component';

@Component({
  selector: 'app-photo-profile',
  templateUrl: './photo-profile.component.html',
  styleUrls: ['./photo-profile.component.scss']
})
export class PhotoProfileComponent {
  @Input() control: AbstractControl = new FormControl();
  @Input() fileType: FileType[];
  @Input() maxSizeFile: number;
  @Input() isEdit: boolean = true;
  @Input() previewHeight: number = 100;
  @Input() previewWidth: number = 100;
  @Input() defaultLabel: string = 'C';
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() changePhoto: EventEmitter<string> = new EventEmitter<string>();
  readonly AppIcon = AppIcon;
  private readonly _modalService = inject(ModalService);

  get img(): string {
    return this.control?.value;
  }

  uploadImage(): void {
    const dialog = this._modalService.open(
      {
        component: AvatarEditModalComponent,
        title: 'MODALS.AVATAR_HEADER',
        actions: [
          { name: 'BUTTON_NAME.CANCEL', type: 'close', color: 'accent' },
          { name: 'BUTTON_NAME.SAVE', type: 'submit', color: 'primary' }
        ],
        context: {
          entity: {
            src: this.control.value,
            types: this.fileType
          } as IAvatar
        }
      },
      { maxWidth: '45rem' }
    );

    dialog.subscribe(data => {
      this.control.setValue(data.src);
      this.changePhoto.emit(data.src);
    });
  }
}
