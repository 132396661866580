import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuillModules } from 'ngx-quill/config';
import { ContentChange } from 'ngx-quill/lib/quill-editor.component';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { quillModules } from '@shared/components/text-editor/quill-modules';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent extends BaseComponent implements OnInit {
  @Output() contentChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input() content: string;
  @Input() isViewMode: boolean;
  @Input() placeholder: string = 'INPUT_NAME.ENTER_TEXT_HERE';
  @Input() changeDelay: number = 1000;
  @Input() theme: 'bubble' | 'snow' = 'bubble';
  @Input() trackUserChangesOnly: boolean;
  modules: QuillModules = quillModules;
  contentChange$: Subject<ContentChange> = new Subject<ContentChange>();

  ngOnInit(): void {
    this.contentChange$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((event: ContentChange): boolean => (this.trackUserChangesOnly ? event?.source === 'user' : true)),
        map((event: ContentChange): string => event?.html || ''),
        distinctUntilChanged()
      )
      .subscribe((content: string): void => {
        this.contentChanged.emit(content);
      });
  }
}
