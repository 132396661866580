import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodoBlockComponent } from './todo-block.component';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [TodoBlockComponent],
  imports: [CommonModule, RouterLink, TranslateModule, MatButtonModule],
  exports: [TodoBlockComponent]
})
export class TodoBlockModule {}
