import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import { PipesModule } from '@pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/material/material.module';
import { PaginatorModule } from '@shared/components/paginator/paginator.module';
import { LoaderContainerModule } from '@shared/components/loader-container/loader-container.module';
import { CroppedTextModule } from '../cropped-text/cropped-text.module';
import { TableComponent } from './table/table.component';
import { DirectivesModule } from '@directives/directives.module';
import { AppFormsModule } from '@forms/forms.module';
import { DateComponent } from '@shared/components/data-table/cell-components/date/date.component';
import { StatusComponent } from './cell-components/status/status.component';
import { TableButtonsComponent } from './cell-components/table-buttons/table-buttons.component';
import { CeilElementHoverComponent } from '@shared/components/data-table/cell-components/ceill-element-hover/ceil-element-hover.component';
import { TableSelectComponent } from './cell-components/table-select/table-select.component';
import { EmptyBlockModule } from '@shared/components/empty-block/empty-block.module';

@NgModule({
  declarations: [
    DataTableComponent,
    TableComponent,
    DateComponent,
    StatusComponent,
    CeilElementHoverComponent,
    TableButtonsComponent,
    TableSelectComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule,
    MaterialModule,
    PaginatorModule,
    LoaderContainerModule,
    CroppedTextModule,
    DirectivesModule,
    AppFormsModule,
    EmptyBlockModule
  ],
  exports: [DataTableComponent, DateComponent, StatusComponent, CeilElementHoverComponent, TableButtonsComponent, TableSelectComponent]
})
export class DataTableModule {}
