import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ClassConstructor } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { attributesToModel } from '@misc/helpers/attributes-to-object.function';
import { convertToModelsArray } from '@misc/helpers/model-conversion/convert-to-models.array';
import { toModel } from '@misc/rxjs-operators/to-model.operator';
import { toModelsArray } from '@misc/rxjs-operators/to-models-array.operator';
import { ModuleAnswer, SegmentAnswer } from '@models/classes/module-answer.model';
import { ModuleMedia } from '@models/classes/module-media.model';
import { ModuleSite } from '@models/classes/module-site.model';
import { Playbook } from '@models/classes/playbook.model';
import { Transformation } from '@models/classes/transformation.model';
import { User } from '@models/classes/user.model';
import { TransformationGroupScope } from '@models/enums/transformation-group-scope.enum';
import { ICreateUpdateTransformationSegment } from '@models/interfaces/create-update-transformation-segment.interface';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { IServicesConfig } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class TransformationsApiService extends ApiBaseAbstractService<Transformation> {
  protected readonly _URL_PATH: string = '/transformations';
  protected readonly _MODEL: ClassConstructor<Transformation> = Transformation;
  protected readonly _MODEL_PLAYBOOK: ClassConstructor<Playbook> = Playbook;

  override getItems(params?: Params, servicesConfig?: IServicesConfig): Observable<Transformation[]> {
    return this._http.get(this.url, { params }, servicesConfig).pipe(toModelsArray(this._MODEL));
  }

  getCoachItems(
    scope: TransformationGroupScope,
    params?: Params,
    servicesConfig?: IServicesConfig
  ): Observable<[string, Transformation[]][]> {
    return this._http.get(`${this._BASE_URL}/coaches${this._URL_PATH}/${scope}`, { params }, servicesConfig).pipe(
      map((res: { objs: { [key: string]: Transformation[] } }) => {
        return Object.values(res.objs).map((items: Transformation[]) => {
          return [
            scope === TransformationGroupScope.module ? items[0]?.module?.name : items[0]?.organization?.name,
            convertToModelsArray(items, this._MODEL)
          ];
        });
      })
    );
  }

  override getItem(id: string, params?: Params, servicesConfig?: IServicesConfig): Observable<Transformation> {
    return this._http.get(`${this.url}/${id}`, { params }, servicesConfig).pipe(toModel(this._MODEL));
  }

  getPlaybookItem(id: string, params?: Params, servicesConfig?: IServicesConfig): Observable<Playbook> {
    return this._http.get(`${this.url}/${id}/playbook`, { params }, servicesConfig).pipe(toModel(this._MODEL_PLAYBOOK));
  }

  override updateItem(data: Partial<Transformation>, params?: Params, servicesConfig?: IServicesConfig): Observable<Transformation> {
    const body: Partial<Transformation> = { ...data };
    return this._http.patch(`${this.url}/${data.id}`, body, { params }, servicesConfig).pipe(toModel(this._MODEL));
  }

  start(id: string, params?: Params, servicesConfig?: IServicesConfig): Observable<Transformation> {
    return this._http.patch(`${this.url}/${id}/start`, null, { params }, servicesConfig).pipe(toModel(this._MODEL));
  }

  complete(id: string): Observable<void> {
    return this._http.patch(`${this.url}/${id}/complete`, null);
  }

  getCoaches(id: string, params?: Params, servicesConfig?: IServicesConfig): Observable<User[]> {
    return this._http
      .get(`${this.url}/${id}/coaches`, { params }, servicesConfig)
      .pipe(map(res => res.map((item: User) => attributesToModel(item, User, {}))));
  }

  getMedia(transformationId: string, mediaId: string, params?: Params, servicesConfig?: IServicesConfig): Observable<ModuleMedia> {
    return this._http.get(`${this.url}/${transformationId}/media/${mediaId}`, { params }, servicesConfig).pipe(toModel(ModuleMedia));
  }

  createOrUpdateTransformationSegment(
    transformationId: string,
    segmentId: string,
    answer: SegmentAnswer,
    answerId?: string
  ): Observable<ModuleAnswer> {
    const body: ICreateUpdateTransformationSegment = {
      segment: segmentId,
      answer: JSON.stringify(answer)
    };
    if (answerId) {
      body.id = answerId;
    }
    return this._http.patch(`${this.url}/${transformationId}/segments`, body).pipe(toModel(ModuleAnswer));
  }

  getTransformationSite(transformationId: string, siteId: string): Observable<ModuleSite> {
    return this._http.get(`${this.url}/${transformationId}/sites/${siteId}`).pipe(toModel(ModuleSite));
  }

  generatePdf(transformationId: string, html: string): Observable<any> {
    const body = JSON.stringify({ sourceHtml: html });
    return this._http.post(`${this.url}/${transformationId}/pdf`, body);
  }
}
