<ng-container [ngSwitch]="true">
  <quill-view *ngSwitchCase="!!isViewMode" [content]="content" class="editor"></quill-view>
  <quill-editor
    (onContentChanged)="contentChange$.next($event)"
    *ngSwitchCase="!isViewMode"
    [debounceTime]="changeDelay"
    [modules]="modules"
    [ngModel]="content"
    [placeholder]="placeholder | translate"
    [theme]="theme"
    class="editor"
  ></quill-editor>
</ng-container>
