import { inject, Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { HttpService, IServicesConfig } from '@services/http/http.service';
import { Observable } from 'rxjs';
import { toModel } from '@misc/rxjs-operators/to-model.operator';
import { ModuleMedia } from '@models/classes/module-media.model';
import { filter, map, tap } from 'rxjs/operators';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { ModuleService } from '@services/module/module.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class AdminMediaApiService extends ApiBaseAbstractService<ModuleMedia> {
  protected readonly _URL_PATH: string = '/admin/modules/:moduleId/media';
  protected readonly _MODEL: ClassConstructor<ModuleMedia> = ModuleMedia;
  protected readonly _moduleService: ModuleService = inject(ModuleService);

  constructor(_http: HttpService) {
    super(_http);
    this._moduleService.module$.pipe(filter(Boolean), takeUntilDestroyed()).subscribe(module => (this._URLParams = [module.id]));
  }

  override createItem(data: FormData, params?: ModuleMedia, servicesConfig?: IServicesConfig): Observable<ModuleMedia> {
    return this._http
      .post(
        this.url,
        data,
        {
          reportProgress: true,
          observe: 'events'
        },

        servicesConfig
      )
      .pipe(
        tap((res: HttpEvent<ModuleMedia>) => {
          if (res.type === HttpEventType.UploadProgress) {
            params.progress = Math.round((res.loaded / res.total) * 100);
          }
        }),
        filter((res: HttpEvent<ModuleMedia>): boolean => res.type === HttpEventType.Response),
        map((res: HttpEvent<ModuleMedia>): ModuleMedia => (res as HttpResponse<ModuleMedia>).body),
        toModel(this._MODEL)
      );
  }
}
