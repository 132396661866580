import { Component, inject, Input, OnInit } from '@angular/core';
import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BLOCK_STYLE_COLORS, BLOCK_STYLE_DEFAULT_COLOR, BlockStyleSettings } from '@shared/blocks/models/block-style-settings.model';
import { AppIcon } from '@base/models/enums/app.icons';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-block-styles-settings',
  templateUrl: './block-styles-settings.component.html',
  styleUrls: ['./block-styles-settings.component.scss']
})
export class BlockStylesSettingsComponent implements OnInit {
  @Input() settings: BlockStyleSettings;
  readonly pageKey: string = 'SETTINGS.';
  readonly BooleanFieldType = BooleanFieldType;
  readonly AppIcon = AppIcon;
  readonly colors: string[] = BLOCK_STYLE_COLORS;
  private _settingsForm: FormGroup;
  private _fb: FormBuilder = inject(FormBuilder);

  get controls(): Record<keyof BlockStyleSettings, FormControl> {
    return this._settingsForm?.controls as Record<keyof BlockStyleSettings, FormControl>;
  }

  get settingsFormValue(): BlockStyleSettings {
    return this._settingsForm?.value;
  }

  ngOnInit(): void {
    this._settingsForm = this._fb.group(this.settings || plainToInstance(BlockStyleSettings, { bgColor: BLOCK_STYLE_DEFAULT_COLOR }));
  }

  setBgColor(color: string): void {
    this.controls.bgColor.setValue(color);
  }
}
