import { Component, Input } from '@angular/core';

import { AppIcon } from '@models/enums/app.icons';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  @Input() value: boolean;
  readonly AppIcon = AppIcon;
}
