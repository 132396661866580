import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { plainToInstance } from 'class-transformer';

import { BooleanFieldType } from '@base/common/modules/forms/base-boolean-field/base-boolean-field.component';
import { ISegmentAnswerValue, SegmentAnswer } from '@base/models/classes/module-answer.model';
import { AppIcon } from '@base/models/enums/app.icons';
import { getBlockIdentifier } from '@misc/helpers';
import { BlockListing, BlockListingInputValue } from '../../models/block.model';
import { BaseBlockComponent } from '../base-block.component';

@Component({
  selector: 'app-block-listing',
  templateUrl: './block-listing.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-listing.component.scss']
})
export class BlockListingComponent extends BaseBlockComponent<BlockListing> implements OnInit {
  readonly tooltipToggleControl: FormControl = new FormControl(false);
  readonly AppIcon = AppIcon;
  readonly pageKey: string = 'BLOCK.LISTING.';
  readonly BooleanFieldType = BooleanFieldType;
  readonly optionName: FormControl = new FormControl('', [Validators.required]);
  readonly answers: BlockListingInputValue[] = [];

  ngOnInit(): void {
    this._setAnswers();
    this.tooltipToggleControl.setValue(this.block.isTooltipEnabled);
  }

  addOption(): void {
    if (this.optionName.invalid) return;
    this.answers.push(
      plainToInstance(BlockListingInputValue, {
        id: getBlockIdentifier(),
        value: this.optionName.value
      })
    );
    this.optionName.reset('');
    this.saveAnswer();
  }

  removeOption(idx: number): void {
    if (this.isUserInputDisabled) return;
    this.answers.splice(idx, 1);
    this.saveAnswer();
  }

  saveSetting(): void {
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.block.isTooltipEnabled = this.tooltipToggleControl.value;
    this.save();
  }

  saveAnswer(): void {
    if (this.isEditMode || !this.isUserScope || this.isUserInputDisabled) return;
    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: this.answers.map((item: BlockListingInputValue) => ({
        subId: item.id,
        value: item.value
      }))
    };
    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }

  private _setAnswers(): void {
    if (this.segmentAnswer?.content)
      this.answers.push(
        ...this.segmentAnswer.content.map((item: ISegmentAnswerValue<any>) => ({
          id: item.subId,
          value: item.value as string
        }))
      );
  }
}
