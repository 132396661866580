import { ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';

@Component({
  selector: 'base',
  template: ''
})
export class BaseComponent {
  protected readonly _destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
}
