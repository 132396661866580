import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

import { AppRoutingPartials } from '@base/app-routing.paths';
import { AppIcon } from '@base/models/enums/app.icons';
import { ModuleSite } from '@models/classes/module-site.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { AnyModuleEntity } from '@models/interfaces/module-section-entity.interface';
import { TransformationService } from '@services/transformation/transformation.service';
import { BaseComponent } from '@shared/components/base/base.component';

export enum ModuleHierarchyLevel {
  module = 1,
  section,
  step,
  site
}

export interface ITransformationHierarchyItem {
  children: ITransformationHierarchyItem[];
  entity: AnyModuleEntity;
  isChecked: boolean;
  isActive: boolean;
  isClickable: boolean;
}

@Component({
  selector: 'app-transformation-hierarchy',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatExpansionModule, TranslateModule],
  templateUrl: './transformation-hierarchy.component.html',
  styleUrl: './transformation-hierarchy.component.scss'
})
export class TransformationHierarchyComponent extends BaseComponent {
  readonly bulletsOnly = input<boolean>(false);

  readonly ModuleHierarchyLevel = ModuleHierarchyLevel;
  readonly AppIcon = AppIcon;

  private readonly _transformationService: TransformationService = inject(TransformationService);
  private readonly _router: Router = inject(Router);
  private readonly _route: ActivatedRoute = inject(ActivatedRoute);

  // Observables
  private readonly _transformation$ = this._transformationService.TRANSFORMATION$;

  private readonly _moduleName$ = this._transformation$.pipe(
    map(transformation => {
      return transformation?.module?.name ?? 'MODULES.UNNAMED_MODULE';
    })
  );

  private readonly _items$ = this._transformation$.pipe(
    map(transformation => {
      if (transformation?.module == null) return [];

      let checkLatest = true;
      const sections = transformation.module.sections.map(section => {
        const steps = section.steps.map(step => {
          const isLatest = step.sites.some(site => site.id === transformation.latest.id);

          let isChecked = false;
          if (checkLatest) {
            if (isLatest) {
              checkLatest = false;
            } else {
              isChecked = true;
            }
          }

          const isActive = step.sites.some(site => site.id === transformation.current.id);
          const isClickable = !isActive && (isChecked || isLatest);

          const item: ITransformationHierarchyItem = {
            entity: step,
            children: [],
            isChecked,
            isActive,
            isClickable
          };
          return item;
        });

        const item: ITransformationHierarchyItem = {
          entity: section,
          children: steps,
          isChecked: false,
          isActive: false,
          isClickable: false
        };

        return item;
      });

      return sections;
    })
  );

  // Computed signals
  private readonly _transformation = toSignal(this._transformation$, { initialValue: null });
  readonly moduleName = toSignal(this._moduleName$, { initialValue: 'MODULES.UNNAMED_MODULE' });
  readonly items = toSignal(this._items$, { initialValue: [] });

  private readonly _currentSite = computed<ModuleSite>(() => {
    return this._transformation()?.current;
  });

  private readonly _latestSite = computed<ModuleSite>(() => {
    return this._transformation()?.latest;
  });

  navigate(item: ITransformationHierarchyItem): void {
    if (item.isClickable) {
      this._navigateToStep(item);
    }
  }

  private _navigateToStep(item: ITransformationHierarchyItem): void {
    const step: ModuleStep = item.entity as ModuleStep;
    if (step === null) return;

    const site: ModuleSite =
      step.sites.find(site => {
        const isCurrentSite = site.id === this._currentSite()?.id;
        const isLatestSite = site.id === this._latestSite()?.id;
        return isCurrentSite || isLatestSite;
      }) ?? step.sites[0];
    if (site === null) return;

    this._transformationService.updateProgress({ id: site.id }).subscribe(() => this._navigateToSite(step, site));
  }

  private _navigateToSite(step: ModuleStep, site: ModuleSite): void {
    this._router.navigate([AppRoutingPartials.STEPS, step.id, AppRoutingPartials.SITES, site.id], {
      relativeTo: this._route
    });
  }
}
