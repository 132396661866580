import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { filter } from 'rxjs/operators';

import { InputType } from '@base/models/enums/input-type.enum';
import { getBlockIdentifier } from '@misc/helpers';
import { SegmentAnswer } from '@models/classes/module-answer.model';
import { BlockInput, BlockInputOption } from '../../models/block.model';
import { BaseBlockComponent } from '../base-block.component';

@Component({
  selector: 'app-block-input',
  templateUrl: './block-input.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-input.component.scss']
})
export class BlockInputComponent extends BaseBlockComponent<BlockInput> implements OnChanges {
  readonly InputType = InputType;
  readonly pageKey: string = 'BLOCK.INPUT.';
  readonly optionsCountControl = new FormControl(1, [Validators.min(1), Validators.max(10)]);

  readonly inputArray: FormArray<
    FormGroup<{
      id: FormControl<string>;
      value: FormControl<string>;
    }>
  > = new FormArray([]);

  constructor() {
    super();

    this.inputArray.valueChanges
      .pipe(
        takeUntilDestroyed(),
        filter(() => !this.isUserInputDisabled),
        debounceTime(1000)
      )
      .subscribe(() => {
        this.saveAnswers();
      });
  }

  get options(): BlockInputOption[] {
    return this.block.options;
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block && this.block.options) {
      this.optionsCountControl.setValue(this.block.options.length);
      this.inputArray.clear();

      for (let i = 0; i < this.block.options.length; i++) {
        const option = this.block.options[i];
        this.inputArray.push(
          new FormGroup({
            id: new FormControl(option.id),
            value: new FormControl({ value: '', disabled: this.isUserInputDisabled })
          }),
          { emitEvent: false }
        );
      }
    }
    if (changes.segment && this.segmentAnswer) {
      for (const group of this.inputArray.controls) {
        for (const answer of this.segmentAnswer.content) {
          if (group.value.id === answer.subId) {
            group.controls.value.setValue(answer.value as string, { emitEvent: false });
          }
        }
      }
    }
  }

  getInputControl(i: number): FormControl<string> {
    const group = this.inputArray.controls[i];
    return group.controls.value as FormControl;
  }

  closeSetting(): void {
    this.optionsCountControl.setValue(this.block.options.length);
  }

  saveSetting(): void {
    const count = this.optionsCountControl.value;
    const diff = count - this.block.options.length;
    if (diff > 0) {
      for (let i = this.block.options.length; i < count; i++) {
        this.block.options.push({
          id: getBlockIdentifier(),
          title: '',
          label: ''
        });
      }
    } else if (diff < 0) {
      this.block.options.splice(count, this.block.options.length);
    }
    this.block.styleSettings = this.updatedStyleSettings;
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  questionChanged(event: string): void {
    this.block.question = event;
    this.save();
  }

  titleChanged(option: BlockInputOption, event: string): void {
    option.title = event;
    this.save();
  }

  labelChanged(option: BlockInputOption, event: string): void {
    option.label = event;
    this.save();
  }

  saveAnswers(): void {
    if (this.isEditMode || this.segment?.id == null || !this._transformationService.canAcceptAnswer() || !this.inputArray.valid) return;
    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: this.inputArray.value.map(item => {
        return {
          subId: item.id,
          value: item.value
        };
      })
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }
}
