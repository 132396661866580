<form [formGroup]="formGroup" class="user-modal" (ngSubmit)="onSubmit()">
  <div class="user-modal__row">
    <base-form-input
      class="user-modal__input"
      classFormField="base-form-field"
      [control]="form?.firstName"
      [required]="true"
      [placeholder]="'INPUT_NAME.FIRST_NAME' | translate"
    ></base-form-input>
    <base-form-input
      class="user-modal__input"
      classFormField="base-form-field"
      [control]="form?.lastName"
      [required]="true"
      [placeholder]="'INPUT_NAME.LAST_NAME' | translate"
    ></base-form-input>
  </div>
  <div class="user-modal__row">
    <base-form-input
      class="user-modal__input"
      classFormField="base-form-field"
      [control]="form?.email"
      [required]="true"
      [placeholder]="'INPUT_NAME.EMAIL' | translate"
      [inputType]="INPUT_TYPE.email"
    ></base-form-input>
  </div>
  <div class="user-modal__row">
    <base-select
      panelClass="custom-panel"
      classFormField="base-form-field"
      [placeholder]="'INPUT_NAME.ORGANISATION' | translate"
      [options]="orgOptions"
      [control]="form?.company"
      class="user-modal__select"
    ></base-select>
  </div>
  <div class="user-modal__additional">
    <button class="user-modal__btn-more" (click)="toggleMore($event)" type="button">
      <span>{{ (accordionOpened ? 'BUTTON_NAME.LESS' : 'BUTTON_NAME.MORE') | translate }}</span>
      <mat-icon class="user-modal__btn-icon" [class.user-modal__btn-icon_opened]="accordionOpened" [svgIcon]="AppIcon.DROPDOWN"></mat-icon>
    </button>
    <mat-accordion class="user-modal__additional-accordion" multi>
      <mat-expansion-panel class="user-modal__additional-panel">
        <div class="user-modal__row">
          <base-form-input
            classFormField="base-form-field"
            class="user-modal__input user-modal__first-additional"
            [control]="form?.phoneNumber"
            [placeholder]="'INPUT_NAME.PHONE_NUMBER' | translate"
          ></base-form-input>
        </div>
        <div class="user-modal__row">
          <base-form-input
            classFormField="base-form-field"
            class="user-modal__input"
            [control]="form?.address"
            [placeholder]="'INPUT_NAME.ADDRESS' | translate"
          ></base-form-input>
          <base-form-input
            classFormField="base-form-field"
            class="user-modal__input"
            [control]="form?.addressAddition"
            [placeholder]="'INPUT_NAME.ADDRESS_ADDITION' | translate"
          ></base-form-input>
        </div>
        <div class="user-modal__row">
          <base-form-input
            classFormField="base-form-field"
            class="user-modal__input"
            [control]="form?.postCode"
            [placeholder]="'INPUT_NAME.POST_CODE' | translate"
          ></base-form-input>
          <base-form-input
            classFormField="base-form-field"
            class="user-modal__input"
            [control]="form?.city"
            [placeholder]="'INPUT_NAME.CITY' | translate"
          ></base-form-input>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</form>
