import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppIcon } from '@base/models/enums/app.icons';

@Component({
  selector: 'app-empty-block',
  templateUrl: './empty-block.component.html',
  styleUrls: ['./empty-block.component.scss']
})
export class EmptyBlockComponent {
  @Input() emptyIcon: AppIcon = AppIcon.NO;
  @Input() emptyMessage: string = 'MESSAGE.EMPTY_LIST';
  @Input() reloadButtonName: string;
  @Output() reload: EventEmitter<void> = new EventEmitter<void>();
}
