import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { plainToInstance } from 'class-transformer';

import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { getBlockIdentifier } from '@misc/helpers';
import { ISegmentAnswerValue, SegmentAnswer } from '@models/classes/module-answer.model';
import { AppIcon } from '@models/enums/app.icons';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { BlockSelection, SelectionBlockOption } from '@shared/blocks/models/block.model';

@Component({
  selector: 'app-block-selection',
  templateUrl: './block-selection.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-selection.component.scss']
})
export class BlockSelectionComponent extends BaseBlockComponent<BlockSelection> implements OnInit {
  readonly ownOptionsToggle: FormControl = new FormControl(false);
  readonly newOptionName: FormControl = new FormControl('', [Validators.required]);
  readonly customOptionName: FormControl = new FormControl('');
  readonly BooleanFieldType = BooleanFieldType;
  readonly AppIcon = AppIcon;
  readonly questions: SelectionBlockOption[] = [];
  readonly answers: SelectionBlockOption[] = [];
  readonly pageKey: string = 'BLOCK.SELECTION.';

  ngOnInit(): void {
    this._setAnswers();
    this._setQuestions();
    this.ownOptionsToggle.setValue(this.block.isOwnOptionsEnabled);
  }

  drop(event: CdkDragDrop<SelectionBlockOption[]>): void {
    if (this.isUserInputDisabled) return;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.saveAnswer();
    }
  }

  deleteOption(option: SelectionBlockOption, idx: number): void {
    this.block.options.splice(idx, 1);
    this.save();
  }

  addOption(): void {
    if (this.newOptionName.invalid) return;
    this.block.options.push(
      plainToInstance(SelectionBlockOption, {
        id: getBlockIdentifier(),
        content: this.newOptionName.value
      })
    );
    this.newOptionName.reset('');
    this.save();
  }

  addCustomOption(): void {
    if (this.isUserInputDisabled) return;
    if (!this.customOptionName.value) return;
    this.answers.push(
      plainToInstance(SelectionBlockOption, {
        id: getBlockIdentifier(),
        isCustom: true,
        content: this.customOptionName.value
      })
    );
    this.customOptionName.reset('');
    this.saveAnswer();
  }

  removeCustomOption(idx: number): void {
    if (this.isUserInputDisabled) return;
    this.answers.splice(idx, 1);
    this.saveAnswer();
  }

  closeSetting(): void {
    this.ownOptionsToggle.setValue(this.block.isOwnOptionsEnabled);
  }

  saveSetting(): void {
    this.block.isOwnOptionsEnabled = this.ownOptionsToggle.value;
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  saveAnswer(): void {
    if (this.isEditMode || !this.isUserScope || this.isUserInputDisabled) return;
    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: this.answers.map(item => ({
        subId: item.id,
        value: item.content,
        meta: { isCustom: item.isCustom }
      }))
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }

  private _setQuestions(): void {
    const filteredQuestions = this.segmentAnswer
      ? this.block.options.filter(
          question => !this.segmentAnswer.content.some((answer: ISegmentAnswerValue) => answer.subId === question.id)
        )
      : this.block.options;
    this.questions.push(...filteredQuestions);
  }

  private _setAnswers(): void {
    if (this.segmentAnswer?.content)
      this.answers.push(
        ...this.segmentAnswer.content.map(item => ({
          id: item.subId,
          content: item.value as string,
          isCustom: item.meta.isCustom
        }))
      );
  }
}
