import { ModuleSite } from '@models/classes/module-site.model';
import { Module } from '@models/classes/module.model';
import { ModuleSection } from '@models/classes/module-section.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { ModuleSegment } from '@models/classes/module-segment.model';

export type ModuleElementPathItem<Elem> = { item: Elem; index: number };
export type ModuleElementPath = {
  section: ModuleElementPathItem<ModuleSection>;
  step: ModuleElementPathItem<ModuleStep>;
  site: ModuleElementPathItem<ModuleSite>;
  segment: ModuleElementPathItem<ModuleSegment>;
};

export function getModuleElementPath(module: Module, elementId: string): ModuleElementPath {
  const path: ModuleElementPath = {} as ModuleElementPath;

  module.sections.forEach((section, i) => {
    if (section.id === elementId) {
      path.section = { item: section, index: i };
      return;
    }
    section.steps.forEach((step, j) => {
      if (step.id === elementId) {
        path.section = { item: section, index: i };
        path.step = { item: step, index: j };
        return;
      }
      step.sites.forEach((site, k) => {
        if (site.id === elementId) {
          path.section = { item: section, index: i };
          path.step = { item: step, index: j };
          path.site = { item: site, index: k };
          return;
        }
        site.segments?.forEach((segment, n) => {
          if (segment.id === elementId || segment.value.id === elementId) {
            path.section = { item: section, index: i };
            path.step = { item: step, index: j };
            path.site = { item: site, index: k };
            path.segment = { item: segment, index: n };
          }
        });
      });
    });
  });

  return path;
}
