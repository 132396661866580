<div *ngIf="form" class="cluster-answer">
  <base-form-input
    class="cluster-answer__field"
    [control]="form.name"
    [hideLabel]="true"
    [placeholder]="'INPUT_NAME.NAME_TITLE' | translate"
  ></base-form-input>
  <base-form-input class="cluster-answer__field" [control]="form.group" [hideLabel]="true"></base-form-input>
  <base-textarea
    class="cluster-answer__field"
    [control]="form.description"
    [hideLabel]="true"
    [minRows]="5"
    [placeholder]="'INPUT_NAME.DESCRIPTION' | translate"
  ></base-textarea>
</div>
