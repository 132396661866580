import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { expandInOutX } from '@base/animations/appearance.animations';
import { AppIcon } from '@base/models/enums/app.icons';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  animations: [expandInOutX(200)]
})
export class IconButtonComponent {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() icon: string = 'add';
  @Input() svgIcon: AppIcon;
  @Input() label: string;
  @Input() labelPosition: 'left' | 'right' = 'left';
  @Input() color: ThemePalette;
  @Input() disabled: boolean;
  @Input() size: 'large' | 'small';
  isHover: boolean;

  handleClick(): void {
    if (this.disabled) return;
    this.clicked.emit();
  }
}
