@if (items().length > 0) {
  <div [class.transformation-hierarchy_bullets-only]="bulletsOnly()" class="transformation-hierarchy">
    <div class="transformation-hierarchy__title-wrap">
      <mat-icon [svgIcon]="AppIcon.LIST" class="transformation-hierarchy__title-icon"></mat-icon>
      <h4 class="transformation-hierarchy__title">
        {{ moduleName() | translate }}
      </h4>
    </div>
    <div class="transformation-hierarchy__items">
      <ng-container *ngFor="let item of items(); let i = index">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: { item, level: 2, index: i } }"></ng-container>
      </ng-container>
    </div>
  </div>
}

<ng-template #itemTemplate let-params>
  <div [ngClass]="'level-' + params.level" class="transformation-hierarchy__item">
    <p
      (click)="navigate(params.item)"
      [class.transformation-hierarchy__text_active]="params.item.isActive"
      [class.transformation-hierarchy__text_checked]="params.item.isChecked"
      [class.transformation-hierarchy__text_site]="params.level === ModuleHierarchyLevel.site"
      [class.transformation-hierarchy__text_step]="params.level === ModuleHierarchyLevel.step"
      [class.transformation-hierarchy__text_clickable]="params.item.isClickable"
      class="body-5 transformation-hierarchy__text"
      tabindex="0"
    >
      <span>{{ params.item.entity?.name }}</span>
    </p>

    <div class="transformation-hierarchy__children">
      <ng-container *ngFor="let item of params.item.children; let i = index">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: { item, level: params.level + 1, index: i } }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
