import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IModalData, ModalComponent } from '@shared/modal/modal.component';

export type IModalProperties = Omit<MatDialogConfig, 'data'> & { shouldHandleFalse?: boolean };

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private readonly _dialog = inject(MatDialog);

  private readonly _close$: Subject<void> = new Subject<void>();

  open<T = any, K = T>(data: IModalData<T>, properties?: IModalProperties): Observable<K> {
    const shouldHandleFalse: boolean = properties?.shouldHandleFalse ?? false;
    delete properties?.shouldHandleFalse;

    return this._dialog
      .open(ModalComponent, {
        backdropClass: 'dialog-backdrop',
        autoFocus: false,
        ...(properties ?? {}),
        data
      })
      .afterClosed()
      .pipe(
        map((res: K): K => {
          this._close$.next();
          return res;
        }),
        filter((res: K): boolean => shouldHandleFalse || Boolean(res))
      );
  }
}
