import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static upperCaseLetter({ value }: AbstractControl): ValidationErrors | null {
    const isValid: boolean = /(.*[A-Z].*)/gm.test(value);

    return isValid || value === '' ? null : { upperCaseLetter: { valid: false, value } };
  }

  static lowerCaseLetter({ value }: AbstractControl): ValidationErrors | null {
    const isValid: boolean = /(.*[a-z].*)/gm.test(value);

    return isValid || value === '' ? null : { lowerCaseLetter: { valid: false, value } };
  }

  static symbols({ value }: AbstractControl): ValidationErrors | null {
    const isValid: boolean = /(.*\W.*)/gm.test(value);

    return isValid || value === '' ? null : { symbols: { valid: false, value } };
  }

  static digits({ value }: AbstractControl): ValidationErrors | null {
    const isValid: boolean = /(.*\d.*)/gm.test(value);

    return isValid || value === '' ? null : { digits: { valid: false, value } };
  }

  static phone({ value }: AbstractControl): ValidationErrors | null {
    return /^\+[0-9]{3,18}$/gm.test(value) || !value ? null : { phone: true };
  }

  static url({ value }: AbstractControl): ValidationErrors | null {
    // eslint-disable-next-line no-useless-escape
    const isValid: boolean = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:\/?#\[\]@!$&'()*+,;=]+$/gm.test(value);
    return isValid || !value ? null : { url: true };
  }

  static validName({ value }: AbstractControl): ValidationErrors | null {
    const isInvalid: boolean = /[<>;|]/gu.test(value);
    return !isInvalid || value === '' ? null : { validName: { valid: false, value } };
  }

  static fullEmail({ value }: AbstractControl): ValidationErrors | null {
    const isValid: boolean = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(value);

    return isValid || value === '' ? null : { email: { valid: false, value } };
  }

  static lettersNumbersOnly({ value }: AbstractControl): ValidationErrors | null {
    const isValid: boolean = /^[a-zA-Z0-9-]+$/.test(value);

    return isValid || value === '' ? null : { lettersNumbersOnly: { valid: false, value } };
  }

  static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const group: FormGroup = formGroup as FormGroup;
      const control: AbstractControl = group.controls[controlName];
      const matchingControl: AbstractControl = group.controls[matchingControlName];

      if (!control.value && matchingControl.errors && !matchingControl.errors.mustMatch) {
        return null;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({
          mustMatch: {
            controlName,
            matchingControlName
          }
        });
      } else {
        matchingControl.setErrors(null);
      }

      return null;
    };
  }
}
