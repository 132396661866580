<div (mouseenter)="!disabled && (isHover = true)" (mouseleave)="!disabled && (isHover = false)" [ngClass]="size" class="icon-btn">
  <span
    *ngIf="label && isHover"
    @expandInOutX
    [class.icon-btn__label_right]="labelPosition === 'right'"
    [title]="label | translate"
    class="icon-btn__label"
  >
    {{ label | translate }}
  </span>
  <button (click)="handleClick()" [color]="color" [disabled]="disabled" class="icon-btn__button" mat-icon-button>
    <mat-icon [svgIcon]="svgIcon">{{ icon }}</mat-icon>
  </button>
</div>
