import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModalComponent } from './image-cropper-modal.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { LoaderContainerModule } from '@shared/components/loader-container/loader-container.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ImageCropperModalComponent],
  exports: [ImageCropperModalComponent],
  imports: [
    CommonModule,
    ImageCropperComponent,
    LoaderContainerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FormsModule,
    MaterialModule,
    TranslateModule
  ]
})
export class ImageCropperModalModule {}
