<label>
  <mat-form-field [appearance]="appearance" [class]="type + ' ' + classFormField">
    <mat-label *ngIf="!hideLabel">{{ placeholder }}</mat-label>
    <mat-icon *ngIf="icon" [svgIcon]="icon" matPrefix></mat-icon>
    <button
      (click)="search.emit(formControl.value)"
      *ngIf="isSearchInput"
      [disableRipple]="true"
      [disabled]="disabled"
      class="base-input__search-btn"
      mat-icon-button
      matPrefix
      tabindex="-1"
      type="button"
    >
      <mat-icon>search</mat-icon>
    </button>
    <input
      (input)="onInput($event)"
      (keydown.enter)="onEnter($event)"
      [autocomplete]="autocomplete"
      [formControl]="formControl"
      [maxLength]="maxLength"
      [max]="max"
      [min]="min"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [required]="required"
      [type]="type"
      matInput
    />
    <button (click)="togglePassword()" *ngIf="isPasswordInput" [disabled]="disabled" mat-icon-button matSuffix tabindex="-1" type="button">
      <mat-icon>{{ buttonIcon }}</mat-icon>
    </button>
    <div *ngIf="suffixTemplate" matSuffix>
      <ng-container *ngTemplateOutlet="suffixTemplate; context: { $implicit: control.value }"></ng-container>
    </div>
    <mat-error *ngIf="isShowError && control?.invalid && errorMessage">
      <span>{{ errorMessage }}</span>
    </mat-error>
  </mat-form-field>
</label>
<ng-content></ng-content>
