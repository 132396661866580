import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

import { FormControlItemType } from '@models/enums/form-control-item.type';
import { InputType } from '@models/enums/input-type.enum';
import { IFormControlItem } from '@models/interfaces/forms/form-control-item.interface';
import { BaseComponent } from '@shared/components/base/base.component';

export interface IFormControls {
  [key: string]: AbstractControl;
}

@Component({
  template: ''
})
export abstract class BaseFormAbstractComponent extends BaseComponent {
  formGroup: FormGroup;
  readonly FORM_CONTROL_ITEM_TYPE: typeof FormControlItemType = FormControlItemType;
  readonly INPUT_TYPE: typeof InputType = InputType;
  get form(): IFormControls {
    return this.formGroup?.controls;
  }

  getGroup(name: string): FormGroup {
    return this._getItemFormGroup(name) as FormGroup;
  }

  asGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  getControl(name: string): FormControl {
    return this._getItemFormGroup(name) as FormControl;
  }

  getArray(name: string): FormArray {
    return this._getItemFormGroup(name) as FormArray;
  }

  asArray(control: AbstractControl): FormArray {
    return control as FormArray;
  }

  setControlsArray(formControls: IFormControlItem[]): void {
    if (!formControls.length) {
      return;
    }

    formControls.forEach((itemControl: IFormControlItem): void => this.formGroup.addControl(itemControl.name, itemControl.control));
  }

  private _getItemFormGroup(name: string): AbstractControl {
    return this.formGroup.get(name) as AbstractControl;
  }
}
