export function sortParameters(prev: any, next: any, direction: string): any {
  const type = typeof prev;
  const isDate = typeof prev?.getMonth === 'function';
  const isBool = type === 'boolean';
  const isNumber = type === 'number';

  if (direction === 'asc') {
    if (isDate) {
      return prev?.getTime?.() - next?.getTime?.();
    }
    if (isBool || isNumber) {
      return Number(prev) - Number(next);
    }

    return prev?.localeCompare?.(next);
  } else {
    if (isDate) {
      return next?.getTime?.() - prev?.getTime?.();
    }
    if (isBool || isNumber) {
      return Number(next) - Number(prev);
    }

    return next?.localeCompare?.(prev);
  }
}
