import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { AppIcon } from '@base/models/enums/app.icons';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { AnyBlockColumn } from '@shared/blocks/models/block-column.model';
import { AnyColumnsBlock, BlockImageText, BlockTextImage, BlockTextTwoColumns } from '@shared/blocks/models/block.model';

@Component({
  selector: 'app-block-columns',
  templateUrl: './block-columns.component.html',
  styleUrls: ['./block-columns.component.scss']
})
export class BlockColumnsComponent extends BaseBlockComponent<AnyColumnsBlock> implements OnInit, OnChanges {
  readonly AppIcon = AppIcon;

  columns: AnyBlockColumn[];

  ngOnInit(): void {
    this._setColumns();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block) {
      this._setColumns();
    }
  }

  swapColumns(idx: number): void {
    const left = this.block.columns[idx];
    const right = this.block.columns[idx + 1];
    const leftOrder: number = left.position;
    left.position = right.position;
    right.position = leftOrder;
    this._setColumns();
    this.save();
  }

  saveSetting(): void {
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  getBlockName(): string {
    if (this.block instanceof BlockTextTwoColumns) return 'TEXT_2_COLUMNS';
    if (this.block instanceof BlockTextImage) return 'TEXT_IMAGE';
    if (this.block instanceof BlockImageText) return 'IMAGE_TEXT';
    return '';
  }

  private _setColumns(): void {
    this.block.columns = this.block.columns || [];
    this.block.columns.forEach(column => delete column.styleSettings); // line can be removed in later deployments
    this.columns = this.block.columns.sort((next, curr) => next.position - curr.position);
  }
}
