import { createToken, CstNode, CstParser, ILexingResult, IToken, Lexer, ParserMethod } from 'chevrotain';

export const Identifier = createToken({
  name: 'Identifier',
  pattern: /[a-z][a-z0-9-.]*/
});

export const StringToken = createToken({
  name: 'String',
  pattern: /"(?:[^"\\]|\\.)*"/
});

export const NumberToken = createToken({
  name: 'Number',
  pattern: /0|-?[1-9]+\d*/
});

export const TrueToken = createToken({
  name: 'True',
  pattern: /TRUE|true/,
  longer_alt: Identifier
});

export const FalseToken = createToken({
  name: 'False',
  pattern: /FALSE|false/,
  longer_alt: Identifier
});

export const LpToken = createToken({
  name: 'LP',
  pattern: /\(/
});

export const AndToken = createToken({
  name: 'And',
  pattern: /AND|and/,
  longer_alt: Identifier
});

export const OrToken = createToken({
  name: 'Or',
  pattern: /OR|or/,
  longer_alt: Identifier
});

export const StringComparatorToken = createToken({
  name: 'StringComparator',
  pattern: /==|!=/
});

export const NumberComparatorToken = createToken({
  name: 'NumberComparator',
  pattern: /<=|>=|<|>/
});

export const NotToken = createToken({
  name: 'Not',
  pattern: /NOT|!/,
  longer_alt: [StringComparatorToken, Identifier]
});

export const RpToken = createToken({
  name: 'RP',
  pattern: /\)/
});

export const Whitespace = createToken({
  name: 'Whitespace',
  pattern: /\s+/,
  group: Lexer.SKIPPED
});

export const allTokens = [
  Whitespace,
  LpToken,
  RpToken,
  AndToken,
  OrToken,
  StringComparatorToken,
  NumberComparatorToken,
  NotToken,
  TrueToken,
  FalseToken,
  Identifier,
  StringToken,
  NumberToken
];

export const cqlLexer = new Lexer(allTokens);

export function lex(inputText: string): ILexingResult {
  const lexingResult = cqlLexer.tokenize(inputText);

  if (lexingResult.errors.length > 0) {
    throw Error(lexingResult.errors.map(e => e.message).join('\n'));
  }

  return lexingResult;
}

export class CqlParser extends CstParser {
  constructor() {
    super(allTokens);

    this.RULE('expression', () => {
      this.SUBRULE(this['orExpression' as keyof CqlParser] as ParserMethod<[], CstNode>);
    });

    this.RULE('orExpression', () => {
      this.SUBRULE(this['andExpression' as keyof CqlParser] as ParserMethod<[], CstNode>, { LABEL: 'lhs' });
      this.OPTION(() => {
        this.CONSUME(OrToken);
        this.SUBRULE2(this['andExpression' as keyof CqlParser] as ParserMethod<[], CstNode>, { LABEL: 'rhs' });
      });
    });

    this.RULE('andExpression', () => {
      this.SUBRULE(this['atomicExpression' as keyof CqlParser] as ParserMethod<[], CstNode>, { LABEL: 'lhs' });
      this.OPTION(() => {
        this.CONSUME(AndToken);
        this.SUBRULE2(this['atomicExpression' as keyof CqlParser] as ParserMethod<[], CstNode>, { LABEL: 'rhs' });
      });
    });

    this.RULE('atomicExpression', () => {
      this.OR([
        { ALT: (): IToken => this.CONSUME(TrueToken) },
        { ALT: (): IToken => this.CONSUME(FalseToken) },
        { ALT: (): CstNode => this.SUBRULE(this['parenthesisExpression' as keyof CqlParser] as ParserMethod<[], CstNode>) },
        { ALT: (): CstNode => this.SUBRULE(this['notExpression' as keyof CqlParser] as ParserMethod<[], CstNode>) },
        { ALT: (): CstNode => this.SUBRULE(this['comparisonExpression' as keyof CqlParser] as ParserMethod<[], CstNode>) }
      ]);
    });

    this.RULE('parenthesisExpression', () => {
      this.CONSUME(LpToken);
      this.SUBRULE(this['expression' as keyof CqlParser] as ParserMethod<[], CstNode>);
      this.CONSUME(RpToken);
    });

    this.RULE('notExpression', () => {
      this.CONSUME(NotToken);
      this.SUBRULE(this['expression' as keyof CqlParser] as ParserMethod<[], CstNode>);
    });

    this.RULE('comparisonExpression', () => {
      this.SUBRULE(this['comparisonLeftOperand' as keyof CqlParser] as ParserMethod<[], CstNode>, { LABEL: 'lhs' });
      this.OPTION(() => {
        this.CONSUME(StringComparatorToken);
        this.SUBRULE2(this['stringComparisonRightOperand' as keyof CqlParser] as ParserMethod<[], CstNode>, { LABEL: 'rhs' });
      });
      this.OPTION2(() => {
        this.CONSUME(NumberComparatorToken);
        this.SUBRULE3(this['numberComparisonRightOperand' as keyof CqlParser] as ParserMethod<[], CstNode>, { LABEL: 'rhs' });
      });
    });

    this.RULE('comparisonLeftOperand', () => {
      this.CONSUME(Identifier);
    });

    this.RULE('stringComparisonRightOperand', () => {
      this.CONSUME(StringToken);
    });

    this.RULE('numberComparisonRightOperand', () => {
      this.CONSUME(NumberToken);
    });

    this.performSelfAnalysis();
  }
}

// We only ever need one as the parser internal state is reset for each new input.
const parserInstance = new CqlParser();

const BaseCqlVisitor = parserInstance.getBaseCstVisitorConstructor();

export type ComparisonOperandType = 'Identifier' | 'Number' | 'String';

export type ComparisonExpressionOperationType = 'Equal' | 'NotEqual' | 'LessThanEqual' | 'GreaterThanEqual' | 'LessThan' | 'GreaterThan';

function expressionOperationTypeFromImage(image: string): ComparisonExpressionOperationType {
  switch (image) {
    case '==':
      return 'Equal';
    case '!=':
      return 'NotEqual';
    case '<=':
      return 'LessThanEqual';
    case '>=':
      return 'GreaterThanEqual';
    case '<':
      return 'LessThan';
    case '>':
      return 'GreaterThan';

    default:
      throw new Error(`Unknown expression operation image type ${image}`);
  }
}

export type CqlExpression =
  | boolean
  | ICqlIdentifierExpression
  | ICqlOrExpression
  | ICqlAndExpression
  | ICqlComparisonExpression
  | ICqlNotExpression;

export interface ICqlComparisonOperand {
  type: ComparisonOperandType;
  value: string;
}

export interface ICqlIdentifierExpression {
  type: 'Identifier';
  value: string;
}

export interface ICqlOrExpression {
  type: 'Or';
  lhs: CqlExpression;
  rhs: CqlExpression;
}

export interface ICqlAndExpression {
  type: 'And';
  lhs: CqlExpression;
  rhs: CqlExpression;
}

export interface ICqlComparisonExpression {
  type: 'Comparison';
  lhs: ICqlComparisonOperand;
  rhs: ICqlComparisonOperand;
  operation: ComparisonExpressionOperationType;
}

export interface ICqlNotExpression {
  type: 'Not';
  rhs: CqlExpression;
}

class CqlToAstVisitor extends BaseCqlVisitor {
  constructor() {
    super();
    this.validateVisitor();
  }

  expression(ctx: any): any {
    return this.visit(ctx.orExpression);
  }

  orExpression(ctx: any): any {
    const result = this.visit(ctx.lhs);
    if (!ctx.rhs) {
      return result;
    }
    return {
      type: 'Or',
      lhs: result,
      rhs: this.visit(ctx.rhs)
    } satisfies ICqlOrExpression;
  }

  andExpression(ctx: any): any {
    const result = this.visit(ctx.lhs);
    if (!ctx.rhs) {
      return result;
    }
    return {
      type: 'And',
      lhs: result,
      rhs: this.visit(ctx.rhs)
    } satisfies ICqlAndExpression;
  }

  atomicExpression(ctx: any): any {
    if (ctx.parenthesisExpression) {
      return this.visit(ctx.parenthesisExpression);
    }

    if (ctx.notExpression) {
      return this.visit(ctx.notExpression);
    }

    if (ctx.comparisonExpression) {
      return this.visit(ctx.comparisonExpression);
    }

    if (ctx.True) {
      return true as CqlExpression;
    }

    if (ctx.False) {
      return false as CqlExpression;
    }

    throw new Error(`Unknown atomic expression type in ${JSON.stringify(ctx)}`);
  }

  parenthesisExpression(ctx: any): any {
    return this.visit(ctx.expression);
  }

  notExpression(ctx: any): ICqlNotExpression {
    return {
      type: 'Not',
      rhs: this.visit(ctx.expression)
    } satisfies ICqlNotExpression;
  }

  comparisonExpression(ctx: any): any {
    const result = this.visit(ctx.lhs);
    if (!ctx.rhs) {
      return result;
    }

    if (ctx.StringComparator) {
      return {
        type: 'Comparison',
        lhs: result,
        rhs: this.visit(ctx.rhs),
        operation: expressionOperationTypeFromImage(ctx.StringComparator[0].image)
      } satisfies ICqlComparisonExpression;
    }

    if (ctx.NumberComparator) {
      return {
        type: 'Comparison',
        lhs: result,
        rhs: this.visit(ctx.rhs),
        operation: expressionOperationTypeFromImage(ctx.NumberComparator[0].image)
      } satisfies ICqlComparisonExpression;
    }
  }

  comparisonLeftOperand(ctx: any): ICqlComparisonOperand {
    if (ctx.Identifier) {
      return {
        type: 'Identifier',
        value: ctx.Identifier[0].image
      } satisfies ICqlComparisonOperand;
    }

    throw new Error(`Unknown comparison operand type in ${JSON.stringify(ctx)}`);
  }

  stringComparisonRightOperand(ctx: any): ICqlComparisonOperand {
    if (ctx.String) {
      return {
        type: 'String',
        value: ctx.String[0].image.slice(1, -1)
      } satisfies ICqlComparisonOperand;
    }

    throw new Error(`Unknown comparison operand type in ${JSON.stringify(ctx)}`);
  }

  numberComparisonRightOperand(ctx: any): ICqlComparisonOperand {
    if (ctx.Number) {
      return {
        type: 'Number',
        value: ctx.Number[0].image
      } satisfies ICqlComparisonOperand;
    }

    throw new Error(`Unknown comparison operand type in ${JSON.stringify(ctx)}`);
  }
}

// Our visitor has no state, so a single instance is sufficient.
const toAstVisitorInstance = new CqlToAstVisitor();

export function parse(inputText: string): CqlExpression {
  const lexResult = lex(inputText);

  // ".input" is a setter which will reset the parser's internal state.
  parserInstance.input = lexResult.tokens;

  const cst = (parserInstance['expression' as keyof CqlParser] as ParserMethod<[], CstNode>)();

  if (parserInstance.errors.length > 0) {
    throw Error(parserInstance.errors.map(e => e.message).join('\n'));
  }

  const ast: CqlExpression = toAstVisitorInstance.visit(cst);

  return ast;
}

export interface IIdentifierResolver {
  bool: (identifier: string) => boolean;
  num: (identifier: string) => number;
  str: (identifier: string) => string;
}

function evaluateExpression(ast: CqlExpression, resolver: IIdentifierResolver): boolean {
  if (typeof ast === 'boolean') {
    return ast;
  }

  switch (ast.type) {
    case 'Identifier':
      return resolver.bool(ast.value);
    case 'Or':
      return evaluateExpression(ast.lhs, resolver) || evaluateExpression(ast.rhs, resolver);
    case 'And':
      return evaluateExpression(ast.lhs, resolver) && evaluateExpression(ast.rhs, resolver);
    case 'Not':
      return !evaluateExpression(ast.rhs, resolver);
    case 'Comparison':
      return evaluateICqlComparisonExpression(ast, resolver);
  }
}

function stringMatchesComparisonExpression(lhs: string, rhs: string): boolean {
  if (rhs.startsWith('%')) {
    if (rhs.endsWith('%')) {
      return lhs.includes(rhs.substring(1, rhs.length - 1));
    }
    return lhs.startsWith(rhs.substring(1));
  }
  if (rhs.endsWith('%')) {
    return lhs.endsWith(rhs.substring(0, rhs.length - 1));
  }
  return lhs === rhs;
}

function parseNumber(input: string): number {
  return parseInt(input);
}

function evaluateICqlComparisonExpression(ce: ICqlComparisonExpression, resolver: IIdentifierResolver): boolean {
  switch (ce.operation) {
    case 'Equal':
      return stringMatchesComparisonExpression(resolver.str(ce.lhs.value), ce.rhs.value);
    case 'NotEqual':
      return !stringMatchesComparisonExpression(resolver.str(ce.lhs.value), ce.rhs.value);
    case 'LessThan':
      return resolver.num(ce.lhs.value) < parseNumber(ce.rhs.value);
    case 'GreaterThan':
      return resolver.num(ce.lhs.value) > parseNumber(ce.rhs.value);
    case 'LessThanEqual':
      return resolver.num(ce.lhs.value) <= parseNumber(ce.rhs.value);
    case 'GreaterThanEqual':
      return resolver.num(ce.lhs.value) >= parseNumber(ce.rhs.value);
  }
}

export function evaluate(inputText: string, resolver: IIdentifierResolver): boolean {
  const ast = parse(inputText);
  return evaluateExpression(ast, resolver);
}
