import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { Module } from './module.model';
import { transformToModel } from '@misc/helpers/model-conversion/transform-helpers';

@Exclude()
export class OrganizationModule extends AbstractModel {
  @Expose()
  @Transform(transformToModel(Module))
  module: Module;
  @Expose()
  progress: number;
  @Expose()
  moduleSlug: string;
}
