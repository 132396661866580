import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from '@shared/material/material.module';
import { BaseSelectModule } from '@forms/base-select/base-select.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PaginatorComponent],
  exports: [PaginatorComponent, NgxPaginationModule],
  imports: [CommonModule, NgxPaginationModule, MaterialModule, BaseSelectModule, TranslateModule]
})
export class PaginatorModule {}
