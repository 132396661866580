import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { ModuleStep } from '@models/classes/module-step.model';

@Injectable({
  providedIn: 'root'
})
export class AdminStepsApiService extends ApiBaseAbstractService<ModuleStep> {
  protected readonly _URL_PATH: string = '/admin/modules';
  protected override _URL_KEY: string = 'steps';
  protected readonly _MODEL: ClassConstructor<ModuleStep> = ModuleStep;
}
