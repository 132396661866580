import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { ModuleSite } from '@models/classes/module-site.model';

@Injectable({
  providedIn: 'root'
})
export class AdminSitesApiService extends ApiBaseAbstractService<ModuleSite> {
  protected readonly _URL_PATH: string = '/admin/modules';
  protected override _URL_KEY: string = 'sites';
  protected readonly _MODEL: ClassConstructor<ModuleSite> = ModuleSite;
}
