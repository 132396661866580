import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { expandInOut } from '@base/animations/appearance.animations';
import { BooleanFieldType } from '@base/common/modules/forms/base-boolean-field/base-boolean-field.component';
import { AppIcon } from '@base/models/enums/app.icons';
import { InputType } from '@base/models/enums/input-type.enum';
import { getBlockIdentifier } from '@misc/helpers';
import { SegmentAnswer } from '@models/classes/module-answer.model';
import { BlockSlider, BlockSliderOption, BlockSliderSettings } from '../../models/block.model';
import { BaseBlockComponent } from '../base-block.component';

interface ISliderPart {
  'left.%': number;
  'width.%': number;
}

@Component({
  selector: 'app-block-slider',
  templateUrl: './block-slider.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-slider.component.scss'],
  animations: [expandInOut()]
})
export class BlockSliderComponent extends BaseBlockComponent<BlockSlider> implements OnChanges, OnInit {
  readonly AppIcon = AppIcon;
  readonly InputType = InputType;
  readonly BooleanFieldType = BooleanFieldType;

  readonly optionsCountControl = new FormControl(1, [Validators.min(1), Validators.max(10)]);
  readonly showDescriptionControl = new FormControl(false);
  readonly showDescriptionsControl = new FormControl(false);
  readonly showTooltipControl = new FormControl(false);
  readonly showLabelsControl = new FormControl(false);

  selectedIndex: number | null = null;
  hoveredIndex: number | null = null;

  get options(): BlockSliderOption[] {
    return this.block.options;
  }

  get showDescription(): boolean {
    return this.block.settings?.showDescription ?? false;
  }

  get showDescriptions(): boolean {
    return this.block.settings?.showDescriptions ?? false;
  }

  get showLabels(): boolean {
    return this.block.settings?.showLabels ?? false;
  }

  get showTooltip(): boolean {
    return this.block.settings?.showTooltip ?? false;
  }

  get showViewDescription(): boolean {
    return !this.isEditMode && this.showDescriptions && (this.selectedIndex != null || this.hoveredIndex != null);
  }

  get tooltipContent(): string {
    const doc = new DOMParser().parseFromString(this.block.tooltip ?? '', 'text/html');
    return doc.body.textContent || '';
  }

  get activePartIndexes(): [number, number][] {
    const parts: [number, number][] = [];
    const options = this.block.options ?? [];
    if (options.length >= 2) {
      let start = null;
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (start == null && option.active) {
          start = i;
        }
        if (start != null && !option.active) {
          parts.push([start, i - 1]);
          start = null;
        }
      }
      if (start != null) {
        parts.push([start, options.length - 1]);
      }
    }
    return parts;
  }

  get activeParts(): ISliderPart[] {
    return this.activePartIndexes.map(([start, end]: [number, number]) => {
      const left = this._getPointPercentage(start, this.block.options.length);
      const width = this._getPointPercentage(end, this.block.options.length) - left;
      return {
        'left.%': left,
        'width.%': width
      };
    });
  }

  ngOnInit(): void {
    if (this.selectedIndex === null) {
      const firstActiveOption = this.options.findIndex(option => option.active);
      this.selectedIndex = firstActiveOption;
      this.saveAnswers();
    }
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block) {
      this.optionsCountControl.setValue(this.block.options.length);
      this.showDescriptionControl.setValue(this.block.settings?.showDescription ?? false);
      this.showLabelsControl.setValue(this.block.settings?.showLabels ?? false);
      this.showDescriptionsControl.setValue(this.block.settings?.showDescriptions ?? false);
      this.showTooltipControl.setValue(this.block.settings?.showTooltip ?? false);
    }
    if (changes.segment && this.segmentAnswer) {
      this.selectedIndex = +this.segmentAnswer.content?.at(0)?.value;
    }
  }

  closeSetting(): void {
    this.optionsCountControl.setValue(this.block.options.length);
  }

  saveSetting(): void {
    const count = this.optionsCountControl.value;
    const settings: BlockSliderSettings = {
      showDescription: this.showDescriptionControl.value,
      showDescriptions: this.showDescriptionsControl.value,
      showTooltip: this.showTooltipControl.value,
      showLabels: this.showLabelsControl.value,
      showInstruction: this.updatedInstructionSettings
    };

    const diff = count - this.block.options.length;
    if (diff > 0) {
      for (let i = this.block.options.length; i < count; i++) {
        this.block.options.push({
          id: getBlockIdentifier(),
          title: '',
          description: '',
          active: true
        });
      }
    } else if (diff < 0) {
      this.block.options.splice(count, this.block.options.length);
    }
    this.block.settings = settings;
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  questionChanged(event: string): void {
    this.block.question = event;
    this.save();
  }

  descriptionChanged(content: string): void {
    this.block.description = content;
    this.save();
  }

  labelLeftChanged(event: string): void {
    this.block.labelLeft = event;
    this.save();
  }

  labelRightChanged(event: string): void {
    this.block.labelRight = event;
    this.save();
  }

  tooltipChanged(event: string): void {
    this.block.tooltip = event;
    this.save();
  }

  getSliderThumbWidth(): string {
    const percentage = (this.selectedIndex / (this.block.options.length - 1)) * 100;
    return percentage.toFixed(2) + '%';
  }

  optionTitleChanged(index: number, event: string): void {
    if (index in this.block.options) {
      this.block.options[index].title = event;
      this.save();
    }
  }

  optionDescriptionChanged(index: number, event: string): void {
    if (index in this.block.options) {
      this.block.options[index].description = event;
      this.save();
    }
  }

  optionActiveChanged(index: number, event: boolean): void {
    if (index in this.block.options) {
      this.block.options[index].active = event;
      this.save();
    }
  }

  setSelectedPoint(index: number): void {
    if (this.isUserInputDisabled) return;
    if (index in this.block.options) {
      const option = this.block.options[index];
      if (option.active) {
        this.selectedIndex = index;
        this.saveAnswers();
      }
    }
  }

  setHoverIndex(index: number | null): void {
    this.hoveredIndex = index === null ? this.selectedIndex ?? index : index;
  }

  saveAnswers(): void {
    if (this.isEditMode || this.segment?.id == null || !this._transformationService.canAcceptAnswer()) return;

    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: [
        {
          subId: this.block.options[this.selectedIndex]?.id,
          value: this.selectedIndex.toString()
        }
      ]
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }

  private _getPointPercentage(index: number, length: number): number {
    if (index === 0) return 0;
    if (index === length - 1) return 100;
    return index * (100 / (length - 1));
  }
}
