<div *ngIf="isEditMode" class="block-readonly">
  <div class="block-readonly__item">
    <span class="block-readonly__label">{{ pageKey + 'SECTION_LABEL' | translate }}</span>
    <base-select
      (changeValue)="handleSelectChange($event)"
      [hideLabel]="true"
      [isShowError]="false"
      [options]="segmentPathGroup.section.options"
      [value]="segmentPathGroup.section.value"
      class="block-readonly__select"
      panelClass="default-panel"
    ></base-select>
  </div>
  <mat-icon [svgIcon]="AppIcon.DROPDOWN" class="block-readonly__item-arrow"></mat-icon>
  <div class="block-readonly__item">
    <span class="block-readonly__label">{{ pageKey + 'STEP_LABEL' | translate }}</span>
    <base-select
      (changeValue)="handleSelectChange($event)"
      [hideLabel]="true"
      [isShowError]="false"
      [options]="segmentPathGroup.step.options"
      [value]="segmentPathGroup.step.value"
      class="block-readonly__select"
      panelClass="default-panel"
    ></base-select>
  </div>
  <mat-icon [svgIcon]="AppIcon.DROPDOWN" class="block-readonly__item-arrow"></mat-icon>
  <div class="block-readonly__item">
    <span class="block-readonly__label">{{ pageKey + 'SITE_LABEL' | translate }}</span>
    <base-select
      (changeValue)="handleSelectChange($event)"
      [hideLabel]="true"
      [isShowError]="false"
      [options]="segmentPathGroup.site.options"
      [value]="segmentPathGroup.site.value"
      class="block-readonly__select"
      panelClass="default-panel"
    ></base-select>
  </div>
  <mat-icon [svgIcon]="AppIcon.DROPDOWN" class="block-readonly__item-arrow"></mat-icon>
  <div class="block-readonly__item">
    <span class="block-readonly__label">{{ pageKey + 'BLOCK_LABEL' | translate }}</span>
    <base-select
      (changeValue)="handleSelectChange($event)"
      [hideLabel]="true"
      [isShowError]="false"
      [options]="segmentPathGroup.segment.options"
      [value]="segmentPathGroup.segment.value"
      class="block-readonly__select"
      panelClass="default-panel"
    ></base-select>
  </div>
</div>

<ng-container *ngIf="!isEditMode">
  <app-block *ngIf="selectedSegment" [isEditMode]="false" [isReadOnly]="true" [segment]="selectedSegment"></app-block>
  <div *ngIf="!selectedSegment" class="block-readonly__no-segment">{{ pageKey + 'NO_SEGMENT' | translate }}</div>
</ng-container>

<ng-template #settingTemplate>
  <app-settings (save)="saveSetting()" [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.READONLY' | translate)">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
