import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { sortEntities } from '@base/misc/helpers';
import { ModuleAnswer, SegmentAnswer } from '@base/models/classes/module-answer.model';
import { ModuleSegment } from '@base/models/classes/module-segment.model';
import { convertToModel } from '@misc/helpers/model-conversion/convert-to-model.function';
import { toMutableSignal } from '@misc/helpers/to-mutable-signal.function';
import { ModuleSection } from '@models/classes/module-section.model';
import { ModuleSite } from '@models/classes/module-site.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { Module } from '@models/classes/module.model';
import { Playbook } from '@models/classes/playbook.model';
import { AdminModulesApiService } from '@services/api/admin/modules/admin-modules-api.service';
import { AdminPlaybookApiService } from '@services/api/admin/playbook/admin-playbook-api.service';

export interface ISidePanelState {
  isOpened: boolean;
  isOver: boolean;
  isToggleShown: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  private _moduleApiService: AdminModulesApiService = inject(AdminModulesApiService);
  private _playbookApiService: AdminPlaybookApiService = inject(AdminPlaybookApiService);

  readonly module$: BehaviorSubject<Module | null> = new BehaviorSubject<Module | null>(null);
  readonly playbook$: BehaviorSubject<Playbook> = new BehaviorSubject<Playbook>(null);
  readonly answerUpdated$: Subject<SegmentAnswer> = new Subject<SegmentAnswer>();
  private readonly _asidePanelState: ISidePanelState = { isOpened: true, isOver: false, isToggleShown: false };

  readonly currentStepId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  readonly currentSectionId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  readonly currentSiteId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  private _isEditModeEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly isEditModeEnabled$: Observable<boolean> = combineLatest([this.module$, this._isEditModeEnabled$]).pipe(
    map(([module, isEditModeEnabled]: [Module, boolean]) => {
      return !module?.isReadOnly || isEditModeEnabled;
    })
  );

  readonly currentSection$: Observable<ModuleSection | null> = combineLatest([this.module$, this.currentSectionId$]).pipe(
    map(([module, currentSectionId]: [Module, string]) => {
      if (module == null || currentSectionId == null) return null;
      return module.sections.find((section: ModuleSection) => section.id === currentSectionId) ?? null;
    })
  );

  readonly currentStep$: Observable<ModuleStep | null> = combineLatest([this.currentSection$, this.currentStepId$]).pipe(
    map(([currentSection, currentStepId]: [ModuleSection, string]) => {
      if (currentSection == null || currentStepId == null) return null;
      return currentSection.steps.find((step: ModuleStep) => step.id === currentStepId) ?? null;
    })
  );

  readonly currentSite$: Observable<ModuleSite | null> = combineLatest([this.currentStep$, this.currentSiteId$]).pipe(
    map(([currentStep, currentSiteId]: [ModuleStep, string]) => {
      if (currentStep == null || currentSiteId == null) return null;
      return currentStep.sites.find((site: ModuleSite) => site.id === currentSiteId) ?? null;
    })
  );

  readonly isPublishedVersion$: Observable<boolean> = this.module$.pipe(
    map((module: Module) => {
      return !!module?.publishedAt;
    })
  );

  get asidePanelState(): ISidePanelState {
    return this._asidePanelState;
  }

  get currentModule(): Module {
    return this.module$.getValue();
  }

  set currentModule(item: Module) {
    this._isEditModeEnabled$.next(false);
    this.module$.next(convertToModel(item, Module));
  }

  get currentPlaybook(): Playbook {
    return this.playbook$.getValue();
  }

  set currentPlaybook(item: Playbook) {
    this.playbook$.next(convertToModel(item, Playbook));
  }

  // Computed signals
  readonly module = toMutableSignal(this.module$);
  readonly currentSection = toMutableSignal(this.currentSection$);
  readonly currentStep = toMutableSignal(this.currentStep$);
  readonly currentSite = toMutableSignal(this.currentSite$);
  readonly playbook = toMutableSignal(this.playbook$);
  readonly isEditModeEnabled = toSignal(this.isEditModeEnabled$, { initialValue: false });
  readonly isPublishedVersion = toSignal(this.isPublishedVersion$, { initialValue: false });

  enableVersionEditMode(): void {
    this._isEditModeEnabled$.next(true);
  }

  getCurrentModule(id: string, skipLoaderStart?: boolean): Observable<Module> {
    return this._moduleApiService.getItem(id, null, { skipLoaderStart }).pipe(
      tap((res: Module) => {
        this.currentModule = res;
        this.getCurrentPlaybook(res.id).subscribe();
      })
    );
  }

  getCurrentPlaybook(moduleId: string, skipLoaderStart?: boolean): Observable<Playbook> {
    return this._playbookApiService.getPlaybook(moduleId, { skipLoaderStart }).pipe(tap((res: Playbook) => (this.currentPlaybook = res)));
  }

  clearModuleData(): void {
    this.module$.next(null);
    this.currentSectionId$.next(null);
    this.currentStepId$.next(null);
    this.currentSiteId$.next(null);
  }

  /**
   * Update the in-memory model of the current module with the given segment answer and trigger observers.
   * We need this to dynamically re-evaluate all segments' cql expressions and show/hide the blocks according to the results.
   * Since every segment cql can reference all other segments in the same module, we need to notify observers when one of the
   * segments gets a new or updated answer.
   */
  updateLocalSegmentAnswer(segmentId: string, moduleAnswer: ModuleAnswer): void {
    this.currentModule.sections.forEach((section: ModuleSection) => {
      section.steps.forEach((step: ModuleStep) => {
        step.sites.forEach((site: ModuleSite) => {
          site.segments.forEach((segment: ModuleSegment) => {
            if (segment.id === segmentId) {
              segment.answer = {
                ...segment.answer,
                ...moduleAnswer
              };
            }
          });
        });
      });
    });
    this.answerUpdated$.next(moduleAnswer.answer);
    this.updateLocalModule();
  }

  /**
   * The easiest method to notify every observable that module or some part of module was changed.
   * We need to call this method every time when changing anything related to the module (add segment, delete step, etc...)
   */
  updateLocalModule(): void {
    const module = this.module$.getValue();

    // Sort entities by fractal indexing
    module.sections.sort(sortEntities);
    for (const section of module.sections) {
      section.steps.sort(sortEntities);
      for (const step of section.steps) {
        step.sites.sort(sortEntities);
        for (const site of step.sites) {
          site.segments.sort(sortEntities);
        }
      }
    }

    this.module$.next(module);
  }

  findEntityById(module: Module, entityId: string): Module | ModuleSection | ModuleStep | ModuleSite | ModuleSegment | null {
    if (module.id === entityId) return module;
    for (const section of module.sections) {
      if (section.id === entityId) return section;
      for (const step of section.steps) {
        if (step.id === entityId) return step;
        for (const site of step.sites) {
          if (site.id === entityId) return site;
          for (const segment of site.segments) {
            if (segment.id === entityId) return segment;
          }
        }
      }
    }
    return null;
  }
}
