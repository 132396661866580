<div class="block-question">
  <ng-container *ngIf="isEditMode">
    <div class="block-common__title">{{ pageKey + 'TITLE' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.question = $event; save()"
      [content]="block.question"
      class="block-common__field"
    ></app-text-editor>

    <ng-container *ngIf="block.isDescriptionEnabled">
      <p class="block-question__field-label">{{ pageKey + 'DESCRIPTION' | translate }}</p>
      <app-text-editor
        (contentChanged)="block.description = $event; save()"
        [content]="block.description"
        class="block-common__field"
      ></app-text-editor>
    </ng-container>

    <div class="block-question__options">
      <div *ngFor="let control of answersForm?.controls; let idx = index" class="block-question__option">
        <p class="block-question__field-label">{{ (pageKey + 'ANSWER_LABEL' | translate) + ' ' + numberToChar(idx + 1) }}</p>
        <div class="block-common__option-wrap">
          <app-block-id [blockID]="[block.id, control.value.id]"></app-block-id>
          <app-text-editor
            (contentChanged)="control.get('value').setValue($event)"
            [content]="control.get('value').value"
            class="block-common__field block-question__field"
          ></app-text-editor>
        </div>
        <base-boolean-field
          [control]="control.get('isCommentEnabled')"
          [type]="BooleanFieldType.toggle"
          class="mat-mdc-slide-toggle_blue block-question__option-toggle"
        >
          <p class="block-question__field-label">{{ pageKey + 'ANSWER_TOGGLE' | translate }}</p>
        </base-boolean-field>
      </div>
    </div>

    <div class="mt-3" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <div class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </ng-container>

  <div *ngIf="!isEditMode" [ngStyle]="blockViewStyles" class="block-question__view">
    <app-text-editor [content]="block.question" [isViewMode]="true" class="block-question__title"></app-text-editor>
    <app-text-editor
      *ngIf="block.isDescriptionEnabled"
      [content]="block.description"
      [isViewMode]="true"
      [placeholder]="pageKey + 'DESCRIPTION' | translate"
      class="block-question__description"
    ></app-text-editor>

    <div [class.block-question__options_disabled]="isUserInputDisabled" class="block-question__options">
      <div class="block-question__answers">
        <button
          (click)="saveAnswer(item)"
          *ngFor="let item of block.answerOptions"
          [class.block-question__answer-btn_active]="selectedAnswer?.subId === item.id"
          class="block-question__answer-btn"
          mat-flat-button
        >
          <span [innerHTML]="item.value"></span>
        </button>
      </div>

      <div *ngIf="isCommentEnabled()" @expandInOut class="block-question__comment-wrap">
        <p class="block-question__field-label">{{ pageKey + 'COMMENT' | translate }}</p>
        <base-textarea
          [control]="commentControl"
          [hideLabel]="true"
          [minRows]="5"
          [placeholder]="pageKey + 'COMMENT_PLACEHOLDER' | translate"
        ></base-textarea>
      </div>
    </div>

    <div class="mt-3" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <app-text-editor
        [content]="block.instruction"
        [class.view-mode]="!isEditMode"
        [isViewMode]="!isEditMode"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.QUESTION' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.COUNT_LABEL' | translate: { max: maxOptionsCount } }}</span>
      </h4>
      <base-form-input
        [control]="optionsCountControl"
        [hideLabel]="true"
        [inputType]="inputType.number"
        [max]="maxOptionsCount.toString()"
        [min]="minOptionsCount.toString()"
        [placeholder]="pageKey + 'SETTINGS.COUNT_LABEL' | translate: { max: maxOptionsCount }"
      ></base-form-input>
    </div>
    <mat-divider></mat-divider>

    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.EXTENDED_DESCRIPTION' | translate }}</span>
      </h4>
      <base-boolean-field
        [control]="descriptionToggleControl"
        [type]="BooleanFieldType.toggle"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>

    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
