import { inject, Injectable } from '@angular/core';
import { mergeMap, Observable, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { map } from 'rxjs/operators';

import { dataUrlToFile, getRandomIdentifier } from '@misc/helpers';
import { ModuleMedia } from '@models/classes/module-media.model';
import { AdminMediaApiService } from '@services/api/admin/media/admin-media-api.service';

@Injectable({
  providedIn: 'root'
})
export class MediaUploadService {
  readonly OPEN_UPLOAD_DIALOG$: Subject<string> = new Subject();
  private _adminMediaApi: AdminMediaApiService = inject(AdminMediaApiService);

  uploadImage(media: ModuleMedia): Observable<ModuleMedia> {
    const file$: Observable<File> = fromPromise(dataUrlToFile(media.src, `image_${getRandomIdentifier()}`));

    return file$.pipe(
      map((file: File): FormData => {
        const fd: FormData = new FormData();
        fd.append('file', file);
        return fd;
      }),
      mergeMap((fd: FormData) => this._adminMediaApi.createItem(fd, media))
    );
  }
}
