import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom, mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { LoomLinkParser, MediaLinkParser, YoutubeLinkParser } from '@misc/helpers/video-link-parsers';
import { ModuleMedia } from '@models/classes/module-media.model';
import { FileType } from '@models/enums/file-type.enum';
import { VerticalAlignment } from '@models/enums/vertical-alignment.enum';
import { MediaUploadService } from '@services/media-upload/media-upload.service';
import { IModalAction } from '@shared/modal/modal-actions/modal-actions.component';
import { IModalComponentContext, IModalFormComponent, ModalComponent } from '@shared/modal/modal.component';
import { ModalService } from '@shared/modal/modal.service';
import { ICroppedImage, ImageCropperModalComponent } from '@shared/modal/modals/image-cropper-modal/image-cropper-modal.component';

export interface IMediaBlockData {
  id: string;
  type: FileType;
  fileLink?: string;
  media?: ModuleMedia;
  originalMedia?: ModuleMedia;
  alignment?: VerticalAlignment;
  hasAlignment?: boolean;
}

@Component({
  selector: 'app-media-upload-modal',
  templateUrl: './media-upload-modal.component.html',
  styleUrls: ['./media-upload-modal.component.scss']
})
export class MediaUploadModalComponent extends BaseFormAbstractComponent implements IModalFormComponent<IMediaBlockData>, OnInit {
  @Input() context: IModalComponentContext<IMediaBlockData>;
  addLinkActions: Partial<Record<FileType, IModalAction[]>> = {
    [FileType.img]: [
      {
        name: this.getI18Key('PASTE_IMAGE'),
        color: 'primary',
        type: 'submit'
      }
    ],
    [FileType.video]: [
      {
        name: this.getI18Key('PASTE_VIDEO'),
        color: 'primary',
        type: 'submit'
      }
    ],
    [FileType.embed]: [
      {
        name: this.getI18Key('PASTE'),
        color: 'primary',
        type: 'submit'
      }
    ]
  };
  inputPlaceholder: Partial<Record<FileType, string>> = {
    [FileType.img]: 'INPUT_IMAGE_LINK',
    [FileType.video]: 'INPUT_VIDEO_LINK',
    [FileType.embed]: 'INPUT_LINK'
  };
  uploadButtonTitle: Partial<Record<FileType, string>> = {
    [FileType.img]: 'IMAGE_UPLOAD_BUTTON',
    [FileType.video]: 'VIDEO_UPLOAD_BUTTON',
    [FileType.embed]: 'EMBEDDED_UPLOAD_BUTTON'
  };
  isTabsVisible: boolean;
  private _fb: FormBuilder = inject(FormBuilder);
  private _modalService: ModalService = inject(ModalService);
  private _mediaUpload: MediaUploadService = inject(MediaUploadService);

  alignImage: VerticalAlignment;

  get dialog(): MatDialogRef<ModalComponent<IMediaBlockData>> {
    return this.context?.dialog;
  }

  get mediaType(): FileType {
    return this.context?.entity?.type;
  }

  ngOnInit(): void {
    this.formGroup = this._fb.group({
      fileLink: [this.context?.entity?.fileLink ?? '', [Validators.required]]
    });
    this.isTabsVisible = true;
  }

  getI18Key(key: string): string {
    return `MEDIA_UPLOAD.${key}`;
  }

  async handleUploadedFile(media: ModuleMedia): Promise<any> {
    switch (this.mediaType) {
      case FileType.img: {
        const originalMedia = await lastValueFrom(this._mediaUpload.uploadImage(media));
        this.dialog.close(
          this._cropImage(media).pipe(map(cropped => ({ media: cropped.media, originalMedia, alignment: this.alignImage })))
        );
        break;
      }
      case FileType.video:
        this.dialog.close({ media });
        break;
      case FileType.embed:
        this.dialog.close({ media });
        break;
    }
  }

  async onSubmit(): Promise<any> {
    if (this.formGroup.invalid) return;

    const { fileLink: src } = this.formGroup.value;

    if (src) {
      switch (this.mediaType) {
        case FileType.img: {
          const originalMedia = await lastValueFrom(this._mediaUpload.uploadImage({ src }));
          this.dialog.close(
            this._cropImage({ src }).pipe(
              map(cropped => {
                return { media: cropped.media, originalMedia, alignImage: cropped.alignment };
              })
            )
          );
          break;
        }
        case FileType.video:
          this._prepareEmbeddedFileLink(src);
          break;
        case FileType.embed:
          this._prepareEmbeddedFileLink(src);
          break;
      }
    }
  }

  private _cropImage(media: ModuleMedia): Observable<IMediaBlockData> {
    return this._modalService
      .open<ICroppedImage>(
        {
          component: ImageCropperModalComponent,
          title: 'MODALS.IMAGE_CROPPER_TITLE',
          actions: [{ name: 'BUTTON_NAME.SAVE', type: 'submit', color: 'primary' }],
          context: {
            entity: {
              id: this.context?.entity?.id,
              src: media.src,
              media: media,
              alignment: this.alignImage,
              hasAlignment: this.context?.entity?.hasAlignment
            } as ICroppedImage
          }
        },
        { maxWidth: '45rem' }
      )
      .pipe(
        mergeMap((res: ICroppedImage) => {
          media.src = res.src;
          this.alignImage = res.alignImage;
          return this._mediaUpload.uploadImage(media);
        }),
        map((media: ModuleMedia) => ({ media, alignment: this.alignImage }) as IMediaBlockData)
      );
  }

  private _prepareEmbeddedFileLink(src: string): void {
    const youtube: MediaLinkParser = new YoutubeLinkParser(src);
    const loom: MediaLinkParser = new LoomLinkParser(src);

    if (youtube.isMatched()) {
      src = youtube.getEmbedLink();
    }

    if (loom.isMatched()) {
      src = loom.getEmbedLink();
    }

    this.dialog.close({ media: { src } } as IMediaBlockData);
  }
}
