<div class="table-buttons">
  <div *ngFor="let button of buttons" class="table-buttons__button" (click)="click($event, button)" tabindex="0">
    <mat-icon
      class="table-buttons__icon"
      [svgIcon]="button.secondIcon ? (logical ? button.icon : button.secondIcon) : button.icon"
    ></mat-icon>
    <span class="table-buttons__name">
      {{ (button.secondName ? (logical ? button.name : button.secondName) : button.name) | translate }}
    </span>
  </div>
</div>
