import { Component, Input } from '@angular/core';
import { INavLink } from '@models/interfaces/nav-link.interface';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent {
  @Input() breadcrumbs: INavLink[];
}
