import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, computed, input, AfterViewInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, filter, fromEvent } from 'rxjs';

import { BaseComponent } from '@base/common/shared/components/base/base.component';
import { MetaTypeAttribute } from '@base/models/classes/meta-type.model';
import { ISegmentAnswerValue, SegmentAnswer } from '@base/models/classes/module-answer.model';
import { AppIcon } from '@base/models/enums/app.icons';
import { BlockRow, BlockTable } from '../../../models/block.model';

@Component({
  selector: 'app-block-table-view',
  templateUrl: './block-table-view.component.html',
  styleUrl: './block-table-view.component.scss'
})
export class BlockTableViewComponent extends BaseComponent implements OnInit, AfterViewInit {
  readonly AppIcon = AppIcon;

  @ViewChild('tableContainerInner') tableContainerInner: ElementRef;

  @ViewChild('table') table: ElementRef;

  block = input.required<BlockTable>();

  rows = input.required<BlockRow[]>();

  answerView = input.required<SegmentAnswer>();

  isUserInputDisabled = input.required<boolean>();

  allowCustomSelection = input.required<boolean>();

  showInstruction = input.required<boolean>();

  customRow = new FormControl('', [Validators.required]);
  @Output()
  saveAnswer = new EventEmitter<ISegmentAnswerValue[]>();

  formArray = new FormArray<FormGroup<{ subId: any; value: FormGroup<{ [key: string]: FormControl }> }>>([]);

  columnsToDisplay = computed(() => {
    return this.block().metaType.attributes.filter((attribute: MetaTypeAttribute) => attribute.displayMode !== 'hide');
  });

  ngOnInit(): void {
    this.answerView()?.content?.forEach((row: ISegmentAnswerValue<any>) => {
      const value = new FormGroup({});

      const answerValue = row.value;

      this.block().metaType.attributes.forEach((attribute: MetaTypeAttribute) => {
        const a = (answerValue as any)[attribute.id];
        value.addControl(
          attribute.id,
          new FormControl({ value: a ?? '', disabled: attribute.displayMode !== 'edit' || this.isUserInputDisabled() })
        );
      });

      fromEvent(window, 'resize')
        .pipe(takeUntilDestroyed(this._destroyRef), debounceTime(200))
        .subscribe(() => this.zoomTableToFitContainer());

      const formGroupRow = new FormGroup({
        subId: new FormControl(row.subId),
        value
      });
      this.formArray.push(formGroupRow);
    });

    this.formArray.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(() => !this.isUserInputDisabled()),
        debounceTime(1000)
      )
      .subscribe(() => {
        this.saveAnswer.emit(this.formArray.getRawValue() as any);
      });
  }

  addRow(): void {
    if (!this.customRow.valid) return;
    const value = new FormGroup({});
    this.block().metaType.attributes.forEach((attribute: MetaTypeAttribute) => {
      value.addControl(
        attribute.id,
        new FormControl({ value: attribute.primary ? this.customRow.value : '', disabled: attribute.displayMode !== 'edit' })
      );
    });
    this.formArray.push(new FormGroup({ subId: new FormControl('subId'), value }));
    this.customRow.reset();
  }

  deleteRow(index: number): void {
    this.formArray.removeAt(index);
  }

  trackColumn(i: any, v: any): string {
    return v.id as string;
  }

  trackRow(i: any, v: any): string {
    return v.subId as string;
  }

  ngAfterViewInit(): void {
    this.zoomTableToFitContainer();
  }

  zoomTableToFitContainer() {
    this.table.nativeElement.style.zoom = 1;
    const widthContainer = this.tableContainerInner.nativeElement.getBoundingClientRect().width;
    const widthTable = this.table.nativeElement.getBoundingClientRect().width;

    const scale = widthContainer / widthTable;

    if (scale < 1) {
      this.table.nativeElement.style.zoom = scale;
    }
  }
}
