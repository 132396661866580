<div class="dialog" [class.dialog_confirm]="isConfirm">
  <button mat-icon-button [mat-dialog-close]="" class="dialog__close"><mat-icon [svgIcon]="AppIcon.CLOSE"></mat-icon></button>
  <div #modalBody class="dialog__body">
    <div class="dialog__header">
      <h3 mat-dialog-title class="dialog__title body-1" [innerHtml]="data?.title | translate"></h3>
    </div>
    <p class="dialog__text body-8" *ngIf="data?.text" [innerHTML]="data?.text | translate"></p>
    <p class="dialog__message body-1" *ngIf="data?.message" [innerHTML]="data?.message | translate"></p>
    <div class="dialog__template" *ngIf="data?.template">
      <ng-container *ngTemplateOutlet="data.template; context: context"></ng-container>
    </div>
  </div>
  <modal-actions *ngIf="data?.actions" [actions]="data?.actions" [isConfirm]="isConfirm" (submitted)="onSubmit($event)"></modal-actions>
</div>
