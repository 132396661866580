@if (withExpansionPanel()) {
  <mat-expansion-panel [expanded]="panelOpen()" (closed)="bodyVisible.set(false)" (opened)="bodyVisible.set(true)" class="module-hierarchy">
    <mat-expansion-panel-header>
      <div class="module-hierarchy__title" (click)="navigateToModule()" tabindex="0">
        <mat-icon [svgIcon]="AppIcon.LIST" class="module-hierarchy__title-icon"></mat-icon>
        <span>{{ module()?.name || ('MODULES.UNNAMED_MODULE' | translate) }}</span>
      </div>
    </mat-expansion-panel-header>

    <div [class.hidden]="!bodyVisible()" class="module-hierarchy__body">
      <div
        *ngIf="moduleSectionsEditorService.items$ | async as root"
        [class.module-hierarchy_bullets-only]="bulletsOnly()"
        [ngClass]="'module-hierarchy_' + mode()"
        class="module-hierarchy"
      >
        <div class="module-hierarchy__items">
          <ng-container *ngFor="let item of root.children; let i = index">
            <ng-container
              *ngTemplateOutlet="itemTemplate; context: { $implicit: { parent: root, item, level: levelFrom(), index: i } }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
} @else {
  <div
    *ngIf="moduleSectionsEditorService.items$ | async as root"
    [class.module-hierarchy_bullets-only]="bulletsOnly()"
    [ngClass]="'module-hierarchy_' + mode()"
    class="module-hierarchy"
  >
    <div class="module-hierarchy__title-wrap">
      <mat-icon [svgIcon]="AppIcon.LIST" class="module-hierarchy__title-icon"></mat-icon>
      <h4 class="module-hierarchy__title">
        {{ module()?.name || ('MODULES.UNNAMED_MODULE' | translate) }}
      </h4>
    </div>
    <div class="module-hierarchy__items">
      <ng-container *ngFor="let item of root.children; let i = index">
        <ng-container
          *ngTemplateOutlet="itemTemplate; context: { $implicit: { parent: root, item, level: levelFrom(), index: i } }"
        ></ng-container>
      </ng-container>
    </div>
  </div>
}

<ng-template #itemTemplate let-params>
  <div [ngClass]="'level-' + params.level" class="module-hierarchy__item">
    <p
      (click)="navigate(params.parent, params.item, params.level)"
      [class.module-hierarchy__text_active]="isItemActive(params.item, params.level)"
      [class.module-hierarchy__text_checked]="params.item.isChecked"
      [class.module-hierarchy__text_site]="params.level === ModuleHierarchyLevel.site"
      [class.module-hierarchy__text_step]="params.level === ModuleHierarchyLevel.step"
      [class.module-hierarchy__text_clickable]="isCanNavigate(params.item, params.level)"
      class="body-5 module-hierarchy__text"
      tabindex="0"
    >
      <span>{{ params.item.entity?.name }}</span>
    </p>
    @if (params.level < levelTo()) {
      <div class="module-hierarchy__children">
        <ng-container *ngFor="let item of params.item.children; let i = index">
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { $implicit: { parent: params.item, item, level: params.level + 1, index: i } }"
          ></ng-container>
        </ng-container>
      </div>
    }
  </div>
</ng-template>
