<loader-container [isLoading]="isLoading()" [spinnerSize]="50">
  <div
    (click)="isEditMode && openDialog($event)"
    [class.view-mode]="!isEditMode"
    [ngStyle]="!isEditMode && blockViewStyles"
    class="block-image"
    tabindex="0"
  >
    <figure *ngIf="block.fileUrl; else emptyImage" class="block-image__figure">
      <img [src]="block.fileUrl" alt="Bild" class="block-image__image" />
    </figure>

    <ng-template #emptyImage>
      <mat-icon [svgIcon]="AppIcon.IMAGE" class="block-image__icon"></mat-icon>
      <p class="block-image__label">{{ placeholder | translate }}</p>
    </ng-template>
  </div>
</loader-container>

<ng-template #settingTemplate>
  <app-settings [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.IMAGE' | translate)" (save)="saveSetting()">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>
    <app-block-styles-settings [settings]="imageBlock.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
