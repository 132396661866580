<div *ngIf="isDraggable; else noDragTemplate" cdkDrag class="block" [class.block_read-only]="isReadOnly">
  <ul class="block__controls">
    <li *ngIf="isDraggable" cdkDragHandle class="block__control block__control_drag">
      <mat-icon [svgIcon]="AppIcon.DRAGGABLE"></mat-icon>
    </li>
    <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
  </ul>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>

<ng-template #noDragTemplate>
  <div class="block" [class.block_read-only]="isReadOnly">
    <ul class="block__controls">
      <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      <li *ngIf="isEditIconShown" class="block__control">
        <mat-icon class="block__edit-icon" [svgIcon]="AppIcon.EDIT"></mat-icon>
      </li>
    </ul>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #actionsTemplate>
  <ng-container *ngFor="let item of _actions">
    <li class="block__control">
      <button (click)="handleActionClick(item.type)" mat-icon-button>
        <mat-icon [svgIcon]="item.icon"></mat-icon>
      </button>
    </li>
  </ng-container>
</ng-template>

<ng-template #contentTemplate>
  <div class="block__content">
    <ng-content></ng-content>
  </div>
</ng-template>
