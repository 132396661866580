import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';

import { ModuleSegment } from '@models/classes/module-segment.model';
import { AppIcon } from '@models/enums/app.icons';
import { SegmentsApiService } from '@services/api/admin/segments/segments-api.service';
import { ModuleService } from '@services/module/module.service';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { BlockActionType } from '@shared/blocks/block/block-wrapper/block-wrapper.component';
import { BlockHistoryHandler } from '@shared/blocks/classes/block-history-handler';
import {
  AnyBlock,
  AnyColumnsBlock,
  BlockCluster,
  BlockEmbedded,
  BlockImage,
  BlockInfoBox,
  BlockInput,
  BlockInputSelection,
  BlockListing,
  BlockNineFieldMatrix,
  BlockQuestion,
  BlockReadonly,
  BlockRoleInput,
  BlockSelection,
  BlockSingleChoice,
  BlockSlider,
  BlockTable,
  BlockText,
  BlockType,
  BlockVideo
} from '@shared/blocks/models/block.model';

export const BlockActionTypeMap = new Map<BlockType, BlockActionType[]>([
  [BlockType.TEXT, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.TEXT_TWO_COLUMNS, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.IMAGE, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.TEXT_IMAGE, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.IMAGE_TEXT, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.VIDEO, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.EMBEDDED, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.INFO_BOX, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.TABLE, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.SINGLE_CHOICE, ['copy', 'settings', 'delete']],
  [BlockType.INPUT, ['copy', 'settings', 'delete']],
  [BlockType.SELECTION, ['copy', 'settings', 'delete']],
  [BlockType.SLIDER, ['copy', 'settings', 'delete']],
  [BlockType.QUESTION, ['copy', 'settings', 'delete']],
  [BlockType.ROLE_INPUT, ['copy', 'settings', 'delete']],
  [BlockType.INPUT_SELECTION, ['copy', 'settings', 'delete']],
  [BlockType.READONLY, ['copy', 'settings', 'delete']],
  [BlockType.NINE_FIELD_MATRIX, ['copy', 'settings', 'delete']],
  [BlockType.LISTING, ['undo', 'copy', 'settings', 'delete']],
  [BlockType.CLUSTER, ['copy', 'settings', 'delete']]
]);

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent implements OnInit {
  @Output() dragged: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() actionClick: EventEmitter<BlockActionType> = new EventEmitter<BlockActionType>();

  @ViewChild('blockComponent') blockComponent: BaseBlockComponent;
  @Input() segment: ModuleSegment;
  @Input() isDraggable: boolean;
  @Input() isEditMode: boolean;
  @Input() isReadOnly: boolean;

  readonly AppIcon = AppIcon;
  readonly BlockType = BlockType;

  private readonly _segmentsApi: SegmentsApiService = inject(SegmentsApiService);
  private readonly _moduleService: ModuleService = inject(ModuleService);

  readonly isOpenSetting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _history = new BlockHistoryHandler();
  actionTypes: BlockActionType[];

  get anyBlock(): AnyBlock {
    return this.segment.value as AnyBlock;
  }

  get blockText(): BlockText {
    return this.segment.value as BlockText;
  }

  get blockColumns(): AnyColumnsBlock {
    return this.segment.value as AnyColumnsBlock;
  }

  get blockImage(): BlockImage {
    return this.segment.value as BlockImage;
  }

  get blockVideo(): BlockVideo {
    return this.segment.value as BlockVideo;
  }

  get blockEmbedded(): BlockEmbedded {
    return this.segment.value as BlockEmbedded;
  }

  get blockInfoBox(): BlockInfoBox {
    return this.segment.value as BlockInfoBox;
  }

  get blockTable(): BlockTable {
    return this.segment.value as BlockTable;
  }

  get blockSingleChoice(): BlockSingleChoice {
    return this.segment.value as BlockSingleChoice;
  }

  get blockInput(): BlockInput {
    return this.segment.value as BlockInput;
  }

  get blockSelection(): BlockSelection {
    return this.segment.value as BlockSelection;
  }

  get blockSlider(): BlockSlider {
    return this.segment.value as BlockSlider;
  }

  get blockQuestion(): BlockQuestion {
    return this.segment.value as BlockQuestion;
  }

  get blockRoleInput(): BlockRoleInput {
    return this.segment.value as BlockRoleInput;
  }

  get blockInputSelection(): BlockInputSelection {
    return this.segment.value as BlockInputSelection;
  }

  get blockReadonly(): BlockReadonly {
    return this.segment.value as BlockReadonly;
  }

  get blockNineFieldMatrix(): BlockNineFieldMatrix {
    return this.segment.value as BlockNineFieldMatrix;
  }

  get blockListing(): BlockListing {
    return this.segment.value as BlockListing;
  }

  get blockCluster(): BlockCluster {
    return this.segment.value as BlockCluster;
  }

  ngOnInit(): void {
    this._setActionTypes();
    this._history.add(this.anyBlock);
  }

  updateSegment(value: Partial<ModuleSegment>): void {
    const module = this._moduleService.module();
    if (module == null) return;

    const skipLoaderStart: boolean = [
      BlockType.TEXT,
      BlockType.TEXT_TWO_COLUMNS,
      BlockType.TEXT_IMAGE,
      BlockType.IMAGE_TEXT,
      BlockType.INFO_BOX,
      BlockType.SINGLE_CHOICE,
      BlockType.INPUT,
      BlockType.SELECTION,
      BlockType.SLIDER,
      BlockType.QUESTION,
      BlockType.ROLE_INPUT,
      BlockType.INPUT_SELECTION,
      BlockType.READONLY,
      BlockType.TABLE,
      BlockType.NINE_FIELD_MATRIX,
      BlockType.LISTING,
      BlockType.CLUSTER
    ].includes(this.segment.template);

    Object.assign(this.segment, value);

    const segment: ModuleSegment = plainToInstance(ModuleSegment, {
      ...this.segment
    });
    this._segmentsApi.updateItemEntity(module.id, instanceToPlain(segment), {}, { skipLoaderStart }).subscribe(res => {
      delete res.value;
      Object.assign(this.segment, res);
      this._history.add(this.segment.value);
      this._moduleService.updateLocalModule();
    });
  }

  actionClickHandler(event: BlockActionType): void {
    switch (event) {
      case 'undo': {
        this._handleUndoClick();
        break;
      }
      case 'settings': {
        this._handleSettingClick();
        break;
      }
      default:
        this.actionClick.emit(event);
    }
  }

  private _handleUndoClick(): void {
    const prevHistorySnapshot: AnyBlock = this._history.getPrev();
    if (prevHistorySnapshot) {
      this.updateSegment(prevHistorySnapshot);
    }
  }

  private _handleSettingClick(): void {
    switch (this.segment.template) {
      // TODO: decide how to edit block data
      // case BlockType.VIDEO:
      // case BlockType.EMBEDDED: {
      //   this.blockComponent.openDialog();
      //   break;
      // }
      default:
        this.isOpenSetting$.next(!this.isOpenSetting$?.value);
    }
  }

  private _setActionTypes(): void {
    this.actionTypes = BlockActionTypeMap.get(this.segment.template);
  }
}
