<div class="block-column" [class.view-mode]="!isEditMode">
  <ng-container [ngSwitch]="column.type">
    <app-block-text
      (dataChanged)="dataChanged.emit()"
      *ngSwitchCase="ColumnType.TEXT"
      [block]="textColumn"
      [isEditMode]="isEditMode"
    ></app-block-text>
    <app-block-image
      (dataChanged)="dataChanged.emit()"
      *ngSwitchCase="ColumnType.IMAGE"
      [block]="imageColumn"
      [isEditMode]="isEditMode"
      [hasAlignment]="true"
    ></app-block-image>
  </ng-container>
</div>
