import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSideMenuComponent } from '@shared/components/left-side-menu/left-side-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BaseBooleanFieldModule } from '@forms/base-boolean-field/base-boolean-field.module';
import { DirectivesModule } from '@directives/directives.module';
import { LeftSideContainerComponent } from './left-side-container/left-side-container.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [LeftSideMenuComponent, LeftSideContainerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    RouterLink,
    BaseBooleanFieldModule,
    RouterLinkActive,
    DirectivesModule,
    MatButtonModule
  ],
  exports: [LeftSideMenuComponent, LeftSideContainerComponent]
})
export class LeftSideMenuModule {}
