<loader-container [isLoading]="isLoading()" [spinnerSize]="50">
  <div [ngStyle]="!isEditMode && blockViewStyles" class="block-info-box">
    <div class="block-info-box__photo">
      <ng-container *ngTemplateOutlet="photoTemplate; context: { $implicit: {} }"></ng-container>
    </div>
    <div class="block-info-box__content">
      <app-text-editor
        (contentChanged)="updateText($event)"
        [class.view-mode]="!isEditMode"
        [content]="block.text"
        [isViewMode]="!isEditMode"
        class="block-info-box__text"
      ></app-text-editor>
      <div class="block-info-box__buttons">
        <a
          *ngIf="block?.CTA1?.show"
          [href]="block?.CTA1?.link"
          class="block-info-box__button"
          color="primary"
          mat-flat-button
          target="_blank"
        >
          {{ block?.CTA1?.label }}
        </a>
        <a
          *ngIf="block?.CTA2?.show"
          [href]="block?.CTA2?.link"
          class="block-info-box__button"
          color="accent"
          mat-flat-button
          target="_blank"
        >
          {{ block?.CTA2?.label }}
        </a>
      </div>
    </div>
  </div>
</loader-container>

<ng-template #photoTemplate let-options>
  <div class="block-info-box__wrap">
    <div [class.block-info-box__template_small]="options.isSettingsMode" class="block-info-box__template">
      <img *ngIf="photoControl?.value; else imgEmpty" [src]="photoControl?.value" class="block-info-box__img" alt="Block info box image" />

      <ng-template #imgEmpty>
        <div [class.alt-image]="!!block.altImage" class="block-info-box__img-empty">
          <mat-icon *ngIf="block.altImage" [svgIcon]="'' + block.altImage"></mat-icon>
          <span *ngIf="!block.altImage" class="block-info-box__img-empty-text">C</span>
          <button (click)="openDialog($event, true)" *ngIf="isEditMode" class="block-info-box__button-icon" mat-icon-button type="button">
            <mat-icon [svgIcon]="AppIcon.CAMERA" class="block-info-box__icon-btn"></mat-icon>
          </button>
        </div>
      </ng-template>

      <button
        (click)="deleteImage($event)"
        *ngIf="photoControl?.value && options.isSettingsMode"
        class="block-info-box__button-icon block-info-box__button-icon_delete"
        mat-icon-button
        type="button"
      >
        <mat-icon [svgIcon]="AppIcon.DELETE" class="block-info-box__icon-btn"></mat-icon>
      </button>
      <button
        (click)="openDialog($event, true)"
        *ngIf="photoControl?.value && options.isSettingsMode"
        class="block-info-box__button-icon"
        mat-icon-button
        type="button"
      >
        <mat-icon [svgIcon]="AppIcon.CAMERA" class="block-info-box__icon-btn"></mat-icon>
      </button>

      <div *ngIf="(photoControl?.value || !isEditMode) && !options.isSettingsMode && block?.showIcon" class="block-info-box__show-icon">
        <mat-icon [svgIcon]="selectedIcon" class="block-info-box__show-mat-icon"></mat-icon>
      </div>
    </div>

    <div *ngIf="options.isSettingsMode" class="block-info-box__images">
      <div
        (click)="selectAltImage(item)"
        *ngFor="let item of altImages"
        [class.block-info-box__image_active]="selectedAltImage === item"
        class="block-info-box__image"
        tabindex="0"
      >
        <mat-icon [svgIcon]="'' + item"></mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.INFO_BOX' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block">
      <h4 class="body-1">{{ 'BLOCK_NAME.IMAGE' | translate }}</h4>
      <ng-container *ngTemplateOutlet="photoTemplate; context: { $implicit: { isSettingsMode: true } }"></ng-container>
    </div>
    <mat-divider></mat-divider>
    <div class="settings__block">
      <div class="settings__title">
        <h4 class="body-1">Icon</h4>
        <base-boolean-field
          [control]="showIconControl"
          [type]="BOOLEAN_FIELD_TYPE.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </div>
      <div class="block-info-box__icons">
        <div class="block-info-box__icons-list">
          <div
            *ngFor="let icon of icons"
            [class.block-info-box__icon-wrap_active]="icon === selectedIcon"
            class="block-info-box__icon-wrap"
          >
            <mat-icon (click)="selectedIconHandler(icon)" [svgIcon]="icon" class="block-info-box__icon"></mat-icon>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>CTA #1</span>
        <base-boolean-field
          [control]="CTA1Group.get('show')"
          [type]="BOOLEAN_FIELD_TYPE.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
      <ng-container *ngIf="CTA1Group.get('show').value">
        <base-form-input
          [control]="CTA1Group.get('label')"
          [hideLabel]="true"
          [icon]="AppIcon.TAG"
          [placeholder]="'INPUT_NAME.RENAME' | translate"
          class="settings__input"
        ></base-form-input>
        <base-form-input
          [control]="CTA1Group.get('link')"
          [hideLabel]="true"
          [icon]="AppIcon.LINK"
          [placeholder]="'INPUT_NAME.INPUT_LINK' | translate"
          class="settings__input"
        ></base-form-input>
      </ng-container>
    </div>
    <mat-divider></mat-divider>
    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>CTA #2</span>
        <base-boolean-field
          [control]="CTA2Group.get('show')"
          [type]="BOOLEAN_FIELD_TYPE.toggle"
          class="mat-mdc-slide-toggle_blue"
        ></base-boolean-field>
      </h4>
      <ng-container *ngIf="CTA2Group.get('show').value">
        <base-form-input
          [control]="CTA2Group.get('label')"
          [hideLabel]="true"
          [icon]="AppIcon.TAG"
          [placeholder]="'INPUT_NAME.RENAME' | translate"
          class="settings__input"
        ></base-form-input>
        <base-form-input
          [control]="CTA2Group.get('link')"
          [hideLabel]="true"
          [icon]="AppIcon.LINK"
          [placeholder]="'INPUT_NAME.INPUT_LINK' | translate"
          class="settings__input"
        ></base-form-input>
      </ng-container>
    </div>

    <mat-divider></mat-divider>

    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
