import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordResetModalComponent } from './password-reset-modal.component';
import { BaseInputModule } from '@forms/base-input/base-input.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PasswordResetModalComponent],
  imports: [CommonModule, BaseInputModule, TranslateModule]
})
export class PasswordResetModalModule {}
