<div class="block-listing">
  <ng-container *ngIf="isEditMode">
    <div class="block-common__title">{{ 'BLOCK.LISTING.TITLE' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.title = $event; save()"
      [content]="block.title"
      [isViewMode]="!isEditMode"
      class="block-common__field"
    ></app-text-editor>
    <div class="block-common__title">{{ 'BLOCK.LISTING.DESCRIPTION' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.description = $event; save()"
      [content]="block.description"
      [isViewMode]="!isEditMode"
      class="block-common__field"
    ></app-text-editor>
    <div class="block-common__title">{{ 'BLOCK.LISTING.TITLE_LIST_SECTION' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.titleListSection = $event; save()"
      [content]="block.titleListSection"
      [isViewMode]="!isEditMode"
      class="block-common__field"
    ></app-text-editor>
    <div *ngIf="block.isTooltipEnabled" class="block-common__title">{{ 'BLOCK.LISTING.TOOLTIP' | translate }}</div>
    <app-text-editor
      *ngIf="block.isTooltipEnabled"
      (contentChanged)="block.tooltip = $event; save()"
      [content]="block.tooltip"
      [isViewMode]="!isEditMode"
      class="block-common__field"
    ></app-text-editor>
  </ng-container>

  <div class="block-listing__view" *ngIf="!isEditMode" [ngStyle]="blockViewStyles">
    <p class="block-listing__card-title">
      <app-text-editor [content]="block.title" [isViewMode]="!isEditMode"></app-text-editor>
    </p>
    <p class="block-listing__card-subtitle">
      <app-text-editor [content]="block.description" [isViewMode]="!isEditMode"></app-text-editor>
    </p>
    <div class="block-listing__card" [class.block-listing__card_disabled]="isUserInputDisabled">
      <p class="block-listing__card-title">
        <app-text-editor [content]="block.titleListSection" [isViewMode]="!isEditMode"></app-text-editor>
      </p>
      <div class="block-listing__card-list">
        <div *ngFor="let item of answers; let idx = index" class="block-listing__card-item">
          <app-text-editor [content]="item.value" [isViewMode]="!isEditMode"></app-text-editor>
          <button *ngIf="!isUserInputDisabled" (click)="removeOption(idx)" class="block-listing__item-remove" mat-icon-button>
            <mat-icon [svgIcon]="AppIcon.CLOSE"></mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="!isUserInputDisabled" class="block-listing__card-add">
        <input [formControl]="optionName" [placeholder]="pageKey + 'ADD_OPTION' | translate" type="text" />
        <button (click)="addOption()" color="primary" mat-flat-button>
          {{ 'BUTTON_NAME.ADD' | translate }}
        </button>
      </div>
      <p *ngIf="!isUserInputDisabled && block.isTooltipEnabled" class="block-listing__card-hint">
        <app-text-editor [content]="block.tooltip" [isViewMode]="!isEditMode"></app-text-editor>
      </p>
    </div>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings (save)="saveSetting()" [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.LISTING' | translate)">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>
    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ 'SETTINGS.INSTRUCTIONS' | translate }}</span>
      </h4>
      <base-boolean-field
        [control]="tooltipToggleControl"
        [type]="BooleanFieldType.toggle"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>

    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
