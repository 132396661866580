import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleCardComponent } from './module-card.component';
import { ProgressBarModule } from '@shared/components/progress-bar/progress-bar.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { BreadCrumbsModule } from '@shared/components/bread-crumbs/bread-crumbs.module';

@NgModule({
  declarations: [ModuleCardComponent],
  exports: [ModuleCardComponent],
  imports: [CommonModule, ProgressBarModule, TranslateModule, MatButtonModule, RouterLink, MatCardModule, MatIconModule, BreadCrumbsModule]
})
export class ModuleCardModule {}
