import { Component, Input } from '@angular/core';

import { getBlockIdentifier } from '@misc/helpers';
import { InputType } from '@models/enums/input-type.enum';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { BlockColumnText } from '@shared/blocks/models/block-column.model';
import { BlockText, BlockType } from '@shared/blocks/models/block.model';

@Component({
  selector: 'app-block-text',
  templateUrl: './block-text.component.html',
  styleUrls: ['./block-text.component.scss']
})
export class BlockTextComponent extends BaseBlockComponent<BlockText | BlockColumnText> {
  @Input() placeholder: string = 'MODULES.TEXT_BLOCK_PLACEHOLDER';
  readonly InputType = InputType;

  get textBlock(): BlockText {
    return this.block as BlockText;
  }

  saveContent(content: string): void {
    this.block.content = content;
    this.block.id = this.block.id || getBlockIdentifier(BlockType.TEXT);
    this.save();
  }

  saveSetting(): void {
    this.textBlock.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }
}
