import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  Renderer2,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AppIcon } from '@base/models/enums/app.icons';
import { IModalAction } from '@shared/modal/modal-actions/modal-actions.component';

export interface IModalComponentContext<T> {
  entity?: T;
  dialog?: MatDialogRef<any>;
}

export interface IModalFormComponent<T = any> {
  context: IModalComponentContext<T>;
  formGroup: FormGroup;
  onSubmit(metadata?: unknown): void;
}

export interface IModalData<T = any> {
  icon?: string;
  title?: string;
  text?: string;
  message?: string;
  template?: TemplateRef<any>;
  component?: Type<IModalFormComponent>;
  context?: IModalComponentContext<T>;
  actions?: IModalAction[];
}

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<T> implements AfterViewInit {
  @ViewChild('modalBody') modalBody: ElementRef<HTMLDivElement>;
  componentRef: ComponentRef<IModalFormComponent<T>>;

  readonly AppIcon = AppIcon;

  get isConfirm(): boolean {
    return !this.data?.component;
  }

  get icon(): string {
    return this.data.icon ?? 'warning_amber';
  }

  get context(): IModalComponentContext<T> {
    return { ...this.data?.context, dialog: this._dialog };
  }

  get componentInstance(): IModalFormComponent<T> {
    return this.componentRef?.instance;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IModalData<T>,
    private _dialog: MatDialogRef<ModalComponent<T>>,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    if (this.data.component) {
      this._createComponent();
    }
  }

  onSubmit(metadata?: unknown): void {
    if (this.componentInstance) {
      this.componentInstance.formGroup?.markAllAsTouched();
      this.componentInstance.onSubmit?.(metadata);
    } else {
      this._dialog.close(metadata);
    }
  }

  private _createComponent(): void {
    this.componentRef = this._viewContainerRef.createComponent(this.data.component);
    this.componentRef.instance.context = this.context;
    this._renderer.appendChild(this.modalBody.nativeElement, this.componentRef.location.nativeElement);
    this._cdr.detectChanges();
  }
}
