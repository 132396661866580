import { Component, Input, OnInit } from '@angular/core';
import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { IModalComponentContext, ModalComponent } from '@shared/modal/modal.component';
import { User } from '@models/classes/user.model';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { difference } from '@misc/helpers/difference.function';
import { CustomValidators } from '@misc/custom-validators';
import { VALIDATORS_SET } from '@misc/constants/validators-set.constant';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent extends BaseFormAbstractComponent implements OnInit {
  @Input() context: IModalComponentContext<User>;

  get user(): User {
    return this.context?.entity;
  }

  get defaultValues(): User {
    return {} as User;
  }

  get dialog(): MatDialogRef<ModalComponent<User>> {
    return this.context?.dialog;
  }

  constructor(
    private _translate: TranslateService,
    private _fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  getModalResult(): User {
    return difference(this.formGroup.getRawValue(), this.defaultValues) as User;
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.dialog.close(this.getModalResult());
  }

  private _initForm(): void {
    this.formGroup = this._fb.group(
      {
        password: ['', Validators.compose([Validators.required, VALIDATORS_SET.PASSWORD])],
        passwordConfirm: ['', Validators.compose([Validators.required, VALIDATORS_SET.PASSWORD])]
      },
      { validators: [CustomValidators.mustMatch('password', 'passwordConfirm')] }
    );
  }
}
