import { BlockType } from './block.model';

export enum SegmentIdPrefix {
  BLOCK = 'mod-',
  OPTION = 'opt-'
}

export const BlockIdPrefix: Record<BlockType, string> = Object.freeze({
  [BlockType.TEXT]: `txt-`,
  [BlockType.TEXT_TWO_COLUMNS]: `t2c-`,
  [BlockType.IMAGE]: `img-`,
  [BlockType.TEXT_IMAGE]: `ti2c-`,
  [BlockType.IMAGE_TEXT]: `it2c-`,
  [BlockType.VIDEO]: `vid-`,
  [BlockType.EMBEDDED]: `obj-`,
  [BlockType.INFO_BOX]: `info-`,
  [BlockType.TABLE]: `tb-`,
  [BlockType.SINGLE_CHOICE]: `sc-`,
  [BlockType.INPUT]: `inp-`,
  [BlockType.SELECTION]: `au-`,
  [BlockType.SLIDER]: `sli-`,
  [BlockType.QUESTION]: `que-`,
  [BlockType.ROLE_INPUT]: `rol-`,
  [BlockType.INPUT_SELECTION]: `se-`,
  [BlockType.READONLY]: `re-`,
  [BlockType.NINE_FIELD_MATRIX]: `nfm-`,
  [BlockType.LISTING]: `li-`,
  [BlockType.CLUSTER]: `clu-`
});
