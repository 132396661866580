<div class="block-selection">
  <ng-container *ngIf="isEditMode">
    <div class="block-common__title">{{ pageKey + 'TITLE' | translate }}</div>
    <app-text-editor (contentChanged)="block.title = $event; save()" [content]="block.title" class="block-common__field"></app-text-editor>

    <p class="block-selection__field-label">{{ pageKey + 'SELECTION_TITLE' | translate }}</p>
    <app-text-editor
      (contentChanged)="block.optionsTitle = $event; save()"
      [content]="block.optionsTitle"
      class="block-common__field"
    ></app-text-editor>

    <div class="block-selection__options">
      <p class="block-selection__field-label">{{ pageKey + 'SELECTION_OPTIONS' | translate }}</p>
      <div *ngFor="let option of block.options; let i = index" class="block-selection__option-item">
        <div *ngIf="isEditMode" class="block-common__option-wrap">
          <app-block-id [blockID]="[block.id, option.id]"></app-block-id>
          <app-block-text
            (dataChanged)="save()"
            [block]="option"
            [isEditMode]="isEditMode"
            [placeholder]="pageKey + 'OPTION_PLACEHOLDER' | translate"
            class="block-common__field block-selection__field"
          ></app-block-text>
        </div>

        <button (click)="deleteOption(option, i)" class="block-selection__delete-button" color="accent" mat-icon-button>
          <mat-icon [svgIcon]="AppIcon.TRASH"></mat-icon>
        </button>
      </div>

      <div class="block-selection__option-item">
        <app-text-editor
          (contentChanged)="newOptionName.setValue($event)"
          [changeDelay]="0"
          [content]="newOptionName.value"
          [isViewMode]="!isEditMode"
          [placeholder]="pageKey + 'OPTION_PLACEHOLDER' | translate"
          class="block-selection__field"
        ></app-text-editor>

        <button (click)="addOption()" class="block-selection__add-button" color="primary" mat-flat-button>
          {{ 'BUTTON_NAME.ADD' | translate }}
        </button>
      </div>
    </div>
    <div class="mt-3" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <div class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </ng-container>

  <div *ngIf="!isEditMode" [ngStyle]="blockViewStyles" class="block-selection__view">
    <app-text-editor [content]="block.title" [isViewMode]="true" class="block-common__field view-mode"></app-text-editor>

    <div class="block-selection__cards">
      <div [class.block-selection__card_disabled]="isUserInputDisabled" class="block-selection__card block-selection__card_question">
        <app-text-editor [content]="block.optionsTitle" [isViewMode]="true" class="block-selection__card-title"></app-text-editor>
        <div
          #listQuestions="cdkDropList"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListConnectedTo]="[listAnswers]"
          [cdkDropListData]="questions"
          cdkDropList
          class="block-selection__card-list"
        >
          <div *ngFor="let item of questions" cdkDrag class="block-selection__card-item">
            <app-text-editor [content]="item.content" [isViewMode]="true"></app-text-editor>
          </div>
        </div>
        <div *ngIf="!isUserInputDisabled" class="block-selection__card-hint">
          <mat-icon [svgIcon]="AppIcon.BLOCK_SELECTION"></mat-icon>
          {{ pageKey + 'CARD_HINT' | translate }}
        </div>
      </div>

      <div [class.block-selection__card_disabled]="isUserInputDisabled" class="block-selection__card block-selection__card_answer">
        <h4 class="block-selection__card-title">{{ pageKey + 'YOUR_SELECTION' | translate }}</h4>
        <div
          #listAnswers="cdkDropList"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListConnectedTo]="[listQuestions]"
          [cdkDropListData]="answers"
          cdkDropList
          class="block-selection__card-list"
        >
          <div
            *ngFor="let item of answers; let idx = index"
            [cdkDragDisabled]="item.isCustom"
            [class.is-custom]="item.isCustom"
            cdkDrag
            class="block-selection__card-item"
          >
            <app-text-editor [content]="item.content" [isViewMode]="true"></app-text-editor>
            <button (click)="removeCustomOption(idx)" *ngIf="item.isCustom" class="block-selection__item-remove" mat-icon-button>
              <mat-icon [svgIcon]="AppIcon.CLOSE"></mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="block.isOwnOptionsEnabled && !isUserInputDisabled" class="block-selection__card-add">
          <input [formControl]="customOptionName" [placeholder]="pageKey + 'OWN_OPTION' | translate" type="text" />
          <button (click)="addCustomOption()" [disabled]="isUserInputDisabled" color="primary" mat-flat-button>
            {{ 'BUTTON_NAME.ADD' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="mt-2" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <app-text-editor
        [content]="block.instruction"
        [class.view-mode]="!isEditMode"
        [isViewMode]="!isEditMode"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.SELECTION' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'ALLOW_OWN_SELECTION' | translate }}</span>
      </h4>
      <base-boolean-field
        [control]="ownOptionsToggle"
        [type]="BooleanFieldType.toggle"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>
    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
