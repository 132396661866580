<div class="avatar-edit">
  <input #inputPhoto [accept]="defaultValues.types" class="avatar-edit__input" type="file" (change)="fileChangeEvent($event)" />

  <image-cropper
    *ngIf="control?.value && !isTakePhoto; else photo"
    class="avatar-edit__cropper image-cropper"
    [imageBase64]="control?.value"
    [maintainAspectRatio]="true"
    [roundCropper]="true"
    [resizeToWidth]="500"
    [resizeToHeight]="500"
    [cropperMinWidth]="100"
    [cropperMinHeight]="100"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>

  <ng-template #photo>
    <webcam
      *ngIf="isTakePhoto"
      class="avatar-edit__webcam"
      [width]="452"
      [height]="340"
      [trigger]="takePhotoWeb"
      (imageCapture)="saveImage($event)"
    ></webcam>
  </ng-template>

  <div class="avatar-edit__buttons" [class.avatar-edit__buttons-padding]="!control?.value">
    <ng-container *ngIf="!isTakePhoto; else webButton">
      <button class="avatar-edit__button" (click)="inputPhoto.click()">
        <mat-icon class="avatar-edit__button-icon" [svgIcon]="AppIcon.UPLOAD"></mat-icon>
        <span class="avatar-edit__button-text">{{ 'BUTTON_NAME.LOAD' | translate }}</span>
      </button>
      <button class="avatar-edit__button" (click)="takePhoto()">
        <mat-icon class="avatar-edit__button-icon" [svgIcon]="AppIcon.CAMERA"></mat-icon>
        <span class="avatar-edit__button-text">{{ 'BUTTON_NAME.TAKE_PHOTO' | translate }}</span>
      </button>
    </ng-container>
    <ng-template #webButton>
      <div class="avatar-edit__web-panel">
        <mat-icon class="avatar-edit__web-panel-icon" [svgIcon]="AppIcon.CAMERA"></mat-icon>
        <p class="body-5">{{ 'PROFILE.TAKE_PICTURES' | translate }}</p>
        <button mat-flat-button class="avatar-edit__web-panel-btn" (click)="takePhoto()">{{ 'BUTTON_NAME.CLICK_HERE' | translate }}</button>
      </div>
    </ng-template>
  </div>
</div>
