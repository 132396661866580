<app-left-side-container [backButtonUrl]="backButtonUrl" [showBackButton]="showBackButton" [title]="header">
  <nav *showForRoles="[UserRole.user]" class="left-side__navigations">
    <a *ngFor="let nav of navigation" [routerLinkActive]="'left-side__link_active'" [routerLink]="nav.route" class="left-side__link">
      <mat-icon [svgIcon]="nav.icon" class="left-side__icon"></mat-icon>
      <span class="left-side__name">{{ nav.name | translate }}</span>
    </a>
    <label *ngIf="themesEnabled" class="left-side__link left-side__link_space" for="theme-control">
      <div class="left-side__link">
        <mat-icon [svgIcon]="AppIcon.USERS_DARKMODE" class="left-side__icon"></mat-icon>
        <span class="left-side__name">{{ 'NAVIGATION.DARK_MODE' | translate }}</span>
      </div>
      <base-boolean-field
        (controlChange)="changeTheme($event)"
        [control]="themeControl"
        [hideLabel]="true"
        [type]="BooleanFieldType.toggle"
        class="left-side__slide"
        id="theme-control"
      ></base-boolean-field>
    </label>
  </nav>
</app-left-side-container>
