import { QuillModules } from 'ngx-quill/config';

export const quillModules: QuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    // ['blockquote', 'code-block'],
    // [{ 'header': 1 }, { 'header': 2 }],
    [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
    // [{ 'script': 'sub' }, { 'script': 'super' }],
    // [{ 'indent': '-1' }, { 'indent': '+1' }],
    // [{ 'direction': 'rtl' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ['link', 'clean']
    // ['link', 'image', 'video']
  ]
};
