import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';

import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { BaseBooleanFieldModule } from '@forms/base-boolean-field/base-boolean-field.module';

@Component({
  selector: 'app-block-instruction-settings',
  standalone: true,
  imports: [BaseBooleanFieldModule, TranslateModule, MatDivider],
  templateUrl: './block-instruction-settings.component.html',
  styleUrl: './block-instruction-settings.component.scss'
})
export class BlockInstructionSettingsComponent implements OnInit {
  @Input() isInstructionEnabled: boolean = true;
  readonly instructionToggleControl: FormControl<boolean> = new FormControl(this.isInstructionEnabled);
  readonly BooleanFieldType = BooleanFieldType;

  get instructionValue(): boolean {
    return this.instructionToggleControl.value;
  }

  ngOnInit(): void {
    this.instructionToggleControl.setValue(this.isInstructionEnabled);
  }
}
