export enum FileType {
  any = '*',
  pdf = 'application/pdf',
  ttf = 'font/ttf',
  otf = 'font/otf',
  jpeg = 'image/jpeg',
  png = 'image/png',
  gif = 'image/gif',
  jpg = 'image/jpg',
  mp4 = 'video/mp4',
  img = 'image/jpeg, image/gif, image/png',
  video = 'video/mp4, video/quicktime',
  embed = 'application/pdf, image/jpeg, image/gif, image/png, video/mp4'
}
