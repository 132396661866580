<div (cdkDropListDropped)="dropBlock($event)" cdkDropList class="blocks" [class.view-mode]="!isEditMode()">
  @for (item of visibleSegments(); track item.id; let idx = $index) {
    @if (isEditMode()) {
      <app-block
        (actionClick)="handleActionClick($event, idx)"
        @fadeInOut
        [segment]="item"
        [isDraggable]="true"
        [isEditMode]="isEditMode()"
        [isReadOnly]="isReadOnly()"
      ></app-block>
    } @else {
      <app-block
        (actionClick)="handleActionClick($event, idx)"
        [segment]="item"
        [isDraggable]="true"
        [isEditMode]="isEditMode()"
        [isReadOnly]="isReadOnly()"
      ></app-block>
    }
  }
</div>
