import { Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { BlockPlaybookSettings } from '@shared/blocks/models/block-playbook-settings.model';

@Component({
  selector: 'app-block-playbook-settings',
  templateUrl: './block-playbook-settings.component.html',
  styleUrls: ['./block-playbook-settings.component.scss']
})
export class BlockPlaybookSettingsComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() isInPlaybook: boolean;
  readonly pageKey: string = 'SETTINGS.';
  readonly BooleanFieldType = BooleanFieldType;
  private _settingsForm: FormGroup;
  private _fb: FormBuilder = inject(FormBuilder);

  get controls(): Record<keyof BlockPlaybookSettings, FormControl> {
    return this._settingsForm?.controls as Record<keyof BlockPlaybookSettings, FormControl>;
  }

  get settingsFormValue(): BlockPlaybookSettings {
    return this._settingsForm?.value;
  }

  ngOnInit(): void {
    this._settingsForm = this._fb.group({ isInPlaybook: [this.isInPlaybook] });
  }
}
