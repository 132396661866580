<app-text-editor
  (contentChanged)="saveContent($event)"
  [class.view-mode]="!isEditMode"
  [content]="block?.content"
  [isViewMode]="!isEditMode"
  [ngStyle]="!isEditMode && blockViewStyles"
  [placeholder]="placeholder"
  class="block-text"
></app-text-editor>

<ng-template #settingTemplate>
  <app-settings [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.TEXT' | translate)" (save)="saveSetting()">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>
    <app-block-styles-settings [settings]="textBlock.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
