<loader-container [isLoading]="isLoading" class="data-table" [style.min-height.rem]="isLoading ? 50 : null">
  <div class="data-table__table-card" [style.min-height.rem]="isEmpty ? 50 : null" [style.flex-grow]="+!isLoading">
    <div class="data-table__table-wrap">
      <table
        [queryParams]="queryParams"
        [paginatePipeArgs]="paginatePipeArgs"
        [dataSource]="dataSource"
        [minWidth]="minWidth"
        [columns]="columns"
        [displayedColumns]="displayedColumns"
        [rowClick]="rowClick"
      ></table>
      <app-empty-block
        *ngIf="isEmpty"
        [emptyIcon]="emptyIcon"
        [emptyMessage]="emptyMessage"
        [reloadButtonName]="reloadButtonName"
        (reload)="reload.emit()"
      ></app-empty-block>
    </div>
    <paginator
      *ngIf="dataSource.length && !isHidePagination"
      class="data-table__pagination"
      [queryParams]="queryParams"
      [(paginatePipeArgs)]="paginatePipeArgs"
    ></paginator>
  </div>
</loader-container>
