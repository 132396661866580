import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { transformToModelsArray } from '@misc/helpers/model-conversion/transform-helpers/transform-to-models-array.function';
import { PlaybookChapterElement } from '@models/classes/playbook-chapter-element.model';

@Exclude()
export class PlaybookChapter extends AbstractModel {
  @Expose()
  name: string;
  @Expose()
  value: string;
  @Expose()
  @Transform(transformToModelsArray(PlaybookChapterElement))
  elements: PlaybookChapterElement[];
  @Expose({ toClassOnly: true })
  active: boolean;
}
