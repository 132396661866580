import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { AppIcon } from '@models/enums/app.icons';
import { ToastType } from '@models/enums/toast-type.enum';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  preserveWhitespaces: false
})
export class NotificationComponent extends Toast {
  get svg(): AppIcon {
    switch (this._package.toastType) {
      case ToastType.success:
        return AppIcon.CHECK;
      case ToastType.info:
        return AppIcon.QUESTION_ICON;
      case ToastType.error:
        return AppIcon.ERROR_ICON;
    }
    return AppIcon.NO;
  }

  get header(): string {
    if (this._package.title) {
      return this._package.title;
    }

    switch (this._package.toastType) {
      case ToastType.success:
        return 'MESSAGE.SUCCESS_HEADER';
      case ToastType.info:
        return 'MESSAGE.INFO_HEADER';
      case ToastType.error:
        return 'MESSAGE.ERROR_HEADER';
      default:
        return '';
    }
  }

  constructor(
    protected _service: ToastrService,
    public _package: ToastPackage
  ) {
    super(_service, _package);
  }

  close(): void {
    this._service.clear(this._package.toastId);
  }
}
