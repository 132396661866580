import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AppIcon } from '@base/models/enums/app.icons';
import { InputType } from '@base/models/enums/input-type.enum';
import { getBlockIdentifier } from '@misc/helpers';
import { SegmentAnswer } from '@models/classes/module-answer.model';
import { BlockSingleChoice, BlockSingleChoiceAnswer, BlockText } from '../../models/block.model';
import { BaseBlockComponent } from '../base-block.component';

@Component({
  selector: 'app-block-single-choice',
  templateUrl: './block-single-choice.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-single-choice.component.scss']
})
export class BlockSingleChoiceComponent extends BaseBlockComponent<BlockSingleChoice> implements OnChanges {
  readonly AppIcon = AppIcon;
  readonly InputType = InputType;

  readonly answerCountControl = new FormControl(2, [Validators.min(2), Validators.max(6)]);

  selectedAnswer: BlockText | null = null;

  get answers(): BlockSingleChoiceAnswer[] {
    return this.block.answers;
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block) {
      if (this.block.answers) {
        this.answerCountControl.setValue(this.block.answers.length);
      }
    }
    if (changes.segment) {
      if (this.segmentAnswer) {
        this.selectedAnswer = this.answers.find(answer => answer.id === this.segmentAnswer?.content?.at(0)?.subId);
      }
    }
  }

  closeSetting(): void {
    this.answerCountControl.setValue(this.block.answers.length);
  }

  saveSetting(): void {
    const count = this.answerCountControl.value;
    const diff = count - this.block.answers.length;
    if (diff > 0) {
      for (let i = this.block.answers.length; i < count; i++) {
        this.block.answers.push({
          id: getBlockIdentifier(),
          content: ''
        });
      }
    } else if (diff < 0) {
      this.block.answers.splice(count, this.block.answers.length);
    }
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  selectAnswer(answer: BlockSingleChoiceAnswer): void {
    if (this.isUserInputDisabled) return;
    this.selectedAnswer = answer;

    if (this.isEditMode || this.segment?.id == null || !this._transformationService.canAcceptAnswer()) return;

    const segmentAnswer: SegmentAnswer = {
      segmentId: this.block.id,
      content: [
        {
          subId: answer.id,
          value: answer.content
        }
      ]
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, segmentAnswer, this.segment?.answer?.id);
  }
}
