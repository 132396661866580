export abstract class MediaLinkParser {
  protected constructor(protected _src: string) {}

  abstract isMatched(): boolean;

  abstract getEmbedLink(): string;
}

export class YoutubeLinkParser extends MediaLinkParser {
  private readonly _youtubeHost: string = 'youtube.com';
  private readonly _youtubeShortHost: string = 'youtu.be';

  constructor(protected override _src: string) {
    super(_src);
  }

  isMatched(): boolean {
    return this._src.includes(this._youtubeHost) || this._src.includes(this._youtubeShortHost);
  }

  getEmbedLink(): string {
    let videoId: string;

    if (this._src.includes(this._youtubeHost) && !this._src.includes('embed')) {
      videoId = this._src.match(/(?<=v=).+/)?.[0];
      this._src = `https://${this._youtubeHost}/embed/${videoId}`;
    } else if (this._src.includes(this._youtubeShortHost)) {
      // eslint-disable-next-line no-useless-escape
      videoId = this._src.match(/[^\/]+$/)[0];
      this._src = `https://${this._youtubeHost}/embed/${videoId}`;
    }

    return this._src;
  }
}

export class LoomLinkParser extends MediaLinkParser {
  private readonly _host: string = 'www.loom.com';

  constructor(src: string) {
    super(src);
  }

  isMatched(): boolean {
    return this._src.includes(this._host);
  }

  getEmbedLink(): string {
    let videoId: string;

    if (this._src.includes(this._host) && !this._src.includes('embed')) {
      videoId = this._src.match(/\/([a-fA-F0-9]+)\??/)?.[1];
      this._src = `https://${this._host}/embed/${videoId}`;
    }

    return this._src;
  }
}
