import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mergeMap, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppIcon } from '@base/models/enums/app.icons';
import { ModuleMedia } from '@models/classes/module-media.model';
import { FileType } from '@models/enums/file-type.enum';
import { MediaUploadService } from '@services/media-upload/media-upload.service';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { IMediaBlockData, MediaUploadModalComponent } from '@shared/blocks/modals/media-upload-modal/media-upload-modal.component';
import { BlockColumnImage } from '@shared/blocks/models/block-column.model';
import { BlockImage } from '@shared/blocks/models/block.model';
import { ModalService } from '@shared/modal/modal.service';
import { ICroppedImage, ImageCropperModalComponent } from '@shared/modal/modals/image-cropper-modal/image-cropper-modal.component';

@Component({
  selector: 'app-block-image',
  templateUrl: './block-image.component.html',
  styleUrls: ['./block-image.component.scss']
})
export class BlockImageComponent extends BaseBlockComponent<BlockImage | BlockColumnImage> implements OnChanges {
  @Input() hasAlignment: boolean;
  readonly placeholder: string = 'MODULES.IMAGE_BLOCK_PLACEHOLDER';
  readonly AppIcon = AppIcon;

  private readonly _modalService: ModalService = inject(ModalService);
  private readonly _mediaUpload: MediaUploadService = inject(MediaUploadService);

  get imageBlock(): BlockImage {
    return this.block as BlockImage;
  }

  constructor() {
    super();
    this._mediaUpload.OPEN_UPLOAD_DIALOG$.pipe(
      filter(id => id === this._currentUuid),
      takeUntilDestroyed()
    ).subscribe((): void => this._openUploadModal());
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block) {
      this._refreshMediaUrl();
    }
  }

  override openDialog(e: Event): void {
    super.openDialog(e);

    if (this.block.fileUrl) {
      this._openEditorModal();
    } else {
      this._openUploadModal();
    }
  }

  saveSetting(): void {
    this.imageBlock.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  private _openUploadModal(): void {
    this._modalService
      .open<IMediaBlockData, Observable<IMediaBlockData>>(
        {
          component: MediaUploadModalComponent,
          context: {
            entity: {
              id: this.block.id,
              type: FileType.img,
              hasAlignment: this.hasAlignment
            }
          }
        },
        {
          maxWidth: '53rem',
          panelClass: 'no-actions'
        }
      )
      .pipe(mergeMap((res: Observable<IMediaBlockData>) => res))
      .subscribe(({ media, originalMedia, alignment }: IMediaBlockData): void => {
        this.block.fileId = media.id;
        this.block.fileUrl = media.src;
        this.block.originalId = originalMedia.id;
        this.block.originalUrl = originalMedia.src;
        this.block.alignment = alignment;
        this.save();
      });
  }

  private _openEditorModal(): void {
    this.getMediaSignedUrl(this.block.originalId || this.block.fileUrl).subscribe((res: ModuleMedia): void => {
      if (this.block.originalId) {
        this.block.originalUrl = res.src;
      } else {
        this.block.fileUrl = res.src;
      }
      this._modalService
        .open<ICroppedImage>(
          {
            component: ImageCropperModalComponent,
            title: 'MODALS.IMAGE_CROPPER_TITLE',
            actions: [{ name: 'BUTTON_NAME.SAVE', type: 'submit', color: 'primary' }],
            context: {
              entity: {
                id: this._currentUuid,
                src: this.block.fileUrl || this.block.originalUrl || '',
                alignImage: this.block.alignment,
                hasAlignment: this.hasAlignment
              } as ICroppedImage
            }
          },
          { maxWidth: '45rem' }
        )
        .pipe(
          mergeMap(({ src, alignImage }: ICroppedImage) => {
            this.block.alignment = alignImage;
            return this._mediaUpload.uploadImage({ src });
          })
        )
        .subscribe(({ id, src }: ModuleMedia): void => {
          this.block.fileId = id;
          this.block.fileUrl = src;
          this.save();
        });
    });
  }

  private _refreshMediaUrl(): void {
    if (this.block.fileId) {
      this.getMediaSignedUrl(this.block.fileId).subscribe((res: ModuleMedia): void => {
        this.block.fileUrl = res.src;
      });
    }
    if (this.isEditMode && this.block.originalId) {
      this.getMediaSignedUrl(this.block.originalId).subscribe((res: ModuleMedia): void => {
        this.block.originalUrl = res.src;
      });
    }
  }
}
