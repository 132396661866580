<div class="block-nine-field-matrix">
  @if (isEditMode) {
    <div class="block-common__title">{{ pageKey + 'TITLE' | translate }}</div>
    <app-text-editor (contentChanged)="block.title = $event; save()" [content]="block.title" class="block-common__field"></app-text-editor>

    <div class="block-common__title">{{ pageKey + 'DESCRIPTION' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.description = $event; save()"
      [content]="block.description"
      class="block-common__field"
    ></app-text-editor>

    <div class="block-common__title">{{ pageKey + 'X_AXIS' | translate }}</div>
    <div class="grid grid-cols-3 gap-5 mb-3">
      <div class="block-common__option-wrap">
        <app-block-id class="narrow" [blockID]="[block.id, block.xConfig.min.id]"></app-block-id>
        <app-text-editor
          [placeholder]="pageKey + 'MIN_LABEL'"
          (contentChanged)="block.xConfig.min.value = $event; save()"
          [content]="block.xConfig.min.value"
          class="block-common__field"
        ></app-text-editor>
      </div>
      <div class="block-common__option-wrap">
        <app-block-id class="narrow" [blockID]="[block.id, block.xConfig.mid.id]"></app-block-id>
        <app-text-editor
          [placeholder]="pageKey + 'MID_LABEL'"
          (contentChanged)="block.xConfig.mid.value = $event; save()"
          [content]="block.xConfig.mid.value"
          class="block-common__field"
        ></app-text-editor>
      </div>
      <div class="block-common__option-wrap">
        <app-block-id class="narrow" [blockID]="[block.id, block.xConfig.max.id]"></app-block-id>
        <app-text-editor
          [placeholder]="pageKey + 'MAX_LABEL'"
          (contentChanged)="block.xConfig.max.value = $event; save()"
          [content]="block.xConfig.max.value"
          class="block-common__field"
        ></app-text-editor>
      </div>
    </div>

    <div class="block-common__title">{{ pageKey + 'Y_AXIS' | translate }}</div>
    <div class="grid grid-cols-3 gap-5 mb-3">
      <div class="block-common__option-wrap">
        <app-block-id class="narrow" [blockID]="[block.id, block.yConfig.min.id]"></app-block-id>
        <app-text-editor
          [placeholder]="pageKey + 'MIN_LABEL'"
          (contentChanged)="block.yConfig.min.value = $event; save()"
          [content]="block.yConfig.min.value"
          class="block-common__field"
        ></app-text-editor>
      </div>
      <div class="block-common__option-wrap">
        <app-block-id class="narrow" [blockID]="[block.id, block.yConfig.mid.id]"></app-block-id>
        <app-text-editor
          [placeholder]="pageKey + 'MID_LABEL'"
          (contentChanged)="block.yConfig.mid.value = $event; save()"
          [content]="block.yConfig.mid.value"
          class="block-common__field"
        ></app-text-editor>
      </div>
      <div class="block-common__option-wrap">
        <app-block-id class="narrow" [blockID]="[block.id, block.yConfig.max.id]"></app-block-id>
        <app-text-editor
          [placeholder]="pageKey + 'MAX_LABEL'"
          (contentChanged)="block.yConfig.max.value = $event; save()"
          [content]="block.yConfig.max.value"
          class="block-common__field"
        ></app-text-editor>
      </div>
    </div>

    <div class="block-common__title">{{ pageKey + 'OPTIONS_TITLE' | translate }}</div>
    <app-text-editor
      (contentChanged)="block.optionsTitle = $event; save()"
      [content]="block.optionsTitle"
      class="block-common__field"
    ></app-text-editor>

    <div class="block-common__title">{{ pageKey + 'OPTIONS' | translate }}</div>
    @if (block.isDynamicOptionsEnabled) {
      <div class="block-nine-field-matrix__selection-id">
        <base-form-input
          [control]="dynamicOptionsBlockIdControl"
          [hideLabel]="true"
          [placeholder]="pageKey + 'OPTION_ID_PLACEHOLDER' | translate"
          class="block-common__option-input"
        ></base-form-input>
        <mat-icon [svgIcon]="isDynamicOptionsBlockIdValid() ? AppIcon.SUCCESS : AppIcon.WARNING"></mat-icon>
      </div>
      <p class="block-nine-field-matrix__selection-id-hint">{{ pageKey + 'OPTION_ID_HINT' | translate }}</p>
    } @else {
      <div *ngFor="let option of block.options; let i = index" class="block-nine-field-matrix__option-item">
        <div *ngIf="isEditMode" class="block-common__option-wrap">
          <app-block-id [blockID]="[block.id, option.id]"></app-block-id>
          <app-text-editor
            (contentChanged)="option.value = $event; save()"
            [content]="option.value"
            [placeholder]="pageKey + 'OPTION_PLACEHOLDER' | translate"
            class="block-common__field"
          ></app-text-editor>
        </div>

        <button (click)="deleteOption(i)" class="block-nine-field-matrix__delete-button" color="accent" mat-icon-button>
          <mat-icon [svgIcon]="AppIcon.TRASH"></mat-icon>
        </button>
      </div>
      <div class="block-nine-field-matrix__option-item">
        <app-text-editor
          (contentChanged)="newOptionName.set($event)"
          [changeDelay]="0"
          [content]="newOptionName()"
          [placeholder]="pageKey + 'OPTION_PLACEHOLDER' | translate"
          class="block-common__field"
        ></app-text-editor>

        <button (click)="addOption()" class="block-nine-field-matrix__add-button" color="primary" mat-flat-button>
          {{ 'BUTTON_NAME.ADD' | translate }}
        </button>
      </div>
    }

    <div *ngIf="block.isInstructionEnabled">
      <div class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  } @else {
    <div [ngStyle]="blockViewStyles" class="block-nine-field-matrix__view">
      <app-text-editor
        [content]="block.title"
        [isViewMode]="true"
        [class.view-mode]="true"
        class="block-common__field block-nine-field-matrix__title mb-2"
      ></app-text-editor>
      <app-text-editor
        [content]="block.description"
        [isViewMode]="true"
        [class.view-mode]="true"
        class="block-common__field block-nine-field-matrix__description"
      ></app-text-editor>

      <div class="block-nine-field-matrix__columns" [class.block-common__readonly]="isUserInputDisabled" cdkDropListGroup>
        <div *ngIf="!isUserInputDisabled" class="block-nine-field-matrix__column block-nine-field-matrix__column_selection">
          <app-text-editor
            [content]="block.optionsTitle"
            [isViewMode]="true"
            [class.view-mode]="true"
            class="block-common__field block-nine-field-matrix__selection-title"
          ></app-text-editor>

          <div
            class="block-nine-field-matrix__options"
            cdkDropListSortingDisabled
            cdkDropList
            (cdkDropListDropped)="onDropItem($event)"
            [cdkDropListData]="initialOptions()"
          >
            <div
              *ngFor="let option of initialOptions()"
              [cdkDragData]="option"
              (mousedown)="onDragStart($event)"
              cdkDrag
              cdkDragBoundary=".block-nine-field-matrix__columns"
              class="block-nine-field-matrix__option"
            >
              <div>
                <app-text-editor
                  [content]="option.value"
                  [isViewMode]="true"
                  [class.view-mode]="true"
                  class="block-common__field block-nine-field-matrix__option-content"
                ></app-text-editor>
                <app-text-editor
                  *ngIf="getOptionGroup(option.groupId)?.value as groupName"
                  [content]="groupName"
                  [isViewMode]="true"
                  [class.view-mode]="true"
                  class="block-common__field block-nine-field-matrix__option-content small-text"
                ></app-text-editor>
              </div>
            </div>
          </div>

          <div class="block-nine-field-matrix__hint">
            <mat-icon [svgIcon]="AppIcon.DRAG_HINT"></mat-icon>
            <span>{{ pageKey + 'D&D_HINT' | translate }}</span>
          </div>
        </div>

        <div class="block-nine-field-matrix__column block-nine-field-matrix__column_chart">
          <app-text-editor
            *ngIf="isUserInputDisabled"
            [content]="block.optionsTitle"
            [isViewMode]="true"
            [class.view-mode]="true"
            class="block-common__field block-nine-field-matrix__selection-title"
          ></app-text-editor>

          <div class="block-nine-field-matrix__chart-area">
            <div class="block-nine-field-matrix__y-labels">
              <app-text-editor
                *ngFor="let label of [block.yConfig.min, block.yConfig.mid, block.yConfig.max]"
                [content]="label.value"
                [isViewMode]="true"
                [class.view-mode]="true"
                class="block-common__field block-nine-field-matrix__chart-label"
              ></app-text-editor>
            </div>

            <div class="block-nine-field-matrix__chart">
              <div *ngFor="let cell of [].constructor(9)" class="block-nine-field-matrix__chart-cell"></div>
              <mat-icon class="block-nine-field-matrix__chart-y-arrow" [svgIcon]="AppIcon.AXIS_ARROW"></mat-icon>
              <mat-icon class="block-nine-field-matrix__chart-x-arrow" [svgIcon]="AppIcon.AXIS_ARROW"></mat-icon>
              <div
                #dropArea
                cdkDropList
                cdkDropListSortingDisabled
                [id]="dropAreaId"
                (cdkDropListDropped)="onDropItem($event)"
                [cdkDropListData]="selectedOptions()"
                class="block-nine-field-matrix__chart-drop-overlay"
              >
                <div
                  *ngFor="let option of selectedOptions()"
                  [cdkDragData]="option"
                  (mousedown)="onDragStart($event)"
                  cdkDrag
                  cdkDragBoundary=".block-nine-field-matrix__chart-drop-overlay"
                  class="block-nine-field-matrix__option"
                  [class.selectable]="isSelectionEnabled"
                  [style.left.%]="option.x"
                  [style.top.%]="option.y"
                >
                  <div>
                    <app-text-editor
                      [content]="option.value"
                      [isViewMode]="true"
                      [class.view-mode]="true"
                      class="block-common__field block-nine-field-matrix__option-content"
                    ></app-text-editor>
                    <app-text-editor
                      *ngIf="getOptionGroup(option.groupId)?.value as groupName"
                      [content]="groupName"
                      [isViewMode]="true"
                      [class.view-mode]="true"
                      class="block-common__field block-nine-field-matrix__option-content small-text"
                    ></app-text-editor>
                  </div>
                  <button
                    *ngIf="isSelectionEnabled && (!isUserInputDisabled || option.isChecked)"
                    (click)="toggleSelection(option)"
                    class="block-nine-field-matrix__option-checkbox"
                    [class.checked]="option.isChecked"
                  >
                    <mat-icon *ngIf="option.isChecked" [svgIcon]="AppIcon.CHECK_CIRCLE_2"></mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div></div>
            <div class="block-nine-field-matrix__x-labels">
              <app-text-editor
                *ngFor="let label of [block.xConfig.min, block.xConfig.mid, block.xConfig.max]"
                [content]="label.value"
                [isViewMode]="true"
                [class.view-mode]="true"
                class="block-common__field block-nine-field-matrix__chart-label"
              ></app-text-editor>
            </div>
          </div>
        </div>
      </div>

      <app-text-editor
        *ngIf="block.isInstructionEnabled && !isUserInputDisabled"
        [content]="block.instruction"
        [isViewMode]="true"
        [class.view-mode]="true"
        class="block-common__field block-nine-field-matrix__instruction"
      ></app-text-editor>
    </div>
  }
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.NINE_FIELD_MATRIX' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.DYNAMIC_OPTIONS' | translate }}</span>
      </h4>
      <base-boolean-field
        [control]="dynamicOptionsToggle"
        [type]="BooleanFieldType.toggle"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>

    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.SINGLE_SELECTION' | translate }}</span>
      </h4>
      <base-boolean-field
        [disabled]="multiSelectionToggle.value"
        [control]="singleSelectionToggle"
        [type]="BooleanFieldType.toggle"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>

    <div class="settings__block settings__block_flex">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.MULTIPLE_SELECTION' | translate }}</span>
      </h4>
      <base-boolean-field
        [disabled]="singleSelectionToggle.value"
        [control]="multiSelectionToggle"
        [type]="BooleanFieldType.toggle"
        class="mat-mdc-slide-toggle_blue"
      ></base-boolean-field>
    </div>
    <mat-divider></mat-divider>

    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
