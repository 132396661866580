import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppIcon } from '@base/models/enums/app.icons';
import { ModuleMedia } from '@models/classes/module-media.model';
import { FileType } from '@models/enums/file-type.enum';
import { AdminMediaApiService } from '@services/api/admin/media/admin-media-api.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Output() uploaded: EventEmitter<ModuleMedia> = new EventEmitter<ModuleMedia>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();
  @Input() mimeType: FileType = FileType.img;
  @Input() placeholder: string = this.getI18Key('PLACEHOLDER');
  @Input() buttonTitle: string = this.getI18Key('BUTTON_NAME');
  @Input() previewUrl: string;
  private readonly _mediaApi: AdminMediaApiService = inject(AdminMediaApiService);
  isDragOver: boolean;
  uploadItem: ModuleMedia;
  cancelLoading$: Subject<void> = new Subject<void>();

  get icon(): AppIcon {
    switch (this.mimeType) {
      case FileType.img:
        return AppIcon.IMAGE;
      case FileType.video:
        return AppIcon.VIDEO;
      case FileType.embed:
        return AppIcon.CODE;
    }
    return AppIcon.NO;
  }

  handleFilesSelected(event: Event): void {
    const files = Array.from((event.target as HTMLInputElement).files || []);
    this._uploadFile(files[0]);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
    const files = Array.from(event.dataTransfer?.files || []);
    this._uploadFile(files[0]);
  }

  getNativeFileUrl(file: File): string {
    if (!file) {
      return '';
    }

    return URL.createObjectURL(file) as string;
  }

  getI18Key(key: string): string {
    return `FILE_UPLOAD.${key}`;
  }

  cancelUpload(): void {
    this.cancelLoading$.next();
    this.cancelled.emit();
  }

  private _uploadFile(file: File): void {
    this._setPreviewUrl(file);
    this.uploadItem = {
      src: this.previewUrl,
      name: file.name,
      type: file.type,
      progress: 0
    };

    if (this.mimeType === FileType.img) {
      this.uploaded.emit(this.uploadItem);
    } else {
      const fd: FormData = new FormData();
      fd.append('file', file);
      this._mediaApi
        .createItem(fd, this.uploadItem, { skipLoaderStart: true })
        .pipe(takeUntil(this.cancelLoading$))
        .subscribe({
          next: (res: ModuleMedia): void => this.uploaded.emit(res),
          error: () => this.cancelled.emit()
        });
    }
  }

  private _setPreviewUrl(file: File): void {
    this.previewUrl = this.getNativeFileUrl(file);
  }
}
