import { Directive, ElementRef, EventEmitter, HostListener, Output, inject } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true
})
export class ClickOutsideDirective {
  @Output() clickOutside: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  private readonly _elementRef = inject(ElementRef<HTMLElement>);

  @HostListener('document:click', ['$event']) onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const isClickedInside: boolean = this._elementRef.nativeElement.contains(target);
    if (!isClickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
