import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppIcon } from '@models/enums/app.icons';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent {
  @Input() icon: string = AppIcon.ERROR_MSG;
  @Input() header: string;
  @Input() text: string;
  @Input() isShowCloseBtn: boolean = true;
  @Output() closeError: EventEmitter<void> = new EventEmitter<void>();

  close(): void {
    this.closeError.emit();
  }
}
