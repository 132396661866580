import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { IServicesConfig } from '@services/http/http.service';
import { Observable } from 'rxjs';
import { Playbook } from '@models/classes/playbook.model';
import { Params } from '@angular/router';
import { PlaybookChapter } from '@models/classes/playbook-chapter.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { toModel } from '@misc/rxjs-operators/to-model.operator';

@Injectable({
  providedIn: 'root'
})
export class AdminPlaybookApiService extends ApiBaseAbstractService<Playbook> {
  protected readonly _URL_PATH: string = '/admin/modules';
  protected readonly _MODEL: ClassConstructor<Playbook> = Playbook;

  getPlaybook(moduleId: string, servicesConfig?: IServicesConfig): Observable<Playbook> {
    return this._http.get(this._getUrlPrefix(moduleId), {}, servicesConfig).pipe(toModel(this._MODEL));
  }

  updatePlaybook(moduleId: string, body: Partial<Playbook>, servicesConfig?: IServicesConfig): Observable<Playbook> {
    return this._http.patch(this._getUrlPrefix(moduleId), body, {}, servicesConfig).pipe(toModel(this._MODEL));
  }

  getChapter(moduleId: string, chapterId: string, params?: Params, servicesConfig?: IServicesConfig): Observable<PlaybookChapter> {
    return this._http
      .get(`${this._getUrlPrefix(moduleId)}/chapter/${chapterId}`, { params }, servicesConfig)
      .pipe(toModel(PlaybookChapter));
  }

  updateChapter(moduleId: string, body: Partial<PlaybookChapter>, servicesConfig?: IServicesConfig): Observable<PlaybookChapter> {
    return this._http.patch(`${this._getUrlPrefix(moduleId)}/chapter`, body, {}, servicesConfig).pipe(toModel(PlaybookChapter));
  }

  getStep(moduleId: string, chapterId: string, stepId: string, servicesConfig?: IServicesConfig): Observable<ModuleStep> {
    return this._http
      .get(`${this._getUrlPrefix(moduleId)}/chapter/${chapterId}/steps/${stepId}`, {}, servicesConfig)
      .pipe(toModel(ModuleStep));
  }

  private _getUrlPrefix(moduleId: string): string {
    return `${this.url}/${moduleId}/playbook`;
  }
}
