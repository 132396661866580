import { inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { AppRoutingPaths } from '@base/app-routing.paths';
import { sortEntities, toMutableSignal } from '@base/misc/helpers';
import { CAN_DROP_ACCESS_SECTION } from '@misc/constants/can-drop-access-section.constant';
import { runInAnyCase } from '@misc/rxjs-operators/run-in-any-case.operator';
import { ModuleSection } from '@models/classes/module-section.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { Module } from '@models/classes/module.model';
import { ModuleElementType } from '@models/enums/module-element-type';
import { AnyModuleEntity, IModuleSectionEntity } from '@models/interfaces/module-section-entity.interface';
import { AdminSectionsApiService } from '@services/api/admin/sections/admin-sections-api.service';
import { AdminSitesApiService } from '@services/api/admin/sites/admin-sites-api.service';
import { AdminStepsApiService } from '@services/api/admin/steps/admin-steps-api.service';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ModuleService } from '@services/module/module.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleSectionsEditorService {
  readonly history$: BehaviorSubject<Module[]> = new BehaviorSubject<Module[]>([]);
  readonly activeHistoryId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly selectedHistoryId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly dragging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _sectionsApiService: AdminSectionsApiService = inject(AdminSectionsApiService);
  private readonly _stepsApiService: AdminStepsApiService = inject(AdminStepsApiService);
  private readonly _sitesApiService: AdminSitesApiService = inject(AdminSitesApiService);
  private readonly _moduleService: ModuleService = inject(ModuleService);
  private readonly _router: Router = inject(Router);
  private readonly _translate: TranslateService = inject(TranslateService);

  // Observables
  readonly items$ = this._moduleService.module$.pipe(
    map(module => {
      if (module == null) return null;

      return this._getSectionTree(module);
    }),
    startWith(null)
  );
  readonly connectedTo$: Observable<string[]> = this.items$.pipe(
    map(items => {
      if (items == null) return [];

      return this._getIdsRecursive(items).reverse();
    })
  );

  // Signals
  readonly loadingSet: WritableSignal<Set<string>> = signal(new Set<string>());
  readonly localItemsMap: WritableSignal<Map<string, IModuleSectionEntity[]>> = signal(new Map<string, IModuleSectionEntity[]>());

  // Computed signals
  readonly items: Signal<IModuleSectionEntity | null> = toMutableSignal(this.items$);
  readonly connectedTo: Signal<string[]> = toSignal(this.connectedTo$, { initialValue: [] });

  constructor() {
    this._handleHistoryChange();
  }

  get isDragging(): boolean {
    return this.dragging$.value;
  }

  get history(): Module[] {
    return this.history$.value;
  }

  get selectedHistoryId(): string {
    return this.selectedHistoryId$.value;
  }

  addItem(parent: IModuleSectionEntity): void {
    const type: ModuleElementType = parent.canDrop[0];
    const item = {
      canDrop: CAN_DROP_ACCESS_SECTION[type],
      entity: {
        type,
        name: this._translate.instant(this.getItemNamePlaceholder(type))
      } as AnyModuleEntity
    };

    this.localItemsMap.update(map => {
      const newMap = new Map(map);
      const array = newMap.get(parent.entity.id);
      if (Array.isArray(array)) {
        array.push(item);
        newMap.set(parent.entity.id, array);
      } else {
        newMap.set(parent.entity.id, [item]);
      }
      return newMap;
    });
  }

  createItem(item: IModuleSectionEntity, parent: IModuleSectionEntity, name: string, position: string): void {
    const module = this._moduleService.module();
    if (module == null) return;

    this._setItemLoadingState(item.entity.id, true);

    this._getApiService(parent.canDrop[0])
      .createItemEntity(
        module.id,
        {
          name,
          position,
          [parent.entity.type.toLowerCase()]: { id: parent.entity.id }
        },
        null,
        { skipLoaderStart: true }
      )
      .pipe(runInAnyCase(() => this._setItemLoadingState(item.entity.id, false)))
      .subscribe((entity: AnyModuleEntity): void => {
        Object.assign(item, this._getSectionTree(entity));
        this._getModuleChildren(parent.entity).push(entity);
        this.localItemsMap.update(map => {
          const newMap = new Map(map);
          const localItems = newMap.get(parent.entity.id) ?? [];
          const index = localItems.indexOf(item);
          if (index >= 0) {
            localItems.splice(index, 1);
            newMap.set(parent.entity.id, localItems);
          }
          return newMap;
        });
        this._updateModuleState();
      });
  }

  removeItem(parent: IModuleSectionEntity, item: IModuleSectionEntity): void {
    const module = this._moduleService.module();
    if (module == null) return;

    const itemId = item.entity.id;
    this._setItemLoadingState(itemId, true);
    this._getApiService(item.entity.type)
      .deleteItemEntity(module.id, itemId, { skipLoaderStart: true })
      .pipe(runInAnyCase(() => this._setItemLoadingState(itemId, false)))
      .subscribe((): void => {
        parent.children = parent.children.filter((el: IModuleSectionEntity): boolean => el.entity.id !== itemId);
        const moduleChildren = this._getModuleChildren(parent.entity);
        const idx: number = moduleChildren.findIndex((item: AnyModuleEntity): boolean => item.id === itemId);
        moduleChildren.splice(idx, 1);
        this._updateModuleState();
      });
  }

  updateItem(item: IModuleSectionEntity, name: string, parent: IModuleSectionEntity): void {
    const module = this._moduleService.module();
    if (module == null) return;

    const body = {
      name,
      id: item.entity.id,
      value: item.entity.value,
      position: item.entity.position,
      [parent.entity.type.toLowerCase()]: { id: parent.entity.id }
    };
    this._setItemLoadingState(item.entity.id, true);
    this._getApiService(item.entity.type)
      .updateItemEntity(module.id, body, null, { skipLoaderStart: true })
      .pipe(runInAnyCase(() => this._setItemLoadingState(item.entity.id, false)))
      .subscribe((entity: AnyModuleEntity): void => {
        Object.assign(item.entity, entity);
        this._updateModuleState();
      });
  }

  updatePosition(item: IModuleSectionEntity, parentId: string, position: string): void {
    const module = this._moduleService.module();
    if (module == null) return;

    const parent = this._moduleService.findEntityById(module, parentId);
    if (parent == null) return;

    this._setItemLoadingState(item.entity.id, true);
    this._getApiService(item.entity.type)
      .updateItemEntity(
        module.id,
        {
          id: item.entity.id,
          name: item.entity.name,
          value: item.entity.value,
          [parent.type.toLowerCase()]: { id: parentId },
          position
        },
        null,
        { skipLoaderStart: true }
      )
      .pipe(runInAnyCase(() => this._setItemLoadingState(item.entity.id, false)))
      .subscribe((entity: AnyModuleEntity) => {
        item.entity.id = entity.id;
        item.entity.position = entity.position;
        this._updateModuleState();
      });
  }

  getItemNamePlaceholder(type: ModuleElementType): string {
    switch (type) {
      case ModuleElementType.section:
        return 'ELEMENT_NAME.SECTION';
      case ModuleElementType.step:
        return 'ELEMENT_NAME.STEP';
      case ModuleElementType.site:
        return 'ELEMENT_NAME.SITE';
      default:
        return 'ELEMENT_NAME.SECTION';
    }
  }

  private _getSectionTree(entity: AnyModuleEntity): IModuleSectionEntity {
    if ((entity as Module)?.sections) {
      return {
        entity,
        canDrop: CAN_DROP_ACCESS_SECTION[ModuleElementType.module],
        children: (entity as Module).sections.sort(sortEntities).map(entity => this._getSectionTree(entity))
      };
    }
    if ((entity as ModuleSection)?.steps) {
      return {
        entity,
        canDrop: CAN_DROP_ACCESS_SECTION[ModuleElementType.section],
        children: (entity as ModuleSection).steps.sort(sortEntities).map(entity => this._getSectionTree(entity))
      };
    }
    if ((entity as ModuleStep)?.sites) {
      return {
        entity,
        canDrop: CAN_DROP_ACCESS_SECTION[ModuleElementType.step],
        children: (entity as ModuleStep).sites.sort(sortEntities).map(entity => this._getSectionTree(entity))
      };
    }

    return {
      entity,
      canDrop: CAN_DROP_ACCESS_SECTION[ModuleElementType.site]
    };
  }

  private _updateModuleState(): void {
    const module = this._moduleService.module();
    if (module == null) return;

    module.publishedAt = null;
    this._moduleService.updateLocalModule();
  }

  private _handleHistoryChange(): void {
    this.selectedHistoryId$.pipe(filter(Boolean)).subscribe((id: string): void => {
      this._router.navigateByUrl(`/${AppRoutingPaths.ADMIN_MODULES_MODULE.replace(':id', id)}`);
    });
  }

  private _getIdsRecursive(item: IModuleSectionEntity): string[] {
    let ids = [item.entity.id];
    item.children?.forEach(childItem => {
      ids = ids.concat(this._getIdsRecursive(childItem));
    });
    return ids;
  }

  private _getModuleChildren(parent: AnyModuleEntity): AnyModuleEntity[] {
    switch (parent.type) {
      case ModuleElementType.module:
        return (parent as Module).sections;
      case ModuleElementType.section:
        return (parent as ModuleSection).steps;
      case ModuleElementType.step:
        return (parent as ModuleStep).sites;
      default:
        return [];
    }
  }

  private _getApiService(type: ModuleElementType): ApiBaseAbstractService<AnyModuleEntity> {
    switch (type) {
      case ModuleElementType.section:
        return this._sectionsApiService;
      case ModuleElementType.step:
        return this._stepsApiService;
      case ModuleElementType.site:
        return this._sitesApiService;
      default:
        return this._sectionsApiService;
    }
  }

  private _setItemLoadingState(itemId: string, loading: boolean): void {
    this.loadingSet.update(set => {
      const newSet = new Set(set);
      if (loading) {
        newSet.add(itemId);
      } else {
        newSet.delete(itemId);
      }
      return newSet;
    });
  }
}
