import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseBooleanFieldModule } from './base-boolean-field/base-boolean-field.module';
import { BaseInputModule } from './base-input/base-input.module';
import { BaseSelectModule } from './base-select/base-select.module';
import { CustomAutocompleteModule } from '@forms/custom-autocomplete/custom-autocomplete.module';

@NgModule({
  exports: [FormsModule, ReactiveFormsModule, BaseInputModule, BaseBooleanFieldModule, BaseSelectModule, CustomAutocompleteModule]
})
export class AppFormsModule {}
