import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { merge } from 'rxjs';

import { AppRoutingPartials, AppRoutingPaths } from '@base/app-routing.paths';
import { AppIcon } from '@base/models/enums/app.icons';
import { ModuleSite } from '@models/classes/module-site.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { Module } from '@models/classes/module.model';
import { AnyModuleEntity, IModuleSectionEntity } from '@models/interfaces/module-section-entity.interface';
import { ModuleSectionsEditorService } from '@services/module-sections-editor/module-sections-editor.service';
import { ModuleService } from '@services/module/module.service';
import { RoleSwitcherService } from '@services/role-switcher/role-switcher.service';
import { TransformationService } from '@services/transformation/transformation.service';
import { BaseComponent } from '@shared/components/base/base.component';

export type ModuleHierarchyMode = 'view' | 'edit' | 'overview';

export enum ModuleHierarchyLevel {
  module = 1,
  section,
  step,
  site
}

@Component({
  selector: 'app-module-hierarchy',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatExpansionModule, TranslateModule],
  templateUrl: './module-hierarchy.component.html',
  styleUrls: ['./module-hierarchy.component.scss']
})
export class ModuleHierarchyComponent extends BaseComponent implements OnInit {
  readonly module = input.required<Module>();
  readonly mode = input<ModuleHierarchyMode>('view');
  readonly levelFrom = input<ModuleHierarchyLevel>(ModuleHierarchyLevel.section);
  readonly levelTo = input<ModuleHierarchyLevel>(ModuleHierarchyLevel.site);
  readonly bulletsOnly = input<boolean>(false);
  readonly isClickable = input<boolean>(true);
  readonly navigationContext = input<ActivatedRoute | null>(null);

  readonly clickableLevel = input<ModuleHierarchyLevel>(ModuleHierarchyLevel.step);
  readonly withExpansionPanel = input<boolean>(true);
  readonly panelOpen = input<boolean>(true);

  readonly ModuleHierarchyLevel = ModuleHierarchyLevel;
  readonly AppIcon = AppIcon;

  readonly moduleSectionsEditorService: ModuleSectionsEditorService = inject(ModuleSectionsEditorService);
  private readonly _moduleService: ModuleService = inject(ModuleService);
  private readonly _transformationService: TransformationService = inject(TransformationService);
  private readonly _router: Router = inject(Router);
  private readonly _route: ActivatedRoute = inject(ActivatedRoute);
  private readonly _roleSwitcher: RoleSwitcherService = inject(RoleSwitcherService);

  // Signals
  readonly bodyVisible = signal<boolean>(false);

  private readonly _currentSite = computed<ModuleSite>(() => {
    return this._roleSwitcher.isAdmin() ? this._moduleService.currentSite() : this._transformationService.transformation()?.current;
  });

  private readonly _latestSite = computed<ModuleSite>(() => {
    return this._transformationService.transformation()?.latest;
  });

  private readonly _isOverviewMode = computed(() => {
    return this.mode() === 'overview';
  });

  ngOnInit(): void {
    if (!this._isOverviewMode()) return;
    if (!this._transformationService.transformation()) return;

    merge(this.moduleSectionsEditorService.items$, this._transformationService.TRANSFORMATION$)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((): void => this._markTreeElements());
  }

  navigateToModule(): void {
    const module = this.module();
    if (module == null) return;
    this._router.navigateByUrl(AppRoutingPaths.ADMIN_MODULES_MODULE.replace(':id', module.id));
  }

  navigate(parent: IModuleSectionEntity, item: IModuleSectionEntity, level: ModuleHierarchyLevel): void {
    if (level === this.clickableLevel()) {
      if (level === ModuleHierarchyLevel.step) {
        this._navigateToStep(item);
      } else if (level === ModuleHierarchyLevel.site) {
        this._navigateToSite(parent.entity as ModuleStep, item.entity as ModuleSite);
      }
    }
  }

  isCanNavigate(item: IModuleSectionEntity, level: ModuleHierarchyLevel): boolean {
    const isAlreadyNavigated: boolean = this._router.url.includes(item.entity.id);
    if (!this.isClickable()) return false;
    if (this.clickableLevel() !== level) return false;
    if ((this._roleSwitcher.isAdmin() || this._roleSwitcher.isCoach()) && !isAlreadyNavigated) return true;

    return (
      (item.isChecked && !isAlreadyNavigated) ||
      (level === ModuleHierarchyLevel.step &&
        item.children.some((siteItem: IModuleSectionEntity): boolean => siteItem.entity.id === this._latestSite()?.id) &&
        !isAlreadyNavigated)
    );
  }

  isItemActive(item: IModuleSectionEntity, level: ModuleHierarchyLevel): boolean {
    let entity: AnyModuleEntity;

    if (level === ModuleHierarchyLevel.step) {
      entity = this._moduleService.currentStep();
    } else if (level === ModuleHierarchyLevel.site) {
      entity = this._currentSite();
    }
    return this._roleSwitcher.isAdmin() || this._roleSwitcher.isCoach() ? entity?.id === item.entity.id : item.isActive;
  }

  private _markTreeElements(): void {
    let isLatestFound: boolean;

    for (const section of this.moduleSectionsEditorService.items().children) {
      for (const step of section.children) {
        step.isActive = false;
        step.isChecked = false;

        for (const site of step.children) {
          if (site.entity.id === this._currentSite()?.id) {
            step.isActive = true;
          }
          if (site.entity.id === this._latestSite()?.id) {
            isLatestFound = true;
          }
        }

        if (this._latestSite() && (!isLatestFound || step.children.at(-1).entity.id === this._latestSite().id)) {
          step.isChecked = true;
        }
      }
    }
  }

  private _navigateToStep(item: IModuleSectionEntity): void {
    if (this.isClickable()) {
      const step: ModuleStep = item.entity as ModuleStep;
      const site: ModuleSite =
        step.sites.find((site: ModuleSite) => site.id === this._currentSite()?.id || site.id === this._latestSite()?.id) || step.sites[0];

      if (this._roleSwitcher.isAdmin() || this._roleSwitcher.isCoach()) {
        this._navigateToSite(step, site);
      } else if (this.isCanNavigate(item, ModuleHierarchyLevel.step)) {
        this._transformationService.updateProgress({ id: site?.id }).subscribe(() => this._navigateToSite(step, site));
      }
    }
  }

  private _navigateToSite(step: ModuleStep, site: ModuleSite): void {
    if (this._isOverviewMode()) {
      // FIXME
      this._router.navigate([AppRoutingPartials.STEPS, step?.id, AppRoutingPartials.SITES, site?.id], {
        relativeTo: this.navigationContext() || this._route
      });
    } else {
      // FIXME
      this._router.navigate([AppRoutingPartials.SITES, site?.id], {
        relativeTo: this.navigationContext() || this._route
      });
    }
  }
}
