<mat-table
  matSort
  (matSortChange)="onSort($event)"
  [style.min-width]="minWidth"
  [dataSource]="paginatePipeArgs ? (dataSource | paginate: params) : dataSource"
  class="table"
  [class.table_hoverable]="rowClick"
>
  <ng-container *ngFor="let columnItem of columns" matColumnDef="{{ columnItem.columnName }}">
    <ng-container *ngIf="columnItem.sort">
      <mat-header-cell
        [mat-sort-header]="columnItem.columnName"
        class="table__cell table__cell_header"
        [style.flex]="columnItem?.flexWidth"
        *matHeaderCellDef
      >
        <ng-container *ngTemplateOutlet="headerCellTemplate; context: { $implicit: columnItem }"></ng-container>
      </mat-header-cell>
    </ng-container>
    <ng-container *ngIf="!columnItem.sort">
      <mat-header-cell class="table__cell table__cell_header" *matHeaderCellDef [style.flex]="columnItem?.flexWidth">
        <ng-container *ngTemplateOutlet="headerCellTemplate; context: { $implicit: columnItem }"></ng-container>
      </mat-header-cell>
    </ng-container>
    <mat-cell
      class="table__cell"
      *matCellDef="let data; let i = index"
      [style.flex]="columnItem?.flexWidth"
      [class.content-center]="columnItem?.isCenterContent"
    >
      <ng-container *ngIf="columnItem?.template; else base">
        <ng-container
          *ngTemplateOutlet="columnItem.template; context: { $implicit: data | pathParse: columnItem.columnName, index: i, entity: data }"
        ></ng-container>
      </ng-container>
      <ng-template #base>
        <cropped-text [text]="(data | pathParse: columnItem.columnName) ?? ''"></cropped-text>
      </ng-template>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" class="table__header-row"></mat-header-row>
  <mat-row class="table__row" *matRowDef="let row; columns: displayedColumns" (click)="rowClick && rowClick(row)"></mat-row>
</mat-table>

<ng-template #headerCellTemplate let-columnItem>
  <ng-container *ngIf="getAsDataTableColumn(columnItem) as columnItem">
    <ng-container *ngIf="columnItem.headerTemplate; else base">
      <ng-container *ngTemplateOutlet="columnItem.headerTemplate; context: { $implicit: columnItem }"></ng-container>
    </ng-container>
    <ng-template #base>
      {{ columnItem.title | translate }}
    </ng-template>
  </ng-container>
</ng-template>
