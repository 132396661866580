import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseInputModule } from '@forms/base-input/base-input.module';
import { TranslateModule } from '@ngx-translate/core';
import { VerificationCodeModalComponent } from '@shared/modal/modals/verefication-code-modal/verification-code-modal.component';

@NgModule({
  declarations: [VerificationCodeModalComponent],
  imports: [CommonModule, BaseInputModule, TranslateModule],
  exports: [VerificationCodeModalComponent]
})
export class VerificationCodeModalModule {}
