<div class="settings__block settings__block_flex">
  <h4 class="body-1 settings__title">{{ pageKey + 'PLAYBOOK' | translate }}</h4>
  <base-boolean-field
    [control]="controls?.isInPlaybook"
    [type]="BooleanFieldType.toggle"
    class="mat-mdc-slide-toggle_blue"
    [disabled]="disabled"
  ></base-boolean-field>
</div>
<mat-divider></mat-divider>
