import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { IAction } from '@models/interfaces/action.interface';

export interface IModalAction extends Omit<IAction<boolean>, 'value'> {
  type: 'submit' | 'close';
  value?: boolean;
  metadata?: unknown;
  class?: string;
}

@Component({
  selector: 'modal-actions',
  templateUrl: './modal-actions.component.html',
  styleUrls: ['./modal-actions.component.scss']
})
export class ModalActionsComponent {
  @Input() actions: IModalAction[];
  @Output() submitted: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Input() isConfirm: boolean = false;

  constructor(private _router: Router) {}

  navigate(url: string | undefined): void {
    if (url) {
      this._router.navigateByUrl(url);
    }
  }
}
