import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarEditModalComponent } from './avatar-edit-modal.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WebcamModule } from 'ngx-webcam';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AvatarEditModalComponent],
  imports: [CommonModule, ImageCropperComponent, MatButtonModule, MatIconModule, WebcamModule, TranslateModule],
  exports: [AvatarEditModalComponent]
})
export class AvatarEditModalModule {}
