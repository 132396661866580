// gets the html from a selector and all the CSS of the document and then returns a html string for printing
export function getHtmlAndCss(selector: string): string {
  let allCSS = '';
  const styleSheets = document.styleSheets;
  for (let i = 0; i < styleSheets.length; i++) {
    let cssRules;
    try {
      cssRules = styleSheets[i].cssRules;
    } catch (e) {
      // if the cssRule is from a cross-origin request, we get an error that we ignore
      continue;
    }
    for (let j = 0; j < cssRules.length; j++) {
      allCSS = allCSS + cssRules[j].cssText;
    }
    allCSS = allCSS + '\n';
  }
  const stylesheet = document.createElement('style');
  stylesheet.innerHTML = allCSS;
  const element = document.querySelector(selector);
  if (element == null) throw new Error(`No element found with selector: ${selector}`);

  const playbookContent = element.cloneNode(true) as Element;
  const html = `<html lang="de" class="theme-light">
      <head><base href='/' />
      ${stylesheet.outerHTML}</head>
      <body class="mat-typography">${playbookContent.innerHTML}</body>
      </html>`;
  return html;
}
