import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ClassConstructor } from 'class-transformer';
import { Observable, switchMap } from 'rxjs';

import { ApiBaseAbstractService } from '@base/services/api/api-base.abstract.service';
import { attributesToModel } from '@misc/helpers/attributes-to-object.function';
import { Module } from '@models/classes/module.model';
import { User } from '@models/classes/user.model';
import { IServicesConfig } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminModulesApiService extends ApiBaseAbstractService<Module> {
  protected readonly _URL_PATH: string = '/admin/modules';
  protected readonly _MODEL: ClassConstructor<Module> = Module;

  publish(id: string, servicesConfig?: IServicesConfig): Observable<Module> {
    return this._http.patch(`${this.url}/${id}/publish`, {}, {}, servicesConfig);
  }

  getCoaches(moduleSlug: string, params?: Params, servicesConfig?: IServicesConfig): Observable<User[]> {
    return this._http
      .get(`${this.url}/${moduleSlug}/coaches`, { params }, servicesConfig)
      .pipe(switchMap(async response => await Promise.all(response.map(async (item: Params) => attributesToModel(item, User, {})))));
  }

  addCoach(moduleSlug: string, user: string): Observable<User> {
    return this._http.post(`${this.url}/${moduleSlug}/coaches`, { user });
  }

  removeCoach(moduleSlug: string, user: string): Observable<void> {
    return this._http.delete(`${this.url}/${moduleSlug}/coaches`, { body: { user } });
  }

  // TODO: implement playbook route for admin
  // getPlaybook(id: string, servicesConfig?: IServicesConfig): Observable<Playbook> {
  //   return this._http.get(`${this.url}/${id}/playbook`, {}, servicesConfig);
  // }

  duplicateModule(id: string): Observable<void> {
    return this._http.post(`${this.url}/${id}/duplicate`, null);
  }

  deleteBySlug(slug: string): Observable<void> {
    return this._http.delete(`${this.url}`, { params: { slug } });
  }
}
