<div class="block-input-selection">
  <ng-container *ngIf="isEditMode">
    <div *ngIf="isEditMode" class="block-common__title">{{ 'BLOCK.INPUT_SELECTION.TITLE' | translate }}</div>
    <app-text-editor (contentChanged)="block.title = $event; save()" [content]="block.title" class="block-common__field"></app-text-editor>

    <div class="block-input-selection__questions">
      <div *ngFor="let control of questionsForm?.controls; let i = index" class="block-input-selection__question">
        <app-block-id
          *ngIf="isEditMode"
          [blockID]="[block.id, control.value?.id]"
          [mode]="'full'"
          class="block-input-selection__card-id"
        ></app-block-id>
        <p class="block-input-selection__field-label">{{ pageKey + 'QUESTION_LABEL' | translate }}</p>
        <app-text-editor
          (contentChanged)="control.get('title').setValue($event)"
          [content]="getQuestionTitleEditMode(control)"
          [placeholder]="pageKey + 'QUESTION_PLACEHOLDER' | translate"
          class="block-common__field"
        ></app-text-editor>

        <p class="block-input-selection__field-label">{{ pageKey + 'OPTIONS_TITLE' | translate }}</p>
        <div
          *ngFor="let option of getAsFormArray(control.get('options'))?.controls; let j = index"
          class="block-input-selection__option-item"
        >
          <div class="block-common__option-wrap">
            <app-block-id [blockID]="[block.id, control.value?.id, option.value?.id]"></app-block-id>
            <base-form-input
              [control]="option.get('value')"
              [hideLabel]="true"
              [placeholder]="pageKey + 'OPTIONS_PLACEHOLDER' | translate"
              class="block-common__option-input block-input-selection__option-input"
            ></base-form-input>
          </div>

          <button (click)="deleteOption(control, j)" class="block-input-selection__delete-button" color="accent" mat-icon-button>
            <mat-icon [svgIcon]="AppIcon.TRASH"></mat-icon>
          </button>
        </div>

        <div class="block-input-selection__option-item">
          <base-form-input
            #newOptionInput
            [hideLabel]="true"
            [placeholder]="pageKey + 'OPTIONS_PLACEHOLDER' | translate"
            class="block-common__option-input block-input-selection__option-input"
          ></base-form-input>

          <button
            (click)="addOption(control, newOptionInput.control)"
            class="block-input-selection__add-button"
            color="primary"
            mat-flat-button
          >
            {{ 'BUTTON_NAME.ADD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="mt-3" *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <div class="block-common__title">{{ 'INPUT_NAME.INSTRUCTIONS' | translate }}</div>
      <app-text-editor
        (contentChanged)="block.instruction = $event; save()"
        [content]="block.instruction"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </ng-container>

  <div *ngIf="!isEditMode" [ngStyle]="blockViewStyles" class="block-input-selection__view">
    <app-text-editor
      [content]="block.title"
      [isViewMode]="true"
      [class.view-mode]="!isEditMode"
      [placeholder]="pageKey + 'TITLE_PLACEHOLDER' | translate"
      class="block-common__field"
    ></app-text-editor>

    <div class="block-input-selection__view-questions">
      <div *ngFor="let question of block.questions; let i = index" class="block-input-selection__view-question">
        <app-text-editor
          [content]="getQuestionTitleRegular(question)"
          [isViewMode]="true"
          class="block-input-selection__question-title"
        ></app-text-editor>
        <base-select
          panelClass="custom-panel"
          classFormField="custom-field"
          [disabled]="isUserInputDisabled"
          [hideLabel]="true"
          [isShowError]="false"
          [placeholder]="pageKey + 'SELECT_PLACEHOLDER' | translate"
          [options]="questionsSelectOptions[i]"
          [control]="inputArray.controls[i].controls.value"
          class="block-input-selection__question-select"
        ></base-select>
      </div>
    </div>

    <div *ngIf="block.isInstructionEnabled && !isUserInputDisabled">
      <app-text-editor
        [content]="block.instruction"
        [class.view-mode]="!isEditMode"
        [isViewMode]="!isEditMode"
        class="block-common__field block-common__field_last"
      ></app-text-editor>
    </div>
  </div>
</div>

<ng-template #settingTemplate>
  <app-settings
    (close)="closeSetting()"
    (save)="saveSetting()"
    [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.INPUT_SELECTION' | translate)"
  >
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook"></app-block-playbook-settings>

    <div class="settings__block">
      <h4 class="body-1 settings__title">
        <span>{{ pageKey + 'SETTINGS.COUNT_LABEL' | translate: { max: maxQuestionsCount } }}</span>
      </h4>
      <base-form-input
        [control]="questionsCountControl"
        [hideLabel]="true"
        [inputType]="InputType.number"
        [max]="maxQuestionsCount.toString()"
        [min]="minQuestionsCount.toString()"
        [placeholder]="pageKey + 'SETTINGS.COUNT_LABEL' | translate: { max: maxQuestionsCount }"
      ></base-form-input>
    </div>
    <mat-divider></mat-divider>
    <app-block-instruction-settings [isInstructionEnabled]="block.isInstructionEnabled"></app-block-instruction-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
