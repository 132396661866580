import { Exclude, Expose, Transform } from 'class-transformer';

export interface ISegmentAnswerValue<M = any> {
  subId: string;
  value: string | ISegmentAnswerValue | ISegmentAnswerValue[] | { [key: string]: string };
  meta?: M;
}

@Exclude()
export class SegmentAnswer {
  @Expose()
  segmentId: string;
  @Expose()
  content: ISegmentAnswerValue[];
}

@Exclude()
export class ModuleAnswer {
  @Expose()
  id: string;
  @Expose()
  author: string;

  @Expose()
  @Transform(
    ({ value }) => {
      if (typeof value === 'string') {
        try {
          return JSON.parse(value);
        } catch (err) {
          return value;
        }
      }
      return value;
    },
    { toClassOnly: true }
  )
  @Transform(({ value }) => JSON.stringify(value), { toPlainOnly: true })
  answer: SegmentAnswer;
}
