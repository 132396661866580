import { Component, OnInit, Type } from '@angular/core';

import { AppIcon } from '@base/models/enums/app.icons';
import { getModuleElementPath } from '@misc/helpers/module-helpers';
import { ModuleElement } from '@models/classes/module-element.model';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { Module } from '@models/classes/module.model';
import { IOption } from '@models/interfaces/forms/option.interface';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { AnyBlock, BlockReadonly, INTERACTIVE_BLOCKS } from '@shared/blocks/models/block.model';

interface IReadonlySegmentPathGroupValue {
  options: IOption[];
  value: string;
}

interface IReadonlySegmentPathGroup {
  section: IReadonlySegmentPathGroupValue;
  step: IReadonlySegmentPathGroupValue;
  site: IReadonlySegmentPathGroupValue;
  segment: IReadonlySegmentPathGroupValue;
}

interface IBlockTitleKeys {
  question: string;
  title: string;
}

@Component({
  selector: 'app-block-readonly',
  templateUrl: './block-readonly.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-readonly.component.scss']
})
export class BlockReadonlyComponent extends BaseBlockComponent<BlockReadonly> implements OnInit {
  readonly AppIcon = AppIcon;
  readonly pageKey: string = 'BLOCK.READONLY.';
  readonly segmentPathGroup: IReadonlySegmentPathGroup = {
    section: { options: [], value: '' },
    step: { options: [], value: '' },
    site: { options: [], value: '' },
    segment: { options: [], value: '' }
  };
  selectedSegment: ModuleSegment;

  ngOnInit(): void {
    this._setSegmentPathGroup(this.block.segmentId);
  }

  saveSetting(): void {
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  handleSelectChange(value: string): void {
    this._setSegmentPathGroup(value, true);
  }

  private _setSegmentPathGroup(elementId: string, updateRequired?: boolean): void {
    const module: Module = this._transformationService.currentTransformation?.module || this._moduleService.currentModule;
    const { section, step, site, segment } = getModuleElementPath(module, elementId);
    this.segmentPathGroup.section.options = this._getOptions(this._moduleService.currentModule.sections);
    this.segmentPathGroup.section.value = section?.item?.id;

    this.segmentPathGroup.step.options = this._getOptions(section?.item?.steps);
    this.segmentPathGroup.step.value = step?.item?.id;

    this.segmentPathGroup.site.options = this._getOptions(step?.item?.sites);
    this.segmentPathGroup.site.value = site?.item?.id;

    this.segmentPathGroup.segment.options = this._getOptions(
      site?.item?.segments,
      (item: ModuleElement): boolean => INTERACTIVE_BLOCKS.some((block: Type<AnyBlock>) => (item as ModuleSegment).value instanceof block),
      (item: ModuleElement): IOption => {
        const block: AnyBlock & IBlockTitleKeys = (item as ModuleSegment).value as AnyBlock & IBlockTitleKeys;
        const headingFromHtml = this._htmlToHeading(block.title || block.question)?.substring(0, 70);
        return { label: headingFromHtml || item.name, value: item.id };
      }
    );
    this.segmentPathGroup.segment.value = segment?.item?.id;
    this.selectedSegment = structuredClone(segment?.item);

    if (this.selectedSegment?.value) {
      this.selectedSegment.value.styleSettings = structuredClone(this.segment.value.styleSettings);
    }

    if (updateRequired && segment?.item) {
      this.block.segmentId = segment.item.id;
      this.save();
    }
  }

  private _getOptions(
    items: ModuleElement[],
    filterFn?: (item: ModuleElement) => boolean,
    mapFn?: (item: ModuleElement) => IOption
  ): IOption[] {
    return (items || []).filter(filterFn || ((): boolean => true)).map(
      mapFn ||
        ((item: ModuleElement): IOption => ({
          label: item.name,
          value: item.id
        }))
    );
  }

  private _htmlToHeading(htmlString: string): string {
    if (!htmlString) {
      return null;
    }
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    const heading = div.querySelector('h1, h2, h3, h4, h5, h6')?.textContent;
    if (heading) {
      return heading;
    }
    return div.textContent;
  }
}
