export enum CqlConstant {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export enum CqlOperator {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
  NOT_SIGN = '!',
  EQ = '==', // equal
  NE = '!=', // not equal
  LT = '<', // lower that
  LTE = '<=', // lower than or equal
  GT = '>', // greater than
  GTE = '>=', // greater than or equal
  LP = '(', // left parenthesis
  RP = ')' // right parenthesis
}

export const cqlConstants: CqlConstant[] = Object.values(CqlConstant);
export const cqlOperators: CqlOperator[] = Object.values(CqlOperator);
