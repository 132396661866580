import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { debounceTime } from 'rxjs';
import { filter } from 'rxjs/operators';

import { getBlockIdentifier } from '@misc/helpers';
import { SegmentAnswer } from '@models/classes/module-answer.model';
import { AppIcon } from '@models/enums/app.icons';
import { InputType } from '@models/enums/input-type.enum';
import { IOption } from '@models/interfaces/forms/option.interface';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { BlockInputSelection, InputSelectionBlockOption, InputSelectionBlockQuestion } from '@shared/blocks/models/block.model';

@Component({
  selector: 'app-block-input-selection',
  templateUrl: './block-input-selection.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-input-selection.component.scss']
})
export class BlockInputSelectionComponent extends BaseBlockComponent<BlockInputSelection> implements OnInit, OnChanges {
  readonly AppIcon = AppIcon;
  readonly InputType = InputType;

  readonly minQuestionsCount: number = 1;
  readonly maxQuestionsCount: number = 10;
  readonly questionsCountControl: FormControl = new FormControl(this.minQuestionsCount, [
    Validators.min(this.minQuestionsCount),
    Validators.max(this.maxQuestionsCount)
  ]);
  questionsForm: FormArray;
  readonly questionsSelectOptions: IOption[][] = [];
  readonly pageKey: string = 'BLOCK.INPUT_SELECTION.';

  readonly inputArray: FormArray<
    FormGroup<{
      subId: FormControl<string>;
      value: FormControl<string | null>;
    }>
  > = new FormArray([]);

  constructor() {
    super();
    this.inputArray.valueChanges
      .pipe(
        takeUntilDestroyed(),
        filter(() => !this.isUserInputDisabled)
      )
      .subscribe(
        (
          array: Partial<{
            subId: string;
            value: string;
          }>[]
        ) => {
          const answer: SegmentAnswer = {
            segmentId: this.block.id,
            content: array.map(
              (
                item: Partial<{
                  subId: string;
                  value: string;
                }>
              ) => {
                return {
                  subId: item.subId,
                  value: item.value
                };
              }
            )
          };
          this.saveAnswer(answer);
        }
      );
  }

  ngOnInit(): void {
    this._setSettingsControls();
    this._setQuestions();
    this._setAnswerOptions();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.block && this.block.questions) {
      this.inputArray.clear();

      for (let i = 0; i < this.block.questions.length; i++) {
        const question = this.block.questions[i];
        this.inputArray.push(
          new FormGroup({
            subId: new FormControl(question.id, [Validators.required]),
            value: new FormControl(null, [Validators.required])
          }),
          { emitEvent: false }
        );
      }
    }
    if (changes.segment && this.segmentAnswer) {
      for (const group of this.inputArray.controls) {
        for (const answer of this.segmentAnswer.content) {
          if (group.value.subId === answer.subId) {
            group.controls.value.setValue(answer.value as string, { emitEvent: false });
          }
        }
      }
    }
  }

  deleteOption(questionControl: AbstractControl, idx: number): void {
    this.getAsFormArray(questionControl.get('options')).removeAt(idx);
    this.block.questions = this.questionsForm.value;
    this.save();
  }

  addOption(questionControl: AbstractControl, optionControl: AbstractControl): void {
    if (!optionControl.value) return;

    this.getAsFormArray(questionControl.get('options')).push(
      new FormGroup({
        id: new FormControl(getBlockIdentifier()),
        value: new FormControl(optionControl.value)
      })
    );
    this.block.questions = this.questionsForm.value;
    optionControl.reset('');
    this.save();
  }

  closeSetting(): void {
    this._setSettingsControls();
  }

  saveSetting(): void {
    this.block.questionsCount = +this.questionsCountControl.value;
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.block.styleSettings = this.updatedStyleSettings;
    this._setQuestions();
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  saveAnswer(answer: SegmentAnswer): void {
    if (this.isEditMode || this.isUserInputDisabled || !this.isUserScope || !this.inputArray.valid) return;

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }

  getAsFormArray(control: AbstractControl): FormArray {
    return control as FormArray;
  }

  // checks first if value.content is used for backwards compatibility
  getQuestionTitleEditMode(control: AbstractControl<any, any>): string {
    return control.get('title').value?.content ?? control.get('title').value;
  }

  // checks first if title.content is used for backwards compatibility
  getQuestionTitleRegular(question: InputSelectionBlockQuestion): string {
    return (question.title as any)?.content ?? question.title;
  }

  private _setSettingsControls(): void {
    this.questionsCountControl.setValue(this.block.questionsCount);
  }

  private _setQuestions(): void {
    const count: number = +this.questionsCountControl.value;
    const length: number = this.block.questions.length;
    const diff: number = count - this.block.questions.length;

    if (diff > 0) {
      for (let i = length; i < count; i++) {
        this.block.questions.push(
          plainToInstance(InputSelectionBlockQuestion, {
            id: getBlockIdentifier(),
            title: '',
            options: []
          })
        );
      }
    } else if (diff < 0) {
      this.block.questions.splice(count, length);
    }

    this.questionsForm = new FormArray(
      this.block.questions.map(
        (question: InputSelectionBlockQuestion) =>
          new FormGroup({
            id: new FormControl(question.id),
            title: new FormControl(question.title),
            options: new FormArray(
              question.options.map(
                (option: InputSelectionBlockOption) =>
                  new FormGroup({
                    id: new FormControl(option.id),
                    value: new FormControl(option.value)
                  })
              )
            )
          })
      )
    );

    this.questionsForm.valueChanges.pipe(takeUntilDestroyed(this._destroyRef), debounceTime(1000)).subscribe((questions: any) => {
      this.block.questions = questions;
      this.save();
    });
  }

  private _setAnswerOptions(): void {
    this.questionsSelectOptions.push(
      ...this.block.questions.map((question: InputSelectionBlockQuestion) => {
        return question.options.map((item: InputSelectionBlockOption) => ({
          value: item.id,
          label: item.value
        }));
      })
    );
  }
}
