import { Component, OnChanges, OnInit, Signal, SimpleChanges, ViewChild, computed, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { plainToInstance } from 'class-transformer';
import { debounceTime } from 'rxjs';

import { BooleanFieldType } from '@base/common/modules/forms/base-boolean-field/base-boolean-field.component';
import { ModuleElementPath, getBlockIdentifier, getModuleElementPath } from '@base/misc/helpers';
import { DisplayMode, MetaType, MetaTypeAttribute } from '@base/models/classes/meta-type.model';
import { ISegmentAnswerValue, SegmentAnswer } from '@base/models/classes/module-answer.model';
import { Module } from '@base/models/classes/module.model';
import { AppIcon } from '@base/models/enums/app.icons';
import { MetaTypeService } from '@services/meta-type/meta-type.service';
import { BlockRow, BlockTable, BlockType } from '@shared/blocks/models/block.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-block-table',
  templateUrl: './block-table.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-table.component.scss']
})
export class BlockTableComponent extends BaseBlockComponent<BlockTable> implements OnInit, OnChanges {

  readonly pageKey: string = 'BLOCK.TABLE.';
  readonly BooleanFieldType = BooleanFieldType;
  readonly AppIcon = AppIcon;

  readonly metaTypeService = inject(MetaTypeService);

  readonly dynamicOptionsBlockIdControl: FormControl<string> = new FormControl<string>('');
  readonly dynamicRowsControl = new FormControl(false);
  readonly allowCustomSelectionControl = new FormControl(false);
  readonly useMetaTypeControl = new FormControl(true);

  readonly newRowName: FormControl = new FormControl('', [Validators.required]);

  metaTypeControl = new FormControl<{ value: MetaType }>(null);

  readonly metaTypeOptions = this.metaTypeService.metaTypes;

  @ViewChild('blockId') blockId: any;

  get showInstruction(): boolean {
    return this.block.isInstructionEnabled ?? true;
  }

  get allowCustomSelection(): boolean {
    return this.block.allowCustomSelection ?? false;
  }

  get useMetaType(): boolean {
    return this.block.useMetaType ?? true;
  }

  get hasDynamicRows(): boolean {
    return this.block.dynamicRows ?? false;
  }

  get primaryAttribute(): MetaTypeAttribute {
    return this.block?.metaType?.attributes.find(attribute => attribute.primary);
  }

  get predefinedAttributes(): MetaTypeAttribute[] {
    return this.block?.metaType?.attributes.filter(attribute => attribute.displayMode === 'predefined');
  }

  readonly dynamicOptionsBlockId: Signal<string> = toSignal(this.dynamicOptionsBlockIdControl.valueChanges);
  readonly dynamicOptionsModuleElementPath: Signal<ModuleElementPath> = computed(() => {
    return getModuleElementPath(this._moduleService.module(), this.dynamicOptionsBlockId());
  });

  readonly isDynamicOptionsBlockIdValid: Signal<boolean> = computed(() => {
    if (!this.dynamicOptionsBlockId()) {
      return false;
    }

    const { segment, site } = this.dynamicOptionsModuleElementPath();

    const segmentItem = segment?.item;

    // don't allow dynamic reference on the same site
    if (this.segment.parentId === site?.item.id) {
      return false;
    }

    if ((segment && segmentItem.template === BlockType.LISTING) || (segment && segmentItem.template === BlockType.SELECTION)) {
      return true;
    }

    if (segment && segmentItem.template === BlockType.TABLE) {
      if ((segmentItem.value as BlockTable).metaType.id === this.block.metaType.id) {
        return true;
      }
    }
    return false;
  });

  // contains segmentAnswer for users. for Admins contains example data
  answerView: SegmentAnswer;

  ngOnInit(): void {
    this.dynamicOptionsBlockIdControl.setValue(this.block.dynamicOptionsBlockId);
    this.dynamicOptionsBlockIdControl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef), debounceTime(300))
      .subscribe((blockId: string): void => {
        if (this.isDynamicOptionsBlockIdValid()) {
          this.block.dynamicOptionsBlockId = blockId;
          this.save();
        }
      });
    let segmentAnswer: SegmentAnswer;
    if (!this.block.metaType) return;
    if (!this.segmentAnswer) {
      if (this.hasDynamicRows && this.block.dynamicOptionsBlockId) {
        const module: Module = this._transformationService.currentTransformation?.module || this._moduleService.currentModule;
        const segment = getModuleElementPath(module, this.block.dynamicOptionsBlockId).segment.item;
        if (segment.template === BlockType.LISTING || segment.template === BlockType.SELECTION) {
          segmentAnswer = {
            segmentId: this.block.id,
            content: segment.answer?.answer?.content.map(row => ({
              subId: row.subId,
              value: { [this.primaryAttribute.id]: row.value }
            })) as any
          };
        }

        if (segment.template === BlockType.TABLE) {
          segmentAnswer = {
            segmentId: this.block.id,
            content: structuredClone(segment.answer?.answer?.content)
          };
        }
      } else {
        segmentAnswer = {
          segmentId: this.block.id,
          content: this.block.rows.map(row => ({ subId: row.id, value: row.values }))
        };
      }
      if (this.isUserScope) {
        this._transformationService.saveSegmentAnswer(this.segment.id, segmentAnswer, this.segment?.answer?.id);
      }
    }
    this.answerView = segmentAnswer ?? this.segmentAnswer;
  }

  saveAnswer(answer: ISegmentAnswerValue[]): void {
    if (!this.isUserScope || !this._transformationService.canAcceptAnswer()) return;

    const segmentAnswer: SegmentAnswer = {
      segmentId: this.block.id,
      content: answer
    };
    this._transformationService.saveSegmentAnswer(this.segment.id, segmentAnswer, this.segment?.answer?.id);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.block) {
      this.dynamicRowsControl.setValue(this.block.dynamicRows ?? false);
      this.allowCustomSelectionControl.setValue(this.block.allowCustomSelection ?? false);
      this.useMetaTypeControl.setValue(this.block.useMetaType ?? true);
    }
    if (changes.block) {
      this.metaTypeControl = new FormControl({ value: changes.block.currentValue.metaType });
    }
  }

  saveMetaType($event: MetaType): void {
    this.block.metaType = structuredClone($event);
    this.block.metaType.attributes = $event.attributes.map(attribute => ({ ...attribute, displayMode: 'display' }));
    this.block.rows = [];
    this.block.dynamicOptionsBlockId = null;
    this.dynamicOptionsBlockIdControl.setValue('');
    this.save();
  }

  deleteRow(row: any, idx: number): void {
    this.block.rows.splice(idx, 1);
    this.save();
  }

  addRow(): void {
    if (this.newRowName.invalid) return;
    this.block.rows.push(
      plainToInstance(BlockRow, {
        id: getBlockIdentifier(),
        values: { [this.primaryAttribute.id]: this.newRowName.value }
      })
    );
    this.newRowName.reset('');
    this.save();
  }

  changeDisplayMode(attribute: MetaTypeAttribute, $event: string) {
    this.block.rows.forEach(row => {
      row.values[attribute.id] = '';
    });
    attribute.displayMode = $event as DisplayMode;
  }

  addColumnStatic() {
    this.block.metaType.attributes.push({ id: uuidv4(), name: '', description: '', type: 'string', primary: false, displayMode: 'predefined' });
  }

  deleteColumnStatic(attributeId: string) {
    const idx = this.block.metaType.attributes.findIndex(attr => attr.id === attributeId);
    this.block.metaType.attributes.splice(idx, 1);
    this.block.rows.forEach(row => delete row.values[attributeId]);
  }

  saveSetting(): void {
    if (this.useMetaType && !this.useMetaTypeControl.value) {
      this.block.metaType = {
        name: 'static',
        id: this.block.id + '_static',
        attributes: [{ id: uuidv4(), name: '', description: '', type: 'string', primary: true }]
      };
      this.block.rows = [];
    }

    if (!this.useMetaType && this.useMetaTypeControl.value) {
      this.block.metaType = null;
      this.block.rows = [];
    }

    this.block.allowCustomSelection = this.allowCustomSelectionControl.value;
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.block.dynamicRows = this.dynamicRowsControl.value;
    this.block.useMetaType = this.useMetaTypeControl.value;

    if (this.block.dynamicRows || this.block.allowCustomSelection) {
      this.block.metaType?.attributes
        .filter(attr => attr.displayMode === 'predefined')
        .forEach(attr => {
          this.changeDisplayMode(attr, 'display');
        });
    }

    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;

    this.save();
  }

  compareWith(option: MetaType, value: MetaType): boolean {
    return option.id === value.id;
  }
}
