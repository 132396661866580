import { Exclude, Expose } from 'class-transformer';

export const BLOCK_STYLE_DEFAULT_COLOR: string = '#F4F6F7';
export const BLOCK_STYLE_STATIC_DEFAULT_COLOR: string = '#FFFFFF';
export const BLOCK_STYLE_COLORS: string[] = [
  BLOCK_STYLE_STATIC_DEFAULT_COLOR,
  '#FBF1F0',
  '#F8EED0',
  '#E5F3CF',
  '#DBE6F5',
  BLOCK_STYLE_DEFAULT_COLOR
];

@Exclude()
export class BlockStyleSettings {
  @Expose()
  bgColor: string = BLOCK_STYLE_DEFAULT_COLOR;
  @Expose()
  showBorder: boolean;
}
