import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Params } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { PER_PAGE_DEFAULT } from '@misc/constants/_base.constant';
import { IOption } from '@models/interfaces/forms/option.interface';
import { IPaginatePipeArgs } from '@models/interfaces/paginate-pipe-args.interface';
import { QueryParamsService } from '@services/query-params/query-params.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent extends BaseComponent implements OnChanges, OnInit {
  @Input() queryParams: QueryParamsService;
  @Input() paginatePipeArgs: IPaginatePipeArgs;
  @Output() paginatePipeArgsChange: EventEmitter<IPaginatePipeArgs> = new EventEmitter<IPaginatePipeArgs>();
  perPage: number;
  page: number;
  perPageControl: FormControl = new FormControl<number>(PER_PAGE_DEFAULT);
  optionsPerPage: IOption[] = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 }
  ];
  protected readonly _PARAMS_CHANGED$: Subject<void> = new Subject<void>();

  get info(): string {
    const items: number = this.perPage * this.page;
    const totalItems: number = Number(this.paginatePipeArgs.totalItems);
    return `${items - this.perPage + 1} - ${items > totalItems ? totalItems : items} von ${totalItems}`;
  }

  get pageIndex(): number {
    return (Number(this.paginatePipeArgs?.currentPage) || 1) - 1;
  }

  ngOnInit(): void {
    this._initialize();
    this.perPageControl.valueChanges.subscribe((value: number) => {
      this.queryParams.paginate(this.queryParams.params.page, value);
    });
  }

  ngOnChanges({ paginatePipeArgs, queryParams }: SimpleChanges): void {
    if (paginatePipeArgs?.currentValue) {
      if (
        paginatePipeArgs?.currentValue?.itemsPerPage !== paginatePipeArgs?.previousValue?.itemsPerPage ||
        paginatePipeArgs?.currentValue?.currentPage !== paginatePipeArgs?.previousValue?.currentPage
      ) {
        this.queryParams.paginate(this.paginatePipeArgs.currentPage as number, this.paginatePipeArgs.itemsPerPage as number);
      }
    }

    if (queryParams?.currentValue) {
      this.page = this.queryParams.params[QueryParamsService.BASE_KEYS.PAGE] ?? 1;
      this.perPage = this.queryParams.params[QueryParamsService.BASE_KEYS.PER_PAGE] ?? PER_PAGE_DEFAULT;

      this._PARAMS_CHANGED$.next();
      this.queryParams.params$
        .pipe(
          takeUntil(this._PARAMS_CHANGED$),
          takeUntilDestroyed(this._destroyRef),
          map(
            ({ [QueryParamsService.BASE_KEYS.PAGE]: page, [QueryParamsService.BASE_KEYS.PER_PAGE]: perPage }): Params => ({ page, perPage })
          ),
          tap(({ page, perPage }): void => {
            this.page = page ?? 1;
            this.perPage = perPage ?? PER_PAGE_DEFAULT;
          })
        )
        .subscribe(this._initialize.bind(this));
    }
  }

  paginate(page: number): void {
    this.setValue(page);
  }

  setValue(page: number): void {
    this.queryParams.paginate(page, this.paginatePipeArgs?.itemsPerPage as number);
  }

  protected _initialize(): void {
    this.paginatePipeArgs.currentPage = this.page;
    this.paginatePipeArgs.itemsPerPage = this.perPage;
    this.paginatePipeArgsChange.emit(this.paginatePipeArgs);

    if (!this.page) {
      this.setValue(1);
    }
  }
}
