import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { RightSideNavService } from '@services/right-side-nav/right-side-nav.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  @Input() title: string = 'SETTINGS.TITLE';
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  rightSideNavService: RightSideNavService = inject(RightSideNavService);

  saveHandler(): void {
    this.rightSideNavService.close();
    this.save.emit();
  }

  cancelHandler(): void {
    this.rightSideNavService.close();
    this.close.emit();
  }
}
