import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizableElementComponent } from './resizable-element.component';

@NgModule({
  declarations: [ResizableElementComponent],
  exports: [ResizableElementComponent],
  imports: [CommonModule]
})
export class ResizableElementModule {}
