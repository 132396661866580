import { Component, inject, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { mergeMap, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { ModuleMedia } from '@models/classes/module-media.model';
import { AppIcon } from '@models/enums/app.icons';
import { FileType } from '@models/enums/file-type.enum';
import { MediaUploadService } from '@services/media-upload/media-upload.service';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import { IMediaBlockData, MediaUploadModalComponent } from '@shared/blocks/modals/media-upload-modal/media-upload-modal.component';
import { BlockInfoBox, InfoBoxIcon, InfoBoxImage } from '@shared/blocks/models/block.model';
import { ModalService } from '@shared/modal/modal.service';
import { ICroppedImage, ImageCropperModalComponent } from '@shared/modal/modals/image-cropper-modal/image-cropper-modal.component';

@Component({
  selector: 'app-block-info-box',
  templateUrl: './block-info-box.component.html',
  styleUrls: ['./block-info-box.component.scss']
})
export class BlockInfoBoxComponent extends BaseBlockComponent<BlockInfoBox> implements OnChanges {
  readonly AppIcon = AppIcon;
  readonly BOOLEAN_FIELD_TYPE = BooleanFieldType;
  icons: InfoBoxIcon[] = Object.values(InfoBoxIcon);
  altImages: InfoBoxImage[] = Object.values(InfoBoxImage) as InfoBoxImage[];
  selectedIcon: InfoBoxIcon;
  selectedAltImage: InfoBoxImage;
  defaultParams: BlockInfoBox;
  photoId: string;
  photoControl: FormControl<string>;
  fb: FormBuilder = inject(FormBuilder);
  CTA1Group: FormGroup;
  CTA2Group: FormGroup;
  showIconControl: FormControl<boolean>;
  private _modalService: ModalService = inject(ModalService);
  private _mediaUpload: MediaUploadService = inject(MediaUploadService);

  constructor() {
    super();
    this._mediaUpload.OPEN_UPLOAD_DIALOG$.pipe(
      filter(id => id === this._currentUuid),
      takeUntilDestroyed()
    ).subscribe((): void => this._openUploadModal(true));
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.block) {
      this.defaultParams = { ...this.block };

      if (this.block.photoId) {
        this.getMediaSignedUrl(this.block.photoId).subscribe((res: ModuleMedia): void => {
          this.photoId = this.block.photoId;
          this.photoControl = new FormControl<string>(res.src ?? '');
        });
      } else {
        this.photoControl = new FormControl<string>('');
      }

      if (this.block.originalPhotoId && this.isEditMode) {
        this.getMediaSignedUrl(this.block.originalPhotoId).subscribe((res: ModuleMedia): void => {
          this.block.originalPhoto = res.src;
        });
      }

      this.CTA1Group = this.fb.group({
        show: this.fb.control(this.block?.CTA1?.show ?? false),
        label: this.fb.control(this.block?.CTA1?.label ?? ''),
        link: this.fb.control(this.block?.CTA1?.link ?? '')
      });

      this.CTA2Group = this.fb.group({
        show: this.fb.control(this.block?.CTA2?.show ?? false),
        label: this.fb.control(this.block?.CTA2?.label ?? ''),
        link: this.fb.control(this.block?.CTA2?.link ?? '')
      });

      this.selectedIcon = this.block?.icon ?? InfoBoxIcon.QUESTION;
      this.selectedAltImage = this.block?.altImage;
      this.showIconControl = this.fb.control(this.block?.showIcon ?? false);
    }
  }

  updateText(event: string): void {
    this.block.text = event;
    this.save();
  }

  override openDialog(e: Event, isSave: boolean = false): void {
    super.openDialog(e);

    if (this.block.photo) {
      this._openEditorModal(isSave);
    } else {
      this._openUploadModal(isSave);
    }
  }

  deleteImage(e: Event): void {
    e.stopPropagation();
    this.photoControl.setValue('');
    this.photoId = '';
  }

  saveSetting(): void {
    this.block.CTA1 = this.CTA1Group.getRawValue();
    this.block.CTA2 = this.CTA2Group.getRawValue();
    this.block.icon = this.selectedIcon;
    this.block.altImage = this.selectedAltImage;
    this.block.showIcon = this.showIconControl.value;
    this.block.photoId = this.photoId;
    this.block.photo = this.photoControl.value;
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  closeSetting(): void {
    this.CTA1Group.setValue({
      show: this.block?.CTA1?.show ?? false,
      label: this.block?.CTA1?.label ?? '',
      link: this.block?.CTA1?.link ?? ''
    });

    this.CTA2Group.setValue({
      show: this.block?.CTA2?.show ?? false,
      label: this.block?.CTA2?.label ?? '',
      link: this.block?.CTA2?.link ?? ''
    });

    this.photoControl.setValue(this.block?.photo ?? '');
    this.photoId = this.block?.photoId ?? '';

    this.selectedIcon = this.block.icon ?? InfoBoxIcon.QUESTION;
    this.selectedAltImage = this.block.altImage;
    this.showIconControl = this.fb.control(this.block?.showIcon ?? false);
  }

  selectedIconHandler(icon: InfoBoxIcon): void {
    this.selectedIcon = icon;
  }

  selectAltImage(icon: InfoBoxImage): void {
    this.selectedAltImage = icon === this.selectedAltImage ? null : icon;
  }

  private _openUploadModal(isSave: boolean): void {
    this._modalService
      .open<IMediaBlockData, Observable<IMediaBlockData>>(
        {
          component: MediaUploadModalComponent,
          context: {
            entity: {
              id: this._currentUuid,
              type: FileType.img
            }
          }
        },
        {
          maxWidth: '53rem',
          panelClass: 'no-actions'
        }
      )
      .pipe(mergeMap((cropModal: Observable<IMediaBlockData>) => cropModal))
      .subscribe(({ media, originalMedia }: IMediaBlockData): void => {
        this.photoControl.setValue(media.src);
        this.photoId = media.id;

        if (isSave) {
          this.block.photoId = media.id;
          this.block.photo = media.src;
          this.block.originalPhotoId = originalMedia.id;
          this.block.originalPhoto = originalMedia.src;
          this.save();
        }
      });
  }

  private _openEditorModal(isSave: boolean): void {
    this.getMediaSignedUrl(this.block.originalPhotoId || this.block.photoId).subscribe((res: ModuleMedia): void => {
      if (this.block.originalPhotoId) {
        this.block.originalPhoto = res.src;
      } else {
        this.photoControl.setValue(res.src);
      }
      this._modalService
        .open<ICroppedImage>(
          {
            component: ImageCropperModalComponent,
            title: 'MODALS.IMAGE_CROPPER_TITLE',
            actions: [{ name: 'BUTTON_NAME.SAVE', type: 'submit', color: 'primary' }],
            context: {
              entity: {
                id: this._currentUuid,
                src: this.block.originalPhoto || this.photoControl.value || ''
              } as ICroppedImage
            }
          },
          { maxWidth: '45rem' }
        )
        .pipe(mergeMap(({ src }: ICroppedImage) => this._mediaUpload.uploadImage({ src })))
        .subscribe(({ id, src }: ModuleMedia): void => {
          this.photoControl.setValue(src);
          this.photoId = id;

          if (isSave) {
            this.block.photoId = id;
            this.block.photo = src;
            this.save();
          }
        });
    });
  }
}
