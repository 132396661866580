import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailChangeModalComponent } from './email-change-modal.component';
import { BaseInputModule } from '@forms/base-input/base-input.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EmailChangeModalComponent],
  imports: [CommonModule, BaseInputModule, TranslateModule],
  exports: [EmailChangeModalComponent]
})
export class EmailChangeModalModule {}
