<mat-card class="module-card">
  <div class="module-card__info">
    <h3 *ngIf="showTitle" class="module-card__title">{{ transformation.organization?.name }}</h3>
    <div class="module-card__header-wrap">
      <h5 class="module-card__header body-1">{{ module.name }}</h5>
      <mat-icon *ngIf="false" [svgIcon]="AppIcon.NOTIFICATION_ACTIVITY" class="module-card__notification"></mat-icon>
    </div>
    <app-progress-bar
      [color]="'#88A8AD'"
      [label]="transformation.status"
      [value]="transformation.progress * 100"
      class="module-card__progress-bar"
    ></app-progress-bar>
    <p class="module-card__last-seen body-7" *ngIf="transformation.progress === 0">{{ 'USER.LAST_SEEN' | translate }}: -</p>
    <p class="module-card__last-seen body-7" *ngIf="transformation.progress !== 0">
      {{
        getI18Key('LAST_ACTIVITY')
          | translate
            : {
                date: module?._updatedAt | date: 'd MMMM YYYY',
                hours: module._updatedAt | date: 'HH',
                minutes: module._updatedAt | date: 'mm'
              }
      }}
    </p>
    <app-bread-crumbs [breadcrumbs]="breadcrumbsList" class="module-card__bread-crumbs"></app-bread-crumbs>
  </div>
  <div class="module-card__buttons">
    <div class="module-card__button-bottom">
      <button
        (click)="onStartClick(true)"
        *ngIf="transformation.latest?.id !== transformation.current?.id"
        class="module-card__button"
        color="accent"
        mat-flat-button
      >
        {{ 'BUTTON_NAME.BACK_TO_LAST_VIEW' | translate }}
      </button>
      <button (click)="onStartClick()" [disabled]="isStartDisabled" class="module-card__button" color="primary" mat-flat-button>
        {{ startButtonName | translate }}
      </button>
    </div>
    <div class="module-card__button-bottom">
      <button (click)="onPlaybookClick()" class="module-card__button" color="accent" mat-flat-button>
        {{ 'BUTTON_NAME.PLAY_BOOK' | translate }}
      </button>
      <button (click)="onOverviewClick()" class="module-card__button" color="accent" mat-flat-button>
        {{ 'BUTTON_NAME.OVERVIEW' | translate }}
      </button>
    </div>
  </div>
</mat-card>
