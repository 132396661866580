<loader-container [isLoading]="isLoading()" [spinnerSize]="50">
  <div
    (click)="isEditMode && openDialog($event)"
    [class.view-mode]="!isEditMode"
    [ngStyle]="!isEditMode && blockViewStyles"
    class="block-video"
    tabindex="0"
  >
    <ng-container [ngSwitch]="true">
      <app-resizable-element
        (sizeChanged)="handleSizeChange($event)"
        *ngSwitchCase="block.fileUrl && !isCustomVideo"
        [height]="null"
        [hideControls]="!isEditMode"
        [showX]="true"
        [showY]="false"
        [width]="block.width"
      >
        <video [src]="videoSafeUrl" class="block-video__video" controls></video>
      </app-resizable-element>

      <app-resizable-element
        (sizeChanged)="handleSizeChange($event)"
        *ngSwitchCase="block.fileUrl && !!isCustomVideo"
        [height]="null"
        [hideControls]="!isEditMode"
        [showX]="true"
        [showY]="false"
        [width]="block.width"
      >
        <iframe [src]="videoSafeUrl" class="block-video__frame"></iframe>
      </app-resizable-element>
      <ng-container *ngSwitchDefault>
        <mat-icon [svgIcon]="AppIcon.VIDEO" class="block-video__icon"></mat-icon>
        <p class="block-video__label">{{ placeholder | translate }}</p>
      </ng-container>
    </ng-container>
  </div>
</loader-container>

<ng-template #settingTemplate>
  <app-settings [title]="('SETTINGS.TITLE' | translate) + ': ' + ('BLOCK_NAME.VIDEO' | translate)" (save)="saveSetting()">
    <app-block-cql-settings [segments]="allSegments" [cql]="segment.cql"></app-block-cql-settings>
    <app-block-playbook-settings [isInPlaybook]="segment.isInPlaybook" [disabled]="true"></app-block-playbook-settings>
    <app-block-styles-settings [settings]="block.styleSettings"></app-block-styles-settings>
  </app-settings>
</ng-template>
