import { Component, inject, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-side-container',
  templateUrl: './left-side-container.component.html',
  styleUrls: ['./left-side-container.component.scss']
})
export class LeftSideContainerComponent {
  @Input() showBackButton: boolean;
  @Input() backButtonUrl: string;
  @Input() title: string;
  private _location: Location = inject(Location);
  private _router: Router = inject(Router);

  goBack(): void {
    this.backButtonUrl ? this._router.navigate([this.backButtonUrl]) : this._location.back();
  }
}
