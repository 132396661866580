import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutingPaths } from '@base/app-routing.paths';
import { Module } from '@models/classes/module.model';
import { Transformation } from '@models/classes/transformation.model';
import { AppIcon } from '@models/enums/app.icons';

type PlaybookMode = 'edit' | 'view' | 'overview';

@Component({
  selector: 'app-module-playbook',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './module-playbook.component.html',
  styleUrls: ['./module-playbook.component.scss']
})
export class ModulePlaybookComponent {
  @Input() module: Module;
  @Input() transformation: Transformation;
  @Input() mode: PlaybookMode = 'view';

  readonly PAGE_KEY: string = 'MODULES.';
  readonly AppIcon = AppIcon;

  private readonly _router: Router = inject(Router);

  onClick(): void {
    let url;
    if (this.transformation && this.mode == 'view') {
      url = AppRoutingPaths.CLIENT_TRANSFORMATIONS_MODULE_PLAYBOOK.replace(':id', this.transformation.id);
    } else if (this.module && this.mode == 'edit') {
      url = AppRoutingPaths.ADMIN_MODULES_PLAYBOOK.replace(':id', this.module.id);
    } else if (this.module && this.mode == 'view') {
      url = AppRoutingPaths.ADMIN_MODULES_PLAYBOOK_PREVIEW.replace(':id', this.module.id);
    }
    this._router.navigateByUrl(url);
  }
}
