import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, Signal, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { debounceTime } from 'rxjs';

import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { getBlockIdentifier } from '@misc/helpers';
import { getModuleElementPath } from '@misc/helpers/module-helpers';
import { ISegmentAnswerValue, SegmentAnswer } from '@models/classes/module-answer.model';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { AppIcon } from '@models/enums/app.icons';
import { BaseBlockComponent } from '@shared/blocks/block/base-block.component';
import {
  BlockAnswerOption,
  BlockCluster,
  BlockClusterOption,
  BlockNineFieldMatrix,
  BlockNineFieldMatrixOption,
  BlockType
} from '@shared/blocks/models/block.model';

interface IDragItemOffset {
  x: number;
  y: number;
}

@Component({
  selector: 'app-block-nine-field-matrix',
  templateUrl: './block-nine-field-matrix.component.html',
  styleUrls: ['../../styles/block-common.scss', './block-nine-field-matrix.component.scss']
})
export class BlockNineFieldMatrixComponent extends BaseBlockComponent<BlockNineFieldMatrix> implements OnInit {
  @ViewChild('dropArea') dropArea: ElementRef<HTMLDivElement>;
  readonly pageKey: string = 'BLOCK.NINE_FIELD_MATRIX.';
  readonly dropAreaId: string = 'nineFieldMatrixDropArea';
  readonly AppIcon = AppIcon;
  readonly BooleanFieldType = BooleanFieldType;
  readonly dynamicOptionsBlockIdControl: FormControl<string> = new FormControl<string>('');
  readonly dynamicOptionsToggle: FormControl<boolean> = new FormControl<boolean>(false);
  readonly singleSelectionToggle: FormControl<boolean> = new FormControl<boolean>(false);
  readonly multiSelectionToggle: FormControl<boolean> = new FormControl<boolean>(false);
  readonly dragItemOffset: IDragItemOffset = { x: 0, y: 0 };
  readonly newOptionName: WritableSignal<string> = signal('');
  readonly dynamicOptionsBlockId: Signal<string> = toSignal(this.dynamicOptionsBlockIdControl.valueChanges);
  readonly dynamicOptionsProvider: Signal<ModuleSegment> = computed(() => {
    return getModuleElementPath(this._moduleService.module(), this.dynamicOptionsBlockId()).segment?.item;
  });
  readonly isDynamicOptionsBlockIdValid: Signal<boolean> = computed(() => {
    const segment: ModuleSegment = this.dynamicOptionsProvider();
    return (
      this.dynamicOptionsBlockId() && segment && [BlockType.SELECTION, BlockType.LISTING, BlockType.CLUSTER].includes(segment.template)
    );
  });
  readonly clusterOptions: Signal<BlockClusterOption[]> = computed(() => {
    const clusterBlock = this.dynamicOptionsProvider().value as BlockCluster;
    let options = clusterBlock.options;

    if (clusterBlock.dynamicOptionsBlockId && clusterBlock.isDynamicOptionsEnabled) {
      const listingOptionsProvider = getModuleElementPath(this._moduleService.module(), clusterBlock.dynamicOptionsBlockId).segment?.item;
      options = listingOptionsProvider.answer.answer.content.map((item: ISegmentAnswerValue) =>
        plainToInstance(BlockClusterOption, {
          id: item.subId,
          value: item.value
        } as BlockClusterOption)
      );
    }
    return options;
  });
  readonly dynamicOptions: Signal<BlockClusterOption[]> = computed(() => {
    if (this.dynamicOptionsProvider()?.template === BlockType.CLUSTER) {
      const dynamicAnswerContent = this.dynamicOptionsProvider()?.answer?.answer?.content;
      const clusterOptions = this.clusterOptions();
      let clusterCustomOptions: BlockClusterOption[] = [];

      if (dynamicAnswerContent) {
        clusterCustomOptions = (dynamicAnswerContent[0].value as ISegmentAnswerValue[]).map((item: ISegmentAnswerValue) => ({
          id: item.subId,
          value: item.value as string,
          isCustom: true
        }));
      }

      return clusterOptions.concat(clusterCustomOptions);
    }
    return [];
  });
  readonly initialOptions: WritableSignal<BlockNineFieldMatrixOption[]> = signal([]);
  readonly selectedOptions: WritableSignal<BlockNineFieldMatrixOption[]> = signal([]);

  get dropAreaSize(): [number, number] {
    const dropAreaElement = this.dropArea.nativeElement;
    if (dropAreaElement == null) return [0, 0];

    const { offsetWidth, offsetHeight } = dropAreaElement;
    return [offsetWidth, offsetHeight];
  }

  get isSelectionEnabled(): boolean {
    return (
      (this.block.isSingleSelectionEnabled || this.block.isMultipleSelectionEnabled) &&
      !this.initialOptions().length &&
      !!this.selectedOptions().length
    );
  }

  ngOnInit(): void {
    this._initDynamicOptionsControls();
    this._setInitialSettingsControls();
    this._setOptions();
  }

  addOption(): void {
    this.block.options.push(
      plainToInstance(BlockNineFieldMatrixOption, {
        id: getBlockIdentifier(),
        value: this.newOptionName()
      })
    );
    this.newOptionName.set('');
    this.save();
  }

  deleteOption(idx: number): void {
    this.block.options.splice(idx, 1);
    this.save();
  }

  closeSetting(): void {
    this._setInitialSettingsControls();
  }

  saveSetting(): void {
    this.block.isDynamicOptionsEnabled = this.dynamicOptionsToggle.value;
    this.block.isSingleSelectionEnabled = this.singleSelectionToggle.value;
    this.block.isMultipleSelectionEnabled = this.multiSelectionToggle.value;
    this.block.isInstructionEnabled = this.updatedInstructionSettings;
    this.block.styleSettings = this.updatedStyleSettings;
    this.segment.cql = this.updatedCqlSettings.cql;
    this.segment.isInPlaybook = this.updatedPlaybookSettings.isInPlaybook;
    this.save();
  }

  onDragStart(mouseEvent: MouseEvent): void {
    const optionRect: DOMRect = (mouseEvent.currentTarget as HTMLElement).getBoundingClientRect();
    const { x: mouseX, y: mouseY } = mouseEvent;
    const { x: optionX, y: optionY } = optionRect;
    this.dragItemOffset.x = mouseX - optionX;
    this.dragItemOffset.y = mouseY - optionY;
  }

  onDropItem(event: CdkDragDrop<BlockNineFieldMatrixOption[]>): void {
    if (!event.isPointerOverContainer) {
      // Slightly move to force applying previous position styles
      event.item.data.x += 1e-10;
      event.item.data.y += 1e-10;
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousContainer.data.indexOf(event.item.data),
        event.currentIndex
      );
    }

    if (event.container.element.nativeElement.id === this.dropAreaId) {
      const mouseEvent: MouseEvent = event.event as MouseEvent;
      const [x, y]: [number, number] = this.getPercentagePosition(
        mouseEvent.offsetX - this.dragItemOffset.x,
        mouseEvent.offsetY - this.dragItemOffset.y
      );

      event.item.data.x = x < 0 ? 0 : x;
      event.item.data.y = y < 0 ? 0 : y;

      this.saveAnswer();
    }
  }

  toggleSelection(option: BlockNineFieldMatrixOption): void {
    if (!this.isSelectionEnabled) return;
    const { isChecked } = option;

    if (this.block.isSingleSelectionEnabled) {
      this.selectedOptions().forEach((option: BlockNineFieldMatrixOption) => (option.isChecked = false));
    }

    option.isChecked = !isChecked;

    this.saveAnswer();
  }

  getPercentagePosition(x: number, y: number): [number, number] {
    const [maxWidth, maxHeight]: [number, number] = this.dropAreaSize;
    return [(100 / maxWidth) * x, (100 / maxHeight) * y];
  }

  saveAnswer(): void {
    if (this.isEditMode || !this.isUserScope || this.isUserInputDisabled) return;
    const answer: SegmentAnswer = {
      segmentId: this.block.id,
      content: this.selectedOptions().map((item: BlockNineFieldMatrixOption) => ({
        subId: item.id,
        value: item.value,
        meta: {
          x: item.x,
          y: item.y,
          isChecked: item.isChecked,
          groupId: item.groupId
        } as Partial<BlockNineFieldMatrixOption>
      }))
    };

    this._transformationService.saveSegmentAnswer(this.segment.id, answer, this.segment?.answer?.id);
  }

  getOptionGroup(groupId: string): BlockClusterOption {
    return this.dynamicOptions().find((item: BlockClusterOption) => item.id === groupId);
  }

  private _initDynamicOptionsControls(): void {
    this.dynamicOptionsBlockIdControl.setValue(this.block.dynamicOptionsBlockId);
    this.dynamicOptionsBlockIdControl.valueChanges.pipe(debounceTime(300)).subscribe((blockId: string): void => {
      if (this.isDynamicOptionsBlockIdValid()) {
        this.block.dynamicOptionsBlockId = blockId;
        this.save();
      }
    });
    this._moduleService.answerUpdated$.subscribe(() => this._setOptions());
  }

  private _setOptions(): void {
    if (this.segmentAnswer?.content) {
      this.selectedOptions.set(
        this.segmentAnswer.content.map(
          (item: ISegmentAnswerValue<Partial<BlockNineFieldMatrixOption>>): BlockNineFieldMatrixOption => ({
            id: item.subId,
            value: item.value as string,
            x: item.meta.x,
            y: item.meta.y,
            isChecked: item.meta.isChecked,
            groupId: item.meta.groupId
          })
        )
      );
    }

    if (this.block.isDynamicOptionsEnabled && this.isDynamicOptionsBlockIdValid()) {
      const providerAnswerContent = this.dynamicOptionsProvider()?.answer?.answer?.content;

      if (providerAnswerContent) {
        if (this.dynamicOptionsProvider().template === BlockType.CLUSTER) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [customOptions, selectedOptions]: ISegmentAnswerValue<any>[] = providerAnswerContent;

          this.initialOptions.set(
            (selectedOptions.value as ISegmentAnswerValue[])
              .map(
                (item: ISegmentAnswerValue<Partial<BlockAnswerOption>>): BlockNineFieldMatrixOption => ({
                  id: item.subId,
                  value: item.value as string,
                  groupId: item.meta.groupId
                })
              )
              .filter(
                (initial: BlockNineFieldMatrixOption) =>
                  !this.selectedOptions().some((selected: BlockNineFieldMatrixOption) => selected.id === initial.id)
              )
          );
        } else {
          this.initialOptions.set(
            providerAnswerContent
              .map((item: ISegmentAnswerValue) =>
                plainToInstance(BlockClusterOption, {
                  id: item.subId,
                  value: item.value
                } as BlockNineFieldMatrixOption)
              )
              .filter(
                (initial: BlockClusterOption) =>
                  !this.selectedOptions().some((selected: BlockNineFieldMatrixOption) => selected.id === initial.id)
              )
          );
        }
      }
    } else {
      this.initialOptions.set(
        this.block.options
          .map((item: BlockNineFieldMatrixOption) => plainToInstance(BlockNineFieldMatrixOption, item))
          .filter(
            (initial: BlockNineFieldMatrixOption) =>
              !this.selectedOptions().some((selected: BlockNineFieldMatrixOption) => selected.id === initial.id)
          )
      );
    }
  }

  private _setInitialSettingsControls(): void {
    this.dynamicOptionsToggle.setValue(this.block.isDynamicOptionsEnabled);
    this.singleSelectionToggle.setValue(this.block.isSingleSelectionEnabled);
    this.multiSelectionToggle.setValue(this.block.isMultipleSelectionEnabled);
  }
}
