import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { Module } from './module.model';
import { Organization } from '@models/classes/organization.model';
import { TransformationStatus } from '@models/enums/transformation-status.enum';
import { transformToModel } from '@misc/helpers/model-conversion/transform-helpers/transform-to-model.function';
import { ModuleSite } from '@models/classes/module-site.model';

@Exclude()
export class Transformation extends AbstractModel {
  @Expose()
  @Transform(transformToModel(Organization))
  organization: Organization;
  @Expose()
  @Transform(transformToModel(Module))
  module: Module;
  @Expose()
  moduleSlug: string;
  @Expose()
  @Transform(transformToModel(ModuleSite))
  current: ModuleSite;
  @Expose()
  @Transform(transformToModel(ModuleSite))
  latest: ModuleSite;
  @Expose()
  progress: number;
  @Expose()
  status: TransformationStatus;
}
