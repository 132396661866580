import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

type AnyFormControlType = FormArray<any> | FormGroup<any> | FormControl<any> | AbstractControl<any>;

export function validateControlAndChildren(control: AnyFormControlType): void {
  // First validate children if exist
  if (control instanceof FormArray) {
    for (const childControl of control.controls) {
      validateControlAndChildren(childControl);
    }
  } else if (control instanceof FormGroup) {
    for (const childControl of Object.values(control.controls)) {
      validateControlAndChildren(childControl);
    }
  }

  // Then validate control itself
  control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
}
