import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartModalComponent } from './start-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [StartModalComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule],
  exports: [CommonModule]
})
export class StartModalModule {}
