import { Exclude, Expose, Transform } from 'class-transformer';
import { ModuleElement } from '@models/classes/module-element.model';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { transformToModelsArray } from '@misc/helpers/model-conversion/transform-helpers/transform-to-models-array.function';
import { AbstractModel } from './_base.model';

@Exclude()
export class ModuleSite extends ModuleElement {
  @Expose()
  @Transform(transformToModelsArray(ModuleSegment))
  segments: ModuleSegment[];
  @Expose()
  value?: string;
  @Expose()
  step?: AbstractModel;
}
