import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppIcon } from '@base/models/enums/app.icons';

export interface IBlockAction {
  type: BlockActionType;
  icon: AppIcon | string;
}

export type BlockActionType = 'copy' | 'delete' | 'settings' | 'undo';

@Component({
  selector: 'app-block-wrapper',
  templateUrl: './block-wrapper.component.html',
  styleUrls: ['./block-wrapper.component.scss']
})
export class BlockWrapperComponent implements OnInit {
  @Output() dragged: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() actionClick: EventEmitter<BlockActionType> = new EventEmitter<BlockActionType>();

  @Input() isDraggable: boolean;
  @Input() isEditIconShown: boolean = true;
  @Input() isReadOnly: boolean;
  @Input() actionTypes: BlockActionType[];

  readonly AppIcon = AppIcon;

  protected _actions: IBlockAction[];

  ngOnInit(): void {
    this._setActions();
  }

  private _setActions(): void {
    this._actions = this.actionTypes
      ? this._getInitialActionsList().filter((item: IBlockAction) => this.actionTypes.includes(item.type))
      : [];
  }

  handleActionClick(type: BlockActionType): void {
    this.actionClick.emit(type);
  }

  private _getInitialActionsList(): IBlockAction[] {
    return [
      {
        type: 'undo',
        icon: 'undo'
      },
      {
        type: 'copy',
        icon: 'copy'
      },
      {
        type: 'delete',
        icon: 'trash'
      },
      {
        type: 'settings',
        icon: 'cog'
      }
    ];
  }
}
