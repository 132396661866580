<div
  #resizableFrame
  (click)="$event.stopImmediatePropagation()"
  [ngStyle]="{ maxWidth: width + 'px', height: height + 'px', minWidth: minWidth + 'px', minHeight: minHeight + 'px' }"
  class="resizable"
  tabindex="0"
>
  <ng-content></ng-content>

  <ng-container *ngIf="!hideControls">
    <div [hidden]="!isResizing" class="resizable__overlay"></div>
    <div #resizeBarX [hidden]="!showX" class="resizable__bar resizable__bar_x"></div>
    <div #resizeBarY [hidden]="!showY" class="resizable__bar resizable__bar_y"></div>
  </ng-container>
</div>
