<app-block-wrapper
  (actionClick)="actionClickHandler($event)"
  (dragged)="dragged.emit($event)"
  *ngIf="isEditMode; else viewModeTemplate"
  [actionTypes]="actionTypes"
  [isDraggable]="isDraggable"
  [isReadOnly]="isReadOnly"
>
  <div class="block-meta">
    <div class="block-meta__query">
      <mat-icon [svgIcon]="AppIcon.QUERY"></mat-icon>
      {{ segment.cql }}
    </div>
    <app-block-id [blockID]="anyBlock.id" [mode]="'full'"></app-block-id>
  </div>
  <ng-container *ngTemplateOutlet="blocksTemplate"></ng-container>
</app-block-wrapper>

<ng-template #viewModeTemplate>
  <ng-container *ngTemplateOutlet="blocksTemplate"></ng-container>
</ng-template>

<ng-template #blocksTemplate>
  <ng-container [ngSwitch]="segment.template">
    <app-block-text
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.TEXT"
      [block]="blockText"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-text>
    <app-block-columns
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.TEXT_TWO_COLUMNS"
      [block]="blockColumns"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-columns>
    <app-block-image
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.IMAGE"
      [block]="blockImage"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-image>
    <app-block-columns
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.TEXT_IMAGE"
      [block]="blockColumns"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-columns>
    <app-block-columns
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.IMAGE_TEXT"
      [block]="blockColumns"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-columns>
    <app-block-video
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.VIDEO"
      [block]="blockVideo"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-video>
    <app-block-embedded
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.EMBEDDED"
      [block]="blockEmbedded"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-embedded>
    <app-block-info-box
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.INFO_BOX"
      [block]="blockInfoBox"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-info-box>
    <app-block-table
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.TABLE"
      [block]="blockTable"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-table>
    <app-block-single-choice
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.SINGLE_CHOICE"
      [block]="blockSingleChoice"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-single-choice>
    <app-block-input
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.INPUT"
      [block]="blockInput"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-input>
    <app-block-selection
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.SELECTION"
      [block]="blockSelection"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-selection>
    <app-block-slider
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.SLIDER"
      [block]="blockSlider"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-slider>
    <app-block-question
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.QUESTION"
      [block]="blockQuestion"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-question>
    <app-block-role-input
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.ROLE_INPUT"
      [block]="blockRoleInput"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-role-input>
    <app-block-input-selection
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.INPUT_SELECTION"
      [block]="blockInputSelection"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-input-selection>
    <app-block-readonly
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.READONLY"
      [block]="blockReadonly"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-readonly>
    <app-block-nine-field-matrix
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.NINE_FIELD_MATRIX"
      [block]="blockNineFieldMatrix"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-nine-field-matrix>
    <app-block-listing
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.LISTING"
      [block]="blockListing"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-listing>
    <app-block-cluster
      #blockComponent
      (dataChanged)="updateSegment($event)"
      *ngSwitchCase="BlockType.CLUSTER"
      [block]="blockCluster"
      [isEditMode]="isEditMode"
      [isOpenSetting]="isOpenSetting$"
      [isReadonly]="isReadOnly"
      [segment]="segment"
    ></app-block-cluster>
  </ng-container>
</ng-template>
