import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, computed, inject, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { generateKeyBetween } from 'fractional-indexing';

import { fadeInOut } from '@base/animations/appearance.animations';
import { updateBlockIdentifiers } from '@base/misc/helpers';
import { evaluate } from '@misc/helpers/cql/cql-parser';
import { SegmentIdentifierResolver } from '@misc/helpers/cql/segment-cql-identifier-resolver';
import { foreachModuleElement } from '@misc/helpers/foreach-module-element';
import { ModuleSegment } from '@models/classes/module-segment.model';
import { SegmentsApiService } from '@services/api/admin/segments/segments-api.service';
import { ModuleService } from '@services/module/module.service';
import { BlockActionType } from '@shared/blocks/block/block-wrapper/block-wrapper.component';
import { ModalService } from '@shared/modal/modal.service';
import { BaseComponent } from '../../components/base/base.component';

@Component({
  selector: 'app-blocks-container',
  templateUrl: './blocks-container.component.html',
  styleUrls: ['./blocks-container.component.scss'],
  animations: [fadeInOut(300)]
})
export class BlocksContainerComponent extends BaseComponent {
  readonly segments = input.required<ModuleSegment[]>();
  readonly isEditMode = input<boolean>(false);
  readonly isPlaybookMode = input<boolean>(false);
  readonly evaluateVisibility = input<boolean>(false);

  private readonly _segmentsApi: SegmentsApiService = inject(SegmentsApiService);
  private readonly _moduleService: ModuleService = inject(ModuleService);
  private readonly _modalService: ModalService = inject(ModalService);
  private readonly _translate: TranslateService = inject(TranslateService);

  private readonly _module = this._moduleService.module;
  private readonly _site = this._moduleService.currentSite;
  private readonly _isEditModeEnabled = this._moduleService.isEditModeEnabled;

  private readonly _allSegments = computed<ModuleSegment[]>(() => {
    const allSegments: ModuleSegment[] = [];
    foreachModuleElement(this._module(), null, null, null, (segment: ModuleSegment) => {
      allSegments.push(segment);
    });
    return allSegments;
  });

  readonly visibleSegments = computed<ModuleSegment[]>(() => {
    const segments = this.segments();
    if (this.evaluateVisibility()) {
      return segments.filter((segment: ModuleSegment) => {
        return this._isVisibleFromCqlEvaluation(segment, this._allSegments());
      });
    } else {
      return [...segments];
    }
  });

  readonly isReadOnly = computed<boolean>(() => {
    return this.isPlaybookMode() || !this._isEditModeEnabled();
  });

  dropBlock(event: CdkDragDrop<ModuleSegment[], ModuleSegment[], any>): void {
    if (event.previousContainer === event.container) {
      this._updateBlockPosition(event);
    } else {
      this._addNewBlock(event);
    }
  }

  handleActionClick(event: BlockActionType, index: number): void {
    switch (event) {
      case 'copy': {
        this._copyBlock(index);
        break;
      }
      case 'delete': {
        this._deleteBlock(index);
        break;
      }
      case 'settings': {
        this._openSetting();
        break;
      }
      case 'undo': {
        break;
      }
    }
  }

  private _updateBlockPosition(event: CdkDragDrop<ModuleSegment[]>): void {
    if (event.previousIndex === event.currentIndex) return;

    const module = this._moduleService.module();
    if (module == null) return;

    const site = this._site();
    if (site == null) return;

    const visibleSegments = this.visibleSegments();
    const segment = visibleSegments[event.previousIndex];
    const indexA = event.currentIndex < event.previousIndex ? event.currentIndex - 1 : event.currentIndex;
    const indexB = event.currentIndex < event.previousIndex ? event.currentIndex : event.currentIndex + 1;
    const position = generateKeyBetween(visibleSegments[indexA]?.position, visibleSegments[indexB]?.position);
    segment.position = position;
    moveItemInArray(visibleSegments, event.previousIndex, event.currentIndex);
    moveItemInArray(site.segments, event.previousIndex, event.currentIndex);

    this._segmentsApi
      .updateItemEntity(this._moduleService.currentModule.id, {
        id: segment.id,
        position,
        site: { id: site.id }
      })
      .subscribe((newSegment: ModuleSegment) => {
        const index = site.segments.findIndex((s: ModuleSegment) => s.id === segment.id);
        site.segments[index] = newSegment;
        this._moduleService.updateLocalModule();
      });
  }

  private _addNewBlock(event: CdkDragDrop<ModuleSegment[]>): void {
    const module = this._moduleService.module();
    if (module == null) return;

    const site = this._site();
    if (site == null) return;

    const segments = this.visibleSegments();
    const position = generateKeyBetween(segments[event.currentIndex - 1]?.position, segments[event.currentIndex]?.position);
    const segment: ModuleSegment = plainToInstance(ModuleSegment, {
      ...event.previousContainer.data[event.previousIndex],
      position,
      site: { id: site.id }
    });
    segments.splice(event.currentIndex, 0, segment);
    this._segmentsApi
      .createItemEntity(this._moduleService.currentModule.id, instanceToPlain(segment))
      .subscribe((newSegment: ModuleSegment): void => {
        Object.assign(segment, newSegment);
        site.segments.splice(event.currentIndex, 0, segment);
        this._moduleService.updateLocalModule();
      });
  }

  private _copyBlock(index: number): void {
    const site = this._site();
    if (site == null) return;

    const segments = this.visibleSegments();
    const position = generateKeyBetween(segments[index]?.position, segments[index + 1]?.position);
    const segment: ModuleSegment = plainToInstance(ModuleSegment, {
      ...segments[index],
      position,
      site: { id: site.id }
    });

    updateBlockIdentifiers(segment);

    segments.splice(index + 1, 0, segment);
    this._segmentsApi
      .createItemEntity(this._moduleService.currentModule.id, instanceToPlain(segment))
      .subscribe((res: ModuleSegment): void => {
        Object.assign(segment, res);
        site.segments.splice(index + 1, 0, segment);
        this._moduleService.updateLocalModule();
      });
  }

  private _deleteBlock(index: number): void {
    const module = this._moduleService.module();
    if (module == null) return;

    const relatedSegments: ModuleSegment[] = [];
    foreachModuleElement(module, null, null, null, (segment: ModuleSegment) => {
      if (segment.cql?.includes(this.segments()[index].value.id)) relatedSegments.push(segment);
    });
    const relatedBlocksId: string[] = relatedSegments?.map((item: ModuleSegment) => item.value.id);
    const deleteBlock = (): void => {
      this._segmentsApi.deleteItemEntity(module.id, this.segments()[index].id).subscribe((): void => {
        this._site().segments.splice(index, 1);
        this._moduleService.updateLocalModule();
      });
    };

    if (relatedSegments.length) {
      this._modalService
        .open(
          {
            title: this._translate.instant(`MODALS.SEGMENT_DELETE_QUERY_WARNING`, { blockIds: relatedBlocksId.join(', ') }),
            actions: [
              { name: 'BUTTON_NAME.CANCEL', type: 'close', color: 'accent' },
              { name: 'BUTTON_NAME.OK', type: 'submit', color: 'primary', value: true }
            ]
          },
          {
            maxWidth: '45rem'
          }
        )
        .subscribe(() => deleteBlock());
    } else {
      deleteBlock();
    }
  }

  private _openSetting(): void {}

  private _isVisibleFromCqlEvaluation(segment: ModuleSegment, allSegments: ModuleSegment[]): boolean {
    if (!segment?.cql) {
      return true;
    }

    return evaluate(segment.cql, new SegmentIdentifierResolver(allSegments));
  }
}
