import { AbstractModel } from './_base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { OrganizationUser } from './organization-user.model';
import { OrganizationModule } from './organization-module.model';
import { transformToModelsArray } from '@misc/helpers/model-conversion/transform-helpers';
import { User } from './user.model';

@Exclude()
export class Organization extends AbstractModel {
  @Expose()
  name: string;
  @Expose()
  @Transform(transformToModelsArray(OrganizationUser))
  users: OrganizationUser[];
  @Expose()
  isDefault: boolean;
  @Expose()
  @Transform(transformToModelsArray(OrganizationModule))
  organizationModules: OrganizationModule[];
  @Expose()
  @Transform(({ obj }) => obj.users.length)
  numberOfMembers: number;
  @Expose()
  emailOfOwner?: string;

  @Expose()
  userList?: User[];
}
