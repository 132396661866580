<div class="bread-crumbs body-7">
  <div
    *ngFor="let item of breadcrumbs; let last = last"
    [class.bread-crumbs__item_is-link]="!!item.route?.length"
    class="bread-crumbs__item"
  >
    <span [routerLink]="item.route" class="bread-crumbs__name">
      <mat-icon *ngIf="item.icon" [svgIcon]="item.icon" class="bread-crumbs__icon-first"></mat-icon>
      <cropped-text *ngIf="item.name" [text]="item.name | translate"></cropped-text>
    </span>
    <mat-icon *ngIf="!last" class="bread-crumbs__icon">keyboard_arrow_right</mat-icon>
  </div>
</div>
