import { Exclude, Expose, Transform } from 'class-transformer';
import { ModuleSection } from '@models/classes/module-section.model';
import { ModuleElement } from '@models/classes/module-element.model';
import { transformToModelsArray } from '@misc/helpers/model-conversion/transform-helpers/transform-to-models-array.function';

@Exclude()
export class Module extends ModuleElement {
  @Expose()
  value?: string;
  @Expose()
  activeTransformations?: number;
  @Expose()
  cummulatedTransformations?: number;
  @Expose()
  isDraft: boolean;
  @Expose()
  isAnnounced?: boolean;
  @Expose()
  @Transform(transformToModelsArray(ModuleSection))
  sections?: ModuleSection[];
}
