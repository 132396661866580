import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { WebcamImage } from 'ngx-webcam';
import { Subject } from 'rxjs';

import { BaseFormAbstractComponent } from '@misc/abstracts/base-form.abstract.component';
import { blobToDataUrl } from '@misc/helpers/blob-to-dataurl';
import { AppIcon } from '@models/enums/app.icons';
import { FileType } from '@models/enums/file-type.enum';
import { IAvatar } from '@models/interfaces/avatar.interface';
import { IModalComponentContext, ModalComponent } from '@shared/modal/modal.component';

@Component({
  selector: 'app-avatar-edit-modal',
  templateUrl: './avatar-edit-modal.component.html',
  styleUrls: ['./avatar-edit-modal.component.scss']
})
export class AvatarEditModalComponent extends BaseFormAbstractComponent implements OnInit {
  @Input() context: IModalComponentContext<IAvatar>;
  @ViewChild('inputPhoto') inputPhoto: ElementRef<HTMLInputElement>;
  control: FormControl<string>;
  isTakePhoto: boolean = false;
  imageChangedEvent: any = '';
  readonly AppIcon = AppIcon;
  takePhotoWeb: any = new Subject();

  constructor(
    private _translate: TranslateService,
    private _fb: FormBuilder
  ) {
    super();
  }

  get avatar(): IAvatar {
    return this.context?.entity;
  }

  get defaultValues(): IAvatar {
    return {
      src: this.avatar?.src || '',
      types: this.avatar?.types || [FileType.png, FileType.jpeg, FileType.jpg]
    } as IAvatar;
  }

  get dialog(): MatDialogRef<ModalComponent<IAvatar>> {
    return this.context?.dialog;
  }

  getFiles(event: Event): File[] {
    return Array.from((event.target as HTMLInputElement).files ?? []).map((file: File): File => file);
  }

  async fileChangeEvent(event: Event): Promise<any> {
    const img = this.getFiles(event)?.[0];
    this.control.setValue(await blobToDataUrl(img));
    this.focusToSubmit();
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.getControl('src').setValue(event.base64);
  }

  ngOnInit(): void {
    this.control = new FormControl<string>(this.defaultValues?.src);
    this._initForm();
  }

  getModalResult(): IAvatar {
    return this.formGroup.getRawValue() as IAvatar;
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.dialog.close(this.getModalResult());
  }

  takePhoto(): void {
    if (!this.isTakePhoto) {
      this.isTakePhoto = true;
      this.dialog.componentInstance.data = {
        ...this.dialog.componentInstance.data,
        title: 'MODALS.PHOTO_HEADER',
        actions: null
      };
    } else {
      this.dialog.componentInstance.data = {
        ...this.dialog.componentInstance.data,
        title: 'MODALS.AVATAR_HEADER',
        actions: [
          { name: 'BUTTON_NAME.CANCEL', type: 'close', color: 'accent' },
          { name: 'BUTTON_NAME.SAVE', type: 'submit', color: 'primary' }
        ]
      };
      this.takePhotoWeb.next();
      this.isTakePhoto = false;
    }
  }

  saveImage(event: WebcamImage): void {
    this.control.setValue(event.imageAsDataUrl);
  }

  focusToSubmit(): void {
    (document.querySelector('.modal-actions__button-submit') as HTMLButtonElement)?.focus?.();
  }

  private _initForm(): void {
    this.formGroup = this._fb.group({
      src: this._fb.control(this.defaultValues?.src, Validators.compose([Validators.required]))
    });
  }
}
