import { Injectable } from '@angular/core';
import { ApiBaseAbstractService } from '@services/api/api-base.abstract.service';
import { ClassConstructor } from 'class-transformer';
import { Organization } from '@models/classes/organization.model';
import { IServicesConfig } from '@services/http/http.service';
import { Observable } from 'rxjs';
import { toModel } from '@misc/rxjs-operators/to-model.operator';

@Injectable({
  providedIn: 'root'
})
export class AdminOrganizationsService extends ApiBaseAbstractService<Organization> {
  protected readonly _URL_PATH: string = '/admin/organizations';
  protected readonly _MODEL: ClassConstructor<Organization> = Organization;

  addUser(
    orgId: string,
    userId: string,
    params?: {
      isOwner: boolean;
    },
    servicesConfig?: IServicesConfig
  ): Observable<Organization> {
    return this._http.put(`${this.url}/${orgId}/user/${userId}`, null, { params }, servicesConfig).pipe(toModel(this._MODEL));
  }
}
