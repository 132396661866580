<div class="set-new-password">
  <app-password-validation class="set-new-password__validation" [formGroup]="formGroup"></app-password-validation>
  <base-form-input
    class="set-new-password__input"
    [inputType]="INPUT_TYPE.password"
    [control]="form.password"
    [hideLabel]="true"
    [isShowError]="false"
    [placeholder]="'INPUT_NAME.NEW_PASSWORD' | translate"
  ></base-form-input>
  <base-form-input
    class="set-new-password__input"
    [inputType]="INPUT_TYPE.password"
    [control]="form.passwordConfirm"
    [hideLabel]="true"
    [isShowError]="false"
    [placeholder]="'INPUT_NAME.NEW_PASSWORD_CONFIRM' | translate"
  ></base-form-input>
</div>
