import { Component, Input } from '@angular/core';
import { hexToRgb } from '@misc/helpers/hex-to-rgb.function';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() color: string;
  @Input() bgColor: string;
  @Input() value: number;
  @Input() label: string;

  get rgb(): string {
    return hexToRgb(this.color).join(', ');
  }
}
