import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITableButton } from '@models/interfaces/table-button.interface';

@Component({
  selector: 'app-table-buttons',
  templateUrl: './table-buttons.component.html',
  styleUrls: ['./table-buttons.component.scss']
})
export class TableButtonsComponent {
  @Input() buttons: ITableButton[];
  @Input() logical: boolean = false;
  @Output() clickButton: EventEmitter<string> = new EventEmitter<string>();

  click(event: MouseEvent, button: ITableButton): void {
    event.stopPropagation();
    let buttonType: string = button.type;

    if (button.secondType && !this.logical) {
      buttonType = button.secondType;
    }

    this.clickButton.emit(buttonType);
  }
}
