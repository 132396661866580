import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClickOutsideDirective } from '@directives/click-outside/click-outside.directive';
import { HasPermissionsDirective } from '@directives/has-permissions/has-permissions.directive';
import { ShowForRolesDirective } from '@directives/show-for-roles/show-for-roles.directive';

@NgModule({
  declarations: [ShowForRolesDirective, HasPermissionsDirective],
  imports: [CommonModule, ClickOutsideDirective],
  exports: [ClickOutsideDirective, ShowForRolesDirective, HasPermissionsDirective]
})
export class DirectivesModule {}
