import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, fromEvent, switchMap, tap } from 'rxjs';
import { auditTime, filter, map, takeUntil } from 'rxjs/operators';

import { MAX_VIDEO_WIDTH } from '@misc/constants/_base.constant';
import { BaseComponent } from '@shared/components/base/base.component';

export interface IResizableResult {
  width: number;
  height: number;
}

@Component({
  selector: 'app-resizable-element',
  templateUrl: './resizable-element.component.html',
  styleUrls: ['./resizable-element.component.scss']
})
export class ResizableElementComponent extends BaseComponent implements AfterViewInit {
  @Input() hideControls: boolean;
  @Input() showX: boolean;
  @Input() showY: boolean = true;
  @Output() sizeChanged: EventEmitter<IResizableResult> = new EventEmitter<IResizableResult>();
  @ViewChild('resizableFrame') resizableFrame: ElementRef;
  @ViewChild('resizeBarX') resizeBarX: ElementRef;
  @ViewChild('resizeBarY') resizeBarY: ElementRef;
  @Input() maxWidth: number = MAX_VIDEO_WIDTH;
  @Input() minWidth: number = 300;
  @Input() minHeight: number = 100;
  @Input() width: number;
  @Input() height: number = 100;
  isResizing: boolean;
  private _document: Document = inject(DOCUMENT);
  private _elementRef: ElementRef = inject(ElementRef);

  get maxWidthLimit(): number {
    return this._elementRef.nativeElement.offsetWidth < this.maxWidth ? this._elementRef.nativeElement.offsetWidth : this.maxWidth;
  }

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this._elementRef.nativeElement, 'click')
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((e: MouseEvent) => e.stopPropagation());

    const mouseMove$ = fromEvent<MouseEvent>(this._document, 'mousemove').pipe(takeUntilDestroyed(this._destroyRef));
    const mouseUp$ = fromEvent<MouseEvent>(this._document, 'mouseup').pipe(takeUntilDestroyed(this._destroyRef));
    const mouseDown$ = fromEvent<MouseEvent>(this.resizableFrame.nativeElement, 'mousedown').pipe(takeUntilDestroyed(this._destroyRef));
    let frameRect: DOMRect;

    const dragStart$ = mouseDown$;
    const dragMove$ = dragStart$.pipe(
      tap(() => (frameRect = this.resizableFrame.nativeElement.getBoundingClientRect())),
      switchMap((start: MouseEvent) =>
        mouseMove$.pipe(
          tap(() => (this.isResizing = true)),
          map((moveEvent: MouseEvent) => {
            return {
              startEvent: start,
              deltaX: moveEvent.pageX - start.pageX,
              deltaY: moveEvent.pageY - start.pageY,
              startOffsetX: start.offsetX,
              startOffsetY: start.offsetY
            };
          }),
          takeUntil(mouseUp$.pipe(tap(() => (this.isResizing = false))))
        )
      )
    );

    dragMove$
      .pipe(
        auditTime(50),
        map((move: { startEvent: MouseEvent; deltaX: number; deltaY: number; startOffsetX: number; startOffsetY: number }) => {
          let width = frameRect.width;
          let height = frameRect.height;

          if (move.startEvent.target === this.resizeBarY.nativeElement) {
            height = frameRect.height + move.deltaY;
            this.height = height;
          }
          if (move.startEvent.target === this.resizeBarX.nativeElement) {
            width = frameRect.width + move.deltaX <= this.maxWidthLimit ? frameRect.width + move.deltaX : this.maxWidthLimit;
            this.width = width;
          }

          return { width, height };
        }),
        filter(({ width, height }: IResizableResult) => width >= this.minWidth && height >= this.minHeight),
        debounceTime(700)
      )
      .subscribe((result: IResizableResult) => this.sizeChanged.emit(result));
  }
}
