<section class="file-upload">
  <ng-container *ngIf="!previewUrl; else uploadingProgress">
    <input #fileInput (change)="handleFilesSelected($event)" [accept]="mimeType" class="file-upload__input" type="file" />

    <div
      (dragenter)="onDragEnter($event)"
      (dragleave)="onDragLeave($event)"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event)"
      [class.drag-over]="isDragOver"
      class="file-upload__drop-area"
    >
      <mat-icon [svgIcon]="icon" class="file-upload__icon"></mat-icon>
      <p class="file-upload__text">{{ placeholder | translate }}</p>
      <p class="file-upload__text">{{ getI18Key('OR') | translate }}</p>
      <button (click)="fileInput.click()" class="file-upload__btn" color="primary" mat-flat-button>{{ buttonTitle | translate }}</button>
    </div>
  </ng-container>

  <ng-template #uploadingProgress>
    <ul class="file-upload__list">
      <li class="file">
        <div class="file__info">
          <mat-icon [svgIcon]="icon" class="file__icon"></mat-icon>
          <span class="file__name">{{ uploadItem?.name }}</span>
        </div>
        <div class="file__progress">
          <mat-progress-bar [color]="'primary'" [value]="uploadItem?.progress"></mat-progress-bar>
          <span class="file__percentage">{{ uploadItem?.progress }}%</span>
        </div>
      </li>
    </ul>

    <button (click)="cancelUpload()" class="file-upload__btn file__btn" color="primary" mat-flat-button>
      {{ getI18Key('CANCEL') | translate }}
    </button>
  </ng-template>
</section>
