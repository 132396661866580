import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BehaviorSubject, map, mergeMap, Observable, of, tap } from 'rxjs';

import { AppRoutingPaths } from '@base/app-routing.paths';
import { toMutableSignal } from '@base/misc/helpers';
import { convertToModel } from '@misc/helpers/model-conversion/convert-to-model.function';
import { getModuleElementPath } from '@misc/helpers/module-helpers';
import { ModuleAnswer, SegmentAnswer } from '@models/classes/module-answer.model';
import { ModuleMedia } from '@models/classes/module-media.model';
import { ModuleSite } from '@models/classes/module-site.model';
import { ModuleStep } from '@models/classes/module-step.model';
import { Playbook } from '@models/classes/playbook.model';
import { Transformation } from '@models/classes/transformation.model';
import { TransformationStatus } from '@models/enums/transformation-status.enum';
import { TransformationsApiService } from '@services/api/transformations/transformations-api.service';
import { RoleSwitcherService } from '@services/role-switcher/role-switcher.service';
import { ModuleService } from '../module/module.service';

@Injectable({
  providedIn: 'root'
})
export class TransformationService {
  readonly TRANSFORMATION$: BehaviorSubject<Transformation> = new BehaviorSubject<Transformation>(null);
  readonly PLAYBOOK$: BehaviorSubject<Playbook> = new BehaviorSubject<Playbook>(null);
  private _transformationApi: TransformationsApiService = inject(TransformationsApiService);
  private _router: Router = inject(Router);
  private _roleSwitcher: RoleSwitcherService = inject(RoleSwitcherService);
  private _moduleService: ModuleService = inject(ModuleService);

  readonly canAcceptAnswer$: Observable<boolean> = this.TRANSFORMATION$.pipe(
    map((transformation: Transformation) => {
      if (transformation == null) return false;
      return transformation.status === TransformationStatus.IN_PROGRESS;
    })
  );

  readonly transformation: Signal<Transformation> = toMutableSignal(this.TRANSFORMATION$);
  readonly canAcceptAnswer: Signal<boolean> = toSignal(this.canAcceptAnswer$, { initialValue: false });

  get currentTransformation(): Transformation {
    return this.TRANSFORMATION$.value;
  }

  set currentTransformation(item: Transformation) {
    this.TRANSFORMATION$.next(convertToModel(item, Transformation));
  }

  get currentTransformationPlaybook(): Playbook {
    return this.PLAYBOOK$.value;
  }

  set currentTransformationPlaybook(item: Playbook) {
    this.PLAYBOOK$.next(convertToModel(item, Playbook));
  }

  get currentSite(): ModuleSite {
    return this.currentTransformation?.current;
  }

  get latestSite(): ModuleSite {
    return this.currentTransformation?.latest;
  }

  getCurrentTransformation(id: string, skipLoaderStart?: boolean): Observable<Transformation> {
    return this._transformationApi
      .getItem(id, null, { skipLoaderStart })
      .pipe(tap((res: Transformation) => (this.currentTransformation = res)));
  }

  getCurrentTransformationPlaybook(id: string, skipLoaderStart?: boolean): Observable<Playbook> {
    return this._transformationApi
      .getPlaybookItem(id, null, { skipLoaderStart })
      .pipe(tap((res: Playbook) => (this.currentTransformationPlaybook = res)));
  }

  startProgress(id: string, siteId: string): Observable<Transformation> {
    return this._transformationApi.start(id).pipe(mergeMap(() => this.updateProgress({ id: siteId }, { id: siteId })));
  }

  updateProgress(
    current: { id: string },
    latest: {
      id: string;
    } = { id: this.latestSite.id }
  ): Observable<Transformation> {
    return this._transformationApi
      .updateItem({
        id: this.currentTransformation.id,
        current: current as ModuleSite,
        latest: latest as ModuleSite
      })
      .pipe(tap((res: Transformation) => (this.currentTransformation = res)));
  }

  startTransformation(toCurrentStep?: boolean): void {
    const firstStep: ModuleStep = this.currentTransformation.module.sections[0]?.steps[0];
    const firstSite: ModuleSite = firstStep?.sites[0];
    if (!firstSite) return;

    if (this.currentTransformation.status === TransformationStatus.NOT_STARTED) {
      (this._roleSwitcher.isCoach() ? of(null) : this.startProgress(this.currentTransformation.id, firstSite.id)).subscribe((): void => {
        this._router.navigateByUrl(this.getTransformationPath(this.currentTransformation.id, firstStep.id, firstSite.id));
      });
    } else {
      this._router.navigateByUrl(
        this.getTransformationPath(
          this.currentTransformation.id,
          (toCurrentStep
            ? getModuleElementPath(this.currentTransformation.module, this.currentSite.id).step?.item
            : getModuleElementPath(this.currentTransformation.module, this.latestSite.id).step?.item
          )?.id,
          (toCurrentStep ? this.currentSite : this.latestSite)?.id
        )
      );
    }
  }

  completeTransformation(): Observable<void> {
    return this._transformationApi.complete(this.currentTransformation.id);
  }

  getTransformationPath(transformationId: string, stepId: string, siteId: string): string {
    return AppRoutingPaths.CLIENT_TRANSFORMATIONS_MODULE_SITE.replace(':id', transformationId)
      .replace(':stepId', stepId)
      .replace(':siteId', siteId);
  }

  getMediaSignedUrl(mediaId: string): Observable<ModuleMedia> {
    return this._transformationApi.getMedia(this.currentTransformation.id, mediaId, {}, { skipLoaderStart: true });
  }

  saveSegmentAnswer(segmentId: string, answer: SegmentAnswer, answerId?: string): void {
    this._transformationApi
      .createOrUpdateTransformationSegment(this.currentTransformation.id, segmentId, answer, answerId)
      .subscribe((response: ModuleAnswer) => {
        this._moduleService.updateLocalSegmentAnswer(
          segmentId,
          convertToModel(
            {
              answer,
              ...response
            },
            ModuleAnswer
          )
        );
      });
  }
}
