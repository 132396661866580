import { Exclude, Expose, Transform } from 'class-transformer';
import { transformToDate } from '@misc/helpers/model-conversion/transform-helpers/transform-to-date.function';

@Exclude()
export abstract class AbstractModel {
  @Expose()
  id: string;
  @Expose({ toClassOnly: true })
  @Transform(transformToDate)
  _createdAt?: Date;
  @Expose({ toClassOnly: true })
  @Transform(transformToDate)
  _updatedAt?: Date;
}
