<label class="base-select" for="base-select-control">
  <mat-form-field [appearance]="appearance" [class]="'base-select__field ' + classFormField" [floatLabel]="floatLabel">
    <mat-label *ngIf="!hideLabel">{{ placeholder }}</mat-label>
    <mat-select
      (valueChange)="valueChange($event)"
      [formControl]="formControl"
      [id]="id"
      [multiple]="multiple"
      [panelClass]="panelClass"
      [placeholder]="placeholder"
      [required]="required"
    >
      <mat-select-trigger *ngIf="triggerTemplate">
        <ng-container *ngTemplateOutlet="triggerTemplate; context: { $implicit: control.value }"></ng-container>
      </mat-select-trigger>
      <ng-container *ngIf="options">
        <mat-option *ngFor="let option of options" [disabled]="option.disabled" [value]="getValue(option)">
          <ng-container *ngIf="!optionTemplate; else withTemplate">
            <span [innerHTML]="getTitle(option) | translate"></span>
          </ng-container>
          <ng-template #withTemplate>
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
          </ng-template>
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="isShowError && control?.invalid && errorMessage">
      <span>{{ errorMessage }}</span>
    </mat-error>
    <div *ngIf="suffixTemplate" matSuffix>
      <ng-container *ngTemplateOutlet="suffixTemplate; context: { $implicit: control.value }"></ng-container>
    </div>
  </mat-form-field>
</label>
<ng-content></ng-content>
