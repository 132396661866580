import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RightSideNavService {
  private _currentId: string;
  template: TemplateRef<unknown>;
  readonly opened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set opened(flag: boolean) {
    setTimeout(() => this.opened$.next(flag), 100);
  }

  get opened(): boolean {
    return this.opened$.value;
  }

  setTemplate<T = unknown>(template: TemplateRef<T>): void {
    if (this.template !== template) {
      this.template = template;
    }
  }

  toggle(id?: string): void {
    if (id) {
      if (id === this._currentId) {
        this.opened = !this.opened;
      } else {
        this.opened = true;
      }

      this._currentId = id;
    } else {
      this.opened = !this.opened;
    }
  }

  close(): void {
    this.opened = false;
  }

  open(): void {
    this.opened = true;
  }
}
